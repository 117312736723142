import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { setActivationDate, setDeActivationDate } from "../../../../pages/clienti/slice"
import { format } from "date-fns"
import { Calendar } from "../../calendar"

export function RappLegaleDataIngUsc() {
    const clientiState = useAppSelector(state => state.clienti)
    const dispatch = useAppDispatch()

    return (
        <div>
            <div className="formInOut pt-2" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                <div style={{ flex: '0.5' }}>
                    <div className="text-left">
                        <span className="input-label">Data Ingresso</span>
                    </div>
                    <Calendar
                        selected={clientiState.rappLegaleEditCreateRequest.activationDate}
                        onChange={(date) => {
                            dispatch(setActivationDate(format(new Date(date), 'yyyy-MM-dd')))
                        }}
                    />

                </div>
                <div style={{ flex: '0.5', paddingBottom: 20 }}>
                    <div className="text-left">
                        <span className="input-label">Data Uscita</span>
                    </div>
                    <Calendar
                        selected={clientiState.rappLegaleEditCreateRequest.deactivationDate}
                        onChange={(date) => {
                            dispatch(setDeActivationDate(format(new Date(date), 'yyyy-MM-dd')))
                        }}
                    />
                </div>

            </div>
        </div>
    )
}