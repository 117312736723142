
import React from "react";
import { IconProps } from "./dto";

export function GestPersonaleIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" >
            <path d="M14.166 18.125H5.83268C2.15768 18.125 1.04102 17.0083 1.04102 13.3333V6.66667C1.04102 2.99167 2.15768 1.875 5.83268 1.875H14.166C17.841 1.875 18.9577 2.99167 18.9577 6.66667V13.3333C18.9577 17.0083 17.841 18.125 14.166 18.125ZM5.83268 3.125C2.84935 3.125 2.29102 3.69167 2.29102 6.66667V13.3333C2.29102 16.3083 2.84935 16.875 5.83268 16.875H14.166C17.1493 16.875 17.7077 16.3083 17.7077 13.3333V6.66667C17.7077 3.69167 17.1493 3.125 14.166 3.125H5.83268Z" fill="#D9E6E8" />
            <path d="M15.8327 7.29175H11.666C11.3243 7.29175 11.041 7.00841 11.041 6.66675C11.041 6.32508 11.3243 6.04175 11.666 6.04175H15.8327C16.1743 6.04175 16.4577 6.32508 16.4577 6.66675C16.4577 7.00841 16.1743 7.29175 15.8327 7.29175Z" fill="#D9E6E8" />
            <path d="M15.8333 10.625H12.5C12.1583 10.625 11.875 10.3417 11.875 10C11.875 9.65833 12.1583 9.375 12.5 9.375H15.8333C16.175 9.375 16.4583 9.65833 16.4583 10C16.4583 10.3417 16.175 10.625 15.8333 10.625Z" fill="#D9E6E8" />
            <path d="M15.8327 13.9583H14.166C13.8243 13.9583 13.541 13.6749 13.541 13.3333C13.541 12.9916 13.8243 12.7083 14.166 12.7083H15.8327C16.1743 12.7083 16.4577 12.9916 16.4577 13.3333C16.4577 13.6749 16.1743 13.9583 15.8327 13.9583Z" fill="#D9E6E8" />
            <path d="M7.08255 10.0333C5.90755 10.0333 4.94922 9.07493 4.94922 7.89993C4.94922 6.72493 5.90755 5.7666 7.08255 5.7666C8.25755 5.7666 9.21589 6.72493 9.21589 7.89993C9.21589 9.07493 8.25755 10.0333 7.08255 10.0333ZM7.08255 7.0166C6.59922 7.0166 6.19922 7.4166 6.19922 7.89993C6.19922 8.38327 6.59922 8.78327 7.08255 8.78327C7.56589 8.78327 7.96589 8.38327 7.96589 7.89993C7.96589 7.4166 7.56589 7.0166 7.08255 7.0166Z" fill="#D9E6E8" />
            <path d="M10.0009 14.2332C9.68423 14.2332 9.40923 13.9916 9.3759 13.6666C9.28423 12.7666 8.55923 12.0416 7.6509 11.9582C7.26757 11.9249 6.88423 11.9249 6.5009 11.9582C5.59257 12.0416 4.86757 12.7582 4.7759 13.6666C4.74257 14.0082 4.43423 14.2666 4.09256 14.2249C3.7509 14.1916 3.5009 13.8832 3.53423 13.5416C3.68423 12.0416 4.8759 10.8499 6.38423 10.7166C6.84257 10.6749 7.30923 10.6749 7.76756 10.7166C9.26756 10.8582 10.4676 12.0499 10.6176 13.5416C10.6509 13.8832 10.4009 14.1916 10.0592 14.2249C10.0426 14.2332 10.0176 14.2332 10.0009 14.2332Z" fill="#D9E6E8" />
        </svg>

    )
}