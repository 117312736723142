
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import './style.scss';
import { useNavigate } from "react-router-dom";
import { RemoveUser, getUsers, selectedUserId, setFindAllUsersFiltersPage } from "./slice";
import { SpinnerComponent } from "../../lib/spinner";
import { EditIcon } from "../../ui/icons/edit";
import { Dropdown } from "../../ui/organisms/dropdown";
import { Pagination } from "../../ui/organisms/pagination";
import { TrashIcon } from "../../ui/icons/trash";
import { mapRole, sot } from "../../utils";

export function UsersList() {
    const usersState = useAppSelector(state => state.users)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(getUsers(usersState.filters))
    }, [dispatch, usersState.filters.page])

    if (usersState.getUsersStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    return (
        <div className="w-[100%]">
            <div style={{ padding: '24px 0' }}>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th style={{ borderTopLeftRadius: '8px' }}>Nome Cognome</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Cellulare</th>
                            <th>Ufficio</th>
                            <th>Funzione</th>
                            <th>Sede</th>
                            <th>Status</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersState.getUsersResponse?.data.map(user =>
                            <tr
                                className="bg-white h-[52px]  border-b-[1px] border-b-neutral-200"
                                key={user.id}
                            >
                                <td className="text-blue-800 robotoBold text-left">{user.name} {user.surname}</td>
                                <td className="text-neutral-700">{user.username ? user.username : '-'}</td>
                                <td className="text-neutral-700">{user.email ? user.email : '-'}</td>
                                <td className="text-neutral-700">{user.phone ? user.phone : '-'}</td>
                                <td className="labelStatus">
                                    <span className="text-white labelOffice bg-brandPrimary-500">
                                        {
                                            mapRole.filter(({ role }) => role === user.role).map(el =>
                                                <span> {el.label}</span>
                                            )
                                        }
                                    </span>
                                </td>
                                <td className="labelStatus">
                                    <span className="text-brandPrimary-600 labelFunz bg-brandPrimary-50">
                                        {user.role}
                                    </span>
                                </td>
                                <td>
                                    <span className="">
                                        {
                                            !user.headQuarter
                                                ? '-'
                                                : sot.filter(({ value }) => value === user.headQuarter).map(
                                                    el =>
                                                        <span className="text-brandPrimary-800 labelFunz bg-brandPrimary-100">
                                                            {el.label}
                                                        </span>
                                                )
                                        }

                                    </span>
                                </td>
                                <td>
                                    {
                                        !user.deactivationDate ? (
                                            <span className="text-green-600 labelStatus activeLabel ">Attivo</span>
                                        ) :
                                            (
                                                <span className="text-red-600 labelStatus deactiveLabel">Non Attivo</span>
                                            )
                                    }
                                </td>
                                <td className="w-[20px] cursor-pointer">
                                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>

                                        <Dropdown items={[
                                            {
                                                label: 'Modifica',
                                                icon: <EditIcon color={""} size={0} />,
                                                onClick: () => {
                                                    dispatch(selectedUserId(user.id))
                                                    navigate('/edit-user/' + user.id)
                                                }
                                            },
                                            {
                                                label: 'Disattiva',
                                                icon: <TrashIcon color={""} size={0} />,
                                                onClick: () => {
                                                    dispatch(RemoveUser(user.id))
                                                    //navigate('/edit-user/' + user.id)
                                                    setTimeout(() => {
                                                        window.location.reload();
                                                    }, 800);

                                                }
                                            }

                                        ]} />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Pagination
                    numbResults={usersState.getUsersResponse ? usersState.getUsersResponse?.total : 0}
                    detailsResults={'utenti'}
                    pages={usersState.getUsersResponse?.totalPage || 1}
                    currentPage={usersState.filters.page} setPage={(page) => dispatch(setFindAllUsersFiltersPage(page))}
                />
            </div>
        </div>
    )
}