import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import './style.scss';
import Input from "../../ui/molecules/input/Input";
import Button from "../../ui/molecules/button";
import  { registerLocale } from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { editUser, findUserById, resetEditUserStatusIdle, setActivationDate, setDeActivationDate, setEmail, setFiscalCode, setHeadQuarter, setName, setOffice, setOfficeProt, setPec, setPhone, setRole, setSotProt, setSurname, setUsername } from "./slice";
import { format } from "date-fns";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { SpinnerComponent } from "../../lib/spinner";
import { officeOptions, role, sot } from "../../utils";
import { SelectCustom } from "../../ui/molecules/select";
import { Calendar } from "../../ui/molecules/calendar";
import RadioButtonGroup from "../../ui/molecules/RadioButtonGroup";
import { ArrowLeftIcon } from "../../ui/icons/arrow-left";

registerLocale('it', it)

interface Props {
    children: ReactNode
}

export function EditUser() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const usersState = useAppSelector(state => state.users)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [selectedOptionOfficeProt, setSelectedOptionOfficeProt] = useState<OfficeProtOptionsCode | null>(null);
    const [selectedOptionSotProt, setSelectedOptionSotProt] = useState<SotProtOptionsCode | null>(null);

    useEffect(() => {
        if (usersState.selectedUserId !== undefined && usersState.selectedUserId != '') {
            dispatch(findUserById(usersState.selectedUserId))

        }
    }, [])

    useEffect(() => {
        if (usersState.editUserStatus === 'successfully') {

            setShowSuccess(true)
            setTimeout(() => {
                dispatch(resetEditUserStatusIdle())
                //navigate('/users/')
            }, 2500);
        }
        else {
            setShowSuccess(false)
        }

        if (usersState.editUserStatus === 'failed') {

            setShowError(true)
            setTimeout(() => {
                dispatch(resetEditUserStatusIdle())

            }, 2500);
        }
        else {
            setShowError(false)
        }

    }, [usersState.editUserStatus])

    enum SotProtOptionsCode {
        SI = 'SI',
        NO = 'NO',
    }

    enum OfficeProtOptionsCode {
        SI = 'SI',
        NO = 'NO',
    }

    const handleOptionOfficeProtChange = (value: OfficeProtOptionsCode) => {
        setSelectedOptionOfficeProt(value);
        //dispatch(setCodeType(value))
        if (value === 'SI') {
            //setCodeDisabled(false)
            dispatch(setOfficeProt(true))
        }
        else {
            //dispatch(setExistingCode(''))
            dispatch(setOfficeProt(false))
        }
    };

    const handleOptionSotProtChange = (value: SotProtOptionsCode) => {
        setSelectedOptionSotProt(value);
        //dispatch(setCodeType(value))
        if (value === 'SI') {
            //setCodeDisabled(false)
            dispatch(setSotProt(true))
        }
        else {
            //dispatch(setExistingCode(''))
            dispatch(setSotProt(false))
        }
    };


    useEffect(() => {

        if (usersState.findUserByIdResponse !== undefined && usersState.findUserByIdStatus === 'successfully') {
            dispatch(setName(usersState.findUserByIdResponse.name))
            dispatch(setSurname(usersState.findUserByIdResponse.surname))
            dispatch(setEmail(usersState.findUserByIdResponse.email))
            dispatch(setUsername(usersState.findUserByIdResponse.username))
            dispatch(setPhone(usersState.findUserByIdResponse.phone))
            dispatch(setRole(usersState.findUserByIdResponse.role))
            dispatch(setOffice(usersState.findUserByIdResponse.office))
            dispatch(setPec(usersState.findUserByIdResponse.pec))
            dispatch(setFiscalCode(usersState.findUserByIdResponse.fiscalCode))
            dispatch(setHeadQuarter(usersState.findUserByIdResponse.headQuarter))
            dispatch(setActivationDate(usersState.findUserByIdResponse.activationDate))
            dispatch(setDeActivationDate(usersState.findUserByIdResponse.deactivationDate))
            dispatch(setOfficeProt(usersState.findUserByIdResponse.managementProtocolOffice))
            dispatch(setSotProt(usersState.findUserByIdResponse.managementProtocolHeadQuarter))

            if (usersState.findUserByIdResponse.managementProtocolOffice) {
                setSelectedOptionOfficeProt(OfficeProtOptionsCode.SI)
            }
            else if (!usersState.findUserByIdResponse.managementProtocolOffice) {
                setSelectedOptionOfficeProt(OfficeProtOptionsCode.NO)
            }

            if (usersState.findUserByIdResponse.managementProtocolHeadQuarter) {
                setSelectedOptionSotProt(SotProtOptionsCode.SI)
            }
            else if (!usersState.findUserByIdResponse.managementProtocolHeadQuarter) {
                setSelectedOptionSotProt(SotProtOptionsCode.NO)
            }

        }
    }, [usersState.findUserByIdResponse])

    const [startDate, setStartDate] = useState(new Date());

    const [dateActivate, setDataActivate] = useState<Date | undefined>(undefined)
    const [dateDeActivate, setDataDeActivate] = useState<Date | undefined>(undefined)

    if (usersState.findUserByIdStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Utenti', 'Modifica Utente']}
            headerLabel={usersState.findUserByIdResponse?.name + ' ' + usersState.findUserByIdResponse?.surname}
            rightBar={true}
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => navigate('/users')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Modifica" color={"orange"}
                        onClick={() => {

                            dispatch(editUser({ id: usersState.selectedUserId, data: usersState.usersEditCreateRequest }))
                        }}
                    />

                </div>

            }
        >

            <div style={{ display: 'flex', height: '100%' }}>
                <div className="fieldset rounded-lg" style={{ flex: 0.7 }}>
                    {
                        showSuccess &&

                        <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>

                    }
                    {
                        showError &&

                        <div className="gap-[8px] text-left bg-red-200" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-red-800 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Errore nel salvataggio.
                            </span>
                        </div>

                    }
                    <div className="gap-[8px]" style={{ background: '#4D797F', margin: '24px', borderRadius: '8px' }}>
                        <>
                            <div className="w-full text-left boxFieldsetTitle" style={{ flex: 1, padding: '16px 24px 16px  24px' }}>
                                <span className="text-white text-uppercase fieldsetTitle">Credenziali</span>
                            </div>
                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7' }}>
                                <div style={{ flex: 0.5 }}>
                                    <Input
                                        label={'Username'}
                                        disabled={true}
                                        placeholder="Username"
                                        onChangeText={(text) => dispatch(setUsername(text))}
                                        defaultValue={usersState.findUserByIdResponse?.username}
                                    />
                                </div>

                            </div>
                        </>

                    </div>

                    <div className="gap-[8px]" style={{ background: '#4D797F', margin: '24px', borderRadius: '8px' }}>
                        <>
                            <div className="w-full text-left boxFieldsetTitle" style={{ flex: 1, padding: '16px 24px 16px  24px' }}>
                                <span className="text-white text-uppercase fieldsetTitle">Anagrafica</span>
                            </div>
                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7' }}>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Nome'}
                                        placeholder="Nome"
                                        onChangeText={(text) => dispatch(setName(text))}
                                        defaultValue={usersState.findUserByIdResponse?.name}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Cognome'}
                                        placeholder="Cognome"
                                        onChangeText={(text) => dispatch(setSurname(text))}
                                        defaultValue={usersState.findUserByIdResponse?.surname}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Codice Fiscale'}
                                        placeholder="Codice Fiscale"
                                        onChangeText={(text) => dispatch(setFiscalCode(text))}
                                        defaultValue={usersState.findUserByIdResponse?.fiscalCode}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7' }}>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Email'}
                                        placeholder="Email"
                                        disabled={true}
                                        onChangeText={(text) => dispatch(setEmail(text))}
                                        defaultValue={usersState.findUserByIdResponse?.email}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'PEC'}
                                        placeholder="PEC"
                                        onChangeText={(text) => dispatch(setPec(text))}
                                        defaultValue={usersState.findUserByIdResponse?.pec}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        label={'Cellulare'}
                                        placeholder="Cellulare"
                                        onChangeText={(text) => dispatch(setPhone(text))}
                                        defaultValue={usersState.findUserByIdResponse?.phone}
                                    />
                                </div>
                            </div>
                        </>

                    </div>

                </div>
                <div className="bg-brandPrimary-100" style={{ flex: 0.3, padding: '24px', borderLeft: '1px solid #E4E4F7' }}>
                    <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                <span className="input-label mb-1">Sede</span>
                            </div>
                            <SelectCustom
                                defaultValue={usersState.findUserByIdResponse?.headQuarter}
                                placeholder={'Sede'}
                                options={sot}
                                onChange={(value) => {
                                    dispatch(setHeadQuarter(value))
                                }}

                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                <span className="input-label mb-1">Ufficio</span>
                            </div>
                            <SelectCustom
                                placeholder={'Ufficio'}
                                defaultValue={usersState.findUserByIdResponse?.office}
                                options={officeOptions}
                                onChange={(value) => {
                                    dispatch(setOffice(value))
                                }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <SelectCustom placeholder={'Ruolo'} options={role}
                                defaultValue={usersState.findUserByIdResponse?.role}
                                onChange={(value) => {
                                    dispatch(setRole(value))
                                }} />
                        </div>
                        <div>
                            <table className="reviewPermissionTable">
                                <tr>
                                    <td className="label" style={{ borderTopLeftRadius: '8px' }}>Ufficio</td>
                                    <td className="value" ></td>
                                </tr>
                                <tr>
                                    <td className="label">Normativa</td>
                                    <td className="value"></td>
                                </tr>
                                <tr>
                                    <td className="label">Regioni</td>
                                    <td className="value"></td>
                                </tr>
                            </table>
                        </div>
                        <div style={{ display: 'flex', textAlign: 'left', gap: '8px', flexDirection: 'column' }}>
                            <div>
                                <span className="text-uppercase text-brandPrimary-600 label-input fieldsetTitle">Data Attivazione</span>
                            </div>
                            <div>
                                <Calendar
                                    selected={dateActivate === undefined ? usersState.findUserByIdResponse?.activationDate : dateActivate}
                                    onChange={(date) => {
                                        dispatch(setActivationDate(format(new Date(date), 'yyyy-MM-dd')));
                                        setDataActivate(date)
                                    }}
                                />
                            </div>

                            <div>
                                <span className="text-uppercase text-brandPrimary-600 label-input fieldsetTitle">Data Disattivazione</span>
                            </div>
                            <div>
                                <Calendar
                                    selected={dateDeActivate === undefined ? usersState.findUserByIdResponse?.deactivationDate : dateDeActivate}
                                    onChange={(date) => {
                                        dispatch(setDeActivationDate(format(new Date(date), 'yyyy-MM-dd')));
                                        setDataDeActivate(date)
                                    }}
                                />
                            </div>

                        </div>
                        {
                            (usersState.usersEditCreateRequest.headQuarter || usersState.usersEditCreateRequest.office) ? (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label" style={{ fontWeight: '700', fontSize: '16px' }}>Gestione Protocollo</span>
                                </div>
                            ) : null
                        }
                        {
                            usersState.usersEditCreateRequest.headQuarter ? (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '24px' }}>
                                    <div>
                                        <span className="input-label" style={{ fontWeight: 'bold' }}>Sede:  </span>
                                        <span className="input-label">
                                            {
                                                sot.filter(({ value }) => value === usersState.usersEditCreateRequest.headQuarter).map(el =>
                                                    <span> {el.label}</span>
                                                )
                                            }
                                        </span>
                                    </div>
                                    <RadioButtonGroup
                                        name={'prot_sot_able'}
                                        options={Object.values(SotProtOptionsCode)}
                                        selectedValue={selectedOptionSotProt}
                                        onChange={handleOptionSotProtChange}
                                        optionLabels={{
                                            [SotProtOptionsCode.SI]: 'Si',
                                            [SotProtOptionsCode.NO]: 'No',
                                        }}
                                    />
                                </div>
                            ) : null
                        }

                        {
                            usersState.usersEditCreateRequest.office ? (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '24px' }}>
                                    <div>
                                        <span className="input-label" style={{ fontWeight: 'bold' }}> Ufficio: </span>
                                        <span className="input-label">
                                            {
                                                officeOptions.filter(({ value }) => value === usersState.usersEditCreateRequest.office).map(el =>
                                                    <span> {el.label}</span>
                                                )
                                            }
                                        </span>
                                    </div>
                                    <div>
                                        <RadioButtonGroup
                                            name={'prot_office_able'}
                                            options={Object.values(OfficeProtOptionsCode)}
                                            selectedValue={selectedOptionOfficeProt}
                                            onChange={handleOptionOfficeProtChange}
                                            optionLabels={{
                                                [OfficeProtOptionsCode.SI]: 'Si',
                                                [OfficeProtOptionsCode.NO]: 'No',
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : null
                        }



                    </div>

                </div>
            </div>



        </Layout>
    )
}