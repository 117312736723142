import { Fragment } from "react/jsx-runtime";
import Input from "../../../ui/molecules/input";
import Button from "../../../ui/molecules/button";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { EntryProtocolObjAttValidation, EntryProtocolObjectCreation, GetAllEntryProtocolObjects, resetEntryProtocolObjAttErrors, resetEntryProtocolObjectCreationStatus, resetEPObjCreationRequest, setEPObjCoding, setEPObjDescription } from "../entry/slice";
import { useEffect } from "react";

interface Props {
    close: () => void
}

export function AddObjectPopupEntry(props: Props) {
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)

    useEffect(() => {
        if(entryProtocolState.entryProtocolObjAttValidationStatus === 'successfully') {
            dispatch(resetEntryProtocolObjAttErrors())
            dispatch(EntryProtocolObjectCreation(entryProtocolState.entryProtocolObjectCreationRequest))
        }
    }, [entryProtocolState.entryProtocolObjAttValidationStatus])

    useEffect(() => {
        if(entryProtocolState.entryProtocolObjectCreationStatus === 'successfully') {
            dispatch(resetEntryProtocolObjectCreationStatus())
            dispatch(GetAllEntryProtocolObjects())
            props.close()
        }
    }, [entryProtocolState.entryProtocolObjectCreationStatus])

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                {/* FIRST ROW */}
                <div className="pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            error={entryProtocolState.entryProtocolObjAttErrors.coding}
                            supportingText={entryProtocolState.entryProtocolObjAttErrors.coding ? 'Codifica mancante' : undefined}
                            label={'Codifica'}
                            placeholder="Specificare codifica..."
                            value={entryProtocolState.entryProtocolObjectCreationRequest.coding}
                            defaultValue={entryProtocolState.entryProtocolObjectCreationRequest.coding}
                            onChangeText={(text) => dispatch(setEPObjCoding(text))}
                            />
                    
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className="pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            error={entryProtocolState.entryProtocolObjAttErrors.description}
                            supportingText={entryProtocolState.entryProtocolObjAttErrors.description ? 'Descrizione mancante' : undefined}
                            label={'Descrizione'}
                            placeholder="Specificare descrizione..."
                            value={entryProtocolState.entryProtocolObjectCreationRequest.description}
                            defaultValue={entryProtocolState.entryProtocolObjectCreationRequest.description}
                            onChangeText={(text) => dispatch(setEPObjDescription(text))}
                            />
                    
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetEPObjCreationRequest())
                        dispatch(resetEntryProtocolObjAttErrors())
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Aggiungi"
                        color="orange"
                        onClick={() => {
                            dispatch(EntryProtocolObjAttValidation(entryProtocolState.entryProtocolObjectCreationRequest))
                        }}
                        />
                </div>
            </div>

        </Fragment>
    )
}