import { RequirementDetailDTO, RequirementDTO } from "./dto";
import { RequirementServiceImpl } from "./serviceImpl";

export interface RequirementService {
    // REQUIREMENT
    CreateRequirement(data: RequirementDTO): Promise<void>
    UpdateRequirement(data: RequirementDTO, id: string): Promise<void>
    DeleteRequirement(id: string): Promise<void>
    GetAllRequirements(): Promise<RequirementDTO[]>
    GetRequirementById(id: string): Promise<RequirementDTO>

    // REQUIREMENT DETAIL
    CreateRequirementDetail(data: RequirementDetailDTO): Promise<void>
    UpdateRequirementDetail(data: RequirementDetailDTO, id: string): Promise<void>
    DeleteRequirementDetail(id: string): Promise<void>
    GetAllRequirementDetails(): Promise<RequirementDetailDTO[]>
    GetRequirementDetailById(id: string): Promise<RequirementDetailDTO>
}

export function NewRequirementService(): RequirementService {
    return new RequirementServiceImpl()
}