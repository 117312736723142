import axios from "axios";
import { GetAllTrainingsFiltersDTO, GetAllTrainingsResponseDTO, TrainingDTO } from "./dto";
import { TrainingService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class TrainingServiceImpl implements TrainingService {

    public CreateTraining(data: TrainingDTO): Promise<void> {
        return axios.post("/api/training",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateTraining(data: TrainingDTO, id: string): Promise<void> {
        return axios.put("/api/training/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteTraining(id: string): Promise<void> {
        return axios.delete("/api/training/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllTraining(filters: GetAllTrainingsFiltersDTO): Promise<GetAllTrainingsResponseDTO> {
        return axios.get("/api/training", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page,
                norm: filters.norm,
                order: filters.order,
                date: filters.date,
                participantSurname: filters.participant,
                sort: filters.sort,
                type: filters.type
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetTrainingById(id: string): Promise<TrainingDTO> {
        return axios.get("/api/training/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}