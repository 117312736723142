
import { ReactNode, useEffect } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import './style.scss';
import { useNavigate } from "react-router-dom";
import { SpinnerComponent } from "../../lib/spinner";
import { EditIcon } from "../../ui/icons/edit";
import { Dropdown } from "../../ui/organisms/dropdown";
import { Pagination } from "../../ui/organisms/pagination";
import { TrashIcon } from "../../ui/icons/trash";
import { formatterDate, getLabelACtivity, mapRole, normativa, normativaView, sot } from "../../utils";
import { ActiveIcon } from "../../ui/icons/activeIcon";
import { InactiveIcon } from "../../ui/icons/inactiveIcon";
import { text } from "stream/consumers";
import { GetCustomers, setFindAllCustomersFiltersPage, setSelectedCustomerId, setSelectedDocCustomerId, setUserId } from "./slice";
import { selectedUserId } from "../users/slice";

interface Props {
    children: ReactNode
}

export function ClientiList() {
    const clientiState = useAppSelector(state => state.clienti)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(GetCustomers(clientiState.filters))
    }, [clientiState.filters.page])
    if (clientiState.getCustomersResponseStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    return (

        <div className="w-[100%]">
            <div style={{ padding: '24px 0' }}>

                <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px' }}>Cod. Op.</th>
                            <th >Ragione Sociale</th>
                            <th>Norma</th>
                            <th>Sede</th>
                            <th>CUAA</th>
                            <th>P.IVA</th>
                            <th>Attività</th>
                            <th>Categoria Prodotti</th>
                            <th>Regione</th>
                            <th>Data Ingresso</th>
                            <th>Data Uscita</th>
                            <th style={{ textAlign: 'center' }}>Status</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientiState.getCustomersResponse?.data.map(customer =>
                            <tr
                                className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                                key={customer.userId}
                            >
                                <td className="text-blue-800 robotoBold text-left">
                                    {customer.clientCode}
                                </td>
                                <td className="text-neutral-700" style={{ fontFamily: 'Manrope', fontSize: '14px', fontWeight: '600', lineHeight: '20px' }}>{customer.companyName}</td>
                                <td className="text-neutral-700">
                                    {
                                        normativaView.filter(({ value }) => value === customer.norm).map(el =>
                                            <span key={el.value} className="labelNorm"> {el.label}</span>
                                        )
                                    }
                                </td>
                                <td className="text-neutral-700"></td>
                                <td className="text-neutral-700">
                                    {customer.cuaa}
                                </td>
                                <td className="text-neutral-700">
                                    {customer.vat}
                                </td>
                                <td className="text-neutral-700">
                                    {
                                        customer.bioActivity && customer.bioActivity.length > 0
                                            ? customer.bioActivity.map((activity, index) => (
                                                <p key={index}>{getLabelACtivity(activity, 'attivita_bio')}</p>
                                            ))
                                            : '-'
                                    }
                                </td>
                                <td className="text-neutral-700">
                                    {
                                        customer.bioProductCategories && customer.bioProductCategories.length > 0
                                            ? customer.bioProductCategories.map((category, index) => (
                                                <p key={index}>{getLabelACtivity(category, 'categorie_prodotti')}</p>
                                            ))
                                            : '-'
                                    }
                                </td>
                                <td className="text-neutral-700">
                                    {customer.countryRegion}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(customer.startingDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {customer.endingDate ? formatterDate(customer.endingDate) : '-'}
                                </td>
                                <td>
                                    {
                                        !customer.endingDate ?
                                            (
                                                <ActiveIcon></ActiveIcon>
                                            )
                                            :
                                            (
                                                <InactiveIcon></InactiveIcon>
                                            )
                                    }
                                </td>
                                <td className="w-[20px] cursor-pointer">
                                    <div className="flex justify-content-between align-items-center">
                                        <button
                                            onClick={() => {
                                                dispatch(setSelectedCustomerId(customer.id))
                                                dispatch(selectedUserId(customer.userId))
                                                dispatch(setSelectedDocCustomerId(customer.id))
                                                localStorage.setItem("selectedCustomer", customer.id)
                                                localStorage.setItem("selectedCustomerName", customer.companyName)
                                                localStorage.setItem("selectedCustomerCode", customer.clientCode)
                                                navigate('/edit-customer/' + customer.id)
                                            }}>
                                            <EditIcon color={"Orange"} size={18} />
                                        </button>

                                    </div>

                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
                {/* <Pagination pages={usersState.getUsersResponse?.totalPage || 1} currentPage={usersState.filters.page} setPage={(page) => console.log() } /> */}
            </div>
        </div>

    )
}