import { ReactElement } from "react"

export enum SearchType {
    CODE,
    COMPANY_NAME,
    TAKEOVER
}

export interface InputProps {
    value?: string | number
    defaultValue?: string | number
    onChangeText?: (text: string) => void
    startIcon?: ReactElement
    endIcon?: ReactElement
    disabled?: boolean
    label?: string
    supportingText?: string
    error?: boolean
    placeholder?: string,
    onClick?:() => void
    onSelect?: (result: string) => void;
    searchType?: SearchType
}
