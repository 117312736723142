import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import Button from "../../../ui/molecules/button"
import { EditIcon } from "../../../ui/icons/edit"
import { TrashIcon } from "../../../ui/icons/trash"
import { PopupForm } from "../../../ui/organisms/popupForm"
import { DeleteOtherInfo, GetByIdOtherInfo, addOthInfoInstance, resetDeleteStatus, resetGetByidStatus, resetOthInfoInstance, resetOtherInfoCreateStatus, resetUpdateStatus, setPMId, setUpdateRequest } from "./slice"
import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus } from "../slice"
import AddOtherInfo from "./boxPopup/add"
import EditOtherInfo from "./boxPopup/edit"

export default function BoxOtherInfo() {
    const dispatch = useAppDispatch()

    const otherInfoState = useAppSelector(state => state.PMOtherInfo)
    const PMState = useAppSelector(state => state.personalManagement)
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const [openEditPopup, setOpenEditPopup] = useState<boolean>(false)

    useEffect(() => {
        dispatch(resetOthInfoInstance())
        if (PMState.currentPersonalManagementId) {
            dispatch(setPMId(PMState.currentPersonalManagementId))
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId))
        }

    }, [PMState.currentPersonalManagementId])

    useEffect(() => {
        dispatch(resetOthInfoInstance())
        if (PMState.getByIdPersonalManagementResourcesStatus === 'successfully' && PMState.getByIdPersonalManagementResourcesResponse?.otherInformationIds) {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))
            for (let index = 0; index < PMState.getByIdPersonalManagementResourcesResponse?.otherInformationIds.length; index++) {
                dispatch(GetByIdOtherInfo(PMState.getByIdPersonalManagementResourcesResponse?.otherInformationIds[index].toString()))
            }
        }

    }, [PMState.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if (otherInfoState.getByIdOtherInfoStatus === 'successfully' && PMState.getByIdPersonalManagementResourcesResponse) {
            
            dispatch(addOthInfoInstance(otherInfoState.GetByIdOtherInfoResponse))
            dispatch(resetGetByidStatus('idle'))
        }

    }, [otherInfoState.getByIdOtherInfoStatus])

    useEffect(() => {
        dispatch(resetOthInfoInstance())
        if (otherInfoState.DeleteIdOtherInfoStatus === 'successfully' && PMState.currentPersonalManagementId) {
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId))
            dispatch(resetDeleteStatus('idle'))
        }

    }, [otherInfoState.DeleteIdOtherInfoStatus])

    useEffect(() => {
        dispatch(resetOthInfoInstance())
        if (otherInfoState.OthInfoUpdateStatus === 'successfully' && PMState.currentPersonalManagementId) {
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId))
            dispatch(resetUpdateStatus('idle'))
        }

    }, [otherInfoState.OthInfoUpdateStatus])

    useEffect(() => {
        dispatch(resetOthInfoInstance())
        if (otherInfoState.createOtherInfoStatus === 'successfully' && PMState.currentPersonalManagementId) {
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId))
            dispatch(resetOtherInfoCreateStatus('idle'))
        }

    }, [otherInfoState.createOtherInfoStatus])

    return (
        <div style={{ padding: '24px' }}>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                    <span id="title-eval-tab">Aggiungi altre Informazioni</span>
                    <Button
                        size="md"
                        iconPosition="off"
                        variant="outline"
                        color="orange"
                        label="Aggiungi altre informazoni"
                        onClick={() => setOpenPopup(true)}
                    />
                </div>

                <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px' }}>Madrelingua</th>
                            <th>Altre Lingue</th>
                            <th>Capacità di lettura</th>
                            <th>Capacità di scrittura</th>
                            <th>Capacità di espressione orale</th>
                            <th>Capacità Personali</th>
                            <th>Capacità Relazionali</th>
                            <th>Capacità e competenze organizzative</th>
                            <th>Capacità e competenze tecniche</th>
                            <th>Altre Capacità e competenze</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {otherInfoState.otherInfoInstances.map(info =>
                            <tr
                                className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                                key={info.id}
                            >
                                <td className="text-neutral-700">
                                    {info.nativeLanguage}
                                </td>
                                <td className="text-neutral-700">
                                    {info.otherLanguage}
                                </td>
                                <td className="text-neutral-700">
                                    {info.readSkill}
                                </td>
                                <td className="text-neutral-700">
                                    {info.writeSkill}
                                </td>
                                <td className="text-neutral-700">
                                    {info.oralExpressionSkill}
                                </td>
                                <td className="text-neutral-700">
                                    {info.personalSkill}
                                </td>
                                <td className="text-neutral-700">
                                    {info.relationalSkill}
                                </td>
                                <td className="text-neutral-700">
                                    {info.organizationalSkill}
                                </td>
                                <td className="text-neutral-700">
                                    {info.technicalSkill}
                                </td>
                                <td className="text-neutral-700">
                                    {info.otherSkill}
                                </td>
                                <td className="w-[30px]">
                                    <div className="flex justify-content-between align-items-center">

                                        <button onClick={() => {
                                
                                            dispatch(setUpdateRequest(info))
                                            setOpenEditPopup(true)
                                        }}>
                                            <EditIcon color={"Orange"} size={18} />
                                        </button>

                                        <div className="pl-5 flex align-items-center">
                                            <button onClick={() => {
                                                if (info.id) {
                                                    dispatch(DeleteOtherInfo(info.id))
                                                }
                                            }
                                            }
                                            >
                                                <TrashIcon color={"Orange"} size={0} />
                                            </button>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
            {
                openPopup && (
                    <PopupForm title={'Aggiungi Informazioni'} close={() => setOpenPopup(false)}>
                        <AddOtherInfo close={() => setOpenPopup(false)} />
                    </PopupForm>
                )
            }
            {
                openEditPopup && (
                    <PopupForm title={'Modifica Informazioni'} close={() => setOpenEditPopup(false)}>
                        <EditOtherInfo close={() => setOpenEditPopup(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}