import React from "react";
import { IconProps } from "./dto";

export function DownloadButton() {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
            <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="15.25" fill="white" />
            <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="15.25" stroke="#C0D5D8" strokeWidth="1.5" />
            <path d="M44 36V41.3333C44 42.0406 43.719 42.7189 43.219 43.219C42.7189 43.719 42.0406 44 41.3333 44H22.6667C21.9594 44 21.2811 43.719 20.781 43.219C20.281 42.7189 20 42.0406 20 41.3333V36M25.3333 29.3333L32 36M32 36L38.6667 29.3333M32 36V20" stroke="#5D9298" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}