import axios from "axios";
import { ContractDTO, GetAllContractsResponseDTO } from "./dto";
import { ContractsService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class ContractsServiceImpl implements ContractsService {

    public GetAllContracts(): Promise<GetAllContractsResponseDTO> {
        return axios.get("/api/pm-contract", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetContractById(id: string): Promise<ContractDTO> {
        return axios.get("/api/pm-contract/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public CreateContract(data: ContractDTO): Promise<void> {
        return axios.post("/api/pm-contract",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateContract(data: ContractDTO, id: string): Promise<void> {
        return axios.put("/api/pm-contract/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteContract(id: string): Promise<void> {
        return axios.delete("/api/pm-contract/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeactivateContract(id: string): Promise<void> {
        return axios.delete("/api/pm-contract/remove/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }
}