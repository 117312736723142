import { useEffect, useState } from "react";
import CheckboxGroup from "../../ui/molecules/CheckBoxGroup";
import { commonRightBarStyle } from "./style";
import { setSqProductionChain } from "../clienti/slice";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";

export function QualitaSicura() {
    const clientiState = useAppSelector((state) => state.clienti);
    const dispatch = useAppDispatch()
    enum ProdChainType {
        FILIERA_BOVINA = "FILIERA_BOVINA",
        FILIERA_GRANO_DURO_E_DERIVATI = "FILIERA_GRANO_DURO_E_DERIVATI",
        FILIERA_AGNELLO_AGNELLONE = "FILIERA_AGNELLO_AGNELLONE",
        FILIERA_LATTE_CRUDO_VACCINO_E_DERIVATI = "FILIERA_LATTE_CRUDO_VACCINO_E_DERIVATI"
    }
    const [selectedTypes, setSelectedTypes] = useState<ProdChainType[]>([]);

    useEffect(() => {
        if (clientiState.getCustomersByIdResponse !== undefined) {
            dispatch(setSqProductionChain(clientiState.getCustomersByIdResponse.sqProductionChain));
            const productionChain = clientiState.getCustomersByIdResponse.sqProductionChain.map((type: string) => ProdChainType[type as keyof typeof ProdChainType]);
           
            setSelectedTypes(productionChain);
        }
    }, [clientiState.getCustomersByIdResponseStatus]);

    const handleTypesChange = (value: ProdChainType) => {
        let currentTypes = [...selectedTypes];
        if (currentTypes.includes(value))
        {
            currentTypes = currentTypes.filter(t => t !== value);
            //setSelectedTypes(currentTypes.filter(t => t !== value))
            //dispatch(setSqProductionChain(selectedTypes))
        }
        else {
            currentTypes.push(value)
            //setSelectedTypes(currentTypes)
        }
        setSelectedTypes(currentTypes);
        dispatch(setSqProductionChain(currentTypes));
    }

    return (
        <div
            style={commonRightBarStyle}
        >
            <CheckboxGroup
                name="tipo_adesione"
                options={Object.values(ProdChainType)}
                selectedValues={selectedTypes}
                onChange={handleTypesChange}
                optionLabels={{
                    [ProdChainType.FILIERA_BOVINA]: "Filiera Bovina",
                    [ProdChainType.FILIERA_GRANO_DURO_E_DERIVATI]: "Filiera Grano Duro e Derivati",
                    [ProdChainType.FILIERA_AGNELLO_AGNELLONE]: "Filiera Agnello / Agnellone",
                    [ProdChainType.FILIERA_LATTE_CRUDO_VACCINO_E_DERIVATI]: "Filiera Latte Crudo, Vaccino e Derivati"
                }}
            />
        </div>
    )
}