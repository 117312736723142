import { useEffect, useState } from "react";
import { genreSelect, OptionType, Province, Region, regioniProvinceMap, regions } from "../../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Input from "../../input";
import { SelectCustom } from "../../select";
import { setActivationDate, setBirthDate, setCity, setContactAddress, setContactCity, setContactCountryRegion, setContactProvince, setContactZipCode, setCountryRegion, setDeActivationDate, setEmail, setFax, setFiscalCode, setName, setNote, setPhone, setProvince, setSex, setSurname } from "../../../../pages/clienti/slice";
import { format } from "date-fns";
import { provinceComuniMap } from "../../../../utils/listaComuni";
import { Calendar } from "../../calendar";

export function RappLegaleAnagrafica () {
    const clientiState = useAppSelector(state => state.clienti)
    const dispatch = useAppDispatch()

    const [dateBirthDate, setDateBirthDate] = useState<Date | undefined>(undefined)
    const [provinceOptions, setProvinceOptions] = useState<OptionType[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<OptionType | null>(null);
    const [comuneOptions, setComuneOptions] = useState<OptionType[]>([]);

    const [selectedComune, setSelectedComune] = useState<OptionType | null>(null);
    const [selectedProvince, setSelectedProvince] = useState<OptionType | null>(null);

    const regionOptions: OptionType[] = Array.from(regioniProvinceMap.keys()).map(region => ({
        value: region,
        label: region
    }));

    const handleRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRegion(selectedOption);
        setSelectedProvince(null); // Reset province when region changes
        setSelectedComune(null);
    };

    const handleProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedProvince(selectedOption);
        setSelectedComune(null);
    };

    useEffect(() => {
        
        if(clientiState.selectedRappLegale !== undefined && clientiState.selectedRappLegale !== null) {
            dispatch(setFiscalCode(clientiState.selectedRappLegale?.fiscalCode))
            dispatch(setSurname(clientiState.selectedRappLegale?.surname))
            dispatch(setName(clientiState.selectedRappLegale?.name))
            dispatch(setSex(clientiState.selectedRappLegale?.sex))
            dispatch(setBirthDate(clientiState.selectedRappLegale?.birthdate))
            dispatch(setCountryRegion(clientiState.selectedRappLegale?.countryRegion))
            dispatch(setProvince(clientiState.selectedRappLegale?.province))
            dispatch(setCity(clientiState.selectedRappLegale?.city))
            dispatch(setContactAddress(clientiState.selectedRappLegale?.contactAddress))
            dispatch(setContactProvince(clientiState.selectedRappLegale?.contactProvince))
            dispatch(setContactCity(clientiState.selectedRappLegale?.city))
            dispatch(setContactZipCode(clientiState.selectedRappLegale?.contactZipCode))
            dispatch(setPhone(clientiState.selectedRappLegale?.phone))
            dispatch(setEmail(clientiState.selectedRappLegale?.email))
            dispatch(setFax(clientiState.selectedRappLegale?.fax))
            dispatch(setActivationDate(clientiState.selectedRappLegale?.activationDate))
            dispatch(setDeActivationDate(clientiState.selectedRappLegale?.deactivationDate))
            dispatch(setNote(clientiState.selectedRappLegale?.note))
            dispatch(setContactCountryRegion(clientiState.selectedRappLegale?.contactCountryRegion))

            console.log(clientiState.selectedRappLegale);
            

            if (clientiState.selectedRappLegale.countryRegion !== null) {
                const foundRegion = regions.find((region: Region) => region.name === clientiState.selectedRappLegale?.countryRegion.toString());
                if (foundRegion) {
                    const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                        value: province.code,
                        label: province.name
                    }));
                    setProvinceOptions(newProvinceOptions);
                } else {
                    setProvinceOptions([]);
                }
            } else {
                setProvinceOptions([]);
            }

            if (clientiState.selectedRappLegale.province !== null) {
                const comuni = provinceComuniMap.get(clientiState.selectedRappLegale.province.toString()) || [];
                const newComuneOptions = comuni.map((comune: string) => ({
                    value: comune,
                    label: comune
                }));
                setComuneOptions(newComuneOptions);
            } else {
                setComuneOptions([]);
            }
        }
    }, [clientiState.selectedRappLegale])

    useEffect(() => {
        if (selectedRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setProvinceOptions(newProvinceOptions);
            } else {
                setProvinceOptions([]);
            }
        } else {
            setProvinceOptions([]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedProvince) {
            const comuni = provinceComuniMap.get(selectedProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setComuneOptions(newComuneOptions);
        } else {
            setComuneOptions([]);
        }
    }, [selectedProvince]);

    return (
        <div className="bg-brandPrimary-100">
        <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

            <div style={{ flex: '1 1 auto', marginTop: 10 }}>
                <Input
                    label={'Codice Fiscale'}
                    placeholder="Codice Fiscale"
                    supportingText={clientiState.createLegalRappErrors.fiscalCode ? "Inserire codice fiscale." : undefined} error={clientiState.createLegalRappErrors.fiscalCode}
                    defaultValue={clientiState.rappLegaleEditCreateRequest.fiscalCode}
                    value={clientiState.rappLegaleEditCreateRequest.fiscalCode}
                    onChangeText={(text) => dispatch(setFiscalCode(text))}
                />
            </div>
        </div>
        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
            <div style={{ flex: '0.5' }}>
                <Input
                    label={'Cognome'}
                    placeholder="Cognome"
                    defaultValue={clientiState.rappLegaleEditCreateRequest.surname}
                    value={clientiState.rappLegaleEditCreateRequest.surname}
                    onChangeText={(text) => dispatch(setSurname(text))}
                />
            </div>
            <div style={{ flex: '0.5' }}>
                <Input
                    label={'Nome'}
                    placeholder="Nome"
                    defaultValue={clientiState.rappLegaleEditCreateRequest.name}
                    value={clientiState.rappLegaleEditCreateRequest.name}
                    onChangeText={(text) => dispatch(setName(text))}
                />
            </div>
        </div>
        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

            <div style={{ flex: '0.5' }}>
                <div className="text-left">
                    <span className="input-label">Sesso</span>
                </div>
                <SelectCustom
                    placeholder={'Sesso'}
                    options={genreSelect}
                    onChange={(value) => dispatch(setSex(value))}
                    defaultValue={clientiState.rappLegaleEditCreateRequest.sex}
                    value={clientiState.rappLegaleEditCreateRequest.sex}
                />
            </div>

            <div style={{ flex: '0.5' }}>
                <div>
                    <div className="text-left">
                        <span className="input-label">Data di nascita</span>
                    </div>
                </div>
                <Calendar
                    selected={clientiState.rappLegaleEditCreateRequest.birthdate}
                    onChange={(date) => {
                        dispatch(setBirthDate(format(new Date(date), 'yyyy-MM-dd')))
                        setDateBirthDate(date)
                    }}
                />
            </div>
        </div>
        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
            <div style={{ flex: '0.5' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                    <span className="input-label">Regione</span>
                </div>
                <SelectCustom
                    error={clientiState.createLegalRappErrors.countryRegion}
                    errorLabel="Inserire Regione."
                    defaultValue={clientiState.rappLegaleEditCreateRequest.countryRegion}
                    value={clientiState.rappLegaleEditCreateRequest.countryRegion}
                    placeholder={'Regione'}
                    options={regionOptions}
                    onChange={(value) => { handleRegionChange(value as unknown as OptionType | null); dispatch(setCountryRegion(value)) }}
                />
            </div>
            <div style={{ flex: '0.5' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                    <span className="input-label">Provincia</span>
                </div>
                <SelectCustom
                    error={clientiState.createLegalRappErrors.province}
                    errorLabel="Inserire provincia."
                    defaultValue={clientiState.rappLegaleEditCreateRequest.province}
                    value={clientiState.rappLegaleEditCreateRequest.province}
                    placeholder={'Provincia'}
                    options={provinceOptions}
                    onChange={(value) => { handleProvinceChange(value as unknown as OptionType | null); dispatch(setProvince(value)) }}
                />
            </div>
            <div style={{ flex: '0.5' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                    <span className="input-label">Comune</span>
                </div>
                <SelectCustom
                    error={clientiState.createLegalRappErrors.city}
                    errorLabel="Inserire Città."
                    placeholder={'Comune'}
                    defaultValue={clientiState.rappLegaleEditCreateRequest.city}
                    value={clientiState.rappLegaleEditCreateRequest.city}
                    options={comuneOptions}
                    onChange={(value) => {
                        dispatch(setCity(value))
                    }}
                />
            </div>
            <div style={{ flex: '0.5', paddingBottom: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                    <span className="input-label">CAP</span>
                </div>
                <Input
                    placeholder="CAP"
                    defaultValue={clientiState.rappLegaleEditCreateRequest.contactZipCode}
                    onChangeText={(text) => dispatch(setContactZipCode(text))}
                />
            </div>
        </div>
    </div>
    )
}

