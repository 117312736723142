import DatePicker from "react-datepicker"
import { CalendarIcon } from "../../icons/calendar"
import { InputDatePickerStyle } from "../input/utils"

interface Props {
    selected?: Date | null
    onChange?: (date: Date) => void
    disabled?: boolean
    errorLabel?: string
}

export function Calendar(props: Props) {
    return (
        <div>
            <DatePicker locale="it" dateFormat="d/MM/yyyy"
                selected={props.selected}
                showIcon
                disabled={props.disabled}
                icon={
                    <div style={{marginTop: '6px'}}>
                        <CalendarIcon size={20} color="black" />
                    </div>
                    }
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                customInput={<input style={{...InputDatePickerStyle, backgroundColor: !props.disabled ? 'white' : '#f2f2f2'}} />}
                onChange={
                    (date) => {
                        if (date !== null) {
                            props.onChange && props.onChange(date)
                        }
                    }
                }
            />
            {
                props.errorLabel && (
                    <span className="input-supporting-text pt-2" style={{color: '#fd665b', justifyContent:'left', display:'flex'}}>{props.errorLabel}</span>
                )
            }
        </div>
    )
}