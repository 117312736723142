import { useEffect, useRef, useState } from "react";
import { MenuKebab } from "../../icons/menuKebab";
import { DropdownProps } from "./Dropdown.types";
import DropdownContainer from "./DropdownContainer";

export function Dropdown(props: DropdownProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef<HTMLTableCellElement>(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            closeMenu();
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <div ref={menuRef} className="relative">
            <button onClick={e => { toggleMenu() }} className="focus:outline-none" >
                <MenuKebab color={'#fff'} size={20} />
            </button>
            {
                isMenuOpen &&
                <DropdownContainer
                    onClose={closeMenu}
                    items={props.items}
                />
            }
        </div>
    )
}