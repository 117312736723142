import { MenuItems } from "../../ui/organisms/navbar/dto";
import { Layout } from "../../ui/layout";

export function DashboardView() {
    

    return (
        <Layout menuItem={MenuItems.DASHBOARD} breadcrumbItems={['Dashboard']} headerLabel="Dashboard">
            <div></div>
        </Layout>
    )
}