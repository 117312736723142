import { GetAllOtherDataResponseDTO, OtherDataDTO } from "./dto";
import { OtherDataServiceImpl } from "./serviceImpl";

export interface OtherDataService {
    GetAllOtherData(): Promise<GetAllOtherDataResponseDTO>
    GetOtherDataById(id: string): Promise<OtherDataDTO>
    CreateOtherData(data: OtherDataDTO): Promise<void>
    UpdateOtherData(data: OtherDataDTO, id: string): Promise<void>
    DeleteOtherData(id: string): Promise<void>
}

export function NewOtherDataService(): OtherDataService {
    return new OtherDataServiceImpl();
}