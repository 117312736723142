import { useState } from "react";
import { CloseIcon } from "../../icons/close";
import "./styles.scss";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { RappLegaleCreation, resetCreateEditRapprLegale, setOnEditRappLegalePopup, setUserId, UpdateRappLegale } from "../../../pages/clienti/slice";
import Button from "../button";
import { HorizontalTab } from "../../organisms/horizontalTab";
import { Tab } from "react-tabs";
import clsx from "clsx"
import { RappLegaleAnagrafica } from "./content/anagrafica";
import { RappLegaleRecapiti } from "./content/recapiti";
import { RappLegaleContatti } from "./content/contatti";
import { RappLegaleDataIngUsc } from "./content/date";
import { RappLegaleNote } from "./content/note";

interface Props {
  closeHandler: () => void;
  onEdit?: boolean;
  idx?: number;
  id?: number;
  visible?: boolean;
}

export function RappLegalePopup(props: Props) {
  const { closeHandler, onEdit, id, visible, idx } = props;
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const dispatch = useAppDispatch();
  const clientiState = useAppSelector((state) => state.clienti);
  const usersState = useAppSelector(state => state.users)

  return (
    <>
      <div className="darkBG" onClick={() => closeHandler()} />

      <div id="rappLegPopup" className="centered">
        <div className="modal">
          <div className="modalHeader pl-5 pr-5">
            <h5 className="heading">{clientiState.onEditRappLegalePopup ? 'Modifica Rappresentante Legale' : 'Aggiungi Rappresentante Legale'}</h5>
            <button
              className="closeBtn"
              onClick={() => {
                dispatch(setOnEditRappLegalePopup(false));
                dispatch(resetCreateEditRapprLegale())
                dispatch(setUserId(usersState.selectedUserId))
                closeHandler();
              }}
            >
              <CloseIcon />
            </button>
          </div>

          <div className="modalContainerRL">
            <HorizontalTab
              children={[
                <>
                  <Tab
                    onClick={() => {
                      setSelectedTab(0)
                    }}
                    className={clsx(
                      "px-6 h-[64px] flex items-center cursor-pointer labelTab",
                      {
                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800":
                          selectedTab === 0,
                        "text-label-sm text-neutral-600":
                          selectedTab !== 0,
                      }
                    )}
                    tabIndex={"0"}
                  >
                    Anagrafica
                  </Tab>
                  <Tab
                    onClick={() => {
                      setSelectedTab(1)
                    }}
                    className={clsx(
                      "px-6 h-[64px] flex items-center cursor-pointer labelTab",
                      {
                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800":
                          selectedTab === 1,
                        "text-label-sm text-brandSecondary-600":
                          selectedTab !== 1,
                      }
                    )}
                    tabIndex={"1"}
                  >
                    Recapiti
                  </Tab>
                  <Tab
                    onClick={() => {
                      setSelectedTab(2)
                    }}
                    className={clsx(
                      "px-6 h-[64px] flex items-center cursor-pointer labelTab",
                      {
                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800":
                          selectedTab === 2,
                        "text-label-sm text-brandSecondary-600":
                          selectedTab !== 2,
                      }
                    )}
                    tabIndex={"2"}
                  >
                    Contatti
                  </Tab>
                  <Tab
                    onClick={() => {
                      setSelectedTab(3)
                    }}
                    className={clsx(
                      "px-6 h-[64px] flex items-center cursor-pointer labelTab",
                      {
                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800":
                          selectedTab === 3,
                        "text-label-sm text-brandSecondary-600":
                          selectedTab !== 3,
                      }
                    )}
                    tabIndex={"3"}
                  >
                    Data Ingresso/Uscita
                  </Tab>
                  <Tab
                    onClick={() => {
                      setSelectedTab(4)
                    }}
                    className={clsx(
                      "px-6 h-[64px] flex items-center cursor-pointer labelTab",
                      {
                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800":
                          selectedTab === 4,
                        "text-label-sm text-brandSecondary-600":
                          selectedTab !== 4,
                      }
                    )}
                    tabIndex={"4"}
                  >
                    Note
                  </Tab>
                </>
              ]}
            />
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                {
                    selectedTab === 0 &&
                    <RappLegaleAnagrafica/>

                }
                {
                    selectedTab === 1 &&
                    <RappLegaleRecapiti />
                }
                {
                    selectedTab === 2 &&
                    <RappLegaleContatti />

                }
                {
                    selectedTab === 3 &&
                    <RappLegaleDataIngUsc />
                }
                {
                    selectedTab === 4 &&
                    <RappLegaleNote />
                }
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 20, marginRight: 20}}>
                <div className="mr-5">
                    <Button 
                        variant="outline" 
                        color="blue" 
                        label="Annulla" 
                        iconPosition="off" 
                        size="md" 
                        onClick={() => {
                            dispatch(setOnEditRappLegalePopup(false));
                            dispatch(resetCreateEditRapprLegale())
                            closeHandler();
                        }}
                    />
                </div>
                <Button 
                    variant="solid" 
                    color="orange" 
                    label="Salva" 
                    iconPosition="off" 
                    size="md"
                    onClick={() => {
                        if(clientiState.onEditRappLegalePopup)
                          dispatch(UpdateRappLegale({id: clientiState.selectedRappLegale?.id || "", data: clientiState.rappLegaleEditCreateRequest}))
                        else
                         dispatch(RappLegaleCreation(clientiState.rappLegaleEditCreateRequest))

                        closeHandler();
                    }}
                    />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
