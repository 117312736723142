import React from "react";
import { IconProps } from "./dto";

export function ChevronrightIcon(props: IconProps) {
    return (
        <svg width="12" height="12" viewBox="0 0 8 12" fill="none">
            <path d="M1.5 11L6.5 6L1.5 1" stroke="#D9E6E8" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}