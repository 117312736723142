import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { Layout } from "../../../../ui/layout";
import Button from "../../../../ui/molecules/button";
import { MenuItems } from "../../../../ui/organisms/navbar/dto";
import { TrainingPopupEdit } from "../popup/edit";
import { GetAllTraining, resetTrainings, resetTrainingUpdateRequest, resetTrainingUpdateStatus, resetTrainingValidationStatus, TrainingUpdate, TrainingValidation } from "../slice";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left";

export function EditTraining() {
    const trainingState = useAppSelector(state => state.PMTraining)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(trainingState.trainingValidationStatus === 'successfully') {
            dispatch(resetTrainingValidationStatus())
            dispatch(TrainingUpdate({data: trainingState.trainingUpdateRequest, id: trainingState.trainingIdToUpdate}))
        }
    }, [trainingState.trainingValidationStatus])

    useEffect(() => {
        if(trainingState.trainingUpdateStatus === 'successfully') {
            dispatch(resetTrainingUpdateRequest())
            dispatch(resetTrainingUpdateStatus())
            dispatch(resetTrainings())
            dispatch(GetAllTraining(trainingState.getAllTrainingFilters))
            navigate('/formazione')
        }
    }, [trainingState.trainingUpdateStatus])

    return (
        <Layout menuItem={MenuItems.GESTIONE_PERSONALE} breadcrumbItems={['Formazione dipendenti', 'Formazione', 'Modifica Formazione']}
            headerLabel="Modifica Formazione"
            rightBar={true}
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => navigate('/formazione')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(TrainingValidation(trainingState.trainingUpdateRequest))
                        }}
                    />
                </div>
            }
        >
            <div>
                <TrainingPopupEdit />
            </div>
        </Layout>
    )
}