import React from "react";
import { IconProps } from "./dto";

export function LogoBio(props: IconProps) {
    return (
        <svg width="80" height="80" viewBox="0 0 512 512" fill="none">
            <g clip-path="url(#clip0_2069_126577)">
                <g clip-path="url(#clip1_2069_126577)">
                    <path d="M576 41.6001H-64V470.4H576V41.6001Z" fill="#97BE0D" />
                    <path d="M168.992 141.948C187.331 143.099 205.427 145.241 221.112 151.701C206.411 157.191 195.251 163.681 182.488 169.672L192.576 127.824C200.573 143.791 209.027 160.758 213.48 176.725C198.944 162.564 184.075 152.066 168.992 141.948Z" fill="white" />
                    <path d="M249.492 137.699L290.86 152.702L258.488 167.923C261.624 154.253 265.113 140.408 269.576 126.075C274.273 143.74 279.108 161.974 282.478 178.976C271.99 164.119 260.59 151.337 249.492 137.699Z" fill="white" />
                    <path d="M331.493 152.447C343.681 154.518 356.522 153.983 369.362 152.45C358.851 161.888 348.161 171.146 336.989 179.921C341.937 165.892 346.542 151.519 350.827 136.823C353.793 151.29 356.761 165.757 359.728 180.224C350.899 172.688 341.07 163.432 331.493 152.447Z" fill="white" />
                    <path d="M414.492 151.197C424.614 148.199 437.737 140.604 449.11 135.2C439.652 147.532 430.195 159.173 420.737 170.171C423.894 157.498 428.438 144.317 432.576 130.573C435.74 140.093 438.148 148.87 442.478 157.474C433.15 156.943 423.82 155.044 414.492 151.197Z" fill="white" />
                    <path d="M378.992 222.198C396.106 219.162 407.589 213.978 421.362 209.201C409.323 221.526 395.163 238.257 385.239 246.172L400.576 201.323C404.021 213.814 408.136 225.633 412.229 237.474C400.695 234.698 389.863 228.961 378.992 222.198Z" fill="white" />
                    <path d="M337.242 278.447C352.747 282.255 366.525 284.972 382.861 283.7C372.659 293.532 360.064 299.775 347.238 305.671C351.501 293.108 355.719 280.449 359.327 266.574C364.704 280.707 369.28 297.342 375.479 308.225C360.176 299.151 348.626 288.828 337.242 278.447Z" fill="white" />
                    <path d="M292.493 314.948C306.866 321.049 320.488 327.35 335.612 330.201C324.629 335.001 313.623 339.775 301.989 343.923C305.252 331.639 308.152 320.107 312.077 307.823C316.882 323.15 322.186 338.31 328.98 352.975C316.021 343.095 303.549 328.729 292.493 314.948Z" fill="white" />
                    <path d="M233.742 337.448C248.448 341.697 263.155 348.057 277.861 354.952C265.587 360.078 253.595 366.048 241.987 373.172C246.928 357.947 252.318 342.406 255.576 327.574C260.125 345.206 266.112 363.684 270.478 381.726C261.606 371.132 245.962 351.041 233.742 337.448Z" fill="white" />
                    <path d="M145.992 349.198C162.878 346.203 179.392 345.448 195.611 346.451C179.99 360.411 167.315 372.408 153.488 385.173C159.934 364.973 166.381 344.774 172.827 324.574C176.867 343.645 180.347 363.837 183.728 384.225C173.922 373.277 159.37 360.077 145.992 349.198Z" fill="white" />
                    <path d="M115.242 196.948C134.258 191.213 152.757 190.908 171.112 192.451L129.238 219.923C133.766 205.473 138.163 191.023 143.326 176.574C151.202 190.677 158.341 204.785 164.229 218.225C151.062 208.719 133.002 202.516 115.242 196.948Z" fill="white" />
                    <path d="M78.4922 278.198C97.0322 268.76 115.821 261.594 134.362 261.451L89.4882 305.173L107.576 245.824C114.338 261.432 121 278.613 124.479 294.226C110.242 287.456 95.3645 281.674 78.4922 278.198Z" fill="white" />
                    <path d="M45.9922 379.197C56.3392 375.445 74.6736 361.18 85.6366 358.042C116.33 305.384 239.584 232.608 296.939 226.77C298.55 226.607 298.363 227.744 297.005 228.141C236.861 245.69 135.883 300.693 98.1105 360.45C83.5858 383.428 77.8848 386.69 58.9882 408.421C64.9838 385.372 71.1165 362.277 79.0766 338.573C86.3782 356.596 91.6161 375.306 96.9793 393.973C82.0571 385.916 64.2455 381.26 45.9922 379.197Z" fill="white" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_2069_126577">
                    <rect width="512" height="512" fill="white" />
                </clipPath>
                <clipPath id="clip1_2069_126577">
                    <rect width="640" height="428.8" fill="white" transform="translate(-64 41.6001)" />
                </clipPath>
            </defs>
        </svg>


    )
}