import clsx from "clsx"
import { Tab } from "react-tabs"
import { Layout } from "../../ui/layout"
import { HorizontalTab } from "../../ui/organisms/horizontalTab"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { setSelectedTab } from "./slice"
import { EntryDocuments } from "./entryDocuments"
import { DepartureDocuments } from "./departureDocuments"
import { UploadedDocuments } from "./uploadedDocuments"
import { Banner } from "../../ui/organisms/banner"

export function GestioneDocumenti() {
    const dispatch = useAppDispatch()
    const customersState = useAppSelector(state => state.clienti)
    const documentManagementState = useAppSelector(state => state.documentManagement)
    /* Dati salvati in sessione per cliente selezionato */
    
    const selectedCustomerName = localStorage.getItem('selectedCustomerName') ? localStorage.getItem('selectedCustomerName') : ''
    const selectedCustomerCode = localStorage.getItem('selectedCustomerCode') ? localStorage.getItem('selectedCustomerCode') : ''
    const selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer')!)
    if (selectedCustomer) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Documenti']}
                noPaddingLat
                headerLabel={"Gestione Documenti"}
                customerName={selectedCustomerName ? selectedCustomerName : undefined}
                customerCode={selectedCustomerCode ? selectedCustomerCode : undefined}
            >
                <div>
                    <HorizontalTab
                        children={[
                            <>
                                <Tab
                                    onClick={() => { dispatch(setSelectedTab(0)) }}
                                    className={
                                        clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                            "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": documentManagementState.selectedTab === 0,
                                            "text-label-sm text-neutral-600": documentManagementState.selectedTab !== 0
                                        })
                                    }
                                    tabIndex={"0"}
                                >
                                    Documenti in Entrata
                                </Tab>

                                <Tab
                                    onClick={() => { dispatch(setSelectedTab(1)) }}
                                    className={
                                        clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                            "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": documentManagementState.selectedTab === 1,
                                            "text-label-sm text-neutral-600": documentManagementState.selectedTab !== 1
                                        })
                                    }
                                    tabIndex={"1"}
                                >
                                    Documenti in Partenza
                                </Tab>

                                <Tab
                                    onClick={() => { dispatch(setSelectedTab(2)) }}
                                    className={
                                        clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                            "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": documentManagementState.selectedTab === 2,
                                            "text-label-sm text-neutral-600": documentManagementState.selectedTab !== 2
                                        })
                                    }
                                    tabIndex={"2"}
                                >
                                    Documenti Caricati
                                </Tab>
                            </>
                        ]}
                    />
                    {
                        /* da rimpiazzare con l'id del customer */
                        true ? (
                            <div style={{ display: 'flex', height: '100%' }} className="bg-brandPrimary-100">
                                <div className="fieldset bg-brandPrimary-200" style={{ flex: 1, borderTopRightRadius: '16px', padding: '24px 0px 0px 0px' }}>
                                    {
                                        documentManagementState.selectedTab === 0 &&
                                        <EntryDocuments selectedCustomer={selectedCustomer}/>
                                    }
                                    {
                                        documentManagementState.selectedTab === 1 &&
                                        <DepartureDocuments selectedCustomer={selectedCustomer}/>
                                    }
                                    {
                                        documentManagementState.selectedTab === 2 &&
                                        <UploadedDocuments selectedCustomer={selectedCustomer}/>
                                    }
                                </div>
                            </div >
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="gap-[8px] text-left bg-red-300" style={{ margin: '24px', borderRadius: '8px' }}>
                                    <span className="text-red-700 fieldsetTitle" style={{ padding: '45px 37px', fontSize: '21px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                        Non è stato selezionato alcun cliente.
                                    </span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Layout>
        )
    }
    else {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Documenti']}
                noPaddingLat
                headerLabel={"Gestione Documenti"}
                customerName={selectedCustomerName ? selectedCustomerName : undefined}
                customerCode={selectedCustomerCode ? selectedCustomerCode : undefined}
            >
                <Banner
                    type="error"
                    visible={selectedCustomer === null}
                    label={"Devi prima selezionare un cliente per accedere all'area"}
                    closeAction={function (): void {
                        alert("Devi prima selezionare un cliente per accedere all'area")
                    }}
                />
            </Layout>
        )
    }
}