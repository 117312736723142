import { IconProps } from "./dto";

export function SidebarIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.475 18.9584H7.47498C2.94998 18.9584 1.01665 17.0251 1.01665 12.5001V7.50008C1.01665 2.97508 2.94998 1.04175 7.47498 1.04175H12.475C17 1.04175 18.9333 2.97508 18.9333 7.50008V12.5001C18.9333 17.0251 17.0083 18.9584 12.475 18.9584ZM7.47498 2.29175C3.63331 2.29175 2.26665 3.65841 2.26665 7.50008V12.5001C2.26665 16.3417 3.63331 17.7084 7.47498 17.7084H12.475C16.3166 17.7084 17.6833 16.3417 17.6833 12.5001V7.50008C17.6833 3.65841 16.3166 2.29175 12.475 2.29175H7.47498Z" fill="#8383AD"/>
            <path d="M6.64165 18.9584C6.29998 18.9584 6.01665 18.6751 6.01665 18.3334V1.66675C6.01665 1.32508 6.29998 1.04175 6.64165 1.04175C6.98331 1.04175 7.26665 1.32508 7.26665 1.66675V18.3334C7.26665 18.6751 6.99165 18.9584 6.64165 18.9584Z" fill="#8383AD"/>
            <path d="M12.475 12.7582C12.3167 12.7582 12.1583 12.6999 12.0333 12.5749L9.89998 10.4416C9.65832 10.1999 9.65832 9.7999 9.89998 9.55824L12.0333 7.4249C12.275 7.18324 12.675 7.18324 12.9167 7.4249C13.1583 7.66657 13.1583 8.06657 12.9167 8.30824L11.2333 9.9999L12.925 11.6916C13.1667 11.9332 13.1667 12.3332 12.925 12.5749C12.8 12.6999 12.6417 12.7582 12.475 12.7582Z" fill="#8383AD"/>
        </svg>

    )
}