import { useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';

import "react-datepicker/dist/react-datepicker.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useNavigate } from "react-router-dom";
import { MinusIcon } from "../../ui/icons/minusIcon";
import React from "react";
import { PlusIconAccordion } from "../../ui/icons/plusIcon";
import AccordionDetails from "@mui/material/AccordionDetails";
import Input from "../../ui/molecules/input/Input";
import TextArea from "../../ui/molecules/textArea/TextArea";

import './style.scss';
import { setContactEmail, setContactFax, setContactMobilePhone, setContactNote, setContactOfficePhone, setContactOtherEmail, setContactPec, setContactPersonalEmail, setContactPhone } from "./slice";


export function BoxRubrica() {
    const pathname = window.location.pathname
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    return (
        <div style={{ padding: '24px' }} id="boxRubrica">

            <div className="mt-6">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel1' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel1-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Contatti
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{padding:'24px'}}>

                        <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input label={'Telefono'} placeholder="Telefono" onChangeText={(text) =>  dispatch(setContactPhone(text)) } />
                            </div>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input label={'Telefono Ufficio'} placeholder="Telefono Ufficio" onChangeText={(text) => dispatch(setContactOfficePhone(text)) } />
                            </div>

                        </div>

                        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input label={'Cellulare'} placeholder="Cellulare" onChangeText={(text) => dispatch(setContactMobilePhone(text)) } />
                            </div>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input label={'Fax'} placeholder="Fax" onChangeText={(text) => dispatch(setContactFax(text)) } />
                            </div>

                        </div>

                        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input label={'Email'} placeholder="Email" onChangeText={(text) =>  dispatch(setContactEmail(text)) } />
                            </div>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input label={'Email Personale'} placeholder="Email Personale" onChangeText={(text) =>  dispatch(setContactPersonalEmail(text)) } />
                            </div>

                        </div>

                        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input label={'PEC'} placeholder="PEC" onChangeText={(text) => dispatch(setContactPec(text)) } />
                            </div>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input label={'Altra Email'} placeholder="Altra Email" onChangeText={(text) => dispatch(setContactOtherEmail(text)) } />
                            </div>

                        </div>

                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="mt-6">
                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel2' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel2-content"
                        className="rounded-xl headingAccordion"
                        id="panel2-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Note
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{padding:'24px'}}>
                        <div className="formInOut">

                            <TextArea onChangeText={(text) => dispatch(setContactNote(text))} />
                        </div>

                    </AccordionDetails>
                </Accordion>
            </div>


        </div >
    )
}