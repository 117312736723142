import { GetAllOtherInfoResponseDTO, OtherInfoDTO, OtherInfoResponseDTO } from "./dto";
import { OtherInfoServiceImpl } from "./serviceImpl";


export interface OtherInfoService {
    GetAllOtherInfo(): Promise<GetAllOtherInfoResponseDTO>
    GetOtherInfoById(id: string): Promise<OtherInfoResponseDTO>
    CreateOtherInfo(data: OtherInfoDTO): Promise<void>
    OtherInfoUpdate(data: OtherInfoDTO, id: string): Promise<void>
    DeleteOtherInfo(id: string): Promise<void>
}

export function NewOtherInfoService(): OtherInfoService {
    return new OtherInfoServiceImpl();
}