import clsx from "clsx";
import { colors } from "../../colors";
import { PlusIcon } from "../../icons/plus";
import { BannerProps } from "./dto";

export function Banner(props: BannerProps) {
    return (
        <>
            <div className={clsx("transition-all duration-[400ms] ease-out rounded-[12px] w-[100%] p-[24px] items-center justify-between", {
                "flex": props.visible,
                "hidden": !props.visible,
                'bg-red-200': props.type === 'error',
                'bg-green-200': props.type === 'success',
            })}>
                <span className={clsx("text-text-md font-semibold", {
                    "text-red-800": props.type === 'error',
                    'text-green-800': props.type === 'success',
                })}>{props.label}</span>

                <div
                    onClick={() => props.closeAction()}
                    className={clsx("rounded-[50%] border-[1px] p-[2px] rotate-45 cursor-pointer", {
                        "border-red-800": props.type === 'error',
                        'border-green-800': props.type === 'success',
                    })}
                >
                    <PlusIcon color={props.type === 'error' ? colors.red[800] : colors.green[800]} size={20} />
                </div>


            </div>
            <div className={clsx({
                "flex": props.visible,
                "hidden": !props.visible,
                'bg-red-200': props.type === 'error',
                'bg-green-200': props.type === 'success',
            })}>
                <div style={{ padding: '0px 0px 24px 24px', textAlign: 'left' }}>
                    {
                        props.errorsList?.map((error) =>

                            <span style={{ marginRight: '10px' }} className={clsx("text-text-sm", {
                                "text-red-800": props.type === 'error',
                                'text-green-800': props.type === 'success',
                            })}> {error + ' '} </span>

                        )
                    }
                </div>

                {/* <ul style={{display:'inline-flex', padding: '0px 0px 24px 0px', marginLeft: '25px', gap:'10px'}}>
                    {
                        props.errorsList?.map((error) =>

                            <li className={clsx("text-text-sm", {
                                "text-red-800": props.type === 'error',
                                'text-green-800': props.type === 'success',
                            })}> {error+ ' ' } </li>

                        )
                    }
                </ul> */}

            </div>

        </>
    )
}