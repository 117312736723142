import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { RequirementDetailDTO, RequirementDetailErrorsDTO, RequirementDTO, RequirementErrorsDTO } from "./dataService/dto";
import { NewRequirementService } from "./dataService/service";

interface RequirementState {
    requirements: RequirementDTO[]
    requirementDetails: RequirementDetailDTO[]

    // REQUEST (REQUIREMENT)
    requirementCreationRequest: RequirementDTO
    requirementUpdateRequest: RequirementDTO
    requirementIdToUpdate: string

    // REQUEST (REQUIREMENT DETAIL)
    requirementDetailCreationRequest: RequirementDetailDTO
    requirementDetailUpdateRequest: RequirementDetailDTO
    requirementDetailIdToUpdate: string

    // ERROR
    requirementErrors: RequirementErrorsDTO
    requirementDetailErrors: RequirementDetailErrorsDTO

    // STATUS (REQUIREMENT)
    requirementCreationStatus: PromiseStatuses
    requirementUpdateStatus: PromiseStatuses
    requirementDeleteStatus: PromiseStatuses
    getRequirementByIdStatus: PromiseStatuses
    getAllRequirementsStatus: PromiseStatuses
    requirementValidationStatus: PromiseStatuses

    // STATUS (REQUIREMENT DETAIL)
    requirementDetailCreationStatus: PromiseStatuses
    requirementDetailUpdateStatus: PromiseStatuses
    requirementDetailDeleteStatus: PromiseStatuses
    getRequirementDetailByIdStatus: PromiseStatuses
    requirementDetailValidationStatus: PromiseStatuses

    // RESPONSE
    getRequirementByIdResponse?: RequirementDTO
    getRequirementDetailByIdResponse?: RequirementDetailDTO
    getAllRequirementsResponse?: RequirementDTO[]
    
}

const initialState: RequirementState = {
    requirements: [],
    requirementDetails: [],

    // REQUEST (REQUIREMENT)
    requirementCreationRequest: {
        requirement: '',
        spec: '',
        norm: []
    },
    requirementUpdateRequest: {
        requirement: '',
        spec: '',
        norm: []
    },
    requirementIdToUpdate: '',

    // REQUEST (REQUIREMENT DETAIL)
    requirementDetailCreationRequest: {
        starting: new Date(),
        ending: new Date(),
        detail: '',
        protocols: '',
        requirementId: '',
        personalManagementId: ''
    },
    requirementDetailUpdateRequest: {
        starting: new Date(),
        ending: new Date(),
        detail: '',
        protocols: '',
    },
    requirementDetailIdToUpdate: '',

    // ERROR
    requirementErrors: {
        requirement: false,
        spec: false,
        norm: false
    },
    requirementDetailErrors: {
        detail: false,
        protocols: false
    },

    // STATUS (REQUIREMENT)
    requirementCreationStatus: 'idle',
    requirementUpdateStatus: 'idle',
    requirementDeleteStatus: 'idle',
    getRequirementByIdStatus: 'idle',
    getAllRequirementsStatus: 'idle',
    requirementValidationStatus: 'idle',

    // STATUS (REQUIREMENT DETAIL)
    requirementDetailCreationStatus: 'idle',
    requirementDetailUpdateStatus: 'idle',
    requirementDetailDeleteStatus: 'idle',
    getRequirementDetailByIdStatus: 'idle',
    requirementDetailValidationStatus: 'idle'
}

export const RequirementCreation = createAsyncThunk(
    'PM/Requirement/Creation',
    async (body: RequirementDTO, thunkApi): Promise<void> => {
        const requirementService = NewRequirementService()

        return requirementService.CreateRequirement(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const RequirementUpdate = createAsyncThunk(
    'PM/Requirement/Update',
    async (request: {body: RequirementDTO, id: string}, thunkApi): Promise<void> => {
        const requirementService = NewRequirementService()

        return requirementService.UpdateRequirement(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const RequirementDelete = createAsyncThunk(
    'PM/Requirement/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const requirementService = NewRequirementService()

        return requirementService.DeleteRequirement(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const GetAllRequirements = createAsyncThunk(
    'PM/Requirement/GetAll',
    async (thunkApi): Promise<RequirementDTO[]> => {
        const requirementService = NewRequirementService()

        return requirementService.GetAllRequirements()
    }
)

export const GetRequirementById = createAsyncThunk(
    'PM/Requirement/GetById',
    async (id: string, thunkApi): Promise<RequirementDTO> => {
        const requirementService = NewRequirementService()

        return requirementService.GetRequirementById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const RequirementValidation = createAsyncThunk(
    'PM/Requirement/Validate',
    async (request: RequirementDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetRequirementErrors())

        if(request.requirement === '') {
            thunkApi.dispatch(setValidateRequirement(true))
            isValid = false
        }

        if(request.norm.length < 1) {
            thunkApi.dispatch(setValidateNorm(true))
            isValid = false
        }

        if(request.spec === '') {
            thunkApi.dispatch(setValidateSpec(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const RequirementDetailCreation = createAsyncThunk(
    'PM/RequirementDetail/Creation',
    async (body: RequirementDetailDTO, thunkApi): Promise<void> => {
        const requirementService = NewRequirementService()

        return requirementService.CreateRequirementDetail(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const RequirementDetailUpdate = createAsyncThunk(
    'PM/RequirementDetail/Update',
    async (request: {body: RequirementDetailDTO, id: string}, thunkApi): Promise<void> => {
        const requirementService = NewRequirementService()

        return requirementService.UpdateRequirementDetail(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const RequirementDetailDelete = createAsyncThunk(
    'PM/RequirementDetail/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const requirementService = NewRequirementService()

        return requirementService.DeleteRequirementDetail(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const GetRequirementDetailById = createAsyncThunk(
    'PM/RequirementDetail/GetById',
    async (id: string, thunkApi): Promise<RequirementDetailDTO> => {
        const requirementService = NewRequirementService()

        return requirementService.GetRequirementDetailById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const RequirementDetailValidation = createAsyncThunk(
    'PM/RequirementDetail/Validate',
    async (request: RequirementDetailDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetRequirementErrors())

        if(request.detail === '') {
            thunkApi.dispatch(setValidateDetail(true))
            isValid = false
        }

        if(request.protocols === '') {
            thunkApi.dispatch(setValidateProtocols(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

const PMRequirements = createSlice({
    name: 'PM/Requirements/Slice',
    initialState,
    reducers: {
        resetRequirements: (state) => {
            state.requirements = []
        },
        addRequirement: (state, action) => {
            state.requirements.push(action.payload)
        },
        setRequirements: (state, action) => {
            state.requirements = action.payload
        },
        resetRequirementDetails: (state) => {
            state.requirementDetails = []
        },
        addRequirementDetail: (state, action) => {
            state.requirementDetails.push(action.payload)
        },
        setRequirementDetails: (state, action) => {
            state.requirementDetails = action.payload
        },

        // REQUEST [REQUIREMENT] (CREATION)
        setRequirement: (state, action) => {
            state.requirementCreationRequest.requirement = action.payload
        },
        setSpec: (state, action) => {
            state.requirementCreationRequest.spec = action.payload
        },
        setNorm: (state, action) => {
            state.requirementCreationRequest.norm = action.payload
        },
        resetRequirementCreationRequest: (state) => {
            state.requirementCreationRequest = {
                requirement: '',
                spec: '',
                norm: []
            }
        },

        // REQUEST [REQUIREMENT] (UPDATE)
        setUpdateRequirement: (state, action) => {
            state.requirementUpdateRequest.requirement = action.payload
        },
        setUpdateSpec: (state, action) => {
            state.requirementUpdateRequest.spec = action.payload
        },
        setUpdateNorm: (state, action) => {
            state.requirementUpdateRequest.norm = action.payload
        },
        setRequirementIdToUpdate: (state, action) => {
            state.requirementIdToUpdate = action.payload
        },
        resetRequirementUpdateRequest: (state) => {
            state.requirementUpdateRequest = {
                requirement: '',
                spec: '',
                norm: []
            }
        },

        // REQUEST [REQUIREMENT DETAIL] (CREATION)
        setReqDetailStarting: (state, action) => {
            state.requirementDetailCreationRequest.starting = action.payload
        },
        setReqDetailEnding: (state, action) => {
            state.requirementDetailCreationRequest.ending = action.payload
        },
        setReqDetailDetail: (state, action) => {
            state.requirementDetailCreationRequest.detail = action.payload
        },
        setReqDetailProtocols: (state, action) => {
            state.requirementDetailCreationRequest.protocols = action.payload
        },
        setReqDetailNote: (state, action) => {
            state.requirementDetailCreationRequest.note = action.payload
        },
        setReqDetailPersonalManagementId: (state, action) => {
            state.requirementDetailCreationRequest.personalManagementId = action.payload
        },
        setReqDetailRequirementId: (state, action) => {
            state.requirementDetailCreationRequest.requirementId = action.payload
        },
        resetRequirementDetailCreationRequest: (state) => {
            state.requirementDetailCreationRequest = {
                starting: new Date(),
                ending: new Date(),
                detail: '',
                protocols: '',
                requirementId: '',
                personalManagementId: state.requirementDetailCreationRequest.personalManagementId
            }
        },

        // REQUEST [REQUIREMENT DETAIL] (UPDATE)
        setUpdateReqDetailStarting: (state, action) => {
            state.requirementDetailUpdateRequest.starting = action.payload
        },
        setUpdateReqDetailEnding: (state, action) => {
            state.requirementDetailUpdateRequest.ending = action.payload
        },
        setUpdateReqDetailDetail: (state, action) => {
            state.requirementDetailUpdateRequest.detail = action.payload
        },
        setUpdateReqDetailProtocols: (state, action) => {
            state.requirementDetailUpdateRequest.protocols = action.payload
        },
        setUpdateReqDetailNote: (state, action) => {
            state.requirementDetailUpdateRequest.note = action.payload
        },
        setRequirementDetailIdToUpdate: (state, action) => {
            state.requirementDetailIdToUpdate = action.payload
        },
        resetRequirementDetailUpdateRequest: (state) => {
            state.requirementDetailUpdateRequest = {
                starting: new Date(),
                ending: new Date(),
                detail: '',
                protocols: '',
                requirementId: '',
                personalManagementId: state.requirementDetailUpdateRequest.personalManagementId
            }
        },

        // ERROR
        setValidateRequirement: (state, action) => {
            state.requirementErrors.requirement = action.payload
        },
        setValidateSpec: (state, action) => {
            state.requirementErrors.spec = action.payload
        },
        setValidateNorm: (state, action) => {
            state.requirementErrors.norm = action.payload
        },
        setValidateDetail: (state, action) => {
            state.requirementDetailErrors.detail = action.payload
        },
        setValidateProtocols: (state, action) => {
            state.requirementDetailErrors.protocols = action.payload
        },
        resetRequirementErrors: (state) => {
            state.requirementErrors = {
                requirement: false,
                spec: false,
                norm: false
            }
        },
        resetRequirementDetailErrors: (state) => {
            state.requirementDetailErrors = {
                detail: false,
                protocols: false
            }
        },

        // STATUS
        resetRequirementCreationStatus: (state) => {
            state.requirementCreationStatus = 'idle'
        },
        resetRequirementUpdateStatus: (state) => {
            state.requirementUpdateStatus = 'idle'
        },
        resetRequirementDeleteStatus: (state) => {
            state.requirementDeleteStatus = 'idle'
        },
        resetGetRequirementByIdStatus: (state) => {
            state.getRequirementByIdStatus = 'idle'
        },
        resetGetAllRequirementStatus: (state) => {
            state.getAllRequirementsStatus = 'idle'
        },
        resetRequirementDetailCreationStatus: (state) => {
            state.requirementDetailCreationStatus = 'idle'
        },
        resetRequirementDetailUpdateStatus: (state) => {
            state.requirementDetailUpdateStatus = 'idle'
        },
        resetRequirementDetailDeleteStatus: (state) => {
            state.requirementDetailDeleteStatus = 'idle'
        },
        resetGetRequirementDetailByIdStatus: (state) => {
            state.getRequirementDetailByIdStatus = 'idle'
        },
        resetRequirementValidationStatus: (state) => {
            state.requirementValidationStatus = 'idle'
        },
        resetRequirementDetailValidationStatus: (state) => {
            state.requirementDetailValidationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder

            // [REQUIREMENT] CREATION
            .addCase(RequirementCreation.pending, (state) => {
                state.requirementCreationStatus = 'loading'
            })
            .addCase(RequirementCreation.fulfilled, (state) => {
                state.requirementCreationStatus = 'successfully'
            })
            .addCase(RequirementCreation.rejected, (state) => {
                state.requirementCreationStatus = 'failed'
            })

            // [REQUIREMENT] UPDATE
            .addCase(RequirementUpdate.pending, (state) => {
                state.requirementUpdateStatus = 'loading'
            })
            .addCase(RequirementUpdate.fulfilled, (state) => {
                state.requirementUpdateStatus = 'successfully'
            })
            .addCase(RequirementUpdate.rejected, (state) => {
                state.requirementUpdateStatus = 'failed'
            })

            // [REQUIREMENT] DELETE
            .addCase(RequirementDelete.pending, (state) => {
                state.requirementDeleteStatus = 'loading'
            })
            .addCase(RequirementDelete.fulfilled, (state) => {
                state.requirementDeleteStatus = 'successfully'
            })
            .addCase(RequirementDelete.rejected, (state) => {
                state.requirementDeleteStatus = 'failed'
            })

            // [REQUIREMENT] GET BY ID
            .addCase(GetRequirementById.pending, (state) => {
                state.getRequirementByIdStatus = 'loading'
            })
            .addCase(GetRequirementById.fulfilled, (state, action) => {
                state.getRequirementByIdStatus = 'successfully'
                state.getRequirementByIdResponse = action.payload
            })
            .addCase(GetRequirementById.rejected, (state) => {
                state.getRequirementByIdStatus = 'failed'
            })

            // [REQUIREMENT] GET ALL
            .addCase(GetAllRequirements.pending, (state) => {
                state.getAllRequirementsStatus = 'loading'
            })
            .addCase(GetAllRequirements.fulfilled, (state, action) => {
                state.getAllRequirementsStatus = 'successfully'
                state.getAllRequirementsResponse = action.payload
            })
            .addCase(GetAllRequirements.rejected, (state) => {
                state.getAllRequirementsStatus = 'failed'
            })

            // [REQUIREMENT] VALIDATION
            .addCase(RequirementValidation.pending, (state) => {
                state.requirementValidationStatus = 'loading'
            })
            .addCase(RequirementValidation.fulfilled, (state) => {
                state.requirementValidationStatus = 'successfully'
            })
            .addCase(RequirementValidation.rejected, (state) => {
                state.requirementValidationStatus = 'failed'
            })

            // [REQUIREMENT DETAIL] CREATION
            .addCase(RequirementDetailCreation.pending, (state) => {
                state.requirementDetailCreationStatus = 'loading'
            })
            .addCase(RequirementDetailCreation.fulfilled, (state) => {
                state.requirementDetailCreationStatus = 'successfully'
            })
            .addCase(RequirementDetailCreation.rejected, (state) => {
                state.requirementDetailCreationStatus = 'failed'
            })

            // [REQUIREMENT DETAIL] UPDATE
            .addCase(RequirementDetailUpdate.pending, (state) => {
                state.requirementDetailUpdateStatus = 'loading'
            })
            .addCase(RequirementDetailUpdate.fulfilled, (state) => {
                state.requirementDetailUpdateStatus = 'successfully'
            })
            .addCase(RequirementDetailUpdate.rejected, (state) => {
                state.requirementDetailUpdateStatus = 'failed'
            })

            // [REQUIREMENT DETAIL] DELETE
            .addCase(RequirementDetailDelete.pending, (state) => {
                state.requirementDetailDeleteStatus = 'loading'
            })
            .addCase(RequirementDetailDelete.fulfilled, (state) => {
                state.requirementDetailDeleteStatus = 'successfully'
            })
            .addCase(RequirementDetailDelete.rejected, (state) => {
                state.requirementDetailDeleteStatus = 'failed'
            })

            // [REQUIREMENT DETAIL] GET BY ID
            .addCase(GetRequirementDetailById.pending, (state) => {
                state.getRequirementDetailByIdStatus = 'loading'
            })
            .addCase(GetRequirementDetailById.fulfilled, (state, action) => {
                state.getRequirementDetailByIdStatus = 'successfully'
                state.getRequirementDetailByIdResponse = action.payload
            })
            .addCase(GetRequirementDetailById.rejected, (state) => {
                state.getRequirementDetailByIdStatus = 'failed'
            })

            // [REQUIREMENT DETAIL] VALIDATION
            .addCase(RequirementDetailValidation.pending, (state) => {
                state.requirementDetailValidationStatus = 'loading'
            })
            .addCase(RequirementDetailValidation.fulfilled, (state) => {
                state.requirementDetailValidationStatus = 'successfully'
            })
            .addCase(RequirementDetailValidation.rejected, (state) => {
                state.requirementDetailValidationStatus = 'failed'
            })

    },
})

export const {
    resetRequirements,
    addRequirement,
    setRequirements,
    resetRequirementDetails,
    addRequirementDetail,
    setRequirementDetails,

    // REQUEST [REQUIREMENT]
    setNorm,
    setRequirement,
    setSpec,
    setUpdateNorm,
    setUpdateRequirement,
    setUpdateSpec,
    setRequirementIdToUpdate,
    resetRequirementCreationRequest,
    resetRequirementUpdateRequest,

    // REQUEST [REQUIREMENT DETAIL]
    setReqDetailDetail,
    setReqDetailEnding,
    setReqDetailNote,
    setReqDetailPersonalManagementId,
    setReqDetailRequirementId,
    setReqDetailProtocols,
    setReqDetailStarting,
    setRequirementDetailIdToUpdate,
    setUpdateReqDetailDetail,
    setUpdateReqDetailEnding,
    setUpdateReqDetailNote,
    setUpdateReqDetailProtocols,
    setUpdateReqDetailStarting,
    resetRequirementDetailCreationRequest,
    resetRequirementDetailUpdateRequest,

    // ERROR
    setValidateDetail,
    setValidateNorm,
    setValidateProtocols,
    setValidateRequirement,
    setValidateSpec,
    resetRequirementDetailErrors,
    resetRequirementErrors,

    // STATUS
    resetGetRequirementByIdStatus,
    resetGetAllRequirementStatus,
    resetGetRequirementDetailByIdStatus,
    resetRequirementCreationStatus,
    resetRequirementDeleteStatus,
    resetRequirementDetailCreationStatus,
    resetRequirementDetailDeleteStatus,
    resetRequirementDetailUpdateStatus,
    resetRequirementUpdateStatus,
    resetRequirementDetailValidationStatus,
    resetRequirementValidationStatus
    
} = PMRequirements.actions

export default PMRequirements.reducer