import axios from "axios";
import { GetFileByIdDTO, GetObjectByIdDTO } from "./dto";
import { ObjectStorageService } from "./service";
import { keycloak } from "../../lib/keycloak";
import { getMimeTypeFromBase64 } from "../utils";

export class ObjectStorageServiceImpl implements ObjectStorageService {

    public GetFileObjectById(id: string): Promise<GetObjectByIdDTO> {
        return axios.get("/api/object-storage/find-object-by-id/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetFileById(id: string): Promise<GetFileByIdDTO> {
        return axios.get("/api/object-storage/find-file-by-id/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public RemoveDots(value?: string): string {
        if (!value) {
            return '';
        }

        let fileNameArray = value.split('.');

        if (fileNameArray[fileNameArray.length - 1] === '') {
            fileNameArray.pop();
        }

        return fileNameArray.join('-');
    }

    public Download(name: string, blob: Blob): void {
        const link = document.createElement('a');
        let newNameFile = this.RemoveDots(name)

        link.download = newNameFile;
        
        link.href = URL.createObjectURL(blob);

        link.click();
    }

    public Base64ToFile(base64String: string, type: string): File {

        const byteCharacters = atob(base64String !== undefined ? base64String : '');
        const byteArray = new Uint8Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }

        return new File([new Blob([byteArray])], 'unknown', { type: type });
    }
}