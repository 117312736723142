import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { PromiseStatuses } from "../../lib/utils"
import { format } from "date-fns";
import { CreateTechnicianDTO, GetBusinessTechnicianDTO, addTechinicianUserDTO, findAllBusinessTechnicianDTO } from "./dto";
import { NewBusinessTechnicianService } from "./service";
import { act } from "react";

interface BusinessTechnicianState {
    createBusinessTechnician: CreateTechnicianDTO
    technicianCreationStatus: PromiseStatuses
    businessTechinicianResponse?: findAllBusinessTechnicianDTO
    businessTechById?: GetBusinessTechnicianDTO
    businessTechByIdStatus: PromiseStatuses
    businessTechinicianResponseStatus: PromiseStatuses
    businessTechinicianEditStatus: PromiseStatuses
    userBusinessTechnicianLink: addTechinicianUserDTO
    idBusinessTechUser: string
    selectedId: string
}

const initialState: BusinessTechnicianState = {

    technicianCreationStatus: 'idle',
    businessTechinicianResponseStatus: 'idle',
    businessTechByIdStatus: 'idle',
    businessTechinicianEditStatus: 'idle',
    idBusinessTechUser: '',
    selectedId: '',
    createBusinessTechnician: {
        companyName: '',
        vat: '',
        fiscalCode: '',
        email: '',
        address: '',
        countryRegion: '',
        province: '',
        city: '',
        zipCode: '',
        activationDate: new Date(),
        pec: '',
        phone: '',
    },
    userBusinessTechnicianLink: {
        id: '',
        techId: ''
    }

}


export const CreateTechnicianCreation = createAsyncThunk(
    'BusinessTechinician/CreateTechnicianCreation',
    async (request: CreateTechnicianDTO, thunkApi): Promise<void> => {
        const businessTechinicianService = NewBusinessTechnicianService()

        return businessTechinicianService.CreateTechnicianCreation(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetBusinessTechnician = createAsyncThunk(
    'BusinessTechinician/GetBusinessTechnician',
    async (thunkApi): Promise<any> => {
        const businessTechinicianService = NewBusinessTechnicianService()
        return businessTechinicianService.GetBusinessTechnician()
    }
)

export const GetBusinessTechnicianById = createAsyncThunk(
    'BusinessTechinician/GetBusinessTechnicianById',
    async (id: string, thunkApi): Promise<any> => {
        const businessTechinicianService = NewBusinessTechnicianService()
        return businessTechinicianService.GetBusinessTechnicianById(id)
    }
)

export const EditBusinessTechnician = createAsyncThunk(
    'BusinessTechinician/EditBusinessTechnician',
    async (request: { id: string, data: CreateTechnicianDTO }, thunkApi): Promise<any> => {
        const businessTechinicianService = NewBusinessTechnicianService()
        return businessTechinicianService.EditBusinessTechnician(request.id, request.data)
    }
)

const BusinessTechnicianSlice = createSlice({
        name: 'BusinessTechinician/slice',
        initialState,
        reducers: {

            //Tecnico aziendale
            setBusinessTechnician: (state, action) => {
                state.userBusinessTechnicianLink.techId = action.payload.toString()
            },
            setUserBusinessTech: (state, action) => {
                state.userBusinessTechnicianLink.id = action.payload.toString()
            },
            setCompanyName: (state, action) => {
                state.createBusinessTechnician.companyName = action.payload
            },
            setFiscalCode: (state, action) => {
                state.createBusinessTechnician.fiscalCode = action.payload
            },
            setVat: (state, action) => {
                state.createBusinessTechnician.vat = action.payload
            },
            setAddress: (state, action) => {
                state.createBusinessTechnician.address = action.payload
            },
            setCountryRegion: (state, action) => {
                state.createBusinessTechnician.countryRegion = action.payload
            },
            setProvince: (state, action) => {
                state.createBusinessTechnician.province = action.payload
            },
            setCity: (state, action) => {
                state.createBusinessTechnician.city = action.payload
            },
            setZipCode: (state, action) => {
                state.createBusinessTechnician.zipCode = action.payload
            },
            setPhone: (state, action) => {
                state.createBusinessTechnician.phone = action.payload
            },
            setMobilePhone: (state, action) => {
                state.createBusinessTechnician.mobilePhone = action.payload
            },
            setEmail: (state, action) => {
                state.createBusinessTechnician.email = action.payload
            },
            setPec: (state, action) => {
                state.createBusinessTechnician.pec = action.payload
            },
            setActivationDate: (state, action) => {
                state.createBusinessTechnician.activationDate = action.payload
            },
            setDeactivationDate: (state, action) => {
                state.createBusinessTechnician.deactivationDate = action.payload
            },
            setIdBusinessTechUser: (state, action) => {
                state.idBusinessTechUser = action.payload.toString()
            },
            setBusinessTechnicianByIdStatusIdle: (state) => {
                state.businessTechByIdStatus = 'idle'
            },

            setSelectedTech: (state, action) => {
                state.selectedId = action.payload.toString()
            },

            resetCreateStatus: (state, action) => {
                state.technicianCreationStatus = action.payload
            },
            resetGetTechByIdStatus: (state, action) => {
                state.businessTechByIdStatus = action.payload
            },
            resetEditStatus: (state, action) => {
                state.businessTechinicianEditStatus = action.payload
            },
        },
        extraReducers(builder) {
            builder
                .addCase(CreateTechnicianCreation.pending, (state) => {
                    state.technicianCreationStatus = 'loading';
                })
                .addCase(CreateTechnicianCreation.fulfilled, (state) => {
                    state.technicianCreationStatus = 'successfully';
                })
                .addCase(CreateTechnicianCreation.rejected, (state) => {
                    state.technicianCreationStatus = 'failed';
                })

                .addCase(GetBusinessTechnician.pending, (state) => {
                    state.businessTechinicianResponseStatus = 'loading';
                })
                .addCase(GetBusinessTechnician.fulfilled, (state, action) => {
                    state.businessTechinicianResponseStatus = 'successfully';
                    state.businessTechinicianResponse = action.payload
                })
                .addCase(GetBusinessTechnician.rejected, (state) => {
                    state.businessTechinicianResponseStatus = 'failed';
                })

                .addCase(GetBusinessTechnicianById.pending, (state) => {
                    state.businessTechByIdStatus = 'loading';
                })
                .addCase(GetBusinessTechnicianById.fulfilled, (state, action) => {
                    state.businessTechByIdStatus = 'successfully';
                    state.businessTechById = action.payload
                })
                .addCase(GetBusinessTechnicianById.rejected, (state) => {
                    state.businessTechByIdStatus = 'failed';
                })

                .addCase(EditBusinessTechnician.pending, (state) => {
                    state.businessTechinicianEditStatus = 'loading';
                })
                .addCase(EditBusinessTechnician.fulfilled, (state, action) => {
                    state.businessTechinicianEditStatus = 'successfully';
                    state.businessTechById = action.payload
                })
                .addCase(EditBusinessTechnician.rejected, (state) => {
                    state.businessTechinicianEditStatus = 'failed';
                });

        },
    })

export const {
    setCompanyName,
    setFiscalCode,
    setAddress,
    setCity,
    setCountryRegion,
    setEmail,
    setMobilePhone,
    setPec,
    setPhone,
    setProvince,
    setVat,
    setZipCode,
    setActivationDate,
    setDeactivationDate,
    setBusinessTechnician,
    setUserBusinessTech,
    setIdBusinessTechUser,
    setSelectedTech,
    resetCreateStatus,
    resetGetTechByIdStatus,
    resetEditStatus,
    setBusinessTechnicianByIdStatusIdle

} = BusinessTechnicianSlice.actions

export default BusinessTechnicianSlice.reducer