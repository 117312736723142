import { Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import InputSearch from "../../../../ui/molecules/input/InputSearch";
import { SearchType } from "../../../../ui/molecules/input/Input.types";
import Input from "../../../../ui/molecules/input";
import { CDXM026Update, CDXM026Validation, GetAllCDXM026, resetCDXM026Errors, resetCDXM026UpdateRequest, resetCDXM026UpdateStatus, resetCDXM026ValidationStatus, setCDXHeadquarter, setCDXNumber, setCDXYear, setUpdateCDXClientRelationshipType, setUpdateCDXEndingDate, setUpdateCDXNote, setUpdateCDXOperator, setUpdateCDXOperatorRelationshipType, setUpdateCDXStartingDate, setUpdateCDXSupervisory } from "../slice";
import { SelectCustom } from "../../../../ui/molecules/select";
import { headquartersOptions, odc, yearOptions } from "../../../../utils";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../../../../ui/icons/calendar";
import { format } from "date-fns";
import TextArea from "../../../../ui/molecules/textArea";
import Button from "../../../../ui/molecules/button";
import '../../../../ui/molecules/input/Input.css'
import { GetByIdPersonalManagement } from "../../slice";
import { Calendar } from "../../../../ui/molecules/calendar";

interface Props {
    close: () => void;
}

export function CDXM026PopupEdit(props: Props) {
    const dispatch = useAppDispatch()
    const CDXM026State = useAppSelector(state => state.PMCDXM026)
    const PMState = useAppSelector(state => state.personalManagement)

    useEffect(() => {
        if(CDXM026State.CDXM026ValidationStatus === 'successfully') {
            dispatch(resetCDXM026ValidationStatus())
            dispatch(resetCDXM026Errors())
            dispatch(CDXM026Update({body: CDXM026State.CDXM026UpdateRequest, id: CDXM026State.CDXM026IdToUpdate}));
        }
    }, [CDXM026State.CDXM026ValidationStatus])

    useEffect(() => {
        if(CDXM026State.CDXM026UpdateStatus === 'successfully') {
            dispatch(resetCDXM026UpdateRequest())
            dispatch(resetCDXM026UpdateStatus())
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
            props.close()
        }
        
    }, [CDXM026State.CDXM026UpdateStatus])
    
    return (
        <Fragment>
             <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>

                {/* FIRST ROW */}
                <div className="formAnagrafica pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px', borderBottomWidth: 1,  }}>

                    <div style={{ flex: '0.5' }}>
                        <InputSearch
                            error={CDXM026State.CDXM026Errors.client}
                            supportingText={CDXM026State.CDXM026Errors.client ? 'Cliente mancante od operatore già presente' : undefined}
                            label={'Seleziona Cliente'}
                            placeholder="Specifica cliente..."
                            searchType={SearchType.COMPANY_NAME} 
                            />
                            {
                                (CDXM026State.CDXM026CompanyName) && (
                                    <span className="label-search-code text-brandPrimary-700"
                                    >{`${CDXM026State.CDXM026UpdateRequest.client} - ${CDXM026State.CDXM026CompanyName}`}</span>
                                )
                            }
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <Input
                            error={CDXM026State.CDXM026Errors.clientRelationshipType}
                            supportingText={CDXM026State.CDXM026Errors.clientRelationshipType ? 'Rapporto mancante od operatore già presente' : undefined}
                            label={'Tipologia Rapporto'}
                            placeholder="Specifica tipologia..."
                            value={CDXM026State.CDXM026UpdateRequest.clientRelationshipType}
                            defaultValue={CDXM026State.CDXM026UpdateRequest.clientRelationshipType}
                            onChangeText={(text) => {dispatch(setUpdateCDXClientRelationshipType(text))}}
                            />
                    </div>
                </div>

                <div className="text-left pt-3">
                    <span className="input-label" style={{fontSize: '16px', fontWeight: 600}}>
                        Per i clienti non assoggettati a Codex
                    </span>
                </div>

                {/* SECOND ROW */}
                <div className="formAnagrafica pt-5 pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px', borderBottomWidth: 1, }}>

                    <div style={{ flex: '0.33' }}>
                        <Input
                            error={CDXM026State.CDXM026Errors.operator}
                            supportingText={CDXM026State.CDXM026Errors.operator ? 'Operatore mancante o cliente già presente' : undefined}
                            label={'Operatore non assoggettato'}
                            placeholder="Specifica cliente..."
                            value={CDXM026State.CDXM026UpdateRequest.operator}
                            defaultValue={CDXM026State.CDXM026UpdateRequest.operator}
                            onChangeText={(text) => {dispatch(setUpdateCDXOperator(text))}}
                            />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <Input
                            error={CDXM026State.CDXM026Errors.operatorRelationshipType}
                            supportingText={CDXM026State.CDXM026Errors.operatorRelationshipType ? 'Rapporto mancante o cliente già presente' : undefined}
                            label={'Tipologia Rapporto'}
                            placeholder="Specifica tipologia..."
                            value={CDXM026State.CDXM026UpdateRequest.operatorRelationshipType}
                            defaultValue={CDXM026State.CDXM026UpdateRequest.operatorRelationshipType}
                            onChangeText={(text) => {dispatch(setUpdateCDXOperatorRelationshipType(text))}}
                            />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Organismo di controllo</span>
                        </div>
                        <SelectCustom
                            error={CDXM026State.CDXM026Errors.odc}
                            errorLabel={CDXM026State.CDXM026Errors.odc ? 'ODC mancante o cliente già presente' : undefined}
                            placeholder={'Seleziona organismo...'}
                            options={odc}
                            onChange={(value) => dispatch(setUpdateCDXSupervisory(value))}
                            defaultValue={CDXM026State.CDXM026UpdateRequest.odc}
                            value={CDXM026State.CDXM026UpdateRequest.odc}
                            />
                    </div>
                </div>

                <div className="text-left pt-3">
                    <span className="input-label" style={{fontSize: '16px', fontWeight: 600}}>
                        Altri dati
                    </span>
                </div>

                {/* THIRD ROW */}
                <div className="formAnagrafica pt-3" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">{'Altro (Protocollo Entrata)'}</span>
                        </div>
                        <SelectCustom
                            placeholder={'Specifica protocollo...'}
                            options={headquartersOptions}
                            onChange={(value) => dispatch(setCDXHeadquarter(value))}
                            defaultValue={CDXM026State.headquarter}
                            value={CDXM026State.headquarter}
                            />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Anno</span>
                        </div>
                        <SelectCustom
                            placeholder={'Anno...'}
                            options={yearOptions}
                            onChange={(value) => dispatch(setCDXYear(value))}
                            defaultValue={CDXM026State.year}
                            value={CDXM026State.year}
                            />
                    
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Numero'}
                            placeholder="Specificare numero..."
                            value={CDXM026State.number}
                            defaultValue={CDXM026State.number}
                            onChangeText={(text) => {dispatch(setCDXNumber(text))}}
                            />
                    
                    </div>
                </div>

                {/* FOURTH ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px'  }}>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Data inizio</span>
                        </div>
                        <Calendar
                            selected={CDXM026State.CDXM026UpdateRequest.starting}
                            onChange={(date) => {
                                dispatch(setUpdateCDXStartingDate(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Data fine</span>
                        </div>
                        <Calendar
                            selected={CDXM026State.CDXM026UpdateRequest.ending}
                            onChange={(date) => {
                                dispatch(setUpdateCDXEndingDate(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>
                </div>

                {/* FIFTH ROW */}
                <div className="formAnagrafica pt-5 pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea 
                            defaultValue={CDXM026State.CDXM026UpdateRequest.note}
                            value={CDXM026State.CDXM026UpdateRequest.note}
                            onChangeText={(text) => dispatch(setUpdateCDXNote(text))} />
                    </div>
                </div>

                </div>

                <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetCDXM026Errors())
                        dispatch(resetCDXM026UpdateRequest());
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(CDXM026Validation(CDXM026State.CDXM026UpdateRequest))
                        }}
                        />
                </div>
                </div>
        </Fragment>
    )
}