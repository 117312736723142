import { IconProps } from "./dto";

export function ChevronIcon(props: IconProps) {
    const directionMap = new Map<'l' | 'r' | 't' | 'b', string>([
        ['l', 'rotate(0)'],
        ['r', 'rotate(180)'],
        ['t', 'rotate(90)'],
        ['b', 'rotate(-90)'],
    ])
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 20 20" transform={directionMap.get(props.direction || 't')} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 15L7.5 10L12.5 5" stroke={props.color} strokeWidth="1.5" strokeLinejoin="round" />
        </svg>
    )
}