import React from "react";
import "./TextArea.css";
import { TextAreaProps } from "./TextArea.types";

const TextArea: React.FC<TextAreaProps> = ({
    ...props
}) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
            {
                props.label &&
                <span className="text-area-label">{props.label}</span>
            }
            <textarea
                rows={5}
                placeholder={props.placeholder}
                disabled={props.disabled}
                value={props.value}
                defaultValue={props.defaultValue}
                className="text-area-container" style={{ color: '#434366',  backgroundColor: props.disabled ? 'rgba(242, 242, 250, 1)' : 'white', borderColor: props.error ? 'rgba(255, 198, 194, 1)' : 'rgba(203, 203, 235, 1)' }}
                onChange={e => props.onChangeText && props.onChangeText(e.target.value)}
            />
            {
                props.supportingText &&
                <span className="text-area-supporting-text" style={{ color: props.error ? 'rgba(253, 102, 91, 1)' : 'rgba(173, 173, 214, 1)' }}>{props.supportingText}</span>
            }
        </div>
    )
};

export default TextArea;