import React from "react";
import "./Input.css";
import { InputProps } from "./Input.types";

const Input: React.FC<InputProps> = ({
    ...props
}) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
            {
                props.label &&
                <span className="input-label">{props.label}</span>
            }
            <div className="input-container" style={{ backgroundColor: props.disabled ? 'rgba(242, 242, 250, 1)' : 'white', borderColor: props.error ? 'rgba(255, 198, 194, 1)' : 'rgba(203, 203, 235, 1)' }}>
                {
                    props.startIcon &&
                    <div style={{ maxHeight: '20px' }}>{props.startIcon}</div>
                }
                <input
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    value={props.value}
                    onClick={props.onClick}
                    defaultValue={props.defaultValue}
                    onChange={e => props.onChangeText && props.onChangeText(e.target.value)}
                    className={"input-value" + props.error ? '-error' : ''}
                    style={{ border: 0, outline: 0, height: '20px', backgroundColor: 'white', width: '100%', color: '#62628A', fontFamily: 'Roboto', fontWeight: '500', fontSize: '14px' }}
                />
                {
                    props.endIcon &&
                    <div style={{ maxHeight: '20px' }}>{props.endIcon}</div>
                }
            </div>
            {
                props.supportingText &&
                <span className="input-supporting-text" style={{ color: props.error ? 'rgba(253, 102, 91, 1)' : 'rgba(173, 173, 214, 1)' }}>{props.supportingText}</span>
            }
        </div>
    )
};

export default Input;