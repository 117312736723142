import { useEffect, useState } from "react";
import CheckboxGroup from "../../ui/molecules/CheckBoxGroup";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { setProductSqnpiAdhesionType, setProductSqnpiSubjectType } from "../clienti/slice";
import { SelectCustom } from "../../ui/molecules/select";
import { tipoSoggetto } from "../../utils";

export function ProduzioneIntegrata() {
    const clientiState = useAppSelector((state) => state.clienti);
    const [selectedTypes, setSelectedTypes] = useState<CohesionType[]>([]);
    enum CohesionType {
        TERRENI = "TERRENI",
        TRASFORMAZIONE_CONFEZIONAMENTO = "TRASFORMAZIONE_CONFEZIONAMENTO",
        INTERMEDIAZIONE = "INTERMEDIAZIONE",
        COMMERCIALIZZAZIONE_PRODOTTO_SFUSO = "COMMERCIALIZZAZIONE_PRODOTTO_SFUSO"
    }
    
    useEffect(() => {
        if (clientiState.getCustomersByIdResponse !== undefined) {
            dispatch(setProductSqnpiSubjectType(clientiState.getCustomersByIdResponse.sqnpiSubjectType));
            const adhesionTypes = clientiState.getCustomersByIdResponse.sqnpiAdhesionType.map((type: string) => CohesionType[type as keyof typeof CohesionType]);
            dispatch(setProductSqnpiAdhesionType(adhesionTypes));
            setSelectedTypes(adhesionTypes);
        }
    }, [clientiState.getCustomersByIdResponseStatus]);


    
    
    const dispatch = useAppDispatch()
    const handleTypesChange = (value: CohesionType) => {
        const currentTypes = [...selectedTypes];
        if (currentTypes.includes(value)) {
            setSelectedTypes(currentTypes.filter(t => t !== value))
        }
        else {
            currentTypes.push(value)
            setSelectedTypes(currentTypes)
            dispatch(setProductSqnpiAdhesionType(currentTypes))
        }
    }

    return (
        <div
            style={{
                gap: "6px",
                padding: "0px 24px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
            }}
        >
            <div style={{ width: '100%' }}>
                <div className="text-left">
                    <span className="input-label text-left">Tipo di certificazione</span>
                </div>

                <SelectCustom
                    placeholder="Tipo Adesione"
                    options={tipoSoggetto}
                    defaultValue={clientiState.clienteEditCreateRequest.sqnpiSubjectType}
                    value={clientiState.clienteEditCreateRequest.sqnpiSubjectType}
                    onChange={(value) => {
                        dispatch(setProductSqnpiSubjectType(value))
                    }}
                />
            </div>
            <div className="mt-4 text-left">
                <span className="input-label">Tipo Adesione</span>
                <CheckboxGroup
                    name="tipo_adesione"
                    options={Object.values(CohesionType)}
                    selectedValues={selectedTypes}
                    onChange={handleTypesChange}
                    optionLabels={{
                        [CohesionType.TERRENI]: "Terreno",
                        [CohesionType.TRASFORMAZIONE_CONFEZIONAMENTO]: "Trasformazione / Confezionamento",
                        [CohesionType.INTERMEDIAZIONE]: "Intermediazione",
                        [CohesionType.COMMERCIALIZZAZIONE_PRODOTTO_SFUSO]: "Commercializzazione Prodotto Sfuso"
                    }}
                />
            </div>

        </div>
    )
}