import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { setNote } from "../../../../pages/clienti/slice";
import TextArea from "../../textArea";

export function RappLegaleNote() {
    const dispatch = useAppDispatch()
    const clientiState = useAppSelector(state => state.clienti)

  return (
    <div className="formInOut pt-5 pb-5">
      <TextArea value={clientiState.rappLegaleEditCreateRequest.note} onChangeText={(text) => dispatch(setNote(text))} />
    </div>
  );
}
