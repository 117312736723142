import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { DocumentCreationUpdateDTO, DocumentDTO, DocumentErrorsDTO, GetAllDocumentsDTO, GetAllDocumentsFilterDTO } from "./dataService/dto";
import { NewDocumentService } from "./dataService/service";

interface DocumentState {
    documents: DocumentDTO[]

    // REQUEST
    documentCreationRequest: DocumentCreationUpdateDTO
    fileToUpload?: File

    // ERRORS
    documentErrors: DocumentErrorsDTO

    // FILTERS
    getAllDocumentsFilters: GetAllDocumentsFilterDTO

    // STATUS
    documentCreationStatus: PromiseStatuses
    getAllDocumentsStatus: PromiseStatuses
    getDocumentByIdStatus: PromiseStatuses
    documentDeleteStatus: PromiseStatuses
    documentValidationStatus: PromiseStatuses

    // RESPONSE
    getAllDocumentsResponse?: GetAllDocumentsDTO
    getDocumentByIdResponse?: DocumentDTO
}

const initialState: DocumentState = {
    documents: [],

    documentCreationRequest: {
        date: new Date(),
        detail: '',
        userId: '',
        customerDataId: ''
    },

    documentErrors: {
        detail: false,
        file: false
    },

    getAllDocumentsFilters: {},

    documentCreationStatus: 'idle',
    getAllDocumentsStatus: 'idle',
    getDocumentByIdStatus: 'idle',
    documentDeleteStatus: 'idle',
    documentValidationStatus: 'idle'
}

export const DocumentCreation = createAsyncThunk(
    'Document/Create',
    async (request: FormData, thunkApi): Promise<void> => {
        const DocumentService = NewDocumentService();

        return DocumentService.CreateDocument(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DocumentDelete = createAsyncThunk(
    'Document/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const DocumentService = NewDocumentService();

        return DocumentService.DeleteDocument(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetDocumentById = createAsyncThunk(
    'Document/GetByID',
    async (id: string, thunkApi): Promise<DocumentDTO> => {
        const DocumentService = NewDocumentService();

        return DocumentService.GetDocumentById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllDocuments = createAsyncThunk(
    'Document/GetAll',
    async (filters: GetAllDocumentsFilterDTO, thunkApi): Promise<GetAllDocumentsDTO> => {
        const DocumentService = NewDocumentService();

        return DocumentService.GetAllDocuments(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DocumentValidation = createAsyncThunk(
    'Document/Validation',
    async (request: {body: DocumentCreationUpdateDTO, file: File}, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetDocumentErrors())

        console.log(request.body);
        

        if(request.body.detail === '') {
            thunkApi.dispatch(setValidateDocumentErrorDetail(true))
            isValid = false
        }

        if(request.file === undefined) {
            thunkApi.dispatch(setValidateDocumentErrorFile(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)


const DocumentSlice = createSlice({
    name: 'document/slice',
    initialState,
    reducers: {
        resetDocuments: (state) => {
            state.documents = []
        },
        addDocument: (state, action) => {
            state.documents.push(action.payload)
        },
        setDocuments: (state, action) => {
            state.documents = action.payload
        },

        // REQUEST
        setDocumentCreationDate: (state, action) => {
            state.documentCreationRequest.date = action.payload
        },
        setDocumentCreationEntryObjectId: (state, action) => {
            state.documentCreationRequest.entryObjectId = action.payload
        },
        setDocumentCreationEntryAttachmentId: (state, action) => {
            state.documentCreationRequest.entryAttachmentId = action.payload
        },
        setDocumentCreationDetail: (state, action) => {
            state.documentCreationRequest.detail = action.payload
        },
        setDocumentCreationNote: (state, action) => {
            state.documentCreationRequest.note = action.payload
        },
        setDocumentCreationUserId: (state, action) => {
            state.documentCreationRequest.userId = action.payload
        },
        setDocumentCreationCustomerDataId: (state, action) => {
            state.documentCreationRequest.customerDataId = action.payload
        },
        setFileToUpload: (state, action) => {
            state.fileToUpload = action.payload
        },
        resetDocumentCreationRequest: (state) => {
            state.documentCreationRequest = {
                date: new Date(),
                detail: '',
                userId: '',
                customerDataId: '',
                entryAttachmentId: undefined,
                entryObjectId: undefined,
                note: undefined
            }
        },

        // ERRORS
        setValidateDocumentErrorDetail: (state, action) => {
            state.documentErrors.detail = action.payload
        },
        setValidateDocumentErrorFile: (state, action) => {
            state.documentErrors.file = action.payload
        },
        resetDocumentErrors: (state) => {
            state.documentErrors = {
                detail: false,
                file: false
            }
        },

        // FILTERS
        setGetAllDocumentsFiltersCustomer: (state, action) => {
            state.getAllDocumentsFilters.customer = action.payload
        },
        setGetAllDocumentsFiltersItemsPerPage: (state, action) => {
            state.getAllDocumentsFilters.itemsPerPage = action.payload
        },
        setGetAllDocumentsFiltersOrder: (state, action) => {
            state.getAllDocumentsFilters.order = action.payload
        },
        setGetAllDocumentsFiltersPage: (state, action) => {
            state.getAllDocumentsFilters.page = action.payload
        },
        setGetAllDocumentsFiltersSort: (state, action) => {
            state.getAllDocumentsFilters.sort = action.payload
        },
        setGetAllDocumentsFiltersYear: (state, action) => {
            state.getAllDocumentsFilters.year = action.payload
        },
        resetGetAllDocumentsFilters: (state) => {
            state.getAllDocumentsFilters = {}
        },

        // STATUS
        resetDocumentCreationStatus: (state) => {
            state.documentCreationStatus = 'idle'
        },
        resetGetAllDocumentsStatus: (state) => {
            state.getAllDocumentsStatus = 'idle'
        },
        resetGetDocumentByIdStatus: (state) => {
            state.getDocumentByIdStatus = 'idle'
        },
        resetDeleteDocumentStatus: (state) => {
            state.documentDeleteStatus = 'idle'
        },
        resetDocumentValidationStatus: (state) => {
            state.documentValidationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder

            // GET ALL
            .addCase(GetAllDocuments.pending, (state) => {
                state.getAllDocumentsStatus = 'loading'
            })
            .addCase(GetAllDocuments.fulfilled, (state, action) => {
                state.getAllDocumentsStatus = 'successfully'
                state.getAllDocumentsResponse = action.payload
            })
            .addCase(GetAllDocuments.rejected, (state) => {
                state.getAllDocumentsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetDocumentById.pending, (state) => {
                state.getDocumentByIdStatus = 'loading'
            })
            .addCase(GetDocumentById.fulfilled, (state, action) => {
                state.getDocumentByIdStatus = 'successfully'
                state.getDocumentByIdResponse = action.payload
            })
            .addCase(GetDocumentById.rejected, (state) => {
                state.getDocumentByIdStatus = 'failed'
            })

            // CREATE
            .addCase(DocumentCreation.pending, (state) => {
                state.documentCreationStatus = 'loading'
            })
            .addCase(DocumentCreation.fulfilled, (state) => {
                state.documentCreationStatus = 'successfully'
            })
            .addCase(DocumentCreation.rejected, (state) => {
                state.documentCreationStatus = 'failed'
            })

            // DELETE
            .addCase(DocumentDelete.pending, (state) => {
                state.documentDeleteStatus = 'loading'
            })
            .addCase(DocumentDelete.fulfilled, (state) => {
                state.documentDeleteStatus = 'successfully'
            })
            .addCase(DocumentDelete.rejected, (state) => {
                state.documentDeleteStatus = 'failed'
            })

            // VALIDATION
            .addCase(DocumentValidation.pending, (state) => {
                state.documentValidationStatus = 'loading'
            })
            .addCase(DocumentValidation.fulfilled, (state) => {
                state.documentValidationStatus = 'successfully'
            })
            .addCase(DocumentValidation.rejected, (state) => {
                state.documentValidationStatus = 'failed'
            })
    },
})

export const {
    resetDocuments,
    addDocument,
    setDocuments,

    // REQUEST
    setDocumentCreationCustomerDataId,
    setDocumentCreationDate,
    setDocumentCreationDetail,
    setDocumentCreationEntryAttachmentId,
    setDocumentCreationEntryObjectId,
    setDocumentCreationNote,
    setDocumentCreationUserId,
    setFileToUpload,
    resetDocumentCreationRequest,

    // ERRORS
    setValidateDocumentErrorDetail,
    setValidateDocumentErrorFile,
    resetDocumentErrors,

    // FILTERS
    setGetAllDocumentsFiltersCustomer,
    setGetAllDocumentsFiltersItemsPerPage,
    setGetAllDocumentsFiltersOrder,
    setGetAllDocumentsFiltersPage,
    setGetAllDocumentsFiltersSort,
    setGetAllDocumentsFiltersYear,
    
    // STATUS
    resetDeleteDocumentStatus,
    resetDocumentCreationStatus,
    resetGetAllDocumentsFilters,
    resetGetAllDocumentsStatus,
    resetGetDocumentByIdStatus,
    resetDocumentValidationStatus
} = DocumentSlice.actions

export default DocumentSlice.reducer