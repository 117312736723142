import { colors } from "../../colors";
import { ChevronIcon } from "../../icons/chevron";
import { PaginationProps } from "./dto";
import './style.scss';
import clsx from "clsx"

export function Pagination(props: PaginationProps) {
    return (
        <div className="flex p-[8px] gap-[8px] drop-shadow-sm items-center justify-end">
            <div
                className={
                    clsx("text-brandPrimary-800 paginationNumbers flex gap-2", {
                        "w-full space-between": props.fullWidth
                    })
                }
            >
                <div className="flex">
                    <span className="textNumberResults">
                        Trovati: <span className="numberResults">{props.numbResults} </span>
                        {props.detailsResults}
                    </span>
                </div>
                <div className="flex">
                    <div className="text-brandPrimary-800 inline-flex gap-2" style={{ verticalAlign: 'midle' }}>
                        <span className="textNumberResults">Pagina</span>
                        <span className="textNumberResults">

                            {props.currentPage + 1}
                        </span>
                        <span className="textNumberResults">di</span>
                        <span className="textNumberResults">
                            {props.pages}
                        </span>


                    </div>

                    <div className="flex">
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                if (props.currentPage > 0) {
                                    props.setPage(props.currentPage - 1)
                                }
                            }}
                        >
                            <ChevronIcon size={23} color={colors.brandPrimary[800]} direction="l" />
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                if (props.currentPage < props.pages - 1) {
                                    props.setPage(props.currentPage + 1)
                                }
                            }}
                        >
                            <ChevronIcon size={23} color={colors.brandPrimary[800]} direction="r" />
                        </div>

                    </div>
                </div>
            </div>
            {/* {
                generatePagination(props.currentPage, props.pages).map(
                    (page, index) =>
                        <div
                            key={'page-' + index}
                            onClick={() => {
                                if (typeof page === "number") {
                                    props.setPage(page - 1)
                                }
                            }}
                            className={
                                clsx(
                                    "w-[24px] text-text-sm text-center", {
                                    "text-neutral-600": typeof page === "number" && page - 1 !== props.currentPage,
                                    "text-brandPrimary-800": typeof page === "number" && page - 1 === props.currentPage,
                                    "cursor-pointer": typeof page !== "string"
                                }
                                )}
                        >{page}
                        </div>
                )} */}

        </div>
    )
}