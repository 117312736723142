import { Layout } from "../../../ui/layout";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import Button from "../../../ui/molecules/button";
import { keycloak } from "../../../lib/keycloak";
import './style.scss';
import { PrintButton } from "../../../ui/icons/printButton";
import { DownloadButton } from "../../../ui/icons/downloadButton";
import { PopupForm } from "../../../ui/organisms/popupForm";
import { useState } from "react";
import { FormDistintaEntrata } from "./formDistintaEntrata";
import { DownloadExcelProt } from "./downloadExcelProt";

export function ReportProtocol() {

    const [openPopupEntrata, setOpenPopupEntrata] = useState<boolean>(false)
    const [openPopupEntrataConAllegati, setOpenPopupEntrataConAllegati] = useState<boolean>(false)
    const [openPopupExcel, setOpenPopupExcel] = useState<boolean>(false)
    const [protType, setProtType] = useState<string>('')


    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Protocollo']}
            noPaddingLat
            headerLabel="Report protocollo"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>

                </div>
            }
        >
            <div className="w-[100%] flex flex-row gap-[24px] filter" style={{ padding: '24px 24px 0px 24px' }}>
                <div className="toolBox">
                    <div className="boxTitle">Protocollo Entrata</div>
                    <div className="boxBody p-6" style={{ display: 'flex', justifyContent: 'left', gap: '20px' }}>
                        <div
                            className="bigButtonUtils"
                            onClick={() => setOpenPopupEntrata(true)}
                            style={{ cursor: 'pointer', flex: '0.4', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            <PrintButton />
                            <div className="bodyLabel">Distinta di consegna</div>
                        </div>
                        <div
                            className="bigButtonUtils"
                            onClick={() => setOpenPopupEntrataConAllegati(true)}
                            style={{ cursor: 'pointer', flex: '0.5', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            <PrintButton />
                            <div className="bodyLabel">Distinta di consegna con allegati</div>
                        </div>
                        <div
                            onClick={() => { setProtType('entrata'); setOpenPopupExcel(true); console.log('prot') }}
                            className="bigButtonUtils"
                            style={{ cursor: 'pointer', flex: '0.4', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            <DownloadButton />
                            <div className="bodyLabel" >Protocollo</div>
                        </div>
                    </div>
                </div>

                <div className="toolBox">
                    <div className="boxTitle">Protocollo Partenza</div>
                    <div className="boxBody p-6" style={{ display: 'flex', justifyContent: 'left', gap: '20px' }}>

                        <div
                            onClick={() => { setProtType('partenza'); setOpenPopupExcel(true); console.log('prot') }}
                            className="bigButtonUtils"
                            style={{ cursor: 'pointer', flex: '0.5', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            <DownloadButton />
                            <div className="bodyLabel" onClick={() => { setProtType('partenza'); setOpenPopupExcel(true) }}>Protocollo</div>
                        </div>
                    </div>
                </div>
            </div>
            {
                (openPopupEntrata) && (
                    <PopupForm title="Distinta di consegna protocollo | Entrata" close={() => {
                        setOpenPopupEntrata(false)
                    }}>
                        <FormDistintaEntrata attachments={false} close={() => setOpenPopupEntrata(false)} />
                    </PopupForm>
                )
            }
            {
                (openPopupEntrataConAllegati) && (
                    <PopupForm title="Distinta di consegna con allegati | Entrata" close={() => {
                        setOpenPopupEntrataConAllegati(false)
                    }}>
                        <FormDistintaEntrata attachments={true} close={() => setOpenPopupEntrataConAllegati(false)} />
                    </PopupForm>
                )
            }

            {
                (openPopupExcel) && (
                    <PopupForm title="Scarica Excel" close={() => {
                        setOpenPopupExcel(false)
                    }}>
                        <DownloadExcelProt protType={protType} close={() => setOpenPopupExcel(false)} />
                    </PopupForm>
                )
            }
        </Layout>
    )
}