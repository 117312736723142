import { ReactNode, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import { keycloak } from "../../lib/keycloak"
import './style.scss';
import Select from 'react-select'
import Button from "../../ui/molecules/button";
import { useNavigate } from "react-router-dom";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { ListingPersonale } from "./list";
import { GetAllPersonalManagement, PMCreation, resetPersonalManagementCreationStatus, setActiveFilter, setFunctionFilter, setNormFilter, setPageFilter, setRegionFilter, setSotFilter, setSurnameFilter } from "./slice";
import { Pagination } from "../../ui/organisms/pagination";
import DatePicker from "react-datepicker";
import Input from "../../ui/molecules/input";
import CalendarIcon from "react-datepicker/dist/calendar_icon";
import { SelectCustom } from "../../ui/molecules/select";
import { activityOptions, normativa, regioniList, sot, status } from "../../utils";
import { setFindAllUsersFiltersPage, setStatusFilter } from "../users/slice";
import { ArrowLeftIcon } from "../../ui/icons/arrow-left";

interface Props {
    children: ReactNode
}

export function GestionePersonale() {

    const PMState = useAppSelector(state => state.personalManagement)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (PMState.personalManagementCreationStatus === 'successfully') {
            dispatch(resetPersonalManagementCreationStatus())
            dispatch(GetAllPersonalManagement(PMState.filters))
            navigate('/nuovoPersonale')
        }
    }, [PMState.personalManagementCreationStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Personale']}
            noPaddingLat
            headerLabel="Gestione Personale"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiungi Personale" color={"orange"}
                        onClick={() => {
                            dispatch(PMCreation())
                        }}
                    />
                </div>
            }
        >
            {/* FILTERS */}
            <div className="flex flex-row gap-[16px]" style={{ backgroundColor: '#F2F7F7', padding: '12px' }}>
                <div style={{ display: 'flex', justifyContent: 'left', width: '85%', alignItems: 'center', gap:'12px' }}>

                    <div style={{ width: '20%', paddingRight: 5 }}>
                        <Input
                            placeholder="Cerca per cognome"
                            onChangeText={(text) => { dispatch(setSurnameFilter(text)) }
                            }
                        />
                    </div>
                    <div style={{width: '10%'}}>
                        <SelectCustom
                            placeholder={'Sede'}
                            options={sot}
                            onChange={(value) => {
                                dispatch(setSotFilter(value))
                            }}
                        />
                    </div>
                    <div style={{width: '15%'}}>
                        <SelectCustom
                            placeholder={'Regione'}
                            options={regioniList}
                            onChange={(value) => {
                                dispatch(setRegionFilter(value))
                            }}
                        />
                    </div>
                    <div style={{width: '10%'}}>
                        <SelectCustom
                            placeholder={'Norma'}
                            options={normativa}

                            onChange={(value) => {
                                dispatch(setNormFilter(value))
                            }}
                        />
                    </div>
                    <div style={{width: '15%'}}>
                        <SelectCustom
                            placeholder={'Funzione'}
                            options={activityOptions}
                            onChange={(value) => {
                                dispatch(setFunctionFilter(value))
                            }}
                        />
                    </div>
                    <div style={{width: '10%'}}>
                        <SelectCustom
                            placeholder={'Stato'}
                            options={status}
                            onChange={(value) => {
                                dispatch(setActiveFilter(value === 'active' ? true : false))
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            variant="outline"
                            color="dimgrey"
                            label="Cerca"
                            iconPosition="off"
                            size='md'
                            onClick={() => dispatch(GetAllPersonalManagement(PMState.filters))}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right' }}>

                    <Pagination
                        numbResults={PMState.getAllPersonalManagementResourcesResponse?.total || 0}
                        pages={PMState.getAllPersonalManagementResourcesResponse?.totalPage || 1}
                        currentPage={PMState.getAllPersonalManagementResourcesResponse?.page || 0}
                        setPage={
                            (page) => {
                                dispatch(setPageFilter(page))
                            }
                        }

                    />
                </div>
            </div>

            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 24px 0px 24px' }}>
                <ListingPersonale />
            </div>

        </Layout>
    )
}