import { ReactElement, ReactNode, useEffect, useState } from "react";
import { TrashIcon } from "../../ui/icons/trash";
import Button from "../../ui/molecules/button";
import { SelectCustom } from "../../ui/molecules/select";
import { attivita_bio, categorie_prodotti, categorie_prodotti_A, OptionType, prodotti } from "../../utils";
import { log } from "console";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { addDefaultBiologicalActivity, setBiologicalActivity, setBiologicalActivityActivityByIndex, setBiologicalActivityCategoryByIndex, setBiologicalActivityProductsByIndex} from "../clienti/slice";
import { commonRightBarStyle } from "./style";
import MultiSelectCheckbox from "../../ui/molecules/multiSelectCheckbox";

interface CustomSelectProps {
  options: { label: string, value: string }[];
  idx: number;
  onDelete: (idx: number) => void;
}


export function Bio() {
  const clientiState = useAppSelector(state => state.clienti)
  const dispatch = useAppDispatch()
  const [selectedCategories, setSelectedCategories] = useState<OptionType[][]>([])
  const [selectedProducts, setSelectedProducts] = useState<OptionType[][]>([])

  useEffect(() => {
    if (clientiState.getCustomersByIdResponse !== undefined) {
      dispatch(setBiologicalActivity(clientiState.getCustomersByIdResponse.biologicalActivity));
    }
  }, [clientiState.getCustomersByIdResponseStatus]);

  useEffect(() => {
    const categories = clientiState.clienteEditCreateRequest.biologicalActivity.map(ba => ba.category) as string[][]
    setSelectedCategories(categories as any)
    const products = clientiState.clienteEditCreateRequest.biologicalActivity.map(ba => ba.products) as string[][]
    setSelectedProducts(products as any)
  }, [clientiState.clienteEditCreateRequest.biologicalActivity])

  return (
    <div>
      <div
        style={commonRightBarStyle}
      >
        <span className="input-label">Attività</span>
        {
          clientiState.clienteEditCreateRequest.biologicalActivity.map((ba, index) => (
            <div style={{display: "flex", flexDirection: 'column', alignItems: "center", width: "100%", paddingBottom: '12px', paddingTop: index !== 0 ? '8px' : '0px', borderBottomWidth: 1 }}>

              {/* ACTIVITY */}
              <div style={{display: "flex", flexDirection: 'row', alignItems: "center", width: "100%", paddingBottom: '12px' }}>
                <div style={{ flex: 0.95, paddingRight: 5 }}>
                  <SelectCustom
                    placeholder="Attività"
                    options={attivita_bio}
                    defaultValue={clientiState.clienteEditCreateRequest.biologicalActivity[index].activity}
                    value={clientiState.clienteEditCreateRequest.biologicalActivity[index].activity}
                    onChange={(value) => dispatch(setBiologicalActivityActivityByIndex({data: value, index: index}))}
                  />
                </div>
                <div style={{flex: 0.05}} onClick={() => {
                  const newActivities = clientiState.clienteEditCreateRequest.biologicalActivity.filter((b, idx) => b != ba)
                  dispatch(setBiologicalActivity(newActivities))
                }}>
                  <TrashIcon color="#8383AD" size={5} />
                </div>
              </div>

              {/* CATEGORY */}
              <div style={{display: "flex", alignItems: "center", width: '100%', paddingBottom: '12px'}}>
                <div style={{flex: 0.95}}>
                  <MultiSelectCheckbox
                    maxSelectedLabel={1}
                    onChange={(e) => {
                        const cats = selectedCategories
                        cats[index] = e.value                                    
                        setSelectedCategories(cats)
                        if(!cats[index].map(c => {return c.value}).includes('G')) {
                          const prods = selectedProducts
                          prods[index] = []                                    
                          setSelectedProducts(prods)
                          dispatch(setBiologicalActivityProductsByIndex({data: [], index: index}))
                        }
                        dispatch(setBiologicalActivityCategoryByIndex({data: e.value, index: index}))
                    }}
                    value={selectedCategories[index]}
                    option={ba.activity === 'A' ? categorie_prodotti_A : categorie_prodotti}
                    placeholder="Categoria"
                    selectAllLabel="Tutte"
                    />
                </div>
              </div>

              {/* PRODUCTS */}
              {
                clientiState.clienteEditCreateRequest.biologicalActivity[index].category.includes('G') && (
                  <div style={{display: "flex", alignItems: "center", width: '100%'}}>
                    <div style={{flex: 0.95}}>
                      <MultiSelectCheckbox
                        maxSelectedLabel={1}
                        onChange={(e) => {
                            const prods = selectedProducts
                            prods[index] = e.value                                    
                            setSelectedProducts(prods)
                            dispatch(setBiologicalActivityProductsByIndex({data: e.value, index: index}))
                        }}
                        value={selectedProducts[index]}
                        option={prodotti}
                        placeholder="Prodotti"
                        selectAllLabel="Tutte"
                        />
                    </div>
                  </div>
                )
              }

            </div>
          ))
        }

        <div className="mt-2">
          <Button
            onClick={() => {
              dispatch(addDefaultBiologicalActivity())
            }}
            label="Aggiungi attività"
            size="sm"
            iconPosition="off"
            variant="outline"
            color="dimgrey"
          />
        </div>

      </div>
    </div>
  );
}
