import { Backdrop, CircularProgress } from "@mui/material"
import colors from '../../utils/index.module.scss'

interface Props {
    fullScreen?: boolean
    size: 'small' | 'medium' | 'large'
}

export function SpinnerComponent(props: Props) {
    const { fullScreen } = props
    const { size } = props

    const spinnerSizeMap = new Map<'small' | 'medium' | 'large', number>([
        ['small', 20],
        ['medium', 40],
        ['large', 60]
    ])

    if (fullScreen) {
        return (
            <div>
                <Backdrop
                    sx={{ backgroundColor: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open
                >
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        size={spinnerSizeMap.get(size)}
                        sx={{ color: colors.primary500 }}
                    />
                </Backdrop>
            </div>
        )
    }

    return (
        <CircularProgress
            variant="indeterminate"
            disableShrink
            size={spinnerSizeMap.get(size)}
            sx={{ color: colors.primary500 }}
        />
    )
}