import { useEffect, useState } from "react"
import { PlusIcon } from "../../../../ui/icons/plus"
import Button from "../../../../ui/molecules/button"
import { FileInputComponent } from "../../../../ui/molecules/fileInput"
import { SelectCustom } from "../../../../ui/molecules/select"
import { EPAttachmentGroupOptions, OptionType, ProtocolDataBanner } from "../../../../utils"
import { PopupForm } from "../../../../ui/organisms/popupForm"
import { TrashIcon } from "../../../../ui/icons/trash"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { addDefaultUpdateEPEntryAttachmentIds, addEPFileAndAtt, addUpdateEPEntryAttachmentId, EntryProtocolRemoveFile, GetAllEntryProtocolAttachments, resetEntryProtocolRemoveFileStatus, resetEPAttCreationRequest, resetGetAllEntryProtocolAttachmentsStatus, setEPFileAndAtt, setFileToUpdate, setUpdatedFile, setUpdateEPEntryAttachmentIds, setUpdateEPEntryAttachmentIdsByIndex } from "../slice"
import { FileViewer } from "../../../../ui/molecules/fileViewer"
import { ProtocolSuccessBanner } from "../protocolSuccessBanner"
import { EntryProtocolFileAndAttachmentRequestDTO } from "../dataService/dto"
import { AddAttachmentPopupEntry } from "../../popup/addAttachmentEntry"


export function NewEntryRight() {
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const userState = useAppSelector(state => state.users)
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [attachments, setAttachments] = useState<OptionType[]>([])
    const [groupValues, setGroupValues] = useState<string | null>(null)

    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [successData, setSuccessData] = useState<ProtocolDataBanner | null>(null)

    const documentUploadPredicate = (index: number, attId: string): boolean => {
        return entryProtocolState.entryProtocolUpdateFileAndAtt
            .find(obj => obj.attachmentId === attId) !== undefined ||
            attId === '' ||
            entryProtocolState.updatedEntryProtocolFileAndAtts?.find(obj => obj.attachmentId === attId) !== undefined
    }

    const NotAvailableAttachments = () => {
        return attachments.filter(att => entryProtocolState.entryProtocolUpdateRequest.entryAttachmentIds.includes(att.value))
    }

    useEffect(() => {
        dispatch(GetAllEntryProtocolAttachments())
    }, [])

    useEffect(() => {
        if (entryProtocolState.getEntryProtocolByIdStatus === 'successfully' &&
            entryProtocolState.getEntryProtocolByIdResponse !== undefined
        ) {
            const data = entryProtocolState.getEntryProtocolByIdResponse
            console.log(data.protocolDate)
            const newData: ProtocolDataBanner = {
                protocol: `${data.headQuarter} E${data.protocolNumber}`,
                date: data.protocolDate,
            }
            setSuccessData(newData)
            setShowSuccess(true)
        }
    }, [entryProtocolState.getEntryProtocolByIdStatus])


    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolAttachmentsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolAttachmentsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolAttachmentsStatus())
            const data = entryProtocolState.getAllEntryProtocolAttachmentsResponse
            const newAtts: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setAttachments(newAtts)
        }
    }, [entryProtocolState.getAllEntryProtocolAttachmentsStatus])

    useEffect(() => {
        if (entryProtocolState.entryProtocolRemoveFileStatus === 'successfully') {
            dispatch(resetEntryProtocolRemoveFileStatus())
            dispatch(setUpdatedFile(undefined))
        }
    }, [entryProtocolState.entryProtocolRemoveFileStatus])

    const handleOnDelete = (attId: string) => {
        const newAtts = entryProtocolState.entryProtocolUpdateRequest.entryAttachmentIds.filter(a => a !== attId)
        const newFilesAtts = entryProtocolState.entryProtocolUpdateFileAndAtt.filter(obj => obj.attachmentId !== attId)
        dispatch(setUpdateEPEntryAttachmentIds(newAtts))
        dispatch(setEPFileAndAtt(newFilesAtts))
    }

    const handleOnGroupSelect = (value: string | null) => {
        if (value === null)
            return
        const Ids: string[] = value?.split('-')!
        for (const id of Ids) {
            if (id !== '' && !entryProtocolState.entryProtocolUpdateRequest.entryAttachmentIds.includes(id))
                dispatch(addUpdateEPEntryAttachmentId(id))
        }
    }

    return (
        <div style={{ padding: '24px' }}>
            <div>
                {
                    (showSuccess) &&
                    <ProtocolSuccessBanner
                        protocol={successData?.protocol || ''}
                        date={successData?.date || undefined}
                        type='entry'
                        closeAction={() => setShowSuccess(false)}
                    />
                }
            </div>

            <div className="text-left pt-5 pb-2">
                <span className="input-label" style={{ fontSize: '16px', fontWeight: 700 }}>
                    Aggiungi PDF
                </span>
            </div>

            <div className="pb-5" style={{ borderBottomWidth: 1 }}>
                <FileInputComponent
                    id={"ep-edit-upload-add"}
                    key={"ep-edit-upload-add"}
                    infoText
                    fullHeight
                    permittedExtensions={['PDF']}
                    color={"green"}
                    onChange={(e) => {
                        dispatch(setFileToUpdate(e[0]))
                    }}
                />
                {
                    entryProtocolState.fileToUpdate && (
                        <div className="pt-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: '0.9' }}>
                                <FileViewer
                                    toUpdate
                                    fileName={entryProtocolState.fileToUpdate.name}
                                    otherInfo={entryProtocolState.fileToUpdate.type}
                                />
                            </div>
                            <div className="cursor-pointer" style={{ display: 'flex', flex: '0.1', justifyContent: 'center' }}
                                onClick={() => dispatch(setFileToUpdate(null))}>
                                <TrashIcon size={20} color="dimgrey" />
                            </div>
                        </div>
                    )
                }
                {
                    entryProtocolState.updatedFile && (
                        <div className="pt-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: '0.9' }}>
                                <FileViewer
                                    toUpdate={false}
                                    fileName={entryProtocolState.updatedFile.name}
                                    otherInfo={entryProtocolState.updatedFile.type}
                                />
                            </div>
                            <div className="cursor-pointer" style={{ display: 'flex', flex: '0.1', justifyContent: 'center' }}
                                onClick={() => dispatch(EntryProtocolRemoveFile(entryProtocolState.entryProtocolIdToUpdate))}>
                                <TrashIcon size={20} color="dimgrey" />
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="text-left pb-3 pt-5">
                <span className="input-label" style={{ fontSize: '16px', fontWeight: 600 }}>
                    Allegati
                </span>
            </div>

            {
                entryProtocolState.entryProtocolUpdateRequest.entryAttachmentIds.map((att, index) => (
                    <div>
                        <div className='pb-2 pt-3' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                            <div style={{ flex: '0.9' }}>
                                <div className="text-left">
                                    <span className="input-label">{`Aggiungi allegato (Singolo)`}</span>
                                </div>
                                <SelectCustom
                                    value={att}
                                    placeholder={'Seleziona...'}
                                    options={attachments}
                                    disabledOptions={NotAvailableAttachments()}
                                    onChange={(value) => dispatch(setUpdateEPEntryAttachmentIdsByIndex({ data: value, index: index }))}
                                />
                            </div>

                            <div style={{ flex: '0.05', alignSelf: 'flex-end', paddingBottom: '3px' }}>
                                <FileInputComponent
                                    id={"ep-edit-upload-add"}
                                    key={"ep-edit-upload-add"}
                                    iconSize
                                    color={"green"}
                                    disabled={documentUploadPredicate(index, att)}
                                    onChange={(e) => {
                                        dispatch(addEPFileAndAtt({ file: e[0], attachmentId: att } as EntryProtocolFileAndAttachmentRequestDTO))
                                    }}
                                />
                            </div>

                            <div style={{ flex: '0.05', alignSelf: 'flex-end', paddingBottom: '3px' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    icon={<TrashIcon size={20} color="red" />}
                                    onClick={() => handleOnDelete(att)}
                                    iconPosition="only"
                                    size="md"
                                />
                            </div>
                        </div>
                        {
                            entryProtocolState.entryProtocolUpdateFileAndAtt.find(obj => obj.attachmentId === att) && (
                                <div className="pb-4 pl-2 pt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: '1', maxHeight: '100px' }}>
                                        <FileViewer
                                            smallSize
                                            toUpdate
                                            fileName={entryProtocolState.entryProtocolUpdateFileAndAtt.find(obj => obj.attachmentId === att)?.file.name!}
                                            otherInfo={entryProtocolState.entryProtocolUpdateFileAndAtt.find(obj => obj.attachmentId === att)?.file.type!}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {
                            entryProtocolState.updatedEntryProtocolFileAndAtts?.find(obj => obj.attachmentId === att) && (
                                <div className="pt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: '0.8' }}>
                                        <FileViewer
                                            fileId={entryProtocolState.updatedEntryProtocolFileAndAtts?.find(obj => obj.attachmentId === att)?.fileInfo.fileId!}
                                            smallSize
                                            fileName={entryProtocolState.updatedEntryProtocolFileAndAtts?.find(obj => obj.attachmentId === att)?.fileInfo.name!}
                                            otherInfo={entryProtocolState.updatedEntryProtocolFileAndAtts?.find(obj => obj.attachmentId === att)?.fileInfo.type!}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                ))
            }

            <div className="pb-2 pt-3">
                <Button
                    variant="outline"
                    color="dimgrey"
                    iconPosition="left"
                    icon={<PlusIcon color="orange" size={16} />}
                    size="sm"
                    label="Nuovo allegato"
                    onClick={() => dispatch(addDefaultUpdateEPEntryAttachmentIds())}
                />
            </div>

            {
                userState.findMeResponse.role === 'admin' &&
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', justifyContent: 'flex-end', borderBottomWidth: 1 }}>
                    <Button
                        variant="outline"
                        color="orange"
                        iconPosition="left"
                        icon={<PlusIcon color="orange" size={16} />}
                        size="sm"
                        label="Nuovo tipo di allegato"
                        onClick={() => setShowPopup(true)}
                    />
                </div>
            }

            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                <div style={{ flex: '0.8' }}>
                    <div className="text-left">
                        <span className="input-label">Inserisci più allegati</span>
                    </div>
                    <SelectCustom
                        placeholder={'Seleziona...'}
                        options={EPAttachmentGroupOptions}
                        onChange={(value) => setGroupValues(value!)}
                    />
                </div>
                <div style={{ flex: '0.2', alignSelf: 'flex-end', paddingBottom: '3px' }}>
                    <Button
                        variant="outline"
                        color="dimgrey"
                        iconPosition="off"
                        size="md"
                        label="Allega"
                        onClick={() => {
                            handleOnGroupSelect(groupValues)
                            setGroupValues(null)
                        }}
                    />
                </div>
            </div>

            {
                showPopup && (
                    <PopupForm title='Aggiungi Allegato' close={() => {
                        dispatch(resetEPAttCreationRequest())
                        setShowPopup(false)
                    }}>
                        <AddAttachmentPopupEntry close={() => {
                            dispatch(resetEPAttCreationRequest())
                            setShowPopup(false)
                        }} />
                    </PopupForm>
                )
            }
        </div>
    )
}