import { useNavigate } from "react-router-dom";
import { keycloak } from "../../../lib/keycloak";
import { Layout } from "../../../ui/layout";
import Button from "../../../ui/molecules/button";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetAllEntryProtocolAttachments, GetAllEntryProtocolObjects, GetAllEntryProtocols, resetEntryProtocolCreationRequest, resetEntryProtocolCreationStatus, resetGetAllEntryProtocolAttachmentsStatus, resetGetAllEntryProtocolObjectsStatus, setGetAllEPFilterAttachmentId, setGetAllEPFilterClient, setGetAllEPFilterFrom, setGetAllEPFilterHeadquarter, setGetAllEPFilterNumber, setGetAllEPFilterObjectId, setGetAllEPFilterObjectSpec, setGetAllEPFilterSender, setGetAllEPFilterTo, setGetAllEPFilterYear, setGetAllEPFilterentryMeans } from "./slice";
import { EntryListProtocol } from "./list";
import { SelectCustom } from "../../../ui/molecules/select";
import { entryMeansOptions, OptionType, sot, yearOptions } from "../../../utils";
import Input from "../../../ui/molecules/input";
import { Calendar } from "../../../ui/molecules/calendar";


export function EntryProtocol() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(resetEntryProtocolCreationRequest())
        dispatch(resetEntryProtocolCreationStatus())
    }, [])

    const entryProtocolState = useAppSelector(state => state.entryProtocol)

    const [objects, setObjects] = useState<OptionType[]>([])
    const [attachments, setAttachments] = useState<OptionType[]>([])

    useEffect(() => {
        dispatch(GetAllEntryProtocolObjects())
        dispatch(GetAllEntryProtocolAttachments())
    }, [])

    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolObjectsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolObjectsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolObjectsStatus())
            const data = entryProtocolState.getAllEntryProtocolObjectsResponse
            const newObjects: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setObjects(newObjects)
        }
    }, [entryProtocolState.getAllEntryProtocolObjectsStatus])

    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolAttachmentsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolAttachmentsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolAttachmentsStatus())
            const data = entryProtocolState.getAllEntryProtocolAttachmentsResponse
            const newAtts: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setAttachments(newAtts)
        }
    }, [entryProtocolState.getAllEntryProtocolAttachmentsStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Protocollo']}
            noPaddingLat
            headerLabel="Protocollo Entrata"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiungi Protocollo Entrata" color={"orange"}
                        onClick={() => {
                            navigate('/addEntryProtocol')
                        }}
                    />

                </div>
            }
        >
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 24px 0px 24px' }}>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Tipologia</span>
                        </div>
                        <SelectCustom
                            placeholder={'Sede / Ufficio'}
                            options={sot}
                            onChange={(value) => {
                                dispatch(setGetAllEPFilterHeadquarter(value))
                            }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Numero'}
                            placeholder="Specifica Numero"
                            onChangeText={(text) => { dispatch(setGetAllEPFilterNumber(text)) }}
                        />

                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Anno</span>
                        </div>
                        <SelectCustom
                            placeholder={'Anno'}
                            options={yearOptions}
                            onChange={(value) => dispatch(setGetAllEPFilterYear(value))}

                        />

                    </div>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Mezzo Entrata</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona'}
                            options={entryMeansOptions}
                            onChange={(value) => dispatch(setGetAllEPFilterentryMeans(value))}

                        />

                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Dal</span>
                        </div>
                        <Calendar
                            selected={entryProtocolState.getAllEntryProtocolFilters.from}
                            onChange={(date) => dispatch(setGetAllEPFilterFrom(date))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Al</span>
                        </div>
                        <Calendar
                            selected={entryProtocolState.getAllEntryProtocolFilters.to}
                            onChange={(date) => dispatch(setGetAllEPFilterTo(date))}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Cliente'}
                            placeholder="Cliente"
                            onChangeText={(text) => { dispatch(setGetAllEPFilterClient(text)) }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Mittente'}
                            placeholder="Mittente"
                            onChangeText={(text) => { dispatch(setGetAllEPFilterSender(text)) }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Allegato</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona Allegato'}
                            options={attachments}
                            onChange={(value) => dispatch(setGetAllEPFilterAttachmentId(value))}

                        />

                    </div>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.49' }}>
                        <div className="text-left">
                            <span className="input-label">Oggetto</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona Oggetto'}
                            options={objects}
                            onChange={(value) => dispatch(setGetAllEPFilterObjectId(value))}
                        />

                    </div>
                    <div style={{ flex: '0.5' }}>
                        <Input
                            label={'Specifiche Oggetto'}
                            placeholder="Specifiche"
                            onChangeText={(text) => { dispatch(setGetAllEPFilterObjectSpec(text)) }}
                        />
                    </div>
                </div>

                <div className="mt-2" style={{ display: 'flex', flex: 'wrap', gap: '24px', justifyContent: 'right', paddingRight: '20px' }}>
                    <Button
                        variant='solid'
                        color='orange'
                        size='sm'
                        iconPosition='off'
                        label='Cerca'
                        onClick={() => dispatch(GetAllEntryProtocols(entryProtocolState.getAllEntryProtocolFilters))}
                    />
                </div>
            </div>
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 24px 0px 24px' }}>
                <EntryListProtocol />
            </div>
        </Layout>
    )
}