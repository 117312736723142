import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { UtilsSchedaPersonale } from "../../../ui/icons/utilsSchedaPersonale"
import { DownloadCV, DownloadSPrev3 } from "../slice"

export default function BoxUtility() {
    const dispatch = useAppDispatch()
    const functionsState = useAppSelector(state => state.PMFunctions)
    const PMState = useAppSelector(state => state.personalManagement)

    return (
        <div style={{ padding: '24px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
                <span id="title-eval-tab">Utility</span>

            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>

                <div
                    onClick={(e) => {
                        if(PMState.currentPersonalManagementId)
                        {
                            dispatch(DownloadSPrev3(PMState.currentPersonalManagementId))
                        }
                        
                    }
                    }
                    className="bigButtonUtils"
                    style={{ cursor: 'pointer', width: '160px', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <UtilsSchedaPersonale />
                    <div>
                        <span className="bigButtonText">Scarica scheda personale CSX SP</span>
                    </div>
                </div>

                <div
                    onClick={(e) => {
                        if(PMState.currentPersonalManagementId)
                        {
                            dispatch(DownloadCV(PMState.currentPersonalManagementId))
                        }
                        
                    } }
                    className="bigButtonUtils"
                    style={{ cursor: 'pointer', width: '160px', alignItems: 'center', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <UtilsSchedaPersonale />
                    <div>
                        <span className="bigButtonText">Scarica Curriculum</span>
                    </div>
                </div>

            </div>



        </div >
    )
}