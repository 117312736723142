import axios from "axios";
import { GetAllOtherDataResponseDTO, OtherDataDTO } from "./dto";
import { OtherDataService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class OtherDataServiceImpl implements OtherDataService {

    public GetAllOtherData(): Promise<GetAllOtherDataResponseDTO> {
        return axios.get("/api/pm-other-data", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetOtherDataById(id: string): Promise<OtherDataDTO> {
        return axios.get("/api/pm-other-data/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public CreateOtherData(data: OtherDataDTO): Promise<void> {
        return axios.post("/api/pm-other-data",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }
    
    public UpdateOtherData(data: OtherDataDTO, id: string): Promise<void> {
        return axios.put("/api/pm-other-data/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteOtherData(id: string): Promise<void> {
        return axios.delete("/api/pm-other-data/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }
}