import { EvaluationDTO, GetAllEvaluationsResponseDTO } from "./dto";
import { EvaluationServiceImpl } from "./serviceImpl";

export interface EvaluationService {
    GetAllEvaluation(): Promise<GetAllEvaluationsResponseDTO>
    GetEvaluationById(id: string): Promise<EvaluationDTO>
    CreateEvaluation(data: EvaluationDTO): Promise<void>
    UpdateEvaluation(data: EvaluationDTO, id: string): Promise<void>
    DeleteEvaluation(id: string): Promise<void>
}

export function NewEvaluationService(): EvaluationService {
    return new EvaluationServiceImpl();
}