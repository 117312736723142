import React, { ReactElement } from "react";
import { TabList, Tabs } from "react-tabs";

interface Props {
    children: ReactElement[]
}

export function HorizontalTab(props: Props) {
    const { children } = props;

    return (
        <Tabs>
            <TabList className={"flex flex-row gap-2 bg-neutral-100"}>
                {children}
            </TabList>
            <div />
        </Tabs>
    )
}