import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { useEffect, useState } from "react"
import { Layout } from "../../../../ui/layout"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { resetEntryProtocolUpdateStatus, resetEPFileAndAtt } from "../slice"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { RightBar } from "../../../../ui/organisms/rightBar"
import { EditEntryLeft } from "./editEntryLeft"
import { EditEntryRight } from "./editEntryRight"

export function EditEntryProtocol() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showUSuccess, setShowUSuccess] = useState<boolean>(false)

    useEffect(() => {
        if(entryProtocolState.entryProtocolUpdateStatus === 'successfully') {
            //dispatch(resetEPFileAndAtt())
            setShowUSuccess(true)
        }
    }, [entryProtocolState.entryProtocolUpdateStatus])

    useEffect(() => {
        if (showUSuccess) {
            setTimeout(() => {
                setShowUSuccess(false)
            }, 2000)
        }
    }, [showUSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Protocollo', 'Entrata', 'Modifica']}
            noPaddingLat
            headerLabel="Protocollo Entrata"
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => {
                    dispatch(resetEntryProtocolUpdateStatus())
                    navigate('/entryProtocol')
                    }}>
                    <ArrowLeftIcon />
                </button>
            }
        >
            <div className="bg-brandPrimary-100" style={{ display: 'flex', height: '100%' }}>
                <div className="w-[100%] flex flex-col gap-[16px] filter bg-brandPrimary-200" style={{flex: 0.7, padding: '24px 24px 24px 24px', borderTopRightRadius: '20px'}}>
                    {
                        showUSuccess &&
                        <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>
                    }
                    <EditEntryLeft />
                </div>
                <div className="bg-brandPrimary-100" style={{flex: 0.3}}>
                    <RightBar>
                        <EditEntryRight />
                    </RightBar>
                </div>
            </div>
        </Layout>
    )
    
}