import React from "react";
import { IconProps } from "./dto";

export function UsersIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1667 16.5V14.8333C14.1667 13.9493 13.8155 13.1014 13.1904 12.4763C12.5653 11.8512 11.7174 11.5 10.8334 11.5H4.16671C3.28265 11.5 2.43481 11.8512 1.80968 12.4763C1.18456 13.1014 0.833374 13.9493 0.833374 14.8333V16.5M19.1667 16.5V14.8333C19.1662 14.0948 18.9203 13.3773 18.4678 12.7936C18.0154 12.2099 17.3818 11.793 16.6667 11.6083M13.3334 1.60833C14.0504 1.79192 14.6859 2.20892 15.1397 2.79359C15.5936 3.37827 15.8399 4.09736 15.8399 4.8375C15.8399 5.57764 15.5936 6.29673 15.1397 6.88141C14.6859 7.46608 14.0504 7.88308 13.3334 8.06667M10.8334 4.83333C10.8334 6.67428 9.34099 8.16667 7.50004 8.16667C5.65909 8.16667 4.16671 6.67428 4.16671 4.83333C4.16671 2.99238 5.65909 1.5 7.50004 1.5C9.34099 1.5 10.8334 2.99238 10.8334 4.83333Z" stroke="#ADADD6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
