import { useNavigate } from "react-router-dom";
import { Layout } from "../../../../ui/layout";
import { MenuItems } from "../../../../ui/organisms/navbar/dto";
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left";
import { NewEntryLeft } from "./newEntryLeft";
import { RightBar } from "../../../../ui/organisms/rightBar";
import { NewEntryRight } from "./newEntryRight";
import {  useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { GetEntryProtocolById, resetEntryProtocolCreationStatus, resetEPFileAndAtt } from "../slice";
import { ProtocolSuccessBanner, ProtocolSuccessType } from "../protocolSuccessBanner";
import { CustomerInfoDTO } from "../dataService/dto";

interface ProtocolData {
    protocol: string
    sender: string
    region: string
    object: string
    operator: CustomerInfoDTO[]
}

export function AddEntryProtocol() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const [showUSuccess, setShowUSuccess] = useState<boolean>(false)

    useEffect(() => {
        if(entryProtocolState.entryProtocolCreationStatus === 'successfully' &&
            entryProtocolState.entryProtocolCreationResponse !== undefined
        ) {
            dispatch(GetEntryProtocolById(entryProtocolState.entryProtocolCreationResponse))
        }
    }, [entryProtocolState.entryProtocolCreationStatus])

    useEffect(() => {
        if(entryProtocolState.entryProtocolUpdateStatus === 'successfully') {
            //dispatch(resetEPFileAndAtt())
            setShowUSuccess(true)
        }
    }, [entryProtocolState.entryProtocolUpdateStatus])

    useEffect(() => {
        if (showUSuccess) {
            setTimeout(() => {
                setShowUSuccess(false)
            }, 2000)
        }
    }, [showUSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Protocollo', 'Entrata', 'Inserimento']}
            noPaddingLat
            headerLabel="Protocollo Entrata"
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => {
                    dispatch(resetEntryProtocolCreationStatus())
                    navigate('/entryProtocol')
                    }}>
                    <ArrowLeftIcon />
                </button>
            }
        >
            <div className="bg-brandPrimary-100" style={{ display: 'flex', height: '100%' }}>
                <div className="w-[100%] flex flex-col gap-[16px] filter bg-brandPrimary-200" style={{flex: 0.7, padding: '24px 24px 24px 24px', borderTopRightRadius: '20px'}}>
                    {
                        showUSuccess &&
                        <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>
                    }

                    <NewEntryLeft />
                </div>
                <div className="bg-brandPrimary-100" style={{flex: 0.3}}>
                    <RightBar disabled={!entryProtocolState.fromCreationToUpdate}>
                        <NewEntryRight />
                    </RightBar>
                </div>
            </div>
        </Layout>
    )
}