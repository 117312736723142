import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { entryMeansOptions, formatterDate, optionsFormatter } from "../../../utils";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { GetFileById, GetFileObjectById, resetGetFileByIdStatus, resetGetFileObjectByIdStatus } from "../../../objectStorage/slice";
import { EntryProtocolDTO } from "../../protocol/entry/dataService/dto";
import { GetAllEntryProtocols, resetGetAllEntryProtocolsStatus } from "../../protocol/entry/slice";
import { MagnifyingIcon } from "../../../ui/icons/magnifying";
import { DownloadButton } from "../../../ui/icons/downloadButton";
import { DownloadIcon } from "../../../ui/icons/DownloadIcon";
import { DocumentIcon } from "../../../ui/icons/documentIcon";
import Button from "../../../ui/molecules/button";
import { PopupForm } from "../../../ui/organisms/popupForm";
import { RightBar } from "../../../ui/organisms/rightBar";
import { UploadedDocumentRightBarAdd } from "./right";
import { GetAllDocuments, resetGetAllDocumentsStatus, setDocumentCreationCustomerDataId } from "./slice";

interface Props {
    selectedCustomer: string
}

export function UploadedDocuments(props: Props) {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const documentState = useAppSelector(state => state.document)
    const objectStorageState = useAppSelector(state => state.objectStorage)
    const [fileName, setFileName] = useState<string | null>(null)

    useEffect(() => {
        dispatch(setDocumentCreationCustomerDataId(props.selectedCustomer))
    }, [])

    useEffect(() => {
        if(documentState.getAllDocumentsFilters.customer)
            dispatch(GetAllDocuments(documentState.getAllDocumentsFilters))
    }, [documentState.getAllDocumentsFilters.customer])

    useEffect(() => {
        if (documentState.getAllDocumentsStatus === 'successfully') {
            dispatch(resetGetAllDocumentsStatus())
        }
    }, [documentState.getAllDocumentsStatus])

    useEffect(() => {
        if(objectStorageState.getFileObjectByIdStatus === 'successfully' &&
            objectStorageState.getFileObjectByIdResponse !== undefined
        ) {
            dispatch(resetGetFileObjectByIdStatus())
            const data = objectStorageState.getFileObjectByIdResponse
            setFileName(data.name)
            dispatch(GetFileById(data.id))
        }
    }, [objectStorageState.getFileObjectByIdStatus])

    useEffect(() => {
        if (objectStorageState.getFileByIdStatus === 'successfully') {
            dispatch(resetGetFileByIdStatus())
            const base64Data = objectStorageState.getFileByIdResponse?.base64;
            if (base64Data && base64Data !== '') {
                const byteCharacters = atob(base64Data);
                const byteNumbers = Uint8Array.from(byteCharacters, char => char.charCodeAt(0));
                const blob = new Blob([byteNumbers], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);

                // Apri una finestra popup con un titolo personalizzato
                const newWindow = window.open('', '_blank', 'width=800,height=600');
                if (newWindow) {
                    newWindow.document.title = fileName!;
                    const iframe = newWindow.document.createElement('iframe');
                    iframe.style.width = '100%';
                    iframe.style.height = '100%';
                    iframe.src = url;
                    newWindow.document.body.appendChild(iframe);
                }
            }
        }
    }, [objectStorageState.getFileByIdStatus]);

    return (

        <div className="bg-brandPrimary-100" style={{ display: 'flex', height: '100%' }}>
                <div className="w-[100%] flex flex-col gap-[16px] filter bg-brandPrimary-200" style={{ flex: 0.6, padding: '0px 24px 24px 24px', borderTopRightRadius: '20px' }}>
                    <div className="w-[100%]">
                        <div style={{ padding: '24px 0' }}>
                            <table id="users-table" className="w-[100%]">
                                <thead className="h-[52px] uppercase">
                                    <tr>
                                        <th style={{borderTopLeftRadius: '8px', width: '5%'}}>Elimina</th>
                                        <th>Data</th>
                                        <th>Dettaglio</th>
                                        <th>Allegato</th>
                                        <th>Oggetto</th>
                                        <th>Utente</th>
                                        <th style={{width: '5%'}}>File</th>
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        documentState.getAllDocumentsResponse?.data.map((doc, index) =>
                                            <tr
                                                key={index}
                                                style={{
                                                    color: '#4D797F',
                                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                                }}
                                            >
                                                <td className="w-[30px]">
                                                    <div className="flex justify-content-between align-items-center">
                                                        <button onClick={() => {
                                                            
                                                        }}>
                                                            <TrashIcon color={"Orange"} size={18} />
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>{formatterDate(doc.date)}</td>
                                                <td>{doc.detail}</td>
                                                <td>{`${doc.entryAttachment?.description} [${doc.entryAttachment?.coding}]`}</td>
                                                <td>{`${doc.entryObject?.description} [${doc.entryObject?.coding}]`}</td>
                                                <td>
                                                    {doc.user.name + " "+ doc.user.surname }
                                                </td>
                                                <td className="w-[30px]">
                                                    <div className="flex justify-content-between align-items-center">
                                                        <button onClick={() => {
                                                            dispatch(GetFileObjectById(String(doc.fileId)))
                                                        }}>
                                                            <DocumentIcon />
                                                        </button>
                                                    </div>
                                                </td>
                                                
                                               
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                            {/* <Pagination
                                numbResults={usersState.getUsersResponse ? usersState.getUsersResponse?.total : 0}
                                detailsResults={'utenti'}
                                pages={usersState.getUsersResponse?.totalPage || 1}
                                currentPage={usersState.filters.page} setPage={(page) => dispatch(setFindAllUsersFiltersPage(page))}
                            /> */}
                        </div>
                    </div>
                </div>
                <div className="bg-brandPrimary-100" style={{ flex: 0.4, borderTopRightRadius: '20px' }}>
                    <RightBar>
                        <UploadedDocumentRightBarAdd />
                    </RightBar>
                </div>
            </div>
    )
}