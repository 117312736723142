import clsx from "clsx";
import React, { useState } from "react";
import { UploadIcon } from "../../icons/uploadIcon";

interface Props {
    id: string
    infoText?: boolean
    disabled?: boolean
    error?: boolean
    multiple?: boolean
    size?: number
    onChange?: (event: File[]) => void
    fullHeight?: boolean
    color?: 'green' | 'gray'
    iconSize?: boolean
    permittedExtensions?: string[]
    maxSize?: string
}

export function FileInputComponent(props: Props) {
    const { id } = props
    const { infoText } = props
    const { disabled } = props
    const { error } = props
    const { multiple } = props
    const { onChange } = props
    const { size } = props
    const { fullHeight } = props
    const { color } = props
    const { iconSize } = props
    const { permittedExtensions } = props
    const { maxSize } = props

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const [dragged, setDragged] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            onChange && onChange(Array.from(event.target.files))
        }
    };

    const max = 10 * 1024 * 1024

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!disabled) {
            setDragged(false);
            const files = event.dataTransfer.files;
            onChange && onChange(Array.from(files));
        }
    };

    return (
        <div style={{ width: '100%', opacity: (dragged ? 0.5 : 1) }}>
            <div
                id={id}
                draggable
                onClick={() => inputRef?.click()}
                onDragOver={e => {
                    if (!disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.dataTransfer.dropEffect = 'copy';
                        !disabled && setDragged(true);
                    }
                }}
                onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!disabled) {
                        setDragged(true);
                    }
                }}
                onDragLeave={e => {
                    if (!disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        !disabled && setDragged(false);
                    }
                }}
                onDrop={handleDrop}
                className={!iconSize ? clsx("w-full rounded-lg flex justify-start cursor-pointer gap-4 flex-col", {
                    "p-2  items-start": !fullHeight,
                    "py-8 px-6  items-center": fullHeight,
                    "border border-dashed border-brandPrimary-300 bg-brandPrimary-50 hover:bg-brandPrimary-100": color === "green",
                    "border border-dashed border-neutral-100 bg-white hover:bg-brandPrimary-50": color === "gray",
                    "opacity-50": disabled
                }) : clsx("cursor-pointer", {"opacity-50": disabled})}
            >
                <div className={!iconSize ? clsx("flex justify-center items-center gap-3", {
                    "flex-row ": !fullHeight,
                    "flex-col w-full": fullHeight
                }) : undefined}>
                    <UploadIcon />
                    {
                        !iconSize && (
                            <span className={"text-text-md font-medium text-neutral-800"}>
                                {'Drag & drop files or '}
                                <span className='text-text-md font-medium text-brandPrimary-500'>
                                    browse files
                                </span>
                            </span>
                        )
                    }
                    <input draggable name={id} id={id} ref={ref => { if (!disabled) { setInputRef(ref) } }} multiple={multiple} type="file" style={{ display: 'none' }} onChange={handleChange} />
        
                </div>
                {
                    !iconSize && (
                        <div>
                            {
                                infoText && fullHeight ?
                                    <span className={"text-text-sm p-2 " + (size && size > max ? ' text-red-400' : ' text-neutral-400')}>
                                        {
                                            permittedExtensions && `${permittedExtensions.slice(0,-1).map(ext => `${ext}, `)}${permittedExtensions[permittedExtensions.length-1]}`
                                        }
                                        {
                                            maxSize && ` - ${maxSize}`
                                        }
                                    </span>
                                    : infoText && !fullHeight ?
                                        <span className={"text-text-sm p-2 " + (size && size > max ? ' text-red-400' : ' text-neutral-500')}>Dimensione massima del file: 10 MB</span>
                                        : <></>
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}