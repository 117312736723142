import { useNavigate } from "react-router-dom"
import { Layout } from "../../../../ui/layout"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { NewDepartureLeft } from "./newDepartureLeft"
import { RightBar } from "../../../../ui/organisms/rightBar"
import { NewDepartureRight } from "./newDepartureRight"
import { useEffect, useState } from "react"
import { CustomerInfoDTO } from "../dataService/dto"
import { ProtocolSuccessBanner } from "../protocolSuccessBanner"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { GetDepartureProtocolById, resetPPFileAndAtt } from "../slice"

export function AddDepartureProtocol() {
    const navigate = useNavigate()
    const [showUSuccess, setShowUSuccess] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const departureProtocolState = useAppSelector(state => state.departureProtocol)

    useEffect(() => {
        if(departureProtocolState.departureProtocolCreationStatus === 'successfully' &&
            departureProtocolState.departureProtocolCreationResponse !== undefined
        ) {
            dispatch(GetDepartureProtocolById(departureProtocolState.departureProtocolCreationResponse))
        }
    }, [departureProtocolState.departureProtocolCreationStatus])

    useEffect(() => {
        if(departureProtocolState.departureProtocolUpdateStatus === 'successfully') {
            // dispatch(resetPPFileAndAtt())
            setShowUSuccess(true)
        }
    }, [departureProtocolState.departureProtocolUpdateStatus])

    useEffect(() => {
        if (showUSuccess) {
            setTimeout(() => {
                setShowUSuccess(false)
            }, 2000)
        }
    }, [showUSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Protocollo', 'Partenza']}
            noPaddingLat
            headerLabel="Protocollo Partenza"
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/departureProtocol')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>

                </div>
            }
        >
            <div className="bg-brandPrimary-100" style={{ display: 'flex', height: '100%' }}>
                <div className="w-[100%] flex flex-col gap-[16px] filter bg-brandPrimary-200" style={{ flex: 0.7, padding: '24px 24px 24px 24px', borderTopRightRadius: '20px' }}>
                    {
                        showUSuccess &&
                        <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>
                    }
                    <NewDepartureLeft />
                </div>
                <div className="bg-brandPrimary-100" style={{ flex: 0.3 }}>
                    <RightBar disabled={!departureProtocolState.fromCreationToUpdate}>
                        <NewDepartureRight />
                    </RightBar>
                </div>
            </div>
        </Layout>
    )
}