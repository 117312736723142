import React from "react";
import "./Dropdown.css";
import { DropdownContainerProps } from "./Dropdown.types";
import DropdownItem from "./DropdownItem";

const DropdownContainer: React.FC<DropdownContainerProps> = ({
    ...props
}) => {
    return (
        <div
            className={"absolute right-[40px] top-[15px] z-50"}>
            <div className="dropdown-container">
                {props.items.map((item, index) => <DropdownItem key={'menu-item-' + index} {...item} onClick={() => { item.onClick && item.onClick(); props.onClose() }} />)}
            </div>
        </div>
    )
};

export default DropdownContainer
