interface Props {
    color?: string
    size: number
}

export function ProtocolIcon(props: Props) {
    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill={props.color || 'none'}>
            <path d="M1.66666 9.08333L10 13.25L18.3333 9.08333M10 0.75L1.66666 4.91667L10 9.08333L18.3333 4.91667L10 0.75Z" stroke="#C0D5D8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
