import { useEffect, useState } from "react";
import { ListItem } from "../../../ui/organisms/listItem";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { addRequirementDetail, GetAllRequirements, GetRequirementDetailById, resetGetAllRequirementStatus, resetGetRequirementDetailByIdStatus, resetRequirementCreationRequest, resetRequirementDetails, resetRequirementErrors, resetRequirements, resetRequirementUpdateRequest, setReqDetailPersonalManagementId, setRequirement, setRequirementIdToUpdate, setRequirements, setUpdateNorm, setUpdateRequirement, setUpdateSpec } from "./slice";
import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus, setPMJobs } from "../slice";
import Button from "../../../ui/molecules/button";
import { PopupForm } from "../../../ui/organisms/popupForm";
import { RequirementPopupAdd } from "./boxPopupRequirement/add";
import { RequirementPopupEdit } from "./boxPopupRequirement/edit";
import { RequirementDTO } from "./dataService/dto";
import MultiSelectCheckbox from "../../../ui/molecules/multiSelectCheckbox";
import { rolesOptions } from "../../../utils";

export function BoxRequirements() {
    const dispatch = useAppDispatch()
    const requirementState = useAppSelector(state => state.PMRequirement)
    const PMState = useAppSelector(state => state.personalManagement)
    const [addPopupShow, setAddPopupShow] = useState<boolean>(false)
    const [editPopupShow, setEditPopupShow] = useState<boolean>(false)
    const [selectedJobs, setSelectedJobs] = useState<{ label: string; value: string }[]>([]);

    useEffect(() => {
        if(PMState.currentPersonalManagementId) {
            dispatch(setReqDetailPersonalManagementId(PMState.currentPersonalManagementId))
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [PMState.currentPersonalManagementId])

    useEffect(() => {
        if(PMState.getByIdPersonalManagementResourcesStatus === 'successfully') {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))

            if (PMState.getByIdPersonalManagementResourcesResponse?.requirementDetailIds) {
                dispatch(resetRequirementDetails())
               for (let index = 0; index < PMState.getByIdPersonalManagementResourcesResponse?.requirementDetailIds.length; index++) {
                   dispatch(GetRequirementDetailById(PMState.getByIdPersonalManagementResourcesResponse?.requirementDetailIds[index].toString()))
               }
            }

            if(PMState.getByIdPersonalManagementResourcesResponse?.jobs) {
                const jobs = PMState.getByIdPersonalManagementResourcesResponse?.jobs
                dispatch(setPMJobs(jobs))
                setSelectedJobs(jobs as any)
            }
   
        }
    }, [PMState.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if(requirementState.getRequirementDetailByIdStatus === 'successfully' &&
            requirementState.getRequirementDetailByIdResponse !== undefined
        ) {
            dispatch(resetGetRequirementDetailByIdStatus())
            dispatch(addRequirementDetail(requirementState.getRequirementDetailByIdResponse))
        }
    }, [requirementState.getRequirementDetailByIdStatus])

    useEffect(() => {
        dispatch(resetRequirements())
        dispatch(GetAllRequirements())
    }, [])

    useEffect(() => {
        if(requirementState.getAllRequirementsStatus === 'successfully' &&
            requirementState.getAllRequirementsResponse !== undefined
        ) {
            dispatch(resetGetAllRequirementStatus())
            const toSort = [...requirementState.getAllRequirementsResponse]
            toSort.sort((r1,r2) => Number(r1.id) - Number(r2.id))
            dispatch(setRequirements(toSort))
        }
    }, [requirementState.getAllRequirementsStatus])

    const handleOnEdit = (r: RequirementDTO) => {
        dispatch(setUpdateRequirement(r.requirement))
        dispatch(setUpdateSpec(r.spec))
        dispatch(setUpdateNorm(r.norm))
        dispatch(setRequirementIdToUpdate(r.id))
        setEditPopupShow(true)
    }

    return (
        <div style={{ padding: '24px' }}>
            <div style={{width: '100%', display: 'flex', flex: 'wrap', gap: '24px', paddingBottom: 10}}>
                <div style={{ flex: '0.85' }}>
                    <MultiSelectCheckbox
                        onChange={(e) => {
                            setSelectedJobs(e.value)
                            dispatch(setPMJobs(e.value))
                        }}
                        value={selectedJobs}
                        option={rolesOptions}
                        placeholder="Disponibile a ricoprire mansione di..."
                        selectAllLabel="Tutte"
                    />
                </div>
                <div style={{ flex: '0.15', justifyContent: 'center' }}>
                    <Button
                        size="md"
                        iconPosition="off"
                        variant="solid"
                        color="orange"
                        label="Aggiungi requisito"
                        onClick={() => setAddPopupShow(true)}
                    />
                </div>
            </div>
            {
                requirementState.requirements.map(r => 
                    <ListItem 
                        key={r.id}
                        code={Number(r.id)} 
                        title={r.requirement}
                        numOfDetails={requirementState.requirementDetails
                            .filter(d => d.requirementId === r.id).length} 
                        subTitle={r.norm}
                        paragraph={r.spec}
                        onEdit={() => handleOnEdit(r)}
                    />
                )
            }
            {
                addPopupShow && (
                    <PopupForm title="Nuovo requisito" close={() => {
                        dispatch(resetRequirementCreationRequest())
                        dispatch(resetRequirementErrors())
                        setAddPopupShow(false)
                    }}>
                        <RequirementPopupAdd close={() => setAddPopupShow(false)} />
                    </PopupForm>
                )
            }
            {
                editPopupShow && (
                    <PopupForm title="Modifica requisito" close={() => {
                        dispatch(resetRequirementUpdateRequest())
                        dispatch(resetRequirementErrors())
                        setEditPopupShow(false)
                    }}>
                        <RequirementPopupEdit close={() => setEditPopupShow(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}