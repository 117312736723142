import { Fragment } from "react/jsx-runtime";
import Button from "../../../../../ui/molecules/button";
import { PlusOrange } from "../../../../../ui/icons/plusOrange";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { addTrainingDefaultExternalTeacher, addTrainingDefaultExternalTeacherFunction, addUpdateTrainingDefaultExternalTeacher, addUpdateTrainingDefaultExternalTeacherFunction, setTrainingExternalTeacher, setTrainingExternalTeacherByIndex, setTrainingExternalTeacherFunction, setTrainingExternalTeacherFunctionByIndex, setUpdateTrainingExternalTeacher, setUpdateTrainingExternalTeacherByIndex, setUpdateTrainingExternalTeacherFunction, setUpdateTrainingExternalTeacherFunctionByIndex } from "../../slice";
import { SelectCustom } from "../../../../../ui/molecules/select";
import { trainingActivityOptions } from "../../../../../utils";
import { TrashIcon } from "../../../../../ui/icons/trash";
import Input from "../../../../../ui/molecules/input";
import { useEffect } from "react";

export function ExternalTeachersAdd() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)

    return (
        <div className="pb-5" style={{borderBottomWidth: 1}}>
            <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div className="text-left" style={{flex: '0.5'}}>
                    <span className="subtitle">Docenti esterni</span>
                </div>
                <div style={{flex: '0.5', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button 
                        size='sm' 
                        color='orange' 
                        variant='outline'
                        label='Aggiungi docente esterno' 
                        iconPosition='right' 
                        onClick={() => {
                            dispatch(addTrainingDefaultExternalTeacher())
                            dispatch(addTrainingDefaultExternalTeacherFunction())
                        }}
                        icon={<PlusOrange color='orange' size={20}/>} />
                </div>
            </div>
            {
                trainingState.trainingCreationRequest.externalTeacher.map((t,index) => (
                    <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{ flex: '0.475' }}>
                            <Input
                                // error={}
                                // supportingText={}
                                label={'Nome e cognome'}
                                placeholder="Nome e cognome del docente esterno"
                                value={trainingState.trainingCreationRequest.externalTeacher[index]}
                                defaultValue={trainingState.trainingCreationRequest.externalTeacher[index]}
                                onChangeText={(text) => {dispatch(setTrainingExternalTeacherByIndex({data: text, index: index}))}}
                                />
                        </div>
                        <div style={{ flex: '0.475' }}>
                            <Input
                                error={trainingState.trainingErrors.externalTeacherFunction}
                                supportingText={trainingState.trainingErrors.externalTeacherFunction? 'Nessuna funzione specificata' : undefined}
                                label={'Funzione'}
                                placeholder="Funzione del docente esterno"
                                value={trainingState.trainingCreationRequest.externalTeacherFunction[index]}
                                defaultValue={trainingState.trainingCreationRequest.externalTeacherFunction[index]}
                                onChangeText={(text) => {dispatch(setTrainingExternalTeacherFunctionByIndex({data: text, index: index}))}}
                                />
                        </div>
                        <div style={{ flex: '0.05', display: 'flex', alignSelf: 'flex-end', paddingBottom: 5}}>
                            <Button
                                size='md'
                                iconPosition='only'
                                icon={<TrashIcon color='#f00' size={20} />} 
                                variant='outline'
                                color='orange'
                                onClick={() => {
                                    const newTeachers = trainingState.trainingCreationRequest.externalTeacher.filter((t,idx) => idx !== index)
                                    const newFuncTeachers = trainingState.trainingCreationRequest.externalTeacherFunction.filter((t,idx) => idx !== index)
                                    dispatch(setTrainingExternalTeacher(newTeachers))
                                    dispatch(setTrainingExternalTeacherFunction(newFuncTeachers))
                                }}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export function ExternalTeachersEdit() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)

    return (
        <div className="pb-5" style={{borderBottomWidth: 1}}>
            <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div className="text-left" style={{flex: '0.5'}}>
                    <span className="subtitle">Docenti esterni</span>
                </div>
                <div style={{flex: '0.5', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button 
                        size='sm' 
                        color='orange' 
                        variant='outline'
                        label='Aggiungi docente esterno' 
                        iconPosition='right' 
                        onClick={() => {
                            dispatch(addUpdateTrainingDefaultExternalTeacher())
                            dispatch(addUpdateTrainingDefaultExternalTeacherFunction())
                        }}
                        icon={<PlusOrange color='orange' size={20}/>} />
                </div>
            </div>
            {
                trainingState.trainingUpdateRequest.externalTeacher.map((t,index) => (
                    <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{ flex: '0.475' }}>
                            <Input
                                // error={}
                                // supportingText={}
                                label={'Nome e cognome'}
                                placeholder="Nome e cognome del docente esterno"
                                value={trainingState.trainingUpdateRequest.externalTeacher[index]}
                                defaultValue={trainingState.trainingUpdateRequest.externalTeacher[index]}
                                onChangeText={(text) => {dispatch(setUpdateTrainingExternalTeacherByIndex({data: text, index: index}))}}
                                />
                        </div>
                        <div style={{ flex: '0.475' }}>
                            <Input
                                // error={}
                                // supportingText={}
                                label={'Funzione'}
                                placeholder="Funzione del docente esterno"
                                value={trainingState.trainingUpdateRequest.externalTeacherFunction[index]}
                                defaultValue={trainingState.trainingUpdateRequest.externalTeacherFunction[index]}
                                onChangeText={(text) => {dispatch(setUpdateTrainingExternalTeacherFunctionByIndex({data: text, index: index}))}}
                                />
                        </div>
                        <div style={{ flex: '0.05', display: 'flex', alignSelf: 'flex-end', paddingBottom: 5}}>
                            <Button
                                size='md'
                                iconPosition='only'
                                icon={<TrashIcon color='#f00' size={20} />} 
                                variant='outline'
                                color='orange'
                                onClick={() => {
                                    const newTeachers = trainingState.trainingUpdateRequest.externalTeacher.filter((t,idx) => idx !== index)
                                    const newFuncTeachers = trainingState.trainingUpdateRequest.externalTeacherFunction.filter((t,idx) => idx !== index)
                                    dispatch(setUpdateTrainingExternalTeacher(newTeachers))
                                    dispatch(setUpdateTrainingExternalTeacherFunction(newFuncTeachers))
                                }}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}