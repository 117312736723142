import React from "react";
import { IconProps } from "./dto";

export function CalendarIcon(props: IconProps) {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none">
            <path d="M9.66667 1.33301V3.99967M4.33333 1.33301V3.99967M1 6.66634H13M2.33333 2.66634H11.6667C12.403 2.66634 13 3.26329 13 3.99967V13.333C13 14.0694 12.403 14.6663 11.6667 14.6663H2.33333C1.59695 14.6663 1 14.0694 1 13.333V3.99967C1 3.26329 1.59695 2.66634 2.33333 2.66634Z" stroke="#8383AD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}