import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ContractDTO, ContractErrorsDTO, GetAllContractsResponseDTO } from "./dataService/dto"
import { setEnding } from "../boxAnagrafica/slice"
import { PromiseStatuses } from "../../../utils"
import { NewContractsService } from "./dataService/service"

interface ContrattiState {
    contracts: ContractDTO[]

    // REQUEST
    contractCreationRequest: ContractDTO
    contractUpdateRequest: ContractDTO
    contractIdToUpdate: string
    headquarter: string
    year: string
    number: string

    // REQUEST (ERROR)
    contractErrors: ContractErrorsDTO

    // STATUS
    contractCreationStatus: PromiseStatuses
    contractUpdateStatus: PromiseStatuses
    contractDeleteStatus: PromiseStatuses
    getAllContractsStatus: PromiseStatuses
    getContractByIdStatus: PromiseStatuses
    contractValidationStatus: PromiseStatuses

    // RESPONSE
    getAllContractsResponse?: GetAllContractsResponseDTO
    getContractByIdResponse?: ContractDTO
}

const initialState: ContrattiState = {
    contracts: [],
    headquarter: '',
    year: '',
    number: '',

    // REQUEST
    contractCreationRequest: {
        contractType: '',
        numContract: '',
        revisionType: '',
        stipulate: new Date(),
        starting: new Date(),
        ending: null,
        kmIndennity: false,
        protocolIdDeparture: 'string',
        personalManagementId: '',
        active: true
    },
    contractUpdateRequest: {
        contractType: '',
        numContract: '',
        revisionType: '',
        stipulate: new Date(),
        starting: new Date(),
        ending: null,
        kmIndennity: false,
        protocolIdDeparture: 'string',
        personalManagementId: '',
        active: true
    },
    contractIdToUpdate: '',

    // REQUEST (ERROR)
    contractErrors: {
        contractType: false,
        numContract: false,
        revisionType: false,
        protocolIdDeparture: false
    },

    // STATUS
    contractCreationStatus: 'idle',
    contractUpdateStatus: 'idle',
    contractDeleteStatus: 'idle',
    getAllContractsStatus: 'idle',
    getContractByIdStatus: 'idle',
    contractValidationStatus: 'idle'
}

export const GetAllContracts = createAsyncThunk(
    'PM/Contracts/GetAll',
    async (request, thunkApi): Promise<GetAllContractsResponseDTO> => {
        const contractsService = NewContractsService()

        return contractsService.GetAllContracts().catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetContractById = createAsyncThunk(
    'PM/Contracts/GetById',
    async (id: string, thunkApi): Promise<ContractDTO> => {
        const contractsService = NewContractsService()

        return contractsService.GetContractById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ContractCreation = createAsyncThunk(
    'PM/Contracts/create',
    async (body: ContractDTO, thunkApi): Promise<void> => {
        const contractsService = NewContractsService()

        return contractsService.CreateContract(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ContractUpdate = createAsyncThunk(
    'PM/Contracts/update',
    async (request: {body: ContractDTO, id: string}, thunkApi): Promise<void> => {
        const contractsService = NewContractsService()

        return contractsService.UpdateContract(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ContractDelete = createAsyncThunk(
    'PM/Contracts/delete',
    async (id: string, thunkApi): Promise<void> => {
        const contractsService = NewContractsService()

        return contractsService.DeleteContract(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ContractValidation = createAsyncThunk(
    'PM/Contract/Validate',
    async (request: ContractDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetContractErrors())

        if(request.contractType === '') {
            thunkApi.dispatch(setValidateContractType(true))
            isValid = false
        }

        if(request.numContract === '') {
            thunkApi.dispatch(setValidateNumContract(true))
            isValid = false
        }

        if(request.revisionType === '') {
            thunkApi.dispatch(setValidateRevisionType(true))
            isValid = false
        }

        if(request.protocolIdDeparture === '') {
            thunkApi.dispatch(setValidateProtocolIdDeparture(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

const PMContracts = createSlice({
    name: 'PM/contractsSlice',
    initialState,
    reducers: {
        resetContracts: (state) => {
            state.contracts = []
        },
        addContracts: (state, action) => {
            state.contracts.push(action.payload)
        },
        setContracts: (state, action) => {
            state.contracts = action.payload
        },

        // REQUEST
        setContractType: (state, action) => {
            state.contractCreationRequest.contractType = action.payload
        },
        setNumContract: (state, action) => {
            state.contractCreationRequest.numContract = action.payload
        },
        setRevisionType: (state, action) => {
            state.contractCreationRequest.revisionType = action.payload
        },
        setStipulate: (state, action) => {
            state.contractCreationRequest.stipulate = action.payload
        },
        setStartingDate: (state, action) => {
            state.contractCreationRequest.starting = action.payload
        },
        setEndingDate: (state, action) => {
            state.contractCreationRequest.ending = action.payload
        },
        setKmIndennity: (state, action) => {
            state.contractCreationRequest.kmIndennity = action.payload
        },
        setHeadQuarter: (state, action) => {
            state.headquarter = action.payload
        },
        setYearContract: (state, action) => {
            state.year = action.payload
        },
        setNumber: (state, action) => {
            state.number = action.payload
        },
        setActive: (state, action) => {
            state.contractCreationRequest.active = action.payload
        },
        setPMId: (state, action) => {
            state.contractCreationRequest.personalManagementId = action.payload
        },
        setProtocolIdDeparture: (state, action) => {
            state.contractCreationRequest.protocolIdDeparture = action.payload
        },
        resetContractCreationRequest: (state) => {
            state.contractCreationRequest = {
                contractType: '',
                numContract: '',
                revisionType: '',
                stipulate: new Date(),
                starting: new Date(),
                ending: null,
                kmIndennity: false,
                protocolIdDeparture: 'string',
                personalManagementId: state.contractCreationRequest.personalManagementId,
                active: true
            }
            state.headquarter = ''
            state.year = ''
            state.number = ''
        },

        // REQUEST (UPDATE)
        setContractIdToUpdate: (state, action) => {
            state.contractIdToUpdate = action.payload
        },
        setUpdateContractType: (state, action) => {
            state.contractUpdateRequest.contractType = action.payload
        },
        setUpdateNumContract: (state, action) => {
            state.contractUpdateRequest.numContract = action.payload
        },
        setUpdateRevisionType: (state, action) => {
            state.contractUpdateRequest.revisionType = action.payload
        },
        setUpdateStipulate: (state, action) => {
            state.contractUpdateRequest.stipulate = action.payload
        },
        setUpdateStartingDate: (state, action) => {
            state.contractUpdateRequest.starting = action.payload
        },
        setUpdateEndingDate: (state, action) => {
            state.contractUpdateRequest.ending = action.payload
        },
        setUpdateKmIndennity: (state, action) => {
            state.contractUpdateRequest.kmIndennity = action.payload
        },
        setUpdateActive: (state, action) => {
            state.contractUpdateRequest.active = action.payload
        },
        setUpdatePMId: (state, action) => {
            state.contractUpdateRequest.personalManagementId = action.payload
        },
        setUpdateProtocolIdDeparture: (state, action) => {
            state.contractUpdateRequest.protocolIdDeparture = action.payload
        },
        resetContractUpdateRequest: (state) => {
            state.contractCreationRequest = {
                contractType: '',
                numContract: '',
                revisionType: '',
                stipulate: new Date(),
                starting: new Date(),
                ending: new Date(),
                kmIndennity: false,
                protocolIdDeparture: 'string',
                personalManagementId: state.contractUpdateRequest.personalManagementId,
                active: true
            }
            state.headquarter = ''
            state.year = ''
            state.number = ''
        },

        // REQUEST (ERROR)
        setValidateContractType: (state, action) => {
            state.contractErrors.contractType = action.payload
        },
        setValidateNumContract: (state, action) => {
            state.contractErrors.numContract = action.payload
        },
        setValidateRevisionType: (state, action) => {
            state.contractErrors.revisionType = action.payload
        },
        setValidateProtocolIdDeparture: (state, action) => {
            state.contractErrors.protocolIdDeparture = action.payload
        },
        resetContractErrors: (state) => {
            state.contractErrors = {
                contractType: false,
                numContract: false,
                revisionType: false,
                protocolIdDeparture: false
            }
        },

        // STATUS
        resetContractCreationStatus: (state) => {
            state.contractCreationStatus = 'idle'
        },
        resetContractUpdateStatus: (state) => {
            state.contractUpdateStatus = 'idle'
        },
        resetContractDeleteStatus: (state) => {
            state.contractDeleteStatus = 'idle'
        },
        resetGetAllContractsStatus: (state) => {
            state.getAllContractsStatus = 'idle'
        },
        resetGetContractByIDStatus: (state) => {
            state.getContractByIdStatus = 'idle'
        },
        resetContractValidationStatus: (state) => {
            state.contractValidationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder
            
            // GET ALL
            .addCase(GetAllContracts.pending, (state) => {
                state.getAllContractsStatus = 'loading'
            })
            .addCase(GetAllContracts.fulfilled, (state, action) => {
                state.getAllContractsStatus = 'successfully'
                state.getAllContractsResponse = action.payload
            })
            .addCase(GetAllContracts.rejected, (state) => {
                state.getAllContractsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetContractById.pending, (state) => {
                state.getContractByIdStatus = 'loading'
            })
            .addCase(GetContractById.fulfilled, (state, action) => {
                state.getContractByIdStatus = 'successfully'
                state.getContractByIdResponse = action.payload
            })
            .addCase(GetContractById.rejected, (state) => {
                state.getContractByIdStatus = 'failed'
            })

            // CREATION
            .addCase(ContractCreation.pending, (state) => {
                state.contractCreationStatus = 'loading'
            })
            .addCase(ContractCreation.fulfilled, (state, action) => {
                state.contractCreationStatus = 'successfully'
            })
            .addCase(ContractCreation.rejected, (state) => {
                state.contractCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(ContractUpdate.pending, (state) => {
                state.contractUpdateStatus = 'loading'
            })
            .addCase(ContractUpdate.fulfilled, (state, action) => {
                state.contractUpdateStatus = 'successfully'
            })
            .addCase(ContractUpdate.rejected, (state) => {
                state.contractUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(ContractDelete.pending, (state) => {
                state.contractDeleteStatus = 'loading'
            })
            .addCase(ContractDelete.fulfilled, (state, action) => {
                state.contractDeleteStatus = 'successfully'
            })
            .addCase(ContractDelete.rejected, (state) => {
                state.contractDeleteStatus = 'failed'
            })

            // VALIDATION
            .addCase(ContractValidation.pending, (state) => {
                state.contractValidationStatus = 'loading'
            })
            .addCase(ContractValidation.fulfilled, (state, action) => {
                state.contractValidationStatus = 'successfully'
            })
            .addCase(ContractValidation.rejected, (state) => {
                state.contractValidationStatus = 'failed'
            })
    },
})

export const {
    resetContracts,
    addContracts,
    setContracts,

    // REQUEST
    setActive,
    setContractType,
    setEndingDate,
    setHeadQuarter,
    setKmIndennity,
    setNumContract,
    setNumber,
    setRevisionType,
    setStartingDate,
    setStipulate,
    setYearContract,
    setProtocolIdDeparture,
    setPMId,
    resetContractCreationRequest,

    // REQUEST (UPDATE)
    setContractIdToUpdate,
    setUpdateActive,
    setUpdateContractType,
    setUpdateEndingDate,
    setUpdateKmIndennity,
    setUpdateNumContract,
    setUpdatePMId,
    setUpdateProtocolIdDeparture,
    setUpdateRevisionType,
    setUpdateStartingDate,
    setUpdateStipulate,
    resetContractUpdateRequest,

    // REQUEST (ERROR)
    setValidateContractType,
    setValidateNumContract,
    setValidateProtocolIdDeparture,
    setValidateRevisionType,
    resetContractErrors,

    // STATUS
    resetContractCreationStatus,
    resetContractUpdateStatus,
    resetContractDeleteStatus,
    resetGetAllContractsStatus,
    resetGetContractByIDStatus,
    resetContractValidationStatus

} = PMContracts.actions

export default PMContracts.reducer