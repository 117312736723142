
export type PromiseStatuses = 'idle' | 'loading' | 'successfully' | 'failed'
export type OnButtonClick = React.MouseEventHandler<HTMLButtonElement> | undefined
export type OnMenuItemClick = React.MouseEventHandler<HTMLLIElement> | undefined
export type OnChange = React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type OnFocus = React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type OnKeyDown = React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type Operation = 'save' | 'edit'
export const BACKEND_HOST = 'http://localhost:8080'
export type QuarkusError = { message: string }[]

export async function toBase64(file: File): Promise<string> {
    const bytes = new Uint8Array(await file.arrayBuffer());

    let binary = '';

    for (let i = 0; i < bytes.byteLength; i++) {

        binary += String.fromCharCode(bytes[i]);

    }
    return btoa(binary);
}

export const breadcrumbsLabel = new Map<string, string>([
    ['dashboard', 'Dashboard'],
    ['users', 'Utenti'],
    ['clienti', 'Clienti'],
])

type Grouped<T> = { [key: string]: T[] };

export function groupBy<T>(array: T[], key: keyof T): { key: string, values: T[] }[] {
    const groupedObject = array.reduce((result, currentValue) => {
        const groupKey = currentValue[key] as unknown as string;
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(currentValue);
        return result;
    }, {} as Grouped<T>);

    return groupedObjectToArray(groupedObject)
}

function groupedObjectToArray<T>(groupedObject: Grouped<T>): { key: string, values: T[] }[] {
    return Object.keys(groupedObject).map(key => ({
        key,
        values: groupedObject[key]
    }));
}

export const generatePagination = (currentPage: number, totalPages: number) => {
    // If the total number of pages is 7 or less,
    // display all pages without any ellipsis.
    if (totalPages <= 7) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    // If the current page is among the first 3 pages,
    // show the first 3, an ellipsis, and the last 2 pages.
    if (currentPage <= 3) {
        return [1, 2, 3, '...', totalPages - 1, totalPages];
    }

    // If the current page is among the last 3 pages,
    // show the first 2, an ellipsis, and the last 3 pages.
    if (currentPage >= totalPages - 2) {
        return [1, 2, '...', totalPages - 2, totalPages - 1, totalPages];
    }

    // If the current page is somewhere in the middle,
    // show the first page, an ellipsis, the current page and its neighbors,
    // another ellipsis, and the last page.
    return [
        1,
        '...',
        currentPage - 1,
        currentPage,
        currentPage + 1,
        '...',
        totalPages,
    ];
};
