import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { entryMeansOptions, formatterDate, optionsFormatter } from "../../../utils";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { Pagination } from "../../../ui/organisms/pagination";
import { GetAllDepartureProtocolAttachments, GetAllDepartureProtocols, setDepartureProtocolIdToUpdate, setGetAllPPFilterPage, setUpdateCorriere, setUpdateDataBolgetta, setUpdateDataReceive, setUpdateDataRicezioneCompiutaGiacenza, setUpdateDataRitornoErratoIndirizzo, setUpdatedFile, setUpdatedPPAttachmentFile, setUpdateNumeroRaccomandataErratoIndirizzo, setUpdateNumRaccomandata, setUpdatePEC, setUpdatePostaElettronica, setUpdatePPCustomerIds, setUpdatePPCustomersNotRegistered, setUpdatePPDepartureAttachmentIds, setUpdatePPDepartureChannel, setUpdatePPDepartureObjectId, setUpdatePPHeadquarter, setUpdatePPLinkExistingProtocol, setUpdatePPNation, setUpdatePPObjectSpec, setUpdatePPProtocolDate, setUpdatePPRegion, setUpdatePPSend, setUpdatepPSender, setUpdatePPTotalPage, setUpdatePPUsersVisibility, setUpdateRaccomandataAr, setUpdateRaccomandataMano } from "./slice";
import { DepartureProtocolDTO } from "./dataService/dto";
import { GetFileObjectById } from "../../../objectStorage/slice";


export function DepartureListProtocol() {
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    const objectStorageState = useAppSelector(state => state.objectStorage)
    const handleOnEdit = (data: DepartureProtocolDTO) => {
        if (data.fileId !== null && data.fileId !== undefined) {

            dispatch(GetFileObjectById(data.fileId))
        }
        dispatch(setDepartureProtocolIdToUpdate(data.id))
        dispatch(setUpdatePPProtocolDate(data.protocolDate))
        dispatch(setUpdatePPHeadquarter(data.headQuarter))
        dispatch(setUpdatePPNation(data.nation))
        dispatch(setUpdatePPRegion(data.region))
        dispatch(setUpdatepPSender(data.receiver))
        if (data.customersInfo !== null)
            dispatch(setUpdatePPCustomerIds(data.customersInfo.map(c => c.id)))
        dispatch(setUpdatePPCustomersNotRegistered(data.customersNotRegistered))
        dispatch(setUpdatePPDepartureObjectId(data.departureObject.id))
        dispatch(setUpdatePPObjectSpec(data.objectSpec))
        dispatch(setUpdatePPTotalPage(data.totalPage))
        dispatch(setUpdatePPDepartureChannel(data.departureChannel))
        dispatch(setUpdatePPSend(data.send))
        dispatch(setUpdatePPUsersVisibility(data.usersVisibility))
        dispatch(setUpdatePPLinkExistingProtocol(data.linkExistingProtocol))
        dispatch(setUpdatePPDepartureAttachmentIds(data.departureAttachments.map(att => att.id)))
        dispatch(setUpdateNumRaccomandata(data.numeroRaccomandata))
        dispatch(setUpdateDataBolgetta(data.dataBolgetta))
        dispatch(setUpdateDataRicezioneCompiutaGiacenza(data.dataRicezioneCompiutaGiacenza))
        dispatch(setUpdateDataRitornoErratoIndirizzo(data.dataRitornoErratoIndirizzo))
        dispatch(setUpdateNumeroRaccomandataErratoIndirizzo(data.numeroRaccomandataErratoIndirizzo))
        dispatch(setUpdateCorriere(data.corriere))
        dispatch(setUpdateRaccomandataMano(data.raccomandataMano))
        dispatch(setUpdatePostaElettronica(data.postaElettronica))
        dispatch(setUpdateDataReceive(data.receive))
        dispatch(setUpdateRaccomandataAr(data.raccomandataAr))
        dispatch(setUpdatePEC(data.postaElettronicaCertificata))
        dispatch(setUpdatedPPAttachmentFile(data.attachmentFile))
        if (data.fileId === null || data.fileId === undefined) {
            navigate('/editDepartureProtocol')
        }
    }

    useEffect(() => {
        if (objectStorageState.getFileObjectByIdStatus === 'successfully') {
            dispatch(setUpdatedFile(objectStorageState.getFileObjectByIdResponse))
            navigate('/editDepartureProtocol')
        }

    }, [objectStorageState.getFileObjectByIdStatus])

    useEffect(() => {
        // dispatch(GetAllDepartureProtocols(departureProtocolState.getAllDepartureProtocolFilters))
        dispatch(GetAllDepartureProtocolAttachments())
    }, [])

    return (
        <div className="w-[100%]">
            <Pagination
                numbResults={departureProtocolState.getAllDepartureProtocolsResponse ? departureProtocolState.getAllDepartureProtocolsResponse?.total : 0}
                detailsResults={'Protocolli'}
                fullWidth={true}
                pages={departureProtocolState.getAllDepartureProtocolsResponse?.totalPage || 1}
                currentPage={departureProtocolState.getAllDepartureProtocolFilters.page ? departureProtocolState.getAllDepartureProtocolFilters.page : 0} setPage={(page) => dispatch(setGetAllPPFilterPage(page))}
            />
            <div style={{ padding: '24px 0' }}>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th>Numero</th>
                            <th>Sede</th>
                            <th>Anno</th>
                            <th>Data</th>
                            <th>Mittente</th>
                            <th>Cliente</th>
                            <th>Oggetto</th>
                            <th>Oggetto Extra</th>
                            <th>Mezzo Entrata</th>
                            <th>Funzionario</th>
                            <th>Allegati</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            departureProtocolState.getAllDepartureProtocolsResponse?.data.map((protocol, index) =>
                                <tr key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                    className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                                >
                                    <td>{protocol.headQuarter} {'P' + protocol.protocolNumber}</td>
                                    <td>{protocol.headQuarter}</td>
                                    <td>{protocol.year}</td>
                                    <td>{formatterDate(protocol.protocolDate)}</td>
                                    <td>{protocol.receiver}</td>
                                    <td></td>
                                    <td>
                                        {
                                            protocol.departureObject.description
                                        }
                                    </td>
                                    <td>{protocol.objectSpec}</td>
                                    <td>
                                        {
                                            protocol.departureChannel &&
                                            optionsFormatter(protocol.departureChannel, entryMeansOptions)
                                        }
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td className="w-[30px]">
                                        <div className="flex justify-content-between align-items-center">
                                            <button onClick={() => {
                                                handleOnEdit(protocol)
                                            }}>
                                                <EditIcon color={"Orange"} size={18} />
                                            </button>

                                            {/* <div className="pl-3 flex align-items-center">
                                                <button onClick={() => { }}>
                                                    <TrashIcon color={"Orange"} size={0} />
                                                </button>
                                            </div> */}
                                        </div>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}