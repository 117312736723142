import { useState } from 'react'
import './style.scss'
import { PlusIcon } from '../../icons/plus';
import { EditIcon } from '../../icons/edit';
import { MinusIcon } from '../../icons/minusIcon';
import { DetailTable } from './detailRequirement';
import { normativa, optionsFormatter } from '../../../utils';
import { useAppSelector } from '../../../lib/redux/hooks';

interface Props {
    code: number
    title: string
    numOfDetails: number
    subTitle: string[]
    paragraph: string
    onEdit?: () => void
}

export function ListItem(props: Props) {
    const {code, title, numOfDetails, subTitle, paragraph, onEdit} = props
    const [expanded, setExpanded] = useState<boolean>(false)
    const requirementState = useAppSelector(state => state.PMRequirement)

    const handleSubtitles = (subs: string[]) => {
        if(subs === null) 
            return '';
        return subs.slice(0,-1)
                 .map(s => `${optionsFormatter(s, normativa)} - `) 
                  + optionsFormatter(subs[subs.length-1], normativa)!
    }

    return (
        <div className='container-li' style={{padding: '10px', backgroundColor: expanded ? '#FFF' : '#F2F7F7'}}>

            <div className='code'>
                <span>{code < 10 ? `0${code}` : code}</span>
            </div>

            <div className='content'>

                <div>
                    <span className='title' style={{color: '#182B2E'}}>{`${title} - `}</span>
                    <span className='title' style={{color: 'orange'}}>{`[${numOfDetails}]`}</span>
                </div>

               
                <div>
                    <span className='sub-notbold'>Norma di riferimento: </span>
                    <span className='sub-bold'>{handleSubtitles(subTitle)}</span>
                </div>
                

                <div className='pb-2' style={{textAlign: 'left', maxWidth: '60%'}}>
                    <span className='paragraph'>{paragraph}</span>
                </div>

                {
                    expanded && (
                        <DetailTable
                            requirementId={String(code)}
                            details={requirementState.requirementDetails
                                .filter(d => Number(d.requirementId) === code)}
                        />
                    )
                }
            </div>

            <div className='options'>
                <div className='sub-options'>
                    <button onClick={() => onEdit && onEdit()}>
                        <EditIcon color='orange' size={20} />
                    </button>
                    {
                        !expanded ? (
                            <button onClick={() => setExpanded(true)} className='ml-3'> 
                                <PlusIcon color='orange' size={20} />
                            </button>
                        ) : (
                            <button onClick={() => setExpanded(false)} className='ml-3'>
                                <MinusIcon color='orange'/>
                            </button>
                        )
                    }
                </div>
            </div>

        </div>
    )
}