import { GetFileByIdDTO, GetObjectByIdDTO } from "./dto";
import { ObjectStorageServiceImpl } from "./serviceImpl";

export interface ObjectStorageService {
    GetFileObjectById(id: string): Promise<GetObjectByIdDTO>
    GetFileById(id: string): Promise<GetFileByIdDTO>
    RemoveDots(value?: string): string
    Download(name: string, blob?: Blob): void
    Base64ToFile(base64String: string, type: string): File
}

export function NewObjectStorageService() {
    return new ObjectStorageServiceImpl()
}