interface Props {
    size?: number
    color?: string
}

export function RoundedCloseIcon(props: Props) {
    return (
        <svg width={props.size ? props.size : '22'} height={props.size ? props.size : '22'} viewBox="0 0 22 22" fill="none">
            <path d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke={props.color ? props.color : '#8383AD'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
