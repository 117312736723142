import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import './style.scss';
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import Button from "../../ui/molecules/button";
import { PopupForm } from "../../ui/organisms/popupForm";
import Input from "../../ui/molecules/input/Input";
import { Province, Region, genreSelect, regioniProvinceMap, regions } from "../../utils";
import { provinceComuniMap } from "../../utils/listaComuni";
import { SelectCustom } from "../../ui/molecules/select";
import { CalendarIcon } from "../../ui/icons/calendar";
import TextArea from "../../ui/molecules/textArea/TextArea";
import { ListCentriAziendali } from "./listCentriAziendali";
import { addCorporateCenter, setActivationDateCorporateCenter, setAddressCorporateCenter, setCapCorporateCenter, setCodeCorporateCenter, setComuneCorporateCenter, setDeactivationDateCorporateCenter, setDescriptionCorporateCenter, setEmailCorporateCenter, setLatCorporateCenter, setLongCorporateCenter, setPecCorporateCenter, setProvinciaCorporateCenter, setRecapitoCorporateCenter, setReferenteCorporateCenter, setRegioneCorporateCenter } from "./slice";
import { format } from "date-fns";
import { Calendar } from "../../ui/molecules/calendar";

registerLocale('it', it)

type OptionType = {
    value: string;
    label: string;
};


export function BoxCentriAziendali() {
    const clientiState = useAppSelector(state => state.clienti)
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const [provinceOptions, setProvinceOptions] = useState<OptionType[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<OptionType | null>(null);
    const [comuneOptions, setComuneOptions] = useState<OptionType[]>([]);
    const [selectedProvince, setSelectedProvince] = useState<OptionType | null>(null);
    const [selectedComune, setSelectedComune] = useState<OptionType | null>(null);
    const dispatch = useAppDispatch()
    const regionOptions: OptionType[] = Array.from(regioniProvinceMap.keys()).map(region => ({
        value: region,
        label: region
    }));

    useEffect(() => {
        if (selectedRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setProvinceOptions(newProvinceOptions);
            } else {
                setProvinceOptions([]);
            }
        } else {
            setProvinceOptions([]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedProvince) {
            const comuni = provinceComuniMap.get(selectedProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setComuneOptions(newComuneOptions);
        } else {
            setComuneOptions([]);
        }
    }, [selectedProvince]);

    const handleRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRegion(selectedOption);
        setSelectedProvince(null); // Reset province when region changes
        setSelectedComune(null);
    };

    const handleProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedProvince(selectedOption);
        setSelectedComune(null);
    };

    const handleComuneChange = (selectedOption: OptionType | null) => {
        setSelectedComune(selectedOption);
    };


    return (
        <>
            <div style={{ padding: '24px' }} id="boxCentriAziendali">
                <>
                    <ListCentriAziendali />
                </>
                <div className="mt-6">
                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiungi Centro Aziendale" color={"orange"}
                        onClick={() => {
                            setShowPopup(true)
                        }}
                    />
                </div>
            </div >
            {
                showPopup &&
                <PopupForm title={'Nuovo centro aziendale'} close={() => setShowPopup(false)}>
                    <div style={{ padding: '24px' }} className="bg-brandPrimary-100" >

                        <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.3' }}>
                                <div className="text-left">
                                    <span className="input-label">ID/Codice</span>
                                </div>
                                <Input placeholder="ID/Codice" onChangeText={(text) => dispatch(setCodeCorporateCenter(text))} />
                            </div>
                            <div style={{ flex: '0.7' }}>
                                <div className="text-left">
                                    <span className="input-label">Descrizione</span>
                                </div>
                                <Input placeholder="Descrizione" onChangeText={(text) => dispatch(setDescriptionCorporateCenter(text))} />
                            </div>

                        </div>

                        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Regione</span>
                                </div>
                                <SelectCustom
                                    defaultValue={selectedRegion?.value}
                                    placeholder={'Regione'}
                                    options={regionOptions}
                                    onChange={(value) => { handleRegionChange(value as unknown as OptionType | null); dispatch(setRegioneCorporateCenter(value)) }}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Provincia</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Provincia'}
                                    options={provinceOptions}
                                    onChange={(value) => { handleProvinceChange(value as unknown as OptionType | null); dispatch(setProvinciaCorporateCenter(value)) }}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Comune</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Comune'}
                                    options={comuneOptions}
                                    onChange={(value) => {
                                        dispatch(setComuneCorporateCenter(value))
                                    }}
                                />
                            </div>

                            <div style={{ flex: '0.5' }}>
                                <Input label={'CAP'} placeholder="CAP" onChangeText={(text) => dispatch(setCapCorporateCenter(text))} />
                            </div>

                        </div>


                        <div className="mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input label={'Indirizzo'} placeholder="Indirizzo" onChangeText={(text) => dispatch(setAddressCorporateCenter(text))} />
                            </div>

                        </div>

                        <div className="formSection mt-6">
                            <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <Input label={'Latitudine'} placeholder="Latitudine" onChangeText={(text) => dispatch(setLatCorporateCenter(text))} />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input label={'Longitudine'} placeholder="Longitudine" onChangeText={(text) => dispatch(setLongCorporateCenter(text))} />
                                </div>
                            </div>
                        </div>

                        <div className="formSection mt-6">
                            <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <Input label={'Referente'} placeholder="Referente" onChangeText={(text) => dispatch(setReferenteCorporateCenter(text))} />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input label={'Recapito'} placeholder="Recapito" onChangeText={(text) => dispatch(setRecapitoCorporateCenter(text))} />
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <Input label={'Email'} placeholder="Email" onChangeText={(text) => dispatch(setEmailCorporateCenter(text))} />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input label={'PEC'} placeholder="PEC" onChangeText={(text) => dispatch(setPecCorporateCenter(text))} />
                                </div>
                            </div>
                        </div>

                        <div className="formSection mt-6">
                            <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <div className="text-left">
                                        <span className="input-label">Data Ingresso</span>
                                    </div>
                                    <Calendar
                                        selected={clientiState.companyCenterEditCreateRequest.activationDate}
                                        onChange={(date) => {
                                            dispatch(setActivationDateCorporateCenter(format(new Date(date), 'yyyy-MM-dd')))
                                        }}
                                    />

                                </div>

                                <div style={{ flex: '0.5' }}>
                                    <div className="text-left">
                                        <span className="input-label">Data Uscita</span>
                                    </div>
                                    <Calendar
                                        selected={clientiState.companyCenterEditCreateRequest.deactivationDate}
                                        onChange={(date) => {
                                            dispatch(setDeactivationDateCorporateCenter(format(new Date(date), 'yyyy-MM-dd')))
                                        }}
                                    />

                                </div>
                            </div>


                            <div className="mt-6" style={{ display: 'flex', justifyContent: 'right' }}>
                                <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Annulla" color={"dimgrey"}
                                    onClick={() => { setShowPopup(false) }}
                                />
                                <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                                    onClick={() => { dispatch(addCorporateCenter()); setShowPopup(false) }}
                                />


                            </div>

                        </div>



                    </div>
                </PopupForm>
            }

        </>
    )
}