import { AnagraphicByIdDTO, AnagraphicCreationDTO } from "./dto";
import { AnagraphicServiceImpl } from "./serviceImpl";


export interface AnagraphicService {
    CreateAnagraphic(data: AnagraphicCreationDTO): Promise<void>
    AnagraphicEdit(data: AnagraphicCreationDTO, id: string): Promise<void>
    GetByIdAnagraphic(id: string): Promise<AnagraphicByIdDTO>
    
}

export function NewAnagraphicService(): AnagraphicService {
    return new AnagraphicServiceImpl();
}