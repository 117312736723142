import clsx from "clsx"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import './style.scss';
import Button from "../../ui/molecules/button";
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { Banner } from "../../ui/organisms/banner";
import { Tab } from "react-tabs";
import { HorizontalTab } from "../../ui/organisms/horizontalTab";

import { GetByIdPersonalManagement, PMUpdate, resetGetByIdPersonalManagementResourcesStatus, resetPersonalManagementUpdateStatus, setSelectedTab } from "./slice";
import { BoxAnagrafica } from "./boxAnagrafica/boxAnagrafica";
import { BoxValutazioni } from "./boxValutazioni/boxValutazioni";
import { BoxContratti } from "./boxContratti/boxContratti";
import BoxOtherData from "./boxOtherData/boxOtherData";
import { BoxCDXM026 } from "./boxCDXM026/boxCDXM026";
import BoxOtherInfo from "./boxOtherInfo/boxOtherInfo";
import { AnagraphicCreate, AnagraphicEdit, GetByIdAnagraphic, resetAnagraphicErrors, resetGetByIdStatus, setAddress, setBirthCity, setBirthDate, setBirthProvince, setBirthRegion, setCity, setCityRegister, setEnding, setExternalCity, setExternalNation, setExternalProvince, setFirstEmail, setFirstFax, setFirstHouse, setFirstOffice, setFirstPhone, setFiscalCode, setFiscalRegime, setIdCode, setName, setNote, setNumRegister, setNumScheda, setPec, setPMId, setProfessionalRegister, setProvince, setRegion, setSdi, setSecondEmail, setSecondFax, setSecondHouse, setSecondOffice, setSecondPhone, setSex, setStarting, setSurname, setValidateAnagraphicCreationStatus, setVat, validateAnagraphicCreation } from "./boxAnagrafica/slice";
import { OptionType, Province, Region, regions } from "../../utils";
import BoxFunzioni from "./boxFunzioni/boxFunzioni";
import { BoxRequirements } from "./boxRequisiti/boxRequirements";
import { BoxFormazioneSingle } from "./boxFormazione/riepilogoSingle";
import BoxUtility from "./boxUtility";
import { ArrowLeftIcon } from "../../ui/icons/arrow-left";
import { useNavigate } from "react-router-dom";

registerLocale('it', it)

export function EditStaff() {
    const personalManagement = useAppSelector(state => state.personalManagement)
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)
    const evaluationsState = useAppSelector(state => state.PMEvaluations)
    const contractsState = useAppSelector(state => state.PMContracts)
    const otherDataState = useAppSelector(state => state.PMOtherData)
    const CDXM026State = useAppSelector(state => state.PMCDXM026)
    const otherInfoState = useAppSelector(state => state.PMOtherInfo)
    const functionsState = useAppSelector(state => state.PMFunctions)
    const requirementState = useAppSelector(state => state.PMRequirement)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [provinceOptions, setProvinceOptions] = useState<OptionType[]>([]);

    const regionOptions: OptionType[] = regions.map((region: Region) => ({
        value: region.name,
        label: region.name
    }));

    useEffect(() => {
        if (personalManagement.currentPersonalManagementId) {
            dispatch(GetByIdPersonalManagement(personalManagement.currentPersonalManagementId))
        }
    }, [personalManagement.currentPersonalManagementId])

    useEffect(() => {
        if (anagraficaState.validateAnagraphicCreationStatus === 'successfully') {
            dispatch(setValidateAnagraphicCreationStatus('idle'))
            dispatch(resetAnagraphicErrors())
            if (personalManagement.currentPersonalManagementId && personalManagement.getByIdPersonalManagementResourcesResponse?.anagraphicId) {
                dispatch(AnagraphicEdit({ data: anagraficaState.createAnagraphicRequest, id: personalManagement.getByIdPersonalManagementResourcesResponse?.anagraphicId.toString() }))
            }
        }
    }, [anagraficaState.validateAnagraphicCreationStatus])


    useEffect(() => {
        if (personalManagement.getByIdPersonalManagementResourcesStatus === 'successfully') {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))
            if (personalManagement.getByIdPersonalManagementResourcesResponse) {
                if (personalManagement.getByIdPersonalManagementResourcesResponse.anagraphicId) {
                    dispatch(GetByIdAnagraphic(personalManagement.getByIdPersonalManagementResourcesResponse.anagraphicId.toString()))
                }

            }
        }

    }, [personalManagement.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if(personalManagement.personalManagementUpdateStatus === 'successfully') {
            dispatch(resetPersonalManagementUpdateStatus())
            dispatch(GetByIdPersonalManagement(personalManagement.currentPersonalManagementId!))
        }
    }, [personalManagement.personalManagementUpdateStatus])

        // CREATION SUCCESS BANNER
        useEffect(() => {
            if(evaluationsState.evaluationCreationStatus === 'successfully' ||
                contractsState.contractCreationStatus === 'successfully' ||
                otherDataState.otherDataCreationStatus === 'successfully' ||
                CDXM026State.CDXM026CreationStatus === 'successfully' ||
                otherInfoState.createOtherInfoStatus === 'successfully' ||
                requirementState.requirementCreationStatus === 'successfully' ||
                requirementState.requirementDetailCreationStatus === 'successfully' ||
                functionsState.functionCreationStatus === 'successfully'
            ) {
                setShowSuccess(true)
            }
        }, [evaluationsState.evaluationCreationStatus,
            contractsState.contractCreationStatus,
            otherDataState.otherDataCreationStatus,
            CDXM026State.CDXM026CreationStatus,
            otherInfoState.createOtherInfoStatus,
            requirementState.requirementCreationStatus,
            requirementState.requirementDetailCreationStatus,
            functionsState.functionCreationStatus
        ])
    
        // UPDATE SUCCESS BANNER
        useEffect(() => {
            if(evaluationsState.evaluationUpdateStatus === 'successfully' ||
                contractsState.contractUpdateStatus === 'successfully' ||
                otherDataState.otherDataUpdateStatus === 'successfully' ||
                CDXM026State.CDXM026UpdateStatus === 'successfully' ||
                anagraficaState.AnagraphicEditStatus === 'successfully' ||
                otherInfoState.OthInfoUpdateStatus === 'successfully' ||
                requirementState.requirementUpdateStatus === 'successfully' ||
                requirementState.requirementDetailUpdateStatus === 'successfully' ||
                personalManagement.personalManagementUpdateStatus === 'successfully'
            ) {
                setShowSuccess(true)
            }
        }, [
            evaluationsState.evaluationUpdateStatus,
            contractsState.contractUpdateStatus,
            otherDataState.otherDataUpdateStatus,
            CDXM026State.CDXM026UpdateStatus,
            otherInfoState.OthInfoUpdateStatus,
            anagraficaState.AnagraphicEditStatus,
            requirementState.requirementUpdateStatus,
            requirementState.requirementDetailUpdateStatus,
            personalManagement.personalManagementUpdateStatus
        ])
    
        // DELETE SUCCESS BANNER
        useEffect(() => {
            if(evaluationsState.evaluationDeleteStatus === 'successfully' ||
                contractsState.contractDeleteStatus === 'successfully' ||
                otherDataState.otherDataDeleteStatus === 'successfully' ||
                CDXM026State.CDXM026DeleteStatus === 'successfully' ||
                otherInfoState.DeleteIdOtherInfoStatus === 'successfully' ||
                functionsState.functionDeleteStatus === 'successfully' ||
                requirementState.requirementDeleteStatus === 'successfully' ||
                requirementState.requirementDetailDeleteStatus === 'successfully'
            ) {
                setShowDeleteSuccess(true)
            }
        }, [
            evaluationsState.evaluationDeleteStatus,
            contractsState.contractDeleteStatus,
            otherDataState.otherDataDeleteStatus,
            CDXM026State.CDXM026DeleteStatus,
            otherInfoState.DeleteIdOtherInfoStatus,
            functionsState.functionDeleteStatus,
            requirementState.requirementDeleteStatus,
            requirementState.requirementDetailDeleteStatus
        ])
    
        useEffect(() => {
            if(showSuccess) {
                setTimeout(() => {
                    setShowSuccess(false)
                }, 2000)
            }
            if(showDeleteSuccess) {
                setTimeout(() => {
                    setShowDeleteSuccess(false)
                }, 2000)
            }
        }, [showSuccess, showDeleteSuccess])

    return (
        <Layout menuItem={MenuItems.GESTIONE_PERSONALE} breadcrumbItems={['Dashboard', 'Gestione Personale', 'Nuovo Utente']}
            headerLabel={"Scheda n° " + anagraficaState.getByIdAnagraphicRequest.numScheda + " - " + anagraficaState.getByIdAnagraphicRequest.surname + " " + anagraficaState.getByIdAnagraphicRequest.name}
            rightBar={true}
            overflow="hidden"
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => navigate('/gestionePersonale')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Modifica" color={"orange"}
                        onClick={() => {
                            if(personalManagement.getByIdPersonalManagementResourcesResponse) {
                                if(personalManagement.getByIdPersonalManagementResourcesResponse.anagraphicId) {
                                    if(personalManagement.selectedTab === 1) {
                                        dispatch(PMUpdate({body: personalManagement.personalManagementUpdateRequest, id: personalManagement.currentPersonalManagementId!}))
                                    } 
                                    else
                                        dispatch(validateAnagraphicCreation(anagraficaState.createAnagraphicRequest))
                                }

                            }

                        }}
                    />
                </div>
            }
        >
            <div>

                {
                    (showSuccess) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Dati salvati correttamente.
                        </span>
                    </div>

                }
                {
                    (showDeleteSuccess) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Dati eliminati correttamente.
                        </span>
                    </div>

                }


                <HorizontalTab
                    children={[
                        <>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(0)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 0,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 0
                                    })
                                }
                                tabIndex={"0"}
                            >
                                Anagrafica
                            </Tab>

                            <Tab
                                onClick={() => { dispatch(setSelectedTab(1)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 1,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 1
                                    })
                                }
                                tabIndex={"1"}
                            >
                                Requisiti
                            </Tab>

                            <Tab
                                onClick={() => { dispatch(setSelectedTab(2)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 2,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 2
                                    })
                                }
                                tabIndex={"2"}
                            >
                                Formazione
                            </Tab>

                            <Tab
                                onClick={() => { dispatch(setSelectedTab(3)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 3,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 3
                                    })
                                }
                                tabIndex={"3"}
                            >
                                Funzioni
                            </Tab>

                            <Tab
                                onClick={() => { dispatch(setSelectedTab(4)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 4,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 4
                                    })
                                }
                                tabIndex={"4"}
                            >
                                Valutazioni
                            </Tab>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(5)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 5,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 5
                                    })
                                }
                                tabIndex={"5"}
                            >
                                Contratti
                            </Tab>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(6)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 6,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 6
                                    })
                                }
                                tabIndex={"6"}
                            >
                                Documenti
                            </Tab>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(7)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 7,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 7
                                    })
                                }
                                tabIndex={"7"}
                            >
                                CDX M26
                            </Tab>

                            <Tab
                                onClick={() => { dispatch(setSelectedTab(8)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 8,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 8
                                    })
                                }
                                tabIndex={"8"}
                            >
                                Altre Informazioni
                            </Tab>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(9)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": personalManagement.selectedTab === 9,
                                        "text-label-sm text-neutral-600": personalManagement.selectedTab !== 9
                                    })
                                }
                                tabIndex={"9"}
                            >
                                Utility/Stampe
                            </Tab>

                        </>
                    ]}
                />

            </div>
            <div style={{ display: 'flex', height: '100%' }} className="bg-brandPrimary-100">
                <div className="fieldset bg-brandPrimary-200" style={{ flex: 1, borderTopRightRadius: '16px' }}>
                    {
                        personalManagement.selectedTab === 0 &&
                        <BoxAnagrafica />
                    }
                    
                    {
                        personalManagement.selectedTab === 1 &&
                        <BoxRequirements />
                    }
                    {
                        personalManagement.selectedTab === 2 &&
                        <BoxFormazioneSingle />
                    }
                    {
                        personalManagement.selectedTab === 3 &&
                        <BoxFunzioni />
                    }
                    {
                        personalManagement.selectedTab === 4 &&
                        <BoxValutazioni />
                    }
                    {
                        personalManagement.selectedTab === 5 &&
                        <BoxContratti />
                    }
                    {
                        personalManagement.selectedTab === 6 &&
                        <BoxOtherData />
                    }
                    {
                        personalManagement.selectedTab === 7 &&
                        <BoxCDXM026 />
                    }
                    {
                        personalManagement.selectedTab === 8 &&
                        <BoxOtherInfo />
                    }
                    {
                        personalManagement.selectedTab === 9 &&
                        <BoxUtility />
                    }

                </div>
            </div>
        </Layout >
    )
}