import axios from "axios";
import { keycloak } from "../../lib/keycloak";
import { EditUserRequestDTO, FindAllUsersFilters, UserDTO, UserEditCreationDTO } from "./dto";
import { UsersService } from "./service";
import { addTechinicianUserDTO } from "../businessTechnician/dto";

export class UsersServiceImpl implements UsersService {

    public createUser(data: UserEditCreationDTO): Promise<void> {
        return axios.post("/api/users",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public linkBusinessTech(data: addTechinicianUserDTO): Promise<void> {
   
        return axios.post("/api/users/"+data.id+"/add-technician/"+data.techId,
        {},    
        {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public findUserById(id: string): Promise<UserDTO> {
        return axios.get("/api/users/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public FindMe(): Promise<void> {
        return axios.get("/api/users/find-me", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public getUsers(filters: FindAllUsersFilters): Promise<any>{
        return axios.get("/api/users", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page,
                sort: filters.sort,
                order: filters.order,
                headQuarter: filters.headquarter,
                active: filters.active
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public editUser(id: string, data: UserEditCreationDTO): Promise<void> {
        return axios.put("/api/users/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public RemoveUser(id: string): Promise<void> {
        return axios.delete("/api/users/remove/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
    


}
