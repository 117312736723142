import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { GetAllOtherDataResponseDTO, OtherDataDTO, OtherDataErrorDTO } from "./dataService/dto";
import { NewOtherDataService } from "./dataService/service";

interface OtherDataState {
    otherDataInstances: OtherDataDTO[]

    // REQUEST
    otherDataCreationRequest: OtherDataDTO
    otherDataUpdateRequest: OtherDataDTO
    otherDataIdToUpdate: string
    headquarter: string
    year: string
    number: string

    // REQUEST (ERROR)
    otherDataErrors: OtherDataErrorDTO

    // STATUS
    otherDataCreationStatus: PromiseStatuses
    otherDataUpdateStatus: PromiseStatuses
    otherDataDeleteStatus: PromiseStatuses
    getAllOtherDataStatus: PromiseStatuses
    getOtherDataByIdStatus: PromiseStatuses
    otherDataValidationStatus: PromiseStatuses

    // RESPONSE
    getAllOtherDataResponse?: GetAllOtherDataResponseDTO
    getOtherDataByIdResponse?: OtherDataDTO
}

const initialState: OtherDataState = {
    otherDataInstances: [],

    // REQUEST
    otherDataCreationRequest: {
        protocolIdEntry: 'string',
        commitmentStatement: '',
        personalManagementId: ''
    },
    otherDataUpdateRequest: {
        protocolIdEntry: 'string',
        commitmentStatement: '',
        personalManagementId: ''
    },
    otherDataIdToUpdate: '',
    headquarter: '',
    year: '',
    number: '',

    // REQUEST (ERROR)
    otherDataErrors: {
        protocolIdEntry: false,
        commitmentStatement: false
    },

    // STATUS
    otherDataCreationStatus: 'idle',
    otherDataUpdateStatus: 'idle',
    otherDataDeleteStatus: 'idle',
    getAllOtherDataStatus: 'idle',
    getOtherDataByIdStatus: 'idle',
    otherDataValidationStatus: 'idle'
}

export const GetAllOtherData = createAsyncThunk(
    'PM/OtherData/GetAll',
    async (request, thunkApi): Promise<GetAllOtherDataResponseDTO> => {
        const otherDataService = NewOtherDataService()

        return otherDataService.GetAllOtherData().catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetOtherDataById = createAsyncThunk(
    'PM/OtherData/GetById',
    async (id: string, thunkApi): Promise<OtherDataDTO> => {
        const otherDataService = NewOtherDataService()

        return otherDataService.GetOtherDataById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const OtherDataCreation = createAsyncThunk(
    'PM/OtherData/create',
    async (body: OtherDataDTO, thunkApi): Promise<void> => {
        const otherDataService = NewOtherDataService()

        return otherDataService.CreateOtherData(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const OtherDataUpdate = createAsyncThunk(
    'PM/OtherData/update',
    async (request: {body: OtherDataDTO, id: string}, thunkApi): Promise<void> => {
        const otherDataService = NewOtherDataService()

        return otherDataService.UpdateOtherData(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const OtherDataDelete = createAsyncThunk(
    'PM/OtherData/delete',
    async (id: string, thunkApi): Promise<void> => {
        const otherDataService = NewOtherDataService()

        return otherDataService.DeleteOtherData(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const OtherDataValidation = createAsyncThunk(
    'PM/OtherData/Validate',
    async (request: OtherDataDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetOtherDataErrors())

        if(request.protocolIdEntry === '') {
            thunkApi.dispatch(setValidateProtocolIdEntry(true))
            isValid = false
        }

        if(request.commitmentStatement === '') {
            thunkApi.dispatch(setValidateCommitmentStatement(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

const PMOtherData = createSlice({
    name: 'PM/otherDataSlice',
    initialState,
    reducers: {
        resetODInstances: (state) => {
            state.otherDataInstances = []
        },
        addODInstance: (state, action) => {
            state.otherDataInstances.push(action.payload)
        },
        setODInstances: (state, action) => {
            state.otherDataInstances = action.payload
        },

        // REQUEST
        setOtherDataHeadquarter: (state, action) => {
            state.headquarter = action.payload
        },
        setOtherDataProtocolIdEntry: (state, action) => {
            state.otherDataCreationRequest.protocolIdEntry = action.payload
        },
        setOtherDataYear: (state, action) => {
            state.year = action.payload
        },
        setOtherDataNumber: (state, action) => {
            state.number = action.payload
        },
        setOtherDataCommitmentStatement: (state, action) => {
            state.otherDataCreationRequest.commitmentStatement = action.payload
        },
        setPMId: (state, action) => {
            state.otherDataCreationRequest.personalManagementId = action.payload
        },
        resetOtherDataCreationRequest: (state) => {
            state.otherDataCreationRequest = {
                protocolIdEntry: 'string',
                commitmentStatement: '',
                personalManagementId: state.otherDataCreationRequest.personalManagementId
            }
            state.headquarter = ''
            state.year = ''
            state.number = ''
        },

        // REQUEST (UPDATE)
        setOtherDataIdToUpdate: (state, action) => {
            state.otherDataIdToUpdate = action.payload
        },
        setUpdateOtherDataProtocolIdEntry: (state, action) => {
            state.otherDataUpdateRequest.protocolIdEntry = action.payload
        },
        setUpdateOtherDataCommitmentStatement: (state, action) => {
            state.otherDataUpdateRequest.commitmentStatement = action.payload
        },
        resetOtherDataUpdateRequest: (state) => {
            state.otherDataUpdateRequest = {
                protocolIdEntry: 'string',
                commitmentStatement: '',
            }
            state.headquarter = ''
            state.year = ''
            state.number = ''
        },

        // REQUEST (ERROR)
        setValidateProtocolIdEntry: (state, action) => {
            state.otherDataErrors.protocolIdEntry = action.payload
        },
        setValidateCommitmentStatement: (state, action) => {
            state.otherDataErrors.commitmentStatement = action.payload
        },
        resetOtherDataErrors: (state) => {
            state.otherDataErrors = {
                protocolIdEntry: false,
                commitmentStatement: false
            }
        },

        // STATUS
        resetOtherDataCreationStatus: (state) => {
            state.otherDataCreationStatus = 'idle'
        },
        resetOtherDataUpdateStatus: (state) => {
            state.otherDataUpdateStatus = 'idle'
        },
        resetOtherDataDeleteStatus: (state) => {
            state.otherDataDeleteStatus = 'idle'
        },
        resetGetAllOtherDataStatus: (state) => {
            state.getAllOtherDataStatus = 'idle'
        },
        resetGetOtherDataByIdStatus: (state) => {
            state.getOtherDataByIdStatus = 'idle'
        },
        resetOtherDataValidationStatus: (state) => {
            state.otherDataValidationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder
            // GET ALL
            .addCase(GetAllOtherData.pending, (state) => {
                state.getAllOtherDataStatus = 'loading'
            })
            .addCase(GetAllOtherData.fulfilled, (state, action) => {
                state.getAllOtherDataStatus = 'successfully'
                state.getAllOtherDataResponse = action.payload
            })
            .addCase(GetAllOtherData.rejected, (state) => {
                state.getAllOtherDataStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetOtherDataById.pending, (state) => {
                state.getOtherDataByIdStatus = 'loading'
            })
            .addCase(GetOtherDataById.fulfilled, (state, action) => {
                state.getOtherDataByIdStatus = 'successfully'
                state.getOtherDataByIdResponse = action.payload
            })
            .addCase(GetOtherDataById.rejected, (state) => {
                state.getOtherDataByIdStatus = 'failed'
            })


            // CREATION
            .addCase(OtherDataCreation.pending, (state) => {
                state.otherDataCreationStatus = 'loading'
            })
            .addCase(OtherDataCreation.fulfilled, (state, action) => {
                state.otherDataCreationStatus = 'successfully'
            })
            .addCase(OtherDataCreation.rejected, (state) => {
                state.otherDataCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(OtherDataUpdate.pending, (state) => {
                state.otherDataUpdateStatus = 'loading'
            })
            .addCase(OtherDataUpdate.fulfilled, (state, action) => {
                state.otherDataUpdateStatus = 'successfully'
            })
            .addCase(OtherDataUpdate.rejected, (state) => {
                state.otherDataUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(OtherDataDelete.pending, (state) => {
                state.otherDataDeleteStatus = 'loading'
            })
            .addCase(OtherDataDelete.fulfilled, (state, action) => {
                state.otherDataDeleteStatus = 'successfully'
            })
            .addCase(OtherDataDelete.rejected, (state) => {
                state.otherDataDeleteStatus = 'failed'
            })

            // VALIDATION
            .addCase(OtherDataValidation.pending, (state) => {
                state.otherDataValidationStatus = 'loading'
            })
            .addCase(OtherDataValidation.fulfilled, (state, action) => {
                state.otherDataValidationStatus = 'successfully'
            })
            .addCase(OtherDataValidation.rejected, (state) => {
                state.otherDataValidationStatus = 'failed'
            })
    },
})

export const {
    resetODInstances,
    addODInstance,
    setODInstances,

    // REQUEST
    setOtherDataCommitmentStatement,
    setOtherDataProtocolIdEntry,
    setOtherDataHeadquarter,
    setOtherDataNumber,
    setOtherDataYear,
    setPMId,
    resetOtherDataCreationRequest,

    // REQUEST (UPDATE)
    setOtherDataIdToUpdate,
    setUpdateOtherDataCommitmentStatement,
    setUpdateOtherDataProtocolIdEntry,
    resetOtherDataUpdateRequest,

    // REQUEST (ERROR)
    setValidateCommitmentStatement,
    setValidateProtocolIdEntry,
    resetOtherDataErrors,

    // STATUS
    resetGetAllOtherDataStatus,
    resetOtherDataCreationStatus,
    resetOtherDataUpdateStatus,
    resetOtherDataDeleteStatus,
    resetGetOtherDataByIdStatus,
    resetOtherDataValidationStatus
} = PMOtherData.actions

export default PMOtherData.reducer