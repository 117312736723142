import { CloseIcon } from '../../icons/close';
import { PopupProps } from './dto';
import './style.scss';

export function PopupForm(props: PopupProps) {
    const { children } = props

    return (
        <div id="popup" className="popupAdd">
            <div className="boxElements">
                <div className="modal">
                    <div className='popupHeader pl-5 pr-5 bg-white' style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px', display: 'flex' }}>
                        <h5 className="heading">{props.title}</h5>
                        <button
                            onClick={() => props.close()}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                    <div className='popup-content'>
                        <div className="pb-3">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}