import { CSSProperties } from "react";

export const InputDatePickerStyle: CSSProperties = {
    border: '1px solid', 
    borderRadius: '8px',
    outline: 0, 
    height: '44px', 
    padding: '0px 40px',
    marginRight: '10px',
    gap: '8px',
    width: '100%', 
    color: '#62628A', 
    fontFamily: 'Roboto', 
    fontWeight: '500', 
    fontSize: '14px' ,
    borderColor: 'rgba(203, 203, 235, 1)',
}