import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { activityOptions, formatterDate, optionsFormatter } from "../../../utils";
import { addEvaluations, DeleteEvaluation, GetAllEvaluations, GetEvaluationById, resetEvaluationCreationRequest, resetEvaluationDeleteStatus, resetEvaluationError, resetEvaluations, resetEvaluationUpdateRequest, resetGetAllEvaluationsStatus, resetGetEvaluationByIdStatus, setEvalIdToUpdate, setEvaluations, setPMId, setUpdateEvaluationActivityId, setUpdateEvaluationDate, setUpdateEvaluatorId, setUpdateNote, setUpdateResult } from "./slice";
import Button from "../../../ui/molecules/button";
import '../style.scss'
import { PopupForm } from "../../../ui/organisms/popupForm";
import { ValutazioniPopupAdd } from "./boxPopupOptions/add";
import { ValutazioniPopupEdit } from "./boxPopupOptions/edit";
import { EvaluationDTO } from "./dataService/dto";
import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus } from "../slice";

export function BoxValutazioni () {
    const dispatch = useAppDispatch()
    const PMState = useAppSelector(state => state.personalManagement)
    const evaluationsState = useAppSelector(state => state.PMEvaluations)
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const [openEditPopup, setOpenEditPopup] = useState<boolean>(false)

    const onEditHandler = (ev: EvaluationDTO) => {
        dispatch(setUpdateEvaluationDate(ev.evaluationDate))
        dispatch(setUpdateEvaluationActivityId(ev.evaluationActivityId))
        dispatch(setUpdateEvaluatorId(ev.evaluatorId))
        dispatch(setUpdateResult(ev.result))
        dispatch(setUpdateNote(ev.note))
        dispatch(setEvalIdToUpdate(ev.id!))
        setOpenEditPopup(true)
    }

    useEffect(() => {
        if(PMState.currentPersonalManagementId) {
            dispatch(setPMId(PMState.currentPersonalManagementId))
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [PMState.currentPersonalManagementId])

    useEffect(() => {
        dispatch(resetEvaluations())
        if (PMState.getByIdPersonalManagementResourcesStatus === 'successfully' &&
             PMState.getByIdPersonalManagementResourcesResponse?.evaluationIds) {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))
            for (let index = 0; index < PMState.getByIdPersonalManagementResourcesResponse?.evaluationIds.length; index++) {
                dispatch(GetEvaluationById(PMState.getByIdPersonalManagementResourcesResponse?.evaluationIds[index].toString()))
            }
        }
    }, [PMState.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if(evaluationsState.getEvaluationByIdStatus === 'successfully' &&
            evaluationsState.getEvaluationByIdResponse !== undefined
        ) {
            dispatch(resetGetEvaluationByIdStatus())
            dispatch(addEvaluations(evaluationsState.getEvaluationByIdResponse))
        }
    }, [evaluationsState.getEvaluationByIdStatus])

    useEffect(() => {
        if(evaluationsState.evaluationDeleteStatus === 'successfully') {
            dispatch(resetEvaluationDeleteStatus())
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [evaluationsState.evaluationDeleteStatus])

    return (
        <div style={{padding: '24px'}}>
            <div>
            <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px'}}>
                <span id="title-eval-tab">Valutazioni</span>
                <Button 
                    size="md" 
                    iconPosition="off" 
                    variant="outline" 
                    color="orange" 
                    label="Aggiungi valutazione" 
                    onClick={() => setOpenPopup(true)}
                />
            </div>
            <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px'}}>DATA</th>
                            <th>FUNZIONE VALUTATA</th>
                            <th>VALUTATORE</th>
                            <th>ESITO</th>
                            <th style={{width: '50%'}}>NOTE</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {evaluationsState.evaluations.map(ev =>
                            <tr
                            className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                            key={ev.id}
                            >
                                <td className="text-neutral-700">
                                    {formatterDate(ev.evaluationDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {optionsFormatter(ev.evaluationActivityId, activityOptions)}
                                </td>
                                <td className="text-neutral-700">
                                    {ev.evaluatorId}
                                </td>
                                <td>
                                    <span className={ev.result === 'POSITIVO' ? 'result positive' : 'result negative'}>{ev.result}</span>
                                </td>
                                <td className="text-neutral-700">
                                    {ev.note}
                                </td>
                                <td className="w-[30px]">
                                    <div className="flex justify-content-between align-items-center">

                                        <button onClick={() => {
                                            onEditHandler(ev)
                                        }}>
                                            <EditIcon color={"Orange"} size={18} />
                                        </button>

                                        <div className="pl-5 flex align-items-center">
                                            <button onClick={() => dispatch(DeleteEvaluation(ev.id!))}>
                                                <TrashIcon color={"Orange"} size={0} />
                                            </button>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
            {
                openPopup && (
                    <PopupForm title={'Aggiungi Valutazione'} close={() => {
                        dispatch(resetEvaluationError())
                        dispatch(resetEvaluationCreationRequest())
                        setOpenPopup(false)
                        }}>
                        <ValutazioniPopupAdd close={() => setOpenPopup(false)} />
                    </PopupForm>
                )
            }
            {
                openEditPopup && (
                    <PopupForm title={'Modifica Valutazione'} close={() => {
                        dispatch(resetEvaluationError())
                        dispatch(resetEvaluationUpdateRequest())
                        setOpenEditPopup(false)
                        }}>
                        <ValutazioniPopupEdit close={() => setOpenEditPopup(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}