import { Fragment } from "react/jsx-runtime";
import Input from "../../../ui/molecules/input";
import Button from "../../../ui/molecules/button";
import RadioButtonGroup from "../../../ui/molecules/RadioButtonGroup";
import { useEffect, useState } from "react";
import { CustomOptions } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { EntryProtocolAttachmentCreation, EntryProtocolObjAttValidation, GetAllEntryProtocolAttachments, resetEntryProtocolAttachmentCreationStatus, resetEntryProtocolObjAttErrors, resetEPAttCreationRequest, setEPAttCoding, setEPAttDescription } from "../entry/slice";

interface Props {
    close: () => void
}

export function AddAttachmentPopupEntry(props: Props) {
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const [selectedOption, setSelectedOption] = useState<CustomOptions | null>(CustomOptions.NO);

    useEffect(() => {
        if(entryProtocolState.entryProtocolObjAttValidationStatus === 'successfully') {
            dispatch(resetEntryProtocolObjAttErrors())
            dispatch(EntryProtocolAttachmentCreation(entryProtocolState.entryProtocolAttachmentCreationRequest))
        }
    }, [entryProtocolState.entryProtocolObjAttValidationStatus])

    useEffect(() => {
        if(entryProtocolState.entryProtocolAttachmentCreationStatus === 'successfully') {
            dispatch(resetEntryProtocolAttachmentCreationStatus())
            dispatch(resetEPAttCreationRequest())
            dispatch(GetAllEntryProtocolAttachments())
            props.close();
        }
    }, [entryProtocolState.entryProtocolAttachmentCreationStatus])

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                {/* FIRST ROW */}
                <div className="pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            error={entryProtocolState.entryProtocolObjAttErrors.coding}
                            supportingText={entryProtocolState.entryProtocolObjAttErrors.coding ? 'Codifica mancante' : undefined}
                            label={'Codifica'}
                            placeholder="Specificare codifica..."
                            value={entryProtocolState.entryProtocolAttachmentCreationRequest.coding}
                            onChangeText={(text) => dispatch(setEPAttCoding(text))}
                            />
                    
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className="pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            error={entryProtocolState.entryProtocolObjAttErrors.description}
                            supportingText={entryProtocolState.entryProtocolObjAttErrors.description ? 'Descrizione mancante' : undefined}
                            label={'Descrizione'}
                            placeholder="Specificare descrizione..."
                            value={entryProtocolState.entryProtocolAttachmentCreationRequest.description}
                            onChangeText={(text) => dispatch(setEPAttDescription(text))}
                            />
                    
                    </div>
                </div>

            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetEPAttCreationRequest())
                        dispatch(resetEntryProtocolObjAttErrors())
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Aggiungi"
                        color="orange"
                        onClick={() => {
                            dispatch(EntryProtocolObjAttValidation(entryProtocolState.entryProtocolAttachmentCreationRequest))
                        }}
                        />
                </div>
            </div>

        </Fragment>
    )
}