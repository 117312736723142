// RadioButtonGroup.tsx
import React from 'react';
import './style.scss';
import clsx from "clsx"

interface CheckboxGroupProps<T extends string | number | symbol> {
    options: T[];
    selectedValues: T[];
    onChange: (value: T) => void;
    optionLabels?: { [key in T]?: string };  // Optional
    disabled?: boolean
    name: string
    inline?: boolean
    index?: number
}

const CheckboxGroup = <T extends string>({ options, selectedValues, onChange, optionLabels, disabled, name, inline, index }: CheckboxGroupProps<T>) => {
    return (
        <div className={
            clsx({
                "checkbox-group": !inline,
                "checkbox-group-inline": inline})
        }>
            {options.map((value) => (
                <div key={value} className="checkbox-container mb-2">
                    <input
                        disabled={disabled}
                        type="checkbox"
                        name={name+index}
                        value={value}
                        checked={selectedValues.includes(value)}
                        onChange={() => onChange(value)}
                    />
                    <label className='checkboxLabel' onClick={() => onChange(value)}>
                        {optionLabels && optionLabels[value] ? optionLabels[value] : value}
                    </label>
                    
                </div>
            ))}
        </div>
    );
};

export default CheckboxGroup;