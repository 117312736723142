import axios from "axios";
import { GetAllOtherInfoResponseDTO, OtherInfoDTO, OtherInfoResponseDTO } from "./dto";
import { OtherInfoService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class OtherInfoServiceImpl implements OtherInfoService {

    public GetAllOtherInfo(): Promise<GetAllOtherInfoResponseDTO> {
        return axios.get("/api/pm-other-information", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetOtherInfoById(id: string): Promise<OtherInfoResponseDTO> {
        return axios.get("/api/pm-other-information/"+id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public CreateOtherInfo(data: OtherInfoDTO): Promise<void> {
        return axios.post("/api/pm-other-information",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public OtherInfoUpdate(data: OtherInfoDTO, id: string): Promise<void> {
        return axios.put("/api/pm-other-information/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteOtherInfo(id: string): Promise<void> {
        return axios.delete("/api/pm-other-information/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

}