import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../utils";
import { GetFileByIdDTO, GetObjectByIdDTO } from "./dataService/dto";
import { NewObjectStorageService } from "./dataService/service";

interface ObjectStorageState {
    // REQUEST
    getFileByIdToZipRequest: string[]

    // STATUS
    getFileObjectByIdStatus: PromiseStatuses
    getFileByIdStatus: PromiseStatuses
    getFileByIdToZipStatus: PromiseStatuses

    // RESPONSE
    getFileObjectByIdResponse?: GetObjectByIdDTO
    getFileByIdResponse?: GetFileByIdDTO
    getFileByIdToZipResponses: GetFileByIdDTO[]
}

const initialState: ObjectStorageState = {
    // REQUEST
    getFileByIdToZipRequest: [],

    // STATUS
    getFileObjectByIdStatus: 'idle',
    getFileByIdStatus: 'idle',
    getFileByIdToZipStatus: 'idle',

    // RESPONSE
    getFileByIdToZipResponses: []
}

export const GetFileObjectById = createAsyncThunk(
    'OS/GetFileObjById',
    async (id: string, thunkApi): Promise<GetObjectByIdDTO> => {
        const osService = NewObjectStorageService()

        return osService.GetFileObjectById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetFileById = createAsyncThunk(
    'OS/GetFileById',
    async (id: string, thunkApi): Promise<GetFileByIdDTO> => {
        const osService = NewObjectStorageService()

        return osService.GetFileById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetFileByIdToZip = createAsyncThunk(
    'OS/GetFileByIdToZip',
    async (id: string, thunkApi): Promise<GetFileByIdDTO> => {
        const osService = NewObjectStorageService()

        return osService.GetFileById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const ObjectStorageSlice = createSlice({
    name: 'objectStorage/slice',
    initialState,
    reducers: {
        // REQUEST
        setGetFileByIdToZipRequest: (state, action) => {
            state.getFileByIdToZipRequest = action.payload
        },
        resetGetFileByIdToZipRequest: (state) => {
            state.getFileByIdToZipRequest = []
        },

        // STATUS
        resetGetFileObjectByIdStatus: (state) => {
            state.getFileObjectByIdStatus = 'idle'
        },
        resetGetFileByIdStatus: (state) => {
            state.getFileByIdStatus = 'idle'
        },
        resetGetFileByIdToZipStatus: (state) => {
            state.getFileByIdToZipStatus = 'idle'
        },

        // RESPONSE
        resetGetFileByIdToZipResponses: (state) => {
            state.getFileByIdToZipResponses = []
        }
    },
    extraReducers(builder) {
        builder
            .addCase(GetFileObjectById.pending, (state) => {
                state.getFileObjectByIdStatus = 'loading'
            })
            .addCase(GetFileObjectById.fulfilled, (state, action) => {
                state.getFileObjectByIdStatus = 'successfully'
                state.getFileObjectByIdResponse = action.payload
            })
            .addCase(GetFileObjectById.rejected, (state) => {
                state.getFileObjectByIdStatus = 'failed'
            })

            .addCase(GetFileById.pending, (state) => {
                state.getFileByIdStatus = 'loading'
            })
            .addCase(GetFileById.fulfilled, (state, action) => {
                state.getFileByIdStatus = 'successfully'
                state.getFileByIdResponse = action.payload
            })
            .addCase(GetFileById.rejected, (state) => {
                state.getFileByIdStatus = 'failed'
            })

            .addCase(GetFileByIdToZip.pending, (state) => {
                state.getFileByIdToZipStatus = 'loading'
            })
            .addCase(GetFileByIdToZip.fulfilled, (state, action) => {
                state.getFileByIdToZipStatus = 'successfully'
                state.getFileByIdToZipResponses.push(action.payload)
            })
            .addCase(GetFileByIdToZip.rejected, (state) => {
                state.getFileByIdToZipStatus = 'failed'
            })
    },
})

export const {
    // REQUEST
    setGetFileByIdToZipRequest,
    resetGetFileByIdToZipRequest,

    // STATUS
    resetGetFileByIdStatus,
    resetGetFileObjectByIdStatus,
    resetGetFileByIdToZipStatus,

    // RESPONSE
    resetGetFileByIdToZipResponses
} = ObjectStorageSlice.actions

export default ObjectStorageSlice.reducer