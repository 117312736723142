import { Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { SelectCustom } from "../../../../ui/molecules/select";
import { headquartersOptions, yearOptions } from "../../../../utils";
import { GetAllOtherData, OtherDataUpdate, OtherDataValidation, resetOtherDataCreationRequest, resetOtherDataErrors, resetOtherDataUpdateRequest, resetOtherDataUpdateStatus, resetOtherDataValidationStatus, setOtherDataHeadquarter, setOtherDataNumber, setOtherDataYear, setUpdateOtherDataCommitmentStatement } from "../slice";
import Input from "../../../../ui/molecules/input";
import TextArea from "../../../../ui/molecules/textArea";
import Button from "../../../../ui/molecules/button";
import { GetByIdPersonalManagement } from "../../slice";

interface Props {
    close: () => void;
}

export function OtherDataPopupEdit(props: Props) {
    const dispatch = useAppDispatch()
    const otherDataState = useAppSelector(state => state.PMOtherData)
    const PMState = useAppSelector(state => state.personalManagement)

    useEffect(() => {
        if(otherDataState.otherDataValidationStatus === 'successfully') {
            dispatch(resetOtherDataValidationStatus())
            dispatch(resetOtherDataErrors())
            dispatch(OtherDataUpdate({body: otherDataState.otherDataUpdateRequest, id: otherDataState.otherDataIdToUpdate}));
        }
    }, [otherDataState.otherDataValidationStatus])

    useEffect(() => {
        if(otherDataState.otherDataUpdateStatus === 'successfully') {
            dispatch(resetOtherDataUpdateStatus())
            dispatch(resetOtherDataUpdateRequest())
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
            props.close()
        }
        
    }, [otherDataState.otherDataUpdateStatus])

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>

                {/* FIRST ROW */}
                <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Ricerca e salva protocollo</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona sede...'}
                            options={headquartersOptions}
                            onChange={(value) => dispatch(setOtherDataHeadquarter(value))}
                            defaultValue={otherDataState.headquarter}
                            value={otherDataState.headquarter}
                            />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Anno</span>
                        </div>
                        <SelectCustom
                            placeholder={'Anno...'}
                            options={yearOptions}
                            onChange={(value) => dispatch(setOtherDataYear(value))}
                            defaultValue={otherDataState.year}
                            value={otherDataState.year}
                            />
                    
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Numero'}
                            placeholder="Specificare numero..."
                            value={otherDataState.number}
                            defaultValue={otherDataState.number}
                            onChangeText={(text) => {dispatch(setOtherDataNumber(text))}}
                            />
                    
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            error={otherDataState.otherDataErrors.commitmentStatement}
                            supportingText={otherDataState.otherDataErrors.commitmentStatement ? 'Note mancanti': undefined} 
                            defaultValue={otherDataState.otherDataUpdateRequest.commitmentStatement}
                            value={otherDataState.otherDataUpdateRequest.commitmentStatement} 
                            onChangeText={(text) => dispatch(setUpdateOtherDataCommitmentStatement(text))} />
                    </div>

                </div>

            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetOtherDataErrors())
                        dispatch(resetOtherDataUpdateRequest());
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(OtherDataValidation(otherDataState.otherDataUpdateRequest))
                        }}
                        />
                </div>
            </div>
        </Fragment>
    )
}