import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { Fragment } from "react"
import Button from "../../../ui/molecules/button";
import { SelectCustom } from "../../../ui/molecules/select";
import { sot } from "../../../utils";
import { Calendar } from "../../../ui/molecules/calendar";
import { GetProtExcel, setFromReportExcel, setHeadQuarterReportExcel, setToReportExcel } from "../entry/slice";
import { GetProtDepartureExcel, setFromReportExcelDeparture, setHeadQuarterReportExcelDeparture, setToReportExcelDeparture } from "../departure/slice";

interface Props {
    close: () => void;
    protType: string
}

export function DownloadExcelProt(props: Props) {
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const departureProtocolState = useAppSelector(state => state.departureProtocol)

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>

                <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Sede/Ufficio</span>
                        </div>
                        <SelectCustom
                            placeholder={'Sede/Ufficio'}
                            value={props.protType === 'entrata' ? entryProtocolState.reportExcelFilters.headQuarterOffice : departureProtocolState.reportExcelFilters.headQuarterOffice}
                            defaultValue={props.protType === 'entrata' ? entryProtocolState.reportExcelFilters.headQuarterOffice : departureProtocolState.reportExcelFilters.headQuarterOffice}
                            options={sot}
                            onChange={(value) => {
                                if (props.protType === 'entrata') {
                                    dispatch(setHeadQuarterReportExcel(value))
                                }
                                else if (props.protType === 'partenza') {
                                    dispatch(setHeadQuarterReportExcelDeparture(value))
                                }
                            }

                            }
                        />
                    </div>

                </div>
                <div className="formAnagrafica mt-4" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Dal</span>
                        </div>
                        <Calendar
                            selected={props.protType === 'entrata' ? entryProtocolState.reportExcelFilters.from : departureProtocolState.reportExcelFilters.from}
                            onChange={(date) => {
                                if (props.protType === 'entrata') {
                                    dispatch(setFromReportExcel(date))
                                }
                                else if (props.protType === 'partenza') {
                                    dispatch(setFromReportExcelDeparture(date))
                                }

                            }}
                        />
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Al</span>
                        </div>
                        <Calendar
                            selected={props.protType === 'entrata' ? entryProtocolState.reportExcelFilters.to : departureProtocolState.reportExcelFilters.to}
                            onChange={(date) => {
                                if (props.protType === 'entrata') {
                                    dispatch(setToReportExcel(date))
                                }
                                else if (props.protType === 'partenza') {
                                    dispatch(setToReportExcelDeparture(date))
                                }

                            }}
                        />
                    </div>
                </div>


                <div className="mt-4" style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                    <Button
                        size="md"
                        variant="outline"
                        iconPosition="off"
                        label="Annulla"
                        color="dimgrey"
                        onClick={() => {
                            props.close();
                        }}
                    />
                    <div className="pl-3">
                        <Button
                            size="md"
                            variant="solid"
                            iconPosition="off"
                            label="Stampa"
                            color="orange"
                            onClick={() => {
                                if (props.protType === 'entrata') {
                                    dispatch(GetProtExcel(entryProtocolState.reportExcelFilters))
                                }
                                else if (props.protType === 'partenza') {
                                    dispatch(GetProtDepartureExcel(departureProtocolState.reportExcelFilters))
                                }

                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}