import { createSlice } from "@reduxjs/toolkit"
import { keycloak } from "."
import { PromiseStatuses } from "../../utils"


interface KeycloakState {
    userRoles?: string[]
    keycloakInitStatus: PromiseStatuses
    socket?: WebSocket
}

const initialState: KeycloakState = {
    userRoles: [],
    keycloakInitStatus: 'idle',
}

const securedSlice = createSlice({
    name: 'secured/slice',
    initialState,
    reducers: {
        setRoles: (state, action) => {
            state.userRoles = action.payload
        },
        setKeycloakInitStatus: (state, action) => {
            state.keycloakInitStatus = action.payload
        },
        setSocket: (state, action) => {
            state.socket = action.payload
        }
    },
})

export const {
    setRoles,
    setKeycloakInitStatus,
    setSocket
} = securedSlice.actions

export default securedSlice.reducer