import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { EditIcon } from "../../../ui/icons/edit"
import { Dropdown } from "../../../ui/organisms/dropdown"
import { formatterDate, normativa, optionsFormatter, OptionType } from "../../../utils"
import { addPMResource, GetAllTraining, resetGetAllTrainingsStatus, resetPMResources, resetTrainingDeleteStatus, setTrainingIdToUpdate, setTrainings, setUpdateTrainingActivity, setUpdateTrainingAttendanceRegister, setUpdateTrainingCredits, setUpdateTrainingEndingDate, setUpdateTrainingExternalTeacher, setUpdateTrainingExternalTeacherFunction, setUpdateTrainingHeadquarter, setUpdateTrainingInternalTeacher, setUpdateTrainingInternalTeacherFunction, setUpdateTrainingOtherData, setUpdateTrainingParticipants, setUpdateTrainingStartingDate, setUpdateTrainingTopicsCovered, setUpdateTrainingWebinar, TrainingDelete } from "./slice"
import { TrashIcon } from "../../../ui/icons/trash"
import { useNavigate } from "react-router-dom"
import { TrainingDTO } from "./dataService/dto"
import { GetAllPersonalManagement, resetGetAllPersonalManagementResourcesStatus } from "../slice"
import { GetByIdAnagraphic, resetGetByIdStatus } from "../boxAnagrafica/slice"
import './styles.scss'

export function BoxFormazione() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const PMState = useAppSelector(state => state.personalManagement)
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(resetPMResources())
        dispatch(GetAllPersonalManagement(PMState.filters))
        dispatch(GetAllTraining(trainingState.getAllTrainingFilters))
    }, [])

    useEffect(() => {
        if(trainingState.getAllTrainingsStatus === 'successfully' &&
            trainingState.getAllTrainingsResponse !== undefined
        ) {
            dispatch(resetGetAllTrainingsStatus())
            dispatch(setTrainings(trainingState.getAllTrainingsResponse.data))
        }
    }, [trainingState.getAllTrainingsStatus])

    useEffect(() => {
        if(PMState.getAllPersonalManagementResourcesStatus === 'successfully' &&
            PMState.getAllPersonalManagementResourcesResponse !== undefined
        ) {
            dispatch(resetGetAllPersonalManagementResourcesStatus())
            const data = PMState.getAllPersonalManagementResourcesResponse.data
            for(let i=0; i<data.length; i++) {
                dispatch(GetByIdAnagraphic(String(data[i].anagraphicId)))
            }
        }
    }, [PMState.getAllPersonalManagementResourcesStatus])

    useEffect(() => {
        if(anagraficaState.GetByIdAnagraphicStatus === 'successfully' &&
            anagraficaState.getByIdAnagraphicRequest !== undefined
        ) {
            dispatch(resetGetByIdStatus('idle'))
            const data = anagraficaState.getByIdAnagraphicRequest
            dispatch(addPMResource({value: data.personalManagementId, label: `${data.name} ${data.surname}`}))
        }
    }, [anagraficaState.GetByIdAnagraphicStatus])

    useEffect(() => {
        if(trainingState.trainingDeleteStatus === 'successfully') {
            dispatch(resetTrainingDeleteStatus())
            dispatch(GetAllTraining(trainingState.getAllTrainingFilters))
        }
    }, [trainingState.trainingDeleteStatus])

    const handleNorms = (subs: string[]) => {
        if(subs === null) 
            return '';
        return subs.slice(0,-1)
                 .map(s => `${optionsFormatter(s, normativa)} - `) 
                  + optionsFormatter(subs[subs.length-1], normativa)!
    }

    const handleParts = (subs: string[]) => {
        if(subs === null) 
            return '';
        return (
            <ul style={{listStyleType: 'disc'}}>
                {
                    subs.map(s => (
                        <li>{s}</li>
                    ))
                }
            </ul>
        )
    }

    const handleOnPart = (participants: string[]): string[] => {
        return trainingState.PMResources.filter(r => participants.includes(r.value)).map(fr => {
            return fr.label
        })
    }

    const handleOnEdit = (t: TrainingDTO) => {
        dispatch(setTrainingIdToUpdate(t.id))
        dispatch(setUpdateTrainingStartingDate(t.starting))
        dispatch(setUpdateTrainingEndingDate(t.ending))
        dispatch(setUpdateTrainingCredits(t.credits))
        dispatch(setUpdateTrainingWebinar(t.webinar))
        dispatch(setUpdateTrainingHeadquarter(t.sede))
        dispatch(setUpdateTrainingInternalTeacher(t.internalTeacher))
        dispatch(setUpdateTrainingInternalTeacherFunction(t.internalTeacherFunction))
        dispatch(setUpdateTrainingExternalTeacher(t.externalTeacher))
        dispatch(setUpdateTrainingExternalTeacherFunction(t.externalTeacherFunction))
        dispatch(setUpdateTrainingParticipants(t.participantsIds))
        dispatch(setUpdateTrainingAttendanceRegister(t.attendanceRegister))
        dispatch(setUpdateTrainingTopicsCovered(t.topicsCovered))
        dispatch(setUpdateTrainingOtherData(t.otherData))
        dispatch(setUpdateTrainingActivity(t.trainingActivities))
        navigate('/editFormazione')
    }

    return (
        <div style={{padding: '24px'}}>
            <div>
                <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px'}}>DAL/AL</th>
                            <th>CREDITI</th>
                            <th>TIPO DI FORMAZIONE</th>
                            <th>NORMATIVA</th>
                            <th>ARGOMENTI TRATTATI</th>
                            <th>REGISTRO PRESENZE - CDX RPA</th>
                            <th>ALTRI DATI</th>
                            <th>ELENCO PARTECIPANTI</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {trainingState.trainings.map(t =>
                            <tr
                            className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                            key={t.id}
                            >
                                <td className="text-box-formazione">
                                    <div>{formatterDate(t.starting)}</div>
                                    <div>{formatterDate(t.ending)}</div>
                                </td>
                                <td className="text-box-formazione">
                                    {t.credits}
                                </td>
                                <td className="text-box-formazione">
                                    {t.webinar ? 'Webinar' : 'In Presenza'}
                                </td>
                                <td className="text-box-formazione">
                                    <ul style={{listStyleType: 'disc'}}>
                                        {
                                            t.trainingActivities.map(t => (
                                                <li>{handleNorms(t.norms!)}</li>
                                            ))
                                        }
                                    </ul>
                                </td>
                                <td className="text-box-formazione">
                                    {t.topicsCovered}
                                </td>
                                <td className="text-box-formazione">
                                    {t.attendanceRegister}
                                </td>
                                <td className="text-box-formazione">
                                    {t.otherData}
                                </td>
                                <td className="text-box-formazione">
                                    {
                                        t.participants.map((part, index) => {
                                            const isLast = index === t.participants.length - 1;
                                            return (
                                                <span>{part} {!isLast && ' - '}</span>
                                                
                                            )
                                        })
                                    }
                                </td>
                                <td className="w-[30px]">
                                    <div className="flex justify-content-between align-items-center">
                                        <button onClick={() => {
                                            handleOnEdit(t)
                                        }}>
                                            <EditIcon color={"Orange"} size={18} />
                                        </button>

                                        <div className="pl-3 flex align-items-center">
                                            <button onClick={() => {dispatch(TrainingDelete(t.id!))}}>
                                                <TrashIcon color={"Orange"} size={0} />
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
        </div>
    )
}