import { CreateTechnicianDTO, addTechinicianUserDTO, } from "./dto";
import { BusinessTechnicianServiceImpl } from "./serviceImpl";


export interface BusinessTechnicianService {
    CreateTechnicianCreation(data: CreateTechnicianDTO): Promise<void>
    GetBusinessTechnician(): Promise<any>
    EditBusinessTechnician(id: string, data: CreateTechnicianDTO): Promise<any>
    GetBusinessTechnicianById(id: string): Promise<any>
}

export function NewBusinessTechnicianService(): BusinessTechnicianService {
    return new BusinessTechnicianServiceImpl();
}
