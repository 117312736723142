import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import './style.scss';
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import './style.scss';
import { Dropdown } from "../../ui/organisms/dropdown";
import { EditIcon } from "../../ui/icons/edit";
import { TrashIcon } from "../../ui/icons/trash";
import { removeCorporateCenter } from "./slice";
import { formatterDate } from "../../utils";


registerLocale('it', it)

export function ListCentriAziendali() {

    const clientiState = useAppSelector(state => state.clienti)

    const dispatch = useAppDispatch()

    return (
        <>
            <table id="tableReview" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}>Descrizione</th>
                        <th>Regione</th>
                        <th>Provincia</th>
                        <th>Città</th>
                        <th >Email</th>
                        <th>Telefono</th>
                        <th>Data Ingresso</th>
                        <th>Data Uscita</th>
                        <th>Note</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        clientiState.corporateCenters.map((center, index) =>

                            <tr key={index}>
                                <td className="text-neutral-700">
                                    {center.description}
                                </td>
                                <td className="text-neutral-700">
                                    {center.countryRegion}
                                </td>
                                <td className="text-neutral-700">
                                    {center.province}
                                </td>
                                <td className="text-neutral-700">
                                    {center.city}
                                </td>
                                <td className="text-neutral-700">
                                    {center.email}
                                </td>
                                <td className="text-neutral-700">
                                    {center.phone}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(center.activationDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(center.deactivationDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {center.description}
                                </td>
                                <td className="w-[20px] cursor-pointer">
                                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>

                                        <Dropdown items={[
                                            {
                                                label: 'Elimina',
                                                icon: <TrashIcon color={""} size={0} />,
                                                onClick: () => {
                                                    dispatch(removeCorporateCenter(index))

                                                }
                                            }

                                        ]} />
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
                <tbody>

                </tbody>

            </table>
        </>
    )
}