import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import './style.scss';
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useNavigate } from "react-router-dom";
import { MinusIcon } from "../../ui/icons/minusIcon";
import React from "react";
import { PlusIconAccordion } from "../../ui/icons/plusIcon";
import AccordionDetails from "@mui/material/AccordionDetails";
import Input from "../../ui/molecules/input/Input";
import { SelectCustom } from "../../ui/molecules/select";
import { Province, Region, genreSelect, regioniProvinceMap, regions } from "../../utils";
import TextArea from "../../ui/molecules/textArea/TextArea";
import { CalendarIcon } from "../../ui/icons/calendar";
import { provinceComuniMap } from "../../utils/listaComuni";
import { setActivationDate, setAddress, setBirthDate, setCity, setContactCity, setContactCountryRegion, setContactProvince, setContactZipCode, setCountryRegion, setDeActivationDate, setEmail, setFax, setFiscalCode, setName, setNote, setPhone, setProvince, setRappLegaleMobilePhone, setSex, setSurname } from "./slice";
import { format } from "date-fns";
import { Calendar } from "../../ui/molecules/calendar";


registerLocale('it', it)

type OptionType = {
    value: string;
    label: string;
};

export function BoxRappLegale() {
    const clientiState = useAppSelector(state => state.clienti)

    const dispatch = useAppDispatch()
    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const [dateActivate, setDataActivate] = useState<Date | undefined>(undefined)
    const [dateDeActivate, setDataDeActivate] = useState<Date | undefined>(undefined)
    const [dateBirthDate, setDateBirthDate] = useState<Date | undefined>(undefined)
    const [provinceOptions, setProvinceOptions] = useState<OptionType[]>([]);
    const [rpProvinceOptions, setRpProvinceOptions] = useState<OptionType[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<OptionType | null>(null);
    const [comuneOptions, setComuneOptions] = useState<OptionType[]>([]);

    const [selectedComune, setSelectedComune] = useState<OptionType | null>(null);
    const [selectedProvince, setSelectedProvince] = useState<OptionType | null>(null);

    //rp
    const [selectedRpRegion, setSelectedRpRegion] = useState<OptionType | null>(null);
    const [selectedRpComune, setSelectedRpComune] = useState<OptionType | null>(null);
    const [selectedRpProvince, setSelectedRpProvince] = useState<OptionType | null>(null);
    const [rpComuneOptions, setRpComuneOptions] = useState<OptionType[]>([]);

    const regionOptions: OptionType[] = Array.from(regioniProvinceMap.keys()).map(region => ({
        value: region,
        label: region
    }));

    useEffect(() => {
        if (selectedRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setProvinceOptions(newProvinceOptions);
            } else {
                setProvinceOptions([]);
            }
        } else {
            setProvinceOptions([]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedProvince) {
            const comuni = provinceComuniMap.get(selectedProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setComuneOptions(newComuneOptions);
        } else {
            setComuneOptions([]);
        }
    }, [selectedProvince]);

    //regioni, province, comuni rp
    useEffect(() => {
        if (selectedRpRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRpRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setRpProvinceOptions(newProvinceOptions);
            } else {
                setRpProvinceOptions([]);
            }
        } else {
            setRpProvinceOptions([]);
        }
    }, [selectedRpRegion]);

    useEffect(() => {
        if (selectedRpProvince) {
            const comuni = provinceComuniMap.get(selectedRpProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setRpComuneOptions(newComuneOptions);
        } else {
            setRpComuneOptions([]);
        }
    }, [selectedRpProvince]);



    const handleRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRegion(selectedOption);
        setSelectedProvince(null); // Reset province when region changes
        setSelectedComune(null);
    };

    const handleRpRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRpRegion(selectedOption);
        setSelectedRpProvince(null); // Reset province when region changes
        setSelectedRpComune(null);
    };

    const handleRpProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedRpProvince(selectedOption);
        setSelectedRpComune(null);
    };

    const handleProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedProvince(selectedOption);
        setSelectedComune(null);
    };

    const handleComuneChange = (selectedOption: OptionType | null) => {
        setSelectedComune(selectedOption);
    };

    return (
        <div style={{ padding: '24px' }} id="boxAnagrafica">
            <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')} defaultExpanded>
                    <AccordionSummary
                        expandIcon={expanded === 'panel1' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel1-header"
                    >
                        <div><span className="text-uppercase text-white label-input">Rappresentante Legale</span></div>
                    </AccordionSummary>

                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="bg-brandPrimary-100">
                            <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '1 1 auto' }}>
                                    <Input
                                        label={'Codice Fiscale'}
                                        placeholder="Codice Fiscale"
                                        supportingText={clientiState.createLegalRappErrors.fiscalCode ? "Inserire codice fiscale." : undefined} error={clientiState.createLegalRappErrors.fiscalCode}
                                        defaultValue={clientiState.rappLegaleEditCreateRequest.fiscalCode}
                                        onChangeText={(text) => dispatch(setFiscalCode(text))}
                                    />
                                </div>
                            </div>
                            <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Cognome'}
                                        placeholder="Cognome"
                                        defaultValue={clientiState.rappLegaleEditCreateRequest.surname}
                                        onChangeText={(text) => dispatch(setSurname(text))}
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Nome'}
                                        placeholder="Nome"
                                        defaultValue={clientiState.rappLegaleEditCreateRequest.name}
                                        onChangeText={(text) => dispatch(setName(text))}
                                    />
                                </div>
                            </div>
                            <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.5' }}>
                                    <div className="text-left">
                                        <span className="input-label">Sesso</span>
                                    </div>
                                    <SelectCustom
                                        placeholder={'Sesso'}
                                        options={genreSelect}
                                        onChange={(value) => dispatch(setSex(value))}
                                        defaultValue={clientiState.rappLegaleEditCreateRequest.sex}
                                    />
                                </div>

                                <div style={{ flex: '0.5' }}>
                                    <div>
                                        <div className="text-left">
                                            <span className="input-label">Data di nascita</span>
                                        </div>
                                    </div>
                                    <Calendar
                                        selected={clientiState.rappLegaleEditCreateRequest.birthdate}
                                        onChange={(date) => {
                                            dispatch(setBirthDate(format(new Date(date), 'yyyy-MM-dd')))
                                            setDateBirthDate(date)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                        <span className="input-label">Regione</span>
                                    </div>
                                    <SelectCustom
                                        error={clientiState.createLegalRappErrors.countryRegion}
                                        errorLabel="Inserire Regione."
                                        defaultValue={clientiState.rappLegaleEditCreateRequest.countryRegion}
                                        placeholder={'Regione'}
                                        options={regionOptions}
                                        onChange={(value) => { handleRegionChange(value as unknown as OptionType | null); dispatch(setCountryRegion(value)) }}
                                    />
                                </div>
                                <div style={{ flex: '0.5' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                        <span className="input-label">Provincia</span>
                                    </div>
                                    <SelectCustom
                                        error={clientiState.createLegalRappErrors.province}
                                        errorLabel="Inserire provincia."
                                        defaultValue={clientiState.rappLegaleEditCreateRequest.province}
                                        placeholder={'Provincia'}
                                        options={provinceOptions}
                                        onChange={(value) => { handleProvinceChange(value as unknown as OptionType | null); dispatch(setProvince(value)) }}
                                    />
                                </div>
                            </div>

                            <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '0.5' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                        <span className="input-label">Comune</span>
                                    </div>
                                    <SelectCustom
                                        error={clientiState.createLegalRappErrors.city}
                                        errorLabel="Inserire Città."
                                        placeholder={'Comune'}
                                        defaultValue={clientiState.rappLegaleEditCreateRequest.city}
                                        options={comuneOptions}
                                        onChange={(value) => {
                                            dispatch(setCity(value))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="mt-6">
                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel2' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel2-content"
                        className="rounded-xl headingAccordion"
                        id="panel2-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Recapiti
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input
                                    supportingText={clientiState.createLegalRappErrors.contactAddress ? "Inserire indirizzo." : undefined} error={clientiState.createLegalRappErrors.contactAddress}
                                    label={'Indirizzo'}
                                    placeholder="Indirizzo"
                                    onChangeText={(text) => dispatch(setAddress(text))}
                                    defaultValue={clientiState.rappLegaleEditCreateRequest.contactAddress}
                                />
                            </div>

                        </div>

                        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Regione</span>
                                </div>
                                <SelectCustom
                                    error={clientiState.createLegalRappErrors.contactCountryRegion}
                                    errorLabel="Inserire Regione."
                                    defaultValue={clientiState.rappLegaleEditCreateRequest.contactCountryRegion}
                                    placeholder={'Regione'}
                                    options={regionOptions}
                                    onChange={(value) => { handleRpRegionChange(value as unknown as OptionType | null); dispatch(setContactCountryRegion(value)) }}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Provincia</span>
                                </div>
                                <SelectCustom
                                    error={clientiState.createLegalRappErrors.contactProvince}
                                    errorLabel="Inserire Provincia."
                                    defaultValue={clientiState.rappLegaleEditCreateRequest.contactProvince}
                                    placeholder={'Provincia'}
                                    options={rpProvinceOptions}
                                    onChange={(value) => { handleRpProvinceChange(value as unknown as OptionType | null); dispatch(setContactProvince(value)) }}
                                />
                            </div>

                        </div>
                        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Comune</span>
                                </div>
                                <SelectCustom
                                    error={clientiState.createLegalRappErrors.city}
                                    errorLabel="Inserire Comune."
                                    defaultValue={clientiState.rappLegaleEditCreateRequest.contactCity}
                                    placeholder={'Comune'}
                                    options={rpComuneOptions}
                                    onChange={(value) => {
                                        dispatch(setContactCity(value))
                                    }}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'CAP'}
                                    placeholder="CAP"
                                    onChangeText={(text) => setContactZipCode(text)}
                                    defaultValue={clientiState.rappLegaleEditCreateRequest.contactZipCode}
                                />
                            </div>
                        </div>


                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="mt-6">
                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel3' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel3-content"
                        className="rounded-xl headingAccordion"
                        id="panel3-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Contatto
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>

                        <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'Telefono'}
                                    placeholder="Telefono"
                                    onChangeText={(text) => dispatch(setPhone(text))}
                                    defaultValue={clientiState.rappLegaleEditCreateRequest.phone}
                                />
                            </div>

                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'Cellulare'}
                                    placeholder="Cellulare"
                                    onChangeText={(text) => dispatch(setRappLegaleMobilePhone(text))}
                                    defaultValue={clientiState.rappLegaleEditCreateRequest.mobilePhone}
                                />
                            </div>

                        </div>

                        <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'Email'}
                                    placeholder="Email" onChangeText={(text) => dispatch(setEmail(text))}
                                    defaultValue={clientiState.rappLegaleEditCreateRequest.email}
                                />
                            </div>

                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'Fax'}
                                    placeholder="Fax"
                                    onChangeText={(text) => dispatch(setFax(text))}
                                    defaultValue={clientiState.rappLegaleEditCreateRequest.fax}
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="mt-6">
                <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccordion('panel5')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel5' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel5-content"
                        className="rounded-xl headingAccordion"
                        id="panel5-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Data ingresso / Uscita
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="formInOut" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Data Ingresso</span>
                                </div>
                                <Calendar 
                                    selected={clientiState.rappLegaleEditCreateRequest.activationDate}
                                    onChange={(date) => {
                                        dispatch(setActivationDate(format(new Date(date), 'yyyy-MM-dd')))
                                        setDataActivate(date)
                                    }}
                                />

                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Data Uscita</span>
                                </div>
                                <Calendar 
                                    selected={clientiState.rappLegaleEditCreateRequest.deactivationDate}
                                    onChange={(date) => {
                                        dispatch(setDeActivationDate(format(new Date(date), 'yyyy-MM-dd')))
                                        setDataDeActivate(date)
                                    }}
                                />
                            </div>

                        </div>

                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="mt-6">
                <Accordion expanded={expanded === 'panel6'} onChange={handleChangeAccordion('panel6')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel6' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel6-content"
                        className="rounded-xl headingAccordion"
                        id="panel6-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Note
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="formInOut">
                            <TextArea onChangeText={(text) => dispatch(setNote(text))} />
                        </div>

                    </AccordionDetails>
                </Accordion>
            </div>


        </div >
    )
}