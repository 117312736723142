import { useEffect, useState } from "react";
import { headquartersOptions, yearOptions } from "../../../utils";
import Input from "../input";
import { SelectCustom } from "../select";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetAllEntryProtocols, GetEntryProtocolById, resetGetAllEntryProtocolsStatus, resetGetEntryProtocolByIdStatus } from "../../../pages/protocol/entry/slice";
import { GetAllDepartureProtocols, GetDepartureProtocolById, resetGetAllDepartureProtocolsStatus, resetGetDepartureProtocolByIdStatus } from "../../../pages/protocol/departure/slice";
import './styles.scss'
import { RoundedCloseIcon } from "../../icons/CloseIcon";

interface ProtocolData {
    name: string
    sender: string
    client: string[]
    object: string
}

interface Props {
    type: 'entry' | 'departure'
    onSelect?: (value: string | undefined) => void
    defaultValue?: string
}

export function ProtocolSearch(props: Props) {
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    const [headquarter, setHeadquarter] = useState<string | undefined>(undefined)
    const [number, setNumber] = useState<string | undefined>(undefined)
    const [protocol, setProtocol] = useState<ProtocolData | null>(null)


    useEffect(() => {
        if(headquarter === undefined ||  number === undefined){
            setProtocol(null)
            props.onSelect && props.onSelect(undefined)
            return
        }        
        
        if(props.type === 'entry')
            dispatch(GetAllEntryProtocols({headquarter: headquarter, number: number, itemsPerPage: '50'}))
        else
            dispatch(GetAllDepartureProtocols({itemsPerPage: '1', headquarter: headquarter, number: number}))
    }, [headquarter, number])

    useEffect(() => {
        if(entryProtocolState.getAllEntryProtocolsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolsStatus())
            const data = entryProtocolState.getAllEntryProtocolsResponse.data
            
            if(data.length !== 1) {
                setProtocol(null)
                props.onSelect && props.onSelect(undefined)
                return
            }
            const newProtocol: ProtocolData = {
                name: `${data[0].headQuarter} E${data[0].protocolNumber}`,
                sender: data[0].sender,
                client: data[0].customersInfo !== null ? [...data[0].customersInfo.map(c => c.companyName), data[0].customersNotRegistered!] : [],
                object: data[0].entryObject.description + `[${data[0].entryObject.coding}]`
            }
            setProtocol(newProtocol)
            props.onSelect && props.onSelect(data[0].id)
        }
    }, [entryProtocolState.getAllEntryProtocolsStatus])

    useEffect(() => {
        if(departureProtocolState.getAllDepartureProtocolsStatus === 'successfully' &&
            departureProtocolState.getAllDepartureProtocolsResponse !== undefined
        ) {
            dispatch(resetGetAllDepartureProtocolsStatus())
            const data = departureProtocolState.getAllDepartureProtocolsResponse.data
            if(data.length !== 1) {
                setProtocol(null)
                props.onSelect && props.onSelect(undefined)
                return
            }
            const newProtocol: ProtocolData = {
                name: `${data[0].headQuarter} D${data[0].protocolNumber}`,
                sender: data[0].receiver,
                client: data[0].customersInfo !== null ? [...data[0].customersInfo.map(c => c.companyName), data[0].customersNotRegistered!] : [],
                object: data[0].departureObject.description + `[${data[0].departureObject.coding}]`
            }
            setProtocol(newProtocol)
            props.onSelect && props.onSelect(data[0].id)
        }
    }, [departureProtocolState.getAllDepartureProtocolsStatus])

    useEffect(() => {
        if(props.defaultValue) {
            if(props.type === 'entry')
                dispatch(GetEntryProtocolById(props.defaultValue))
            else
                dispatch(GetDepartureProtocolById(props.defaultValue))
        }
    }, [])

    useEffect(() => {
        if(entryProtocolState.getEntryProtocolByIdStatus === 'successfully' &&
            entryProtocolState.getEntryProtocolByIdResponse !== undefined
        ) {
            dispatch(resetGetEntryProtocolByIdStatus())
            const data = entryProtocolState.getEntryProtocolByIdResponse
            const newProtocol: ProtocolData = {
                name: `${data.headQuarter} E${data.protocolNumber}`,
                sender: data.sender,
                client: data.customersInfo !== null ? [...data.customersInfo.map(c => c.companyName), data.customersNotRegistered!] : [],
                object: data.entryObject.description + `[${data.entryObject.coding}]`
            }
            setProtocol(newProtocol)
        }
    }, [entryProtocolState.getEntryProtocolByIdStatus])

    useEffect(() => {
        if(departureProtocolState.getDepartureProtocolByIdStatus === 'successfully' &&
            departureProtocolState.getDepartureProtocolByIdResponse !== undefined
        ) {
            dispatch(resetGetDepartureProtocolByIdStatus())
            const data = departureProtocolState.getDepartureProtocolByIdResponse
            const newProtocol: ProtocolData = {
                name: `${data.headQuarter} E${data.protocolNumber}`,
                sender: data.receiver,
                client: data.customersInfo !== null ? [...data.customersInfo.map(c => c.companyName), data.customersNotRegistered!] : [],
                object: data.departureObject.description + `[${data.departureObject.coding}]`
            }
            setProtocol(newProtocol)
        }
    }, [departureProtocolState.getDepartureProtocolByIdStatus])

    return (
        <div>
            <div style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div style={{ flex: '0.33' }}>
                    <div className="text-left">
                        <span className="input-label">
                            {`Protocollo Richiesta Codex ${props.type === 'entry' ? 'Entrata' : 'Partenza'}`}
                        </span>
                    </div>
                    <SelectCustom
                        placeholder={'Seleziona sede...'}
                        options={headquartersOptions}
                        onChange={(value) => setHeadquarter(value)}
                    />
                </div>

              

                <div style={{ flex: '0.33' }}>
                    <Input
                        label={'Numero'}
                        placeholder="Specificare numero..."
                        onChangeText={(text) => setNumber(text === '' ? undefined : text)}
                    />
                </div>
            </div>

            {
                protocol && (
                    <div className="mt-4" style={{display: 'flex', alignItems: 'center'}}>

                        <div className="pr-5" style={{display: 'flex', gap: '24px'}}>
                            <div className="text-left">
                                <span className="protocol-bold-text">Protocollo trovato: </span>
                                <span className="protocol-text">{protocol.name}</span>
                            </div>
                            <div className="text-left">
                                <span className="protocol-bold-text">{props.type === 'entry' ? 'Mittente: ' : 'Destinatario: '}</span>
                                <span className="protocol-text">{protocol.sender}</span>
                            </div>
                            <div className="text-left">
                                <span className="protocol-bold-text">Clienti: </span>
                                <span className="protocol-text">{protocol.client.map(c => `${c} `)}</span>
                            </div>
                            <div className="text-left">
                                <span className="protocol-bold-text">Oggetto: </span>
                                <span className="protocol-text">{protocol.object}</span>
                            </div>
                        </div>

                        <button onClick={() => {
                            setProtocol(null)
                            props.onSelect && props.onSelect(undefined)
                        }}>
                            <RoundedCloseIcon color="#26AB45"/>
                        </button>
                    </div>
                )
            }
        </div>
    )
}