import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from "../../ui/molecules/button";
import { useNavigate } from "react-router-dom";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import Input from "../../ui/molecules/input/Input";
import { MinusIcon } from "../../ui/icons/minusIcon";
import { PlusIconAccordion } from "../../ui/icons/plusIcon";
import React from "react";
import { SelectCustom } from "../../ui/molecules/select";
import { Province, Region, genreSelect, regioniProvinceMap, regions } from "../../utils";
import DatePicker, { registerLocale } from "react-datepicker";
import { CalendarIcon } from "../../ui/icons/calendar";
import { format } from "date-fns";
import { provinceComuniMap } from "../../utils/listaComuni";
import TextArea from "../../ui/molecules/textArea/TextArea";
import { ListBusinessTech } from "./list";
import { GetBusinessTechnician } from "./slice";

interface Props {
    children: ReactNode
}

type OptionType = {
    value: string;
    label: string;
};

export function TecnicoAziendale() {

    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const layoutState = useAppSelector(state => state.layout)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchCliente, setSearchCliente] = useState('')
    useEffect(() => {
        dispatch(GetBusinessTechnician())
    }, []);
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Tecnico Aziendale']}
            headerLabel="Tecnico Aziendale"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Nuovo Tecnico" color={"orange"}
                        onClick={() => {
                            navigate('/tecnicoAziendale')
                        }}
                    />

                </div>
            }
        >
            <div style={{ padding: '24px' }} id="boxAnagrafica">
                <ListBusinessTech />
            </div >
        </Layout>
    )
}