import React, { ReactElement, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { SelectCustom } from "../../ui/molecules/select";
import {
  campo_di_applicazione,
  categorie_piante,
  edizioni,
  global_standards,
  manipolazione,
  opzioni_certificazione_CoC,
  opzioni_certificazione_IFA,
  boolean_values,
} from "../../utils";
import {
  addAdOn,
  addCustomAddOn,
  DeleteAddOn,
  GetAddOnById,
  GetAddOns,
  removeAddOn,
  resetDeleteAddOnStatus,
  resetGetAddOnByIdStatus,
  resetGetAddOnsStatus,
  setAddOns,
  setGgn,
  setGlobalGapApplicationField,
  setGlobalGapEdizione,
  setGlobalGapEtichettaturaCoC,
  setGlobalGapLicenzaGNNCoC,
  setGlobalGapManipolazioneIFA,
  setGlobalGapOpzioneCertCoC,
  setGlobalGapOpzioneCertIFA,
  setGlobalGapPlantCategory,
  setGlobalGapPropParallele,
  setGlobalGapStandard,
  setGlobalGapTrasformazioneParzialeCoC,
} from "../clienti/slice";
import { commonRightBarStyle } from "./style";
import { AddOnComponent } from "../../ui/molecules/addOnComponent";
import Button from "../../ui/molecules/button";
import { AddOnPopup } from "../../ui/molecules/addOnPopup";
import Input from "../../ui/molecules/input";

interface Props {
  title?: string;
  placeholder?: string;
  defaultValue: string | undefined;
  value: string | undefined;
  options: {
    value: string;
    label: string;
  }[];
  onChangeHandler: (value: string | undefined) => void;
}

const CustomSelectComponent = (props: Props): ReactElement => {
  const { title, placeholder, defaultValue, value, options, onChangeHandler } =
    props;
  const clientiState = useAppSelector((state) => state.clienti);

  return (
    <div
      className="borderTopSection mt-6"
      style={{ ...commonRightBarStyle, paddingTop: "14px" }}
    >
      {title && <span className="input-label">{title}</span>}
      <SelectCustom
        placeholder={placeholder || ""}
        defaultValue={defaultValue}
        value={value}
        options={options}
        onChange={(value) => {
          onChangeHandler(value);
        }}
      />
    </div>
  );
};

export function GlobalGap() {
  const clientiState = useAppSelector((state) => state.clienti);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      clientiState.getCustomersByIdResponseStatus === "successfully" &&
      clientiState.getCustomersByIdResponse !== undefined
    ) {
      dispatch(
        setGlobalGapStandard(clientiState.getCustomersByIdResponse.ggStandard)
      );
      dispatch(setGgn(clientiState.getCustomersByIdResponse.ggGgn));
      dispatch(setGgn(clientiState.getCustomersByIdResponse.ggGgn));
      dispatch(
        setGlobalGapEdizione(clientiState.getCustomersByIdResponse.ggEdition)
      );
      dispatch(
        setGlobalGapApplicationField(
          clientiState.getCustomersByIdResponse.ggApplicationField
        )
      );
      dispatch(
        setGlobalGapPlantCategory(
          clientiState.getCustomersByIdResponse.ggCategory
        )
      );
      dispatch(
        setGlobalGapOpzioneCertIFA(
          clientiState.getCustomersByIdResponse.ggIFACertificationOption
        )
      );
      dispatch(
        setGlobalGapManipolazioneIFA(
          clientiState.getCustomersByIdResponse.ggManipulation
        )
      );
      dispatch(
        setGlobalGapPropParallele(
          clientiState.getCustomersByIdResponse.ggParallelProperty
        )
      );
      dispatch(
        setGlobalGapOpzioneCertCoC(
          clientiState.getCustomersByIdResponse.ggCOCCertificationOption
        )
      );
      dispatch(
        setGlobalGapEtichettaturaCoC(
          clientiState.getCustomersByIdResponse.ggLabelling
        )
      );
      dispatch(
        setGlobalGapLicenzaGNNCoC(
          clientiState.getCustomersByIdResponse.ggLicenseGnnLabelLogo
        )
      );
      dispatch(
        setGlobalGapTrasformazioneParzialeCoC(
          clientiState.getCustomersByIdResponse.ggPartialTransformation
        )
      );
      clientiState.getCustomersByIdResponse.ggAddOnsId.map(id => dispatch(GetAddOnById(id)));
    }
  }, [clientiState.getCustomersByIdResponseStatus]);

  useEffect(() => {
    if(clientiState.getAddOnByIdStatus === 'successfully' && 
      clientiState.getAddOnByIdResponse !== undefined
    ) {
      dispatch(resetGetAddOnByIdStatus());
      dispatch(addCustomAddOn(clientiState.getAddOnByIdResponse));
    }
  }, [clientiState.getAddOnByIdStatus])

  useEffect(() => {
    if(clientiState.getAddOnsStatus === 'successfully' &&
      clientiState.getAddOnsResponse !== undefined
    ) {
      dispatch(resetGetAddOnsStatus());
      clientiState.getAddOnsResponse.data.map(addOn => {
        if(addOn.customerDataId === clientiState.selectedCustomerId)
          dispatch(addCustomAddOn(addOn));
      })
    }
  })

  useEffect(() => {
    if(clientiState.deleteAddOnStatus === 'successfully') {
      dispatch(resetDeleteAddOnStatus());
      dispatch(setAddOns([]));
      dispatch(GetAddOns());
    }
  }, [clientiState.deleteAddOnStatus])

  const [showPopup, setShowPopup] = useState<boolean>(false);

  return (
    <div>
      <div style={{ padding: "0px 24px" }}>
        <Input
          label={"GGN"}
          value={clientiState.clienteEditCreateRequest.ggGgn}
          placeholder="GGN"
          onChangeText={(text) => dispatch(setGgn(text))}
        />
      </div>
      <div style={commonRightBarStyle} className="mt-6">
        <span className="input-label">Standard</span>
        <SelectCustom
          placeholder="Standard"
          defaultValue={clientiState.clienteEditCreateRequest.ggStandard}
          value={clientiState.clienteEditCreateRequest.ggStandard}
          options={global_standards}
          onChange={(value) => {
            dispatch(setGlobalGapStandard(value));
          }}
        />
      </div>
      {clientiState.clienteEditCreateRequest.ggStandard === "IFA" && (
        <div>
          <CustomSelectComponent
            title="Edizione"
            placeholder="Seleziona edizione..."
            defaultValue={clientiState.clienteEditCreateRequest.ggEdition}
            value={clientiState.clienteEditCreateRequest.ggEdition}
            options={edizioni}
            onChangeHandler={(value) => dispatch(setGlobalGapEdizione(value))}
          />
          <CustomSelectComponent
            title="Campo di applicazione"
            placeholder="Seleziona campo di applicazione..."
            defaultValue={
              clientiState.clienteEditCreateRequest.ggApplicationField
            }
            value={clientiState.clienteEditCreateRequest.ggApplicationField}
            options={campo_di_applicazione}
            onChangeHandler={(value) =>
              dispatch(setGlobalGapApplicationField(value))
            }
          />
          {clientiState.clienteEditCreateRequest.ggApplicationField ===
            "PIANTE" && (
              <CustomSelectComponent
                title="Categoria"
                placeholder="Seleziona categoria..."
                defaultValue={clientiState.clienteEditCreateRequest.ggCategory}
                value={clientiState.clienteEditCreateRequest.ggCategory}
                options={categorie_piante}
                onChangeHandler={(value) =>
                  dispatch(setGlobalGapPlantCategory(value))
                }
              />
            )}
          <CustomSelectComponent
            title="Opzioni per la Certificazione"
            placeholder="Seleziona opzione..."
            defaultValue={
              clientiState.clienteEditCreateRequest.ggIFACertificationOption
            }
            value={
              clientiState.clienteEditCreateRequest.ggIFACertificationOption
            }
            options={opzioni_certificazione_IFA}
            onChangeHandler={(value) =>
              dispatch(setGlobalGapOpzioneCertIFA(value))
            }
          />
          <CustomSelectComponent
            title="Manipolazione"
            placeholder="Seleziona manipolazione..."
            defaultValue={clientiState.clienteEditCreateRequest.ggManipulation}
            value={clientiState.clienteEditCreateRequest.ggManipulation}
            options={manipolazione}
            onChangeHandler={(value) =>
              dispatch(setGlobalGapManipolazioneIFA(value))
            }
          />
          <CustomSelectComponent
            title="Proprietà parallele"
            placeholder="Seleziona proprietà..."
            defaultValue={
              clientiState.clienteEditCreateRequest.ggParallelProperty
                ? "SI"
                : ""
            }
            value={
              clientiState.clienteEditCreateRequest.ggParallelProperty
                ? "SI"
                : ""
            }
            options={boolean_values}
            onChangeHandler={(value) => {
              if (value === "SI") {
                dispatch(setGlobalGapPropParallele(true));
              } else {
                dispatch(setGlobalGapPropParallele(false));
              }
            }}
          />
          <div
            className="borderTopSection mt-6"
            style={{ ...commonRightBarStyle, paddingTop: "14px" }}
          >
            <span className="input-label">{"Add-on (Moduli aggiuntivi)"}</span>
            {
              clientiState.clienteEditCreateRequest.ggAddOns &&
              clientiState.clienteEditCreateRequest.ggAddOns.map(
                (add, index) => (
                  <AddOnComponent
                    popUpVisible={showPopup}
                    onEdit
                    idx={index}
                    id={Number(add.id)}
                    key={index}
                    title={add.addModule}
                    onDelete={() => {
                        dispatch(removeAddOn(index));
                    }}
                    startDate={add.starting}
                    endDate={add.ending}
                    note={add.addOn}
                  />
                )
              )
            }
            <div className="mt-4">
              <Button
                size={"sm"}
                iconPosition={"off"}
                variant="outline"
                label="Aggiungi Add-on"
                color="dimgrey"
                onClick={() => {
                  setShowPopup(true);
                }}
              />
            </div>
            {showPopup && (
              <AddOnPopup closeHandler={() => setShowPopup(false)} />
            )}
          </div>
        </div>
      )}
      {clientiState.clienteEditCreateRequest.ggStandard === "CoC" && (
        <div>
          <CustomSelectComponent
            title="Opzioni per la Certificazione"
            placeholder="Seleziona opzione..."
            defaultValue={clientiState.globalGapOpzioneCertCoC}
            value={clientiState.globalGapOpzioneCertCoC}
            options={opzioni_certificazione_CoC}
            onChangeHandler={(value) =>
              dispatch(setGlobalGapOpzioneCertCoC(value))
            }
          />
          <CustomSelectComponent
            title="Proprietà parallele"
            placeholder="Seleziona proprietà..."
            defaultValue={clientiState.globalGapPropParallele}
            value={clientiState.globalGapPropParallele}
            options={boolean_values}
            onChangeHandler={(value) => {
              if (value === "SI") {
                dispatch(setGlobalGapPropParallele(true));
              } else {
                dispatch(setGlobalGapPropParallele(false));
              }
            }}
          />
          <CustomSelectComponent
            title="Etichettatura"
            placeholder="Seleziona etichettatura..."
            defaultValue={clientiState.globalGapEtichettaturaCoC}
            value={clientiState.globalGapEtichettaturaCoC}
            options={boolean_values}
            onChangeHandler={(value) => {
              if (value === "SI") {
                dispatch(setGlobalGapEtichettaturaCoC(true));
              } else {
                dispatch(setGlobalGapEtichettaturaCoC(false));
              }
            }}
          />
          <CustomSelectComponent
            title="Licenza per “GNN Label Logo”"
            placeholder="Seleziona licenza..."
            defaultValue={clientiState.globalGapLicenzaGNNCoC}
            value={clientiState.globalGapLicenzaGNNCoC}
            options={boolean_values}
            onChangeHandler={(value) => {
              if (value === "SI") {
                dispatch(setGlobalGapLicenzaGNNCoC(true));
              } else {
                dispatch(setGlobalGapLicenzaGNNCoC(false));
              }
            }}
          />
          <CustomSelectComponent
            title="Trasformazione parziale"
            placeholder="Seleziona trasformazione..."
            defaultValue={clientiState.globalGapTrasformazioneParzialeCoC}
            value={clientiState.globalGapTrasformazioneParzialeCoC}
            options={boolean_values}
            onChangeHandler={(value) => {
              if (value === "SI") {
                dispatch(setGlobalGapTrasformazioneParzialeCoC(true));
              } else {
                dispatch(setGlobalGapTrasformazioneParzialeCoC(false));
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
