import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import Button from "../../../ui/molecules/button"
import { formatterDate, optionsFormatter, revisioneOptions, typologyOptions } from "../../../utils"
import { EditIcon } from "../../../ui/icons/edit"
import { TrashIcon } from "../../../ui/icons/trash"
import { addContracts, ContractDelete, GetAllContracts, GetContractById, resetContractCreationRequest, resetContractDeleteStatus, resetContractErrors, resetContracts, resetContractUpdateRequest, resetGetAllContractsStatus, resetGetContractByIDStatus, setContractIdToUpdate, setContracts, setPMId, setUpdateActive, setUpdateContractType, setUpdateEndingDate, setUpdateKmIndennity, setUpdateNumContract, setUpdatePMId, setUpdateProtocolIdDeparture, setUpdateRevisionType, setUpdateStartingDate, setUpdateStipulate } from "./slice"
import { PopupForm } from "../../../ui/organisms/popupForm"
import { ContrattiPopupAdd } from "./boxPopupOptions/add"
import { ContractDTO } from "./dataService/dto"
import { ContrattiPopupEdit } from "./boxPopupOptions/edit"
import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus } from "../slice"

export function BoxContratti () {
    const dispatch = useAppDispatch()
    const contractsState = useAppSelector(state => state.PMContracts)
    const PMState = useAppSelector(state => state.personalManagement)
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const [openPopupEdit, setOpenPopupEdit] = useState<boolean>(false)

    const onEditHandler = (contract: ContractDTO) =>{
        dispatch(setUpdateProtocolIdDeparture(contract.protocolIdDeparture))
        dispatch(setUpdatePMId(contract.personalManagementId))
        dispatch(setUpdateActive(contract.active))
        dispatch(setUpdateKmIndennity(contract.kmIndennity))
        dispatch(setUpdateEndingDate(contract.ending))
        dispatch(setUpdateStartingDate(contract.starting))
        dispatch(setUpdateStipulate(contract.stipulate))
        dispatch(setUpdateRevisionType(contract.revisionType))
        dispatch(setUpdateNumContract(contract.numContract))
        dispatch(setUpdateContractType(contract.contractType))
        dispatch(setContractIdToUpdate(contract.id))
        setOpenPopupEdit(true)
    }

    useEffect(() => {
        if(PMState.currentPersonalManagementId) {
            dispatch(setPMId(PMState.currentPersonalManagementId))
            dispatch(setUpdatePMId(PMState.currentPersonalManagementId))
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [])

    useEffect(() => {
        dispatch(resetContracts())
        if (PMState.getByIdPersonalManagementResourcesStatus === 'successfully' && 
            PMState.getByIdPersonalManagementResourcesResponse?.contractIds) {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))
            for (let index = 0; index < PMState.getByIdPersonalManagementResourcesResponse?.contractIds.length; index++) {
                dispatch(GetContractById(PMState.getByIdPersonalManagementResourcesResponse?.contractIds[index].toString()))
            }
        }
    }, [PMState.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if(contractsState.getContractByIdStatus === 'successfully' &&
            contractsState.getContractByIdResponse !== undefined
        ) {
            dispatch(resetGetContractByIDStatus())
            dispatch(addContracts(contractsState.getContractByIdResponse))
        }
    }, [contractsState.getContractByIdStatus])

    useEffect(() => {
        if(contractsState.contractDeleteStatus === 'successfully') {
            dispatch(resetContractDeleteStatus());
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [contractsState.contractDeleteStatus])

    return (
        <div style={{padding: '24px'}}>
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px'}}>
                    <span id="title-eval-tab">Contratti</span>
                    <Button 
                        size="md" 
                        iconPosition="off" 
                        variant="outline" 
                        color="orange" 
                        label="Aggiungi contratto" 
                        onClick={() => setOpenPopup(true)}
                    />
                </div>

                <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px'}}>TIPOLOGIA</th>
                            <th>REVISIONE</th>
                            <th>DATA STIPULA</th>
                            <th>DATA INIZIO</th>
                            <th>DATA FINE</th>
                            <th>PROTOCOLLO PARTENZA</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {contractsState.contracts.map(contract =>
                            <tr
                            className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                            key={contract.id}
                            >
                                <td className="text-neutral-700">
                                    {`${optionsFormatter(contract.contractType, typologyOptions)} n. ${contract.numContract}`}
                                </td>
                                <td className="text-neutral-700">
                                    {optionsFormatter(contract.revisionType, revisioneOptions)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(contract.stipulate)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(contract.starting)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(contract.ending!)}
                                </td>
                                <td className="text-neutral-700">
                                    {contract.protocolIdDeparture}
                                </td>
                                <td className="w-[30px]">
                                    <div className="flex justify-content-between align-items-center">

                                        <button onClick={() => {
                                            onEditHandler(contract)
                                        }}>
                                            <EditIcon color={"Orange"} size={18} />
                                        </button>

                                        <div className="pl-5 flex align-items-center">
                                            <button onClick={() => dispatch(ContractDelete(contract.id!))}>
                                                <TrashIcon color={"Orange"} size={0} />
                                            </button>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
            {
                openPopup && (
                    <PopupForm title="Aggiungi Contratto" close={() => {
                        dispatch(resetContractErrors())
                        dispatch(resetContractCreationRequest())
                        setOpenPopup(false)
                        }}>
                        <ContrattiPopupAdd close={() => setOpenPopup(false)} />
                    </PopupForm>
                )
            }
            {
                openPopupEdit && (
                    <PopupForm title="Modifica Contratto" close={() => {
                        dispatch(resetContractUpdateRequest())
                        dispatch(resetContractErrors())
                        setOpenPopupEdit(false)
                        }}>
                        <ContrattiPopupEdit close={() => setOpenPopupEdit(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}