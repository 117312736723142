import { useNavigate } from "react-router-dom";
import { Layout } from "../../../ui/layout";
import Button from "../../../ui/molecules/button";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { SelectCustom } from "../../../ui/molecules/select";
import Input from "../../../ui/molecules/input";
import { Calendar } from "../../../ui/molecules/calendar";
import { DepartureListProtocol } from "./list";
import { departureMeansOptions, OptionType, sot, yearOptions } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { setGetAllPPFilterHeadquarter, setGetAllPPFilterNumber, setGetAllPPFilterYear, setGetAllPPFilterFrom, setGetAllPPFilterTo, setGetAllPPFilterClient, setGetAllPPFilterSender, setGetAllPPFilterAttachmentId, setGetAllPPFilterObjectSpec, setGetAllPPFilterObjectId, GetAllDepartureProtocols, resetGetAllDepartureProtocolObjectsStatus, GetAllDepartureProtocolObjects, setGetAllPPFilterdepartureMeans, GetAllDepartureProtocolAttachments, resetGetAllDepartureProtocolAttachmentsStatus } from "./slice";
import { useEffect, useState } from "react";

export function DepartureProtocol() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    const [attachments, setAttachments] = useState<OptionType[]>([])
    const [objects, setObjects] = useState<OptionType[]>([])

    useEffect( () => {
        dispatch(GetAllDepartureProtocolObjects())
        dispatch(GetAllDepartureProtocolAttachments())
    }, [])

    useEffect(() => {
        if (departureProtocolState.getAllDepartureProtocolAttachmentsStatus === 'successfully' &&
            departureProtocolState.getAllDepartureProtocolAttachmentsResponse !== undefined
        ) {
            dispatch(resetGetAllDepartureProtocolAttachmentsStatus())
            const data = departureProtocolState.getAllDepartureProtocolAttachmentsResponse
            const newAtts: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setAttachments(newAtts)
        }
    }, [departureProtocolState.getAllDepartureProtocolAttachmentsStatus])

    useEffect(() => {
        if (departureProtocolState.getAllDepartureProtocolObjectsStatus === 'successfully' &&
            departureProtocolState.getAllDepartureProtocolObjectsResponse !== undefined
        ) {
            dispatch(resetGetAllDepartureProtocolObjectsStatus())
            const data = departureProtocolState.getAllDepartureProtocolObjectsResponse
            const newObjects: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setObjects(newObjects)
        }
    }, [departureProtocolState.getAllDepartureProtocolObjectsStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Protocollo']}
            noPaddingLat
            headerLabel="Protocollo Partenza"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiungi Protocollo Partenza" color={"orange"}
                        onClick={() => {
                            navigate('/addDepartureProtocol')
                        }}
                    />
                </div>
            }
        >
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 24px 0px 24px' }}>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Tipologia</span>
                        </div>
                        <SelectCustom
                            placeholder={'Sede / Ufficio'}
                            options={sot}
                            onChange={(value) => {
                                dispatch(setGetAllPPFilterHeadquarter(value))
                            }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Numero'}
                            placeholder="Specifica Numero"
                            onChangeText={(text) => { dispatch(setGetAllPPFilterNumber(text)) }}
                        />

                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Anno</span>
                        </div>
                        <SelectCustom
                            placeholder={'Anno'}
                            options={yearOptions}
                            onChange={(value) => dispatch(setGetAllPPFilterYear(value))}

                        />

                    </div>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Mezzo Partenza</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona'}
                            options={departureMeansOptions}
                            onChange={(value) => dispatch(setGetAllPPFilterdepartureMeans(value))}

                        />

                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Dal</span>
                        </div>
                        <Calendar
                            selected={departureProtocolState.getAllDepartureProtocolFilters.from}
                            onChange={(date) => dispatch(setGetAllPPFilterFrom(date))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Al</span>
                        </div>
                        <Calendar
                            selected={departureProtocolState.getAllDepartureProtocolFilters.to}
                            onChange={(date) => dispatch(setGetAllPPFilterTo(date))}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Cliente'}
                            placeholder="Cliente"
                            onChangeText={(text) => { dispatch(setGetAllPPFilterClient(text)) }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Mittente'}
                            placeholder="Mittente"
                            onChangeText={(text) => { dispatch(setGetAllPPFilterSender(text)) }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Allegato</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona Allegato'}
                            options={attachments}
                            onChange={(value) => dispatch(setGetAllPPFilterAttachmentId(value))}

                        />

                    </div>
                </div>
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.49' }}>
                        <div className="text-left">
                            <span className="input-label">Oggetto</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona Oggetto'}
                            options={objects}
                            onChange={(value) => dispatch(setGetAllPPFilterObjectId(value))}
                        />

                    </div>

                    <div style={{ flex: '0.5' }}>
                        <Input
                            label={'Specifiche Oggetto'}
                            placeholder="Specifiche"
                            onChangeText={(text) => { dispatch(setGetAllPPFilterObjectSpec(text)) }}
                        />
                    </div>

                </div>

                <div className="mt-2" style={{ display: 'flex', flex: 'wrap', gap: '24px', justifyContent: 'right', paddingRight: '20px' }}>
                    <Button
                        variant='solid'
                        color='orange'
                        size='sm'
                        iconPosition='off'
                        label='Cerca'
                        onClick={() => dispatch(GetAllDepartureProtocols(departureProtocolState.getAllDepartureProtocolFilters))}
                    />
                </div>
            </div>
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 24px 0px 24px' }}>
                <DepartureListProtocol />
            </div>
        </Layout>
    )
}