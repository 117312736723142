import { createSlice } from "@reduxjs/toolkit"

interface DocumentManagementState {
    selectedTab: number
}

const initialState: DocumentManagementState = {
    selectedTab: 0
}

const DocumentManagementSlice = createSlice({
    name: 'documentManagement/slice',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        }
    }
})

export const {
    setSelectedTab
} = DocumentManagementSlice.actions

export default DocumentManagementSlice.reducer