import React from "react";
import { IconProps } from "./dto";

export function PrintButton() {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
            <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="15.25" fill="white" />
            <rect x="0.75" y="0.75" width="62.5" height="62.5" rx="15.25" stroke="#C0D5D8" strokeWidth="1.5" />
            <path d="M24 27.9998V18.6665H40V27.9998M24 39.9998H21.3334C20.6261 39.9998 19.9478 39.7189 19.4477 39.2188C18.9476 38.7187 18.6667 38.0404 18.6667 37.3332V30.6665C18.6667 29.9593 18.9476 29.281 19.4477 28.7809C19.9478 28.2808 20.6261 27.9998 21.3334 27.9998H42.6667C43.3739 27.9998 44.0522 28.2808 44.5523 28.7809C45.0524 29.281 45.3334 29.9593 45.3334 30.6665V37.3332C45.3334 38.0404 45.0524 38.7187 44.5523 39.2188C44.0522 39.7189 43.3739 39.9998 42.6667 39.9998H40M24 34.6665H40V45.3332H24V34.6665Z" stroke="#5D9298" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}