import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { setEmail, setFax, setPhone, setRappLegaleMobilePhone } from "../../../../pages/clienti/slice";
import Input from "../../input";

export function RappLegaleContatti() {
    const clientiState = useAppSelector(state => state.clienti)
    const dispatch = useAppDispatch()

    return (
        <div className="bg-brandPrimary-100 pt-3">
            <div className="formRappLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                <div style={{ flex: '0.5' }}>
                    <Input
                        label={'Telefono'}
                        placeholder="Telefono"
                        onChangeText={(text) => dispatch(setPhone(text))}
                        defaultValue={clientiState.rappLegaleEditCreateRequest.phone}
                        value={clientiState.rappLegaleEditCreateRequest.phone}
                    />
                </div>

                <div style={{ flex: '0.5' }}>
                    <Input
                        label={'Cellulare'}
                        placeholder="Cellulare"
                        onChangeText={(text) => dispatch(setRappLegaleMobilePhone(text))}
                        defaultValue={clientiState.rappLegaleEditCreateRequest.mobilePhone}
                        value={clientiState.rappLegaleEditCreateRequest.mobilePhone}
                    />
                </div>

                </div>

                <div className="formRappLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                <div style={{ flex: '0.5' }}>
                    <Input
                        label={'Email'}
                        placeholder="Email" onChangeText={(text) => dispatch(setEmail(text))}
                        defaultValue={clientiState.rappLegaleEditCreateRequest.email}
                        value={clientiState.rappLegaleEditCreateRequest.email}
                    />
                </div>

                <div style={{ flex: '0.5', paddingBottom: 20 }}>
                    <Input
                        label={'Fax'}
                        placeholder="Fax"
                        onChangeText={(text) => dispatch(setFax(text))}
                        defaultValue={clientiState.rappLegaleEditCreateRequest.fax}
                        value={clientiState.rappLegaleEditCreateRequest.fax}
                    />
                </div>
            </div>
        </div>
    )
}