import { CSSProperties } from "react"
import { RoundedCloseIcon } from "../../../../ui/icons/CloseIcon"
import { formatterDate } from "../../../../utils"

export interface ProtocolSuccessType {
    type: 'entry' | 'departure'
    protocol: string
    date?: Date
    closeAction: () => void
}

export function ProtocolSuccessBanner(props: ProtocolSuccessType) {
   

    return (
        <div className="gap-[8px] text-left" style={{  borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', display: 'flex', borderWidth: 2 }}>
            <div className="text-left">
                <span style={{...BannerTextStyle, padding: '12px'}}>Protocollo creato: <span style={{ color: '#166428'}}>{props.protocol} del {formatterDate(props.date)}</span> </span>
            </div>
        </div>
    )
}

const BannerTextStyle: CSSProperties = {
    fontSize: '16px',
    fontFamily: 'Manrope',
    fontWeight: '500',
    lineHeight: '20px',
    display: 'block'
}