import { FunctionsDTO } from "./dto";
import { FunctionsServiceImpl } from "./serviceImpl";

export interface FunctionsService {
    FunctionCreation(data: FunctionsDTO): Promise<void>
    GetFunctionById(id: string): Promise<FunctionsDTO>
    FunctionUpdate(data: FunctionsDTO, id: string): Promise<void>
    GetAllFunctions(): Promise<FunctionsDTO[]>
    DeleteFunction(id: string): Promise<void>
}

export function NewFunctionsService(): FunctionsService {
    return new FunctionsServiceImpl();
}