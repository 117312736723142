import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../../../utils"
import { NewFunctionsService } from "./dataService/service"
import { ActivitiesDTO, FunctionsDTO } from "./dataService/dto"

interface FunctionsState {
    functionRequest: FunctionsDTO
    functionUpdateRequest: FunctionsDTO
    functionsActivities?: ActivitiesDTO
    functionCreationStatus: PromiseStatuses
    functionUpdateStatus: PromiseStatuses
    functionDeleteStatus: PromiseStatuses
    GetFunctionById?: FunctionsDTO
    getFunctionByIdStatus: PromiseStatuses
    GetAllFunctionsByPMIdStatus: PromiseStatuses
    GetAllFunctionsByPMIdResponse?: FunctionsDTO[]
    GetAllFunctionsStatus: PromiseStatuses
    GetAllFunctionsResponse?: FunctionsDTO[]
    functionValidateStatus: PromiseStatuses
    functionsInstances: FunctionsDTO[]
    functionsErrors: {
        assignedFunction: boolean,
        startingFunction: boolean,
        norm: boolean,
        ministerialNote: boolean,
        noteDate: boolean,
    },
}

const initialState: FunctionsState = {
    functionsInstances: [],
    functionRequest: {
        assignedFunction: '',
        startingFunction: new Date(),
        norm: '',
        operativesAreas: [],
        protocolIdRequestDeparture: '',
        protocolIdMinisterialReceivedEntry: '',
        ministerialNote: '',
        noteDate: new Date(),
        personalManagementId: ''
    },
    functionsErrors: {
        assignedFunction: false,
        startingFunction: false,
        norm: false,
        ministerialNote: false,
        noteDate: false,
    },
    functionUpdateRequest: {
        assignedFunction: '',
        startingFunction: new Date(),
        norm: '',
        operativesAreas: [],
        protocolIdRequestDeparture: '',
        protocolIdMinisterialReceivedEntry: '',
        ministerialNote: '',
        noteDate: new Date(),
        personalManagementId: ''
    },

    functionCreationStatus: 'idle',
    getFunctionByIdStatus: 'idle',
    functionUpdateStatus: 'idle',
    GetAllFunctionsByPMIdStatus: 'idle',
    functionDeleteStatus: 'idle',
    functionValidateStatus: 'idle',
    GetAllFunctionsStatus: 'idle'
}

export const validateFunctionCreation = createAsyncThunk(
    'PM/validateFunctionCreation',
    async (request: FunctionsDTO, thunkApi): Promise<void> => {
        let isValid = true;
        thunkApi.dispatch(resetFunctionsErrors())

        if (request.assignedFunction === '') {
            thunkApi.dispatch(setValidateAssignedFunction(true))
            isValid = false
        }

        if (request.norm === '') {
            thunkApi.dispatch(setValidateNorm(true))
            isValid = false
        }

        if (!request.startingFunction) {
            thunkApi.dispatch(setValidateStartingFunction(true))
            isValid = false
        }

        if (request.ministerialNote === '') {
            thunkApi.dispatch(setValidateMinisterlialNote(true))
            isValid = false
        }

        if (!request.noteDate) {
            thunkApi.dispatch(setValidateNoteDate(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()

    }
)

export const FunctionCreation = createAsyncThunk(
    'PM/Functions/create',
    async (data: FunctionsDTO, thunkApi): Promise<void> => {
        const functionsService = NewFunctionsService()

        return functionsService.FunctionCreation(data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const FunctionUpdate = createAsyncThunk(
    'PM/OtherInfo/Update',
    async (request: { data: FunctionsDTO, id: string }, thunkApi): Promise<void> => {
        const otherInfoService = NewFunctionsService()

        return otherInfoService.FunctionUpdate(request.data, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetFunctionById = createAsyncThunk(
    'PM/Functions/GetFunctionById',
    async (id: string, thunkApi): Promise<FunctionsDTO> => {
        const functionsService = NewFunctionsService()

        return functionsService.GetFunctionById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllFunctionsByPMId = createAsyncThunk(
    'PM/Functions/GetAllById',
    async (PMId: string, thunkApi): Promise<FunctionsDTO[]> => {
        const functionsService = NewFunctionsService()

        return (await functionsService.GetAllFunctions()).filter(f => f.personalManagementId === PMId)
    },
)

export const GetAllFunctions = createAsyncThunk(
    'PM/Functions/GetAll',
    async (thunkApi): Promise<FunctionsDTO[]> => {
        const functionsService = NewFunctionsService()

        return functionsService.GetAllFunctions()
    },
)

export const DeleteFunction = createAsyncThunk(
    'PM/OtherData/DeleteFunction',
    async (id: string, thunkApi): Promise<void> => {
        const functionsService = NewFunctionsService()

        return functionsService.DeleteFunction(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const PMFunctions = createSlice({
    name: 'PM/functionsSlice',
    initialState,
    reducers: {
        setFunction: (state, action) => {
            state.functionRequest.assignedFunction = action.payload
        },
        setUpdateFunction: (state, action) => {
            state.functionUpdateRequest.assignedFunction = action.payload
        },
        setStarting: (state, action) => {
            state.functionRequest.startingFunction = action.payload
        },
        setUpdateStarting: (state, action) => {
            state.functionUpdateRequest.startingFunction = action.payload
        },
        setEnding: (state, action) => {
            state.functionRequest.endingFunction = action.payload
        },
        setUpdateEnding: (state, action) => {
            state.functionUpdateRequest.endingFunction = action.payload
        },
        setScheme: (state, action) => {
            state.functionRequest.norm = action.payload
        },
        setUpdateScheme: (state, action) => {
            state.functionUpdateRequest.norm = action.payload
        },
        setAreeOperative: (state, action) => {
            state.functionRequest.operativesAreas = action.payload
        },
        setUpdateAreeOperative: (state, action) => {
            state.functionUpdateRequest.operativesAreas = action.payload
        },
        setProtocolRequestId: (state, action) => {
            state.functionRequest.protocolIdRequestDeparture = action.payload
        },
        setMinisterialNote: (state, action) => {
            state.functionRequest.ministerialNote = action.payload
        },
        setUpdateMinisterialNote: (state, action) => {
            state.functionUpdateRequest.ministerialNote = action.payload
        },
        setDateNote: (state, action) => {
            state.functionRequest.noteDate = action.payload
        },
        setUpdateDateNote: (state, action) => {
            state.functionUpdateRequest.noteDate = action.payload
        },
        setNote: (state, action) => {
            state.functionRequest.note = action.payload
        },
        setUpdateNote: (state, action) => {
            state.functionUpdateRequest.note = action.payload
        },
        setRowsActivityBio: (state, action) => {
            state.functionRequest.functionActivities = action.payload
        },
        setUpdateRowsActivityBio: (state, action) => {
            state.functionUpdateRequest.functionActivities = action.payload
        },
        setRowsActivityGG: (state, action) => {
            state.functionRequest.functionActivities = action.payload
        },
        setUpdateRowsActivityGG: (state, action) => {
            state.functionUpdateRequest.functionActivities = action.payload
        },
        setPMId: (state, action) => {
            state.functionRequest.personalManagementId = action.payload
        },
        setUpdateRequest: (state, action) => {
            state.functionUpdateRequest = action.payload
        },
        resetCreationFunction: (state, action) => {
            state.functionCreationStatus = action.payload
        },
        resetUpdateFunction: (state, action) => {
            state.functionUpdateStatus = action.payload
        },
        resetGetFunctionByIdStatus: (state, action) => {
            state.getFunctionByIdStatus = action.payload
        },
        addFunctionsInstance: (state, action) => {
            state.functionsInstances.push(action.payload)
        },
        resetFunctionsInstance: (state) => {
            state.functionsInstances = []
        },
        //VALIDATION
        setValidateAssignedFunction: (state, action) => {
            state.functionsErrors.assignedFunction = action.payload
        },
        setValidateStartingFunction: (state, action) => {
            state.functionsErrors.startingFunction = action.payload
        },
        setValidateNorm: (state, action) => {
            state.functionsErrors.norm = action.payload
        },
        setValidateMinisterlialNote: (state, action) => {
            state.functionsErrors.ministerialNote = action.payload
        },
        setValidateNoteDate: (state, action) => {
            state.functionsErrors.noteDate = action.payload
        },
        resetFunctionsErrors: (state) => {
            state.functionsErrors = {
                assignedFunction: false,
                startingFunction: false,
                norm: false,
                ministerialNote: false,
                noteDate: false,
            }
        },
        resetGetAllFunctionsByPMIdStatus: (state) => {
            state.GetAllFunctionsByPMIdStatus = 'idle'
        },
        resetGetAllFunctionsStatus: (state) => {
            state.GetAllFunctionsStatus = 'idle'
        },
        resetFunctionsValidationStatus: (state) => {
            state.functionValidateStatus = 'idle'
        },
        resetFunctionDeleteStatus: (state) => {
            state.functionDeleteStatus = 'idle'
        },
        resetFunctionsCreateForm: (state) => {
            state.functionRequest = {
                assignedFunction: '',
                startingFunction: new Date(),
                norm: '',
                operativesAreas: [],
                protocolIdRequestDeparture: '',
                protocolIdMinisterialReceivedEntry: '',
                ministerialNote: '',
                noteDate: new Date(),
                personalManagementId: state.functionRequest.personalManagementId
            }
        },
    },
    extraReducers(builder) {
        builder
            .addCase(FunctionCreation.pending, (state) => {
                state.functionCreationStatus = 'loading'
            })
            .addCase(FunctionCreation.fulfilled, (state, action) => {
                state.functionCreationStatus = 'successfully'
            })
            .addCase(FunctionCreation.rejected, (state) => {
                state.functionCreationStatus = 'failed'
            })

            .addCase(GetFunctionById.pending, (state) => {
                state.getFunctionByIdStatus = 'loading'
            })
            .addCase(GetFunctionById.fulfilled, (state, action) => {
                state.GetFunctionById = action.payload
                state.getFunctionByIdStatus = 'successfully'
            })
            .addCase(GetFunctionById.rejected, (state) => {
                state.getFunctionByIdStatus = 'failed'
            })

            .addCase(GetAllFunctionsByPMId.pending, (state) => {
                state.GetAllFunctionsByPMIdStatus = 'loading'
            })
            .addCase(GetAllFunctionsByPMId.fulfilled, (state, action) => {
                state.GetAllFunctionsByPMIdResponse = action.payload
                state.GetAllFunctionsByPMIdStatus = 'successfully'
            })
            .addCase(GetAllFunctionsByPMId.rejected, (state) => {
                state.GetAllFunctionsByPMIdStatus = 'failed'
            })

            .addCase(GetAllFunctions.pending, (state) => {
                state.GetAllFunctionsStatus = 'loading'
            })
            .addCase(GetAllFunctions.fulfilled, (state, action) => {
                state.GetAllFunctionsResponse = action.payload
                state.GetAllFunctionsStatus = 'successfully'
            })
            .addCase(GetAllFunctions.rejected, (state) => {
                state.GetAllFunctionsStatus = 'failed'
            })

            .addCase(FunctionUpdate.pending, (state) => {
                state.functionUpdateStatus = 'loading'
            })
            .addCase(FunctionUpdate.fulfilled, (state, action) => {
                state.functionUpdateStatus = 'successfully'
            })
            .addCase(FunctionUpdate.rejected, (state) => {
                state.functionUpdateStatus = 'failed'
            })

            .addCase(validateFunctionCreation.pending, (state) => {
                state.functionValidateStatus = 'loading'
            })
            .addCase(validateFunctionCreation.fulfilled, (state) => {
                state.functionValidateStatus = 'successfully'
            })
            .addCase(validateFunctionCreation.rejected, (state) => {
                state.functionValidateStatus = 'failed'
            })

            .addCase(DeleteFunction.pending, (state) => {
                state.functionDeleteStatus = 'loading'
            })
            .addCase(DeleteFunction.fulfilled, (state, action) => {
                state.functionDeleteStatus = 'successfully'
            })
            .addCase(DeleteFunction.rejected, (state) => {
                state.functionDeleteStatus = 'failed'
            })
    },
})

export const {

    setFunction,
    setStarting,
    setEnding,
    setAreeOperative,
    setDateNote,
    setMinisterialNote,
    setNote,
    setPMId,
    setProtocolRequestId,
    setScheme,
    setRowsActivityGG,
    setRowsActivityBio,
    resetCreationFunction,
    addFunctionsInstance,
    resetFunctionsInstance,
    resetUpdateFunction,
    resetGetFunctionByIdStatus,
    setUpdateRowsActivityGG,
    setUpdateRowsActivityBio,
    setUpdateAreeOperative,
    setUpdateDateNote,
    setUpdateEnding,
    setUpdateFunction,
    setUpdateMinisterialNote,
    setUpdateNote,
    setUpdateScheme,
    setUpdateStarting,
    setValidateAssignedFunction,
    setValidateMinisterlialNote,
    setValidateNorm,
    setValidateNoteDate,
    setValidateStartingFunction,
    resetFunctionsErrors,
    resetGetAllFunctionsByPMIdStatus,
    resetFunctionsValidationStatus,
    resetFunctionsCreateForm,
    resetFunctionDeleteStatus,
    resetGetAllFunctionsStatus,
    setUpdateRequest

} = PMFunctions.actions

export default PMFunctions.reducer