import { GetAllTrainingsFiltersDTO, GetAllTrainingsResponseDTO, TrainingDTO } from "./dto";
import { TrainingServiceImpl } from "./serviceImpl";

export interface TrainingService {
    CreateTraining(data: TrainingDTO): Promise<void>
    UpdateTraining(data: TrainingDTO, id: string): Promise<void>
    DeleteTraining(id: string): Promise<void>
    GetAllTraining(filters: GetAllTrainingsFiltersDTO): Promise<GetAllTrainingsResponseDTO>
    GetTrainingById(id: string): Promise<TrainingDTO>
}

export function NewTrainingService(): TrainingService {
    return new TrainingServiceImpl();
}