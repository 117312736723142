import { Fragment } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import DatePicker from "react-datepicker";
import Input from "../../../../ui/molecules/input";
import { CalendarIcon } from "../../../../ui/icons/calendar";
import { RequirementDetailUpdate, RequirementDetailValidation, resetRequirementDetailErrors, resetRequirementDetails, resetRequirementDetailUpdateRequest, resetRequirementDetailUpdateStatus, resetRequirementDetailValidationStatus, setUpdateReqDetailDetail, setUpdateReqDetailEnding, setUpdateReqDetailNote, setUpdateReqDetailProtocols, setUpdateReqDetailStarting } from "../slice";
import { format } from "date-fns";
import TextArea from "../../../../ui/molecules/textArea";
import Button from "../../../../ui/molecules/button";
import { useEffect } from "react";
import { GetByIdPersonalManagement } from "../../slice";
import { Calendar } from "../../../../ui/molecules/calendar";

interface Props {
    close: () => void
}

export function RequirementDetailPopupEdit(props: Props) {
    const dispatch = useAppDispatch()
    const requirementState = useAppSelector(state => state.PMRequirement)
    const PMState = useAppSelector(state => state.personalManagement)

    useEffect(() => {
        if(requirementState.requirementDetailValidationStatus === 'successfully') {
            dispatch(resetRequirementDetailValidationStatus())
            dispatch(resetRequirementDetailErrors())
            dispatch(RequirementDetailUpdate({body: requirementState.requirementDetailUpdateRequest, id: requirementState.requirementDetailIdToUpdate}))
        }
    }, [requirementState.requirementDetailValidationStatus])

    useEffect(() => {
        if(requirementState.requirementDetailUpdateStatus === 'successfully') {
            dispatch(resetRequirementDetailUpdateStatus())
            dispatch(resetRequirementDetailUpdateRequest())
            dispatch(resetRequirementDetails())
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
            props.close()
        }
    }, [requirementState.requirementDetailUpdateStatus])

    return (
        <Fragment>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                
                {/* FIRST ROW */}
                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Dal</span>
                        </div>
                        <Calendar
                            selected={requirementState.requirementDetailUpdateRequest.starting}
                            onChange={(date) => {
                                dispatch(setUpdateReqDetailStarting(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">al</span>
                        </div>
                        <Calendar
                            selected={requirementState.requirementDetailUpdateRequest.ending}
                            onChange={(date) => {
                                dispatch(setUpdateReqDetailEnding(format(new Date(date), 'yyyy-MM-dd')))
                            }}
                        />
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className="formAnagrafica mt-3 pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Dettagli requisito</span>
                        </div>
                        <TextArea
                            placeholder="Descrivi i dettagli del requisito"
                            value={requirementState.requirementDetailUpdateRequest.detail}
                            defaultValue={requirementState.requirementDetailUpdateRequest.detail}
                            error={requirementState.requirementDetailErrors.detail}
                            supportingText={requirementState.requirementDetailErrors.detail ? 'Dettagli mancanti!' : undefined} 
                            onChangeText={(text) => dispatch(setUpdateReqDetailDetail(text))} />
                    </div>
                </div>

                {/* THIRD ROW */}
                <div className="formAnagrafica mt-3 pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Specifiche protocollo</span>
                        </div>
                        <TextArea
                            placeholder="Specificare Sede, Data e Numero di Protocollo..."
                            value={requirementState.requirementDetailUpdateRequest.protocols}
                            defaultValue={requirementState.requirementDetailUpdateRequest.protocols}
                            error={requirementState.requirementDetailErrors.protocols}
                            supportingText={requirementState.requirementDetailErrors.protocols ? 'Dettagli mancanti!' : undefined} 
                            onChangeText={(text) => dispatch(setUpdateReqDetailProtocols(text))} />
                    </div>
                </div>

                {/* FOURTH ROW */}
                <div className="formAnagrafica mt-3 pb-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            value={requirementState.requirementDetailUpdateRequest.note}
                            defaultValue={requirementState.requirementDetailUpdateRequest.note}
                            onChangeText={(text) => dispatch(setUpdateReqDetailNote(text))} />
                    </div>
                </div>
                
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15}}>
                <Button 
                    size="md" 
                    variant="outline" 
                    iconPosition="off" 
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        dispatch(resetRequirementDetailUpdateRequest())
                        dispatch(resetRequirementDetailErrors())
                        props.close();
                    }}
                    />
                <div className="pl-2">
                    <Button 
                        size="md" 
                        variant="solid" 
                        iconPosition="off" 
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(RequirementDetailValidation(requirementState.requirementDetailUpdateRequest))
                        }}
                        />
                </div>
            </div>
        </Fragment>
    )
}