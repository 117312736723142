import './styles.scss'

interface Props {
    checked: boolean
    setChecked: (b: boolean) => void
    disabled?: boolean
    name?: string
    label: string
}

export function SingleCheckbox(props: Props) {
    return(
        <div className="checkbox-container">
            <input
                disabled={props.disabled}
                type="checkbox"
                name={props.name}
                checked={props.checked}
                onClick={() => props.setChecked(!props.checked)}
            />
            <label className='checkboxLabel' onClick={() => props.setChecked(!props.checked)}>
                {props.label}
            </label>
        </div>
    )
}