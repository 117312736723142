import { IconProps } from "./dto";

interface Props {
    color?: string
}

export function MinusIcon(props: Props) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M3.75 9H14.25" stroke={props.color ? props.color : 'white'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}