import { DocumentDTO, GetAllDocumentsDTO, GetAllDocumentsFilterDTO } from "./dto"
import { DocumentServiceImpl } from "./serviceImpl"

export interface DocumentService {
    CreateDocument(body: FormData): Promise<void>
    UpdateDocument(body: FormData, id: string): Promise<void>
    GetAllDocuments(filters: GetAllDocumentsFilterDTO): Promise<GetAllDocumentsDTO>
    GetDocumentById(id: string): Promise<DocumentDTO>
    DeleteDocument(id: string): Promise<void>
}

export function NewDocumentService(): DocumentService {
    return new DocumentServiceImpl()
}