import { ContractDTO, GetAllContractsResponseDTO } from "./dto";
import { ContractsServiceImpl } from "./serviceImpl";

export interface ContractsService {
    GetAllContracts(): Promise<GetAllContractsResponseDTO>
    GetContractById(id: string): Promise<ContractDTO>
    CreateContract(data: ContractDTO): Promise<void>
    UpdateContract(data: ContractDTO, id: string): Promise<void>
    DeleteContract(id: string): Promise<void>
    DeactivateContract(id: string): Promise<void>
}

export function NewContractsService(): ContractsService {
    return new ContractsServiceImpl();
}