import clsx from "clsx"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import './style.scss';
import Button from "../../ui/molecules/button";
import { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { Banner } from "../../ui/organisms/banner";
import { Tab } from "react-tabs";
import { RightBar } from "../../ui/organisms/rightBar";
import { HorizontalTab } from "../../ui/organisms/horizontalTab";
import { EditCustomerContacts, EditCustomerRequest, GetContactsById, GetCustomersById, resetContactsStatus, resetCreateCompanyCenterStatus, resetCreateRappLegaleStatus, resetCustomerEditStatus, resetIdsCorporateCenterResponse, setIdsCorporateCenterResponse, setIdsLegalRapp, setOnEditCustomer, setSelectedTab, setUserId } from "./slice";
import { normativa } from "../../utils";
import { Bio } from "../normative/bio";
import { BoxAnagraficaEdit } from "./edit/boxAnagraficaEdit";
import { SpinnerComponent } from "../../lib/spinner";
import { BoxRappLegaleEdit } from "./edit/boxRappLegaleEdit";
import { findUserById, linkBusinessTech, resetGetUserByIdStatusIdle } from "../users/slice";
import { BoxRubricaEdit } from "./edit/boxRubricaEdit";
import { BoxCentriAziendaliEdit } from "./edit/boxCentriAziendaliEdit";
import { ProduzioneIntegrata } from "../normative/prodInteg";
import { QualitaSicura } from "../normative/safeQuality";
import { ResiduoZero } from "../normative/residuoZero";
import { ClientTypology } from "../../ui/organisms/clientTypology";
import { AuthorityOffices } from "../../ui/organisms/sedeDiCompetenza";
import { GlobalGap } from "../normative/global";
import { setIdBusinessTechUser } from "../businessTechnician/slice";
import { BoxTecnicoAziendaleEdit } from "./edit/boxTecnicoAziendaleEdit";
import { SelectCustom } from "../../ui/molecules/select";
import { LogoBio } from "../../ui/icons/logoBio";
import { GlobalGapIcon } from "../../ui/icons/logoGlobalGap";
import { LogoSQNPI } from "../../ui/icons/logoSqnpi";
import { ArrowLeftIcon } from "../../ui/icons/arrow-left";
import { useNavigate } from "react-router-dom";

registerLocale('it', it)

export function EditCustomer() {

    const clientiState = useAppSelector(state => state.clienti)
    const usersState = useAppSelector(state => state.users)
    const businessTech = useAppSelector(state => state.businessTechinician)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showSuccessRl, setShowSuccessRl] = useState<boolean>(false)
    const [showSuccessContacts, setShowSuccessContacts] = useState<boolean>(false)
    const [showSuccessCa, setShowSuccessCa] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)

    useEffect(() => {
        dispatch(GetCustomersById(clientiState.selectedCustomerId))
        dispatch(findUserById(usersState.selectedUserId))
        dispatch(setOnEditCustomer(true));
        console.log("[EDIT CUSTOMER]");
    }, [])

    useEffect(() => {
        if (usersState.findUserByIdStatus === 'successfully') {
            dispatch(resetIdsCorporateCenterResponse())
            console.log('prova ', usersState.findUserByIdResponse)
            if (usersState.findUserByIdResponse !== undefined) {
                if (clientiState.userId) {
                    dispatch(setUserId(clientiState.userId))
                }

                if (usersState.findUserByIdResponse.legalRepresentativeIds !== undefined) {
                    dispatch(setIdsLegalRapp(usersState.findUserByIdResponse.legalRepresentativeIds))
                }
                if (usersState.findUserByIdResponse.contactId !== undefined) {

                    dispatch(GetContactsById(usersState.findUserByIdResponse.contactId))
                }
                if (usersState.findUserByIdResponse.businessTechnicianIds !== undefined && usersState.findUserByIdResponse.businessTechnicianIds.length > 0) {
                    console.log('tecnico ', usersState.findUserByIdResponse.businessTechnicianIds)
                    dispatch(setIdBusinessTechUser(usersState.findUserByIdResponse.businessTechnicianIds[usersState.findUserByIdResponse.businessTechnicianIds.length - 1]))
                }
                if (usersState.findUserByIdResponse.companyCenterdIds !== undefined && usersState.findUserByIdResponse.companyCenterdIds.length > 0) {

                    dispatch(setIdsCorporateCenterResponse(usersState.findUserByIdResponse.companyCenterdIds))
                }
            }
            dispatch(resetGetUserByIdStatusIdle())
        }
    }, [usersState.findUserByIdStatus])

    useEffect(() => {
        if (clientiState.customerCreateResponseStatus === 'successfully') {
            setShowSuccessRl(true)
            dispatch(resetCreateRappLegaleStatus('idle'))
        }
    }, [clientiState.rappLegaleCreateResponseStatus]);

    useEffect(() => {
        if (clientiState.corporateCenterCreateResponseStatus === 'successfully') {
            setShowSuccessCa(true)
            dispatch(resetCreateCompanyCenterStatus('idle'))
        }
    }, [clientiState.corporateCenterCreateResponseStatus]);

    useEffect(() => {
        if (clientiState.customerEditStatus === 'successfully') {
            dispatch(resetCustomerEditStatus())
            setShowSuccess(true)
        }
    }, [clientiState.customerEditStatus])


    useEffect(() => {
        if (clientiState.contactsCreateResponseStatus === 'successfully') {
            setShowSuccessContacts(true)
            dispatch(resetContactsStatus('idle'))
        }
    }, [clientiState.contactsCreateResponseStatus]);

    useEffect(() => {
        if (showSuccess && showSuccessRl && showSuccessContacts) {
            setTimeout(() => {
                setShowSuccess(false)
                setShowSuccessContacts(false)
                setShowSuccessRl(false)
            }, 2500);
        }
    }, [showSuccess, showSuccessContacts, showSuccessRl]);

    if (clientiState.getCustomersByIdResponseStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Clienti', 'Nuovo Utente']}
            headerLabel={clientiState.getCustomersByIdResponse?.companyName}
            rightBar={true}
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/clienti')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(EditCustomerRequest({ id: clientiState.selectedCustomerId, data: clientiState.clienteEditCreateRequest }))
                            if (usersState.findUserByIdResponse !== undefined) {
                                // if (usersState.findUserByIdResponse.legalRepresentativeId !== undefined) {
                                //     dispatch(EditLegalRapRequest({ id: usersState.findUserByIdResponse.legalRepresentativeId, data: clientiState.rappLegaleEditCreateRequest }))
                                // }
                                if (usersState.findUserByIdResponse.contactId !== undefined) {
                                    dispatch(EditCustomerContacts({ id: usersState.findUserByIdResponse.contactId, data: clientiState.contactsCreateRequest }))
                                }
                                if (clientiState.getCustomersByIdResponse?.userId !== undefined && businessTech.userBusinessTechnicianLink.techId) {
                                    dispatch(linkBusinessTech({ id: clientiState.getCustomersByIdResponse.userId, techId: businessTech.userBusinessTechnicianLink.techId }))
                                }
                            }
                        }}
                    />
                </div>
            }
        >
            <div>
                {
                    (showSuccess || showSuccessRl || showSuccessContacts) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Dati salvati correttamente.
                        </span>
                    </div>

                }
                <Banner
                    type="error"
                    visible={clientiState.customerCreateResponseStatus === 'failed'}
                    label={"Si è verificato un errore durante la creazione dell'utente"}
                    closeAction={function (): void {

                    }}
                />

                <HorizontalTab
                    children={[
                        <>
                            <Tab
                                onClick={() => { dispatch(setSelectedTab(0)) }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 0,
                                        "text-label-sm text-neutral-600": clientiState.selectedTab !== 0
                                    })
                                }
                                tabIndex={"0"}
                            >
                                Anagrafica
                            </Tab>
                            <Tab
                                onClick={() => {
                                    dispatch(setSelectedTab(1))
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 1,
                                        "text-label-sm text-brandSecondary-600": clientiState.selectedTab !== 1
                                    }
                                    )
                                }
                                tabIndex={"1"}
                            >
                                Centri Aziendali
                            </Tab>
                            <Tab
                                onClick={() => {
                                    dispatch(setSelectedTab(2))
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 2,
                                        "text-label-sm text-brandSecondary-600": clientiState.selectedTab !== 2
                                    }
                                    )
                                }
                                tabIndex={"2"}
                            >
                                Rappresentante Legale
                            </Tab>
                            <Tab
                                onClick={() => {
                                    dispatch(setSelectedTab(3))
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 3,
                                        "text-label-sm text-brandSecondary-600": clientiState.selectedTab !== 3
                                    }
                                    )
                                }
                                tabIndex={"3"}
                            >
                                Tecnico Aziendale
                            </Tab>
                            <Tab
                                onClick={() => {
                                    dispatch(setSelectedTab(4))
                                }}
                                className={
                                    clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                        "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": clientiState.selectedTab === 4,
                                        "text-label-sm text-brandSecondary-600": clientiState.selectedTab !== 4
                                    }
                                    )
                                }
                                tabIndex={"4"}
                            >
                                Rubrica
                            </Tab>
                        </>
                    ]}
                />

            </div>
            <div style={{ display: 'flex', height: '100%' }} className="bg-brandPrimary-100">
                <div className="fieldset bg-brandPrimary-200" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                    {
                        clientiState.selectedTab === 0 &&
                        <BoxAnagraficaEdit></BoxAnagraficaEdit>

                    }
                    {
                        clientiState.selectedTab === 1 &&
                        <BoxCentriAziendaliEdit></BoxCentriAziendaliEdit>
                    }
                    {
                        clientiState.selectedTab === 2 &&
                        <BoxRappLegaleEdit></BoxRappLegaleEdit>

                    }
                    {
                        clientiState.selectedTab === 3 &&
                        <BoxTecnicoAziendaleEdit></BoxTecnicoAziendaleEdit>
                    }
                    {
                        clientiState.selectedTab === 4 &&
                        <BoxRubricaEdit></BoxRubricaEdit>
                    }
                </div>

                <div className="bg-brandPrimary-100" style={{ flex: 0.3 }}>
                    <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}></div>
                    <RightBar>
                        <div>
                            <div className="mb-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '6px', padding: '0px 24px' }}>
                                {
                                    clientiState.getCustomersByIdResponse?.norm === 'NORMA_BIO' && (
                                        <LogoBio color={""} size={0} />
                                    )
                                }
                                {
                                    clientiState.getCustomersByIdResponse?.norm === 'PRODUZIONE_INTEGRATA' && (
                                        <LogoSQNPI color={""} size={0} />
                                    )
                                }
                                {
                                    clientiState.getCustomersByIdResponse?.norm === 'GLOBAL_GAP' && (
                                        <GlobalGapIcon />
                                    )
                                }
                            </div>


                            <div className="mt-4 mb-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '6px', padding: '0px 24px' }}>

                                <span className="clientCode text-brandPrimary-500">CODICE: </span>
                                <span className="clientCode text-brandPrimary-700">
                                    {
                                        clientiState.getCustomersByIdResponse?.norm === 'NORMA_BIO' && (
                                            <span>IT-BIO-002-</span>
                                        )
                                    }
                                    {
                                        clientiState.getCustomersByIdResponse?.norm === 'PRODUZIONE_INTEGRATA' && (
                                            <span>PI</span>
                                        )
                                    }
                                    {
                                        clientiState.getCustomersByIdResponse?.norm === 'DETERGENZA' && (
                                            <span>DT</span>
                                        )
                                    }
                                    {
                                        clientiState.getCustomersByIdResponse?.norm === 'GLOBAL_GAP' && (
                                            <span>CDX_GG_</span>
                                        )
                                    }
                                    {
                                        clientiState.getCustomersByIdResponse?.norm === 'QUALITA_SICURA' && (
                                            <span>QS</span>
                                        )
                                    }
                                    {
                                        clientiState.getCustomersByIdResponse?.norm === 'RESIDUO_ZERO' && (
                                            <span>RZ</span>
                                        )
                                    }
                                    {clientiState.getCustomersByIdResponse?.clientCode}</span>
                            </div>
                            <div style={{ padding: '0px 24px' }}>
                                <div className="borderTopSection "></div>
                            </div>

                            <div className="mt-4" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px', padding: '0px 24px' }}>
                                <span className="input-label">Norma di riferimento</span>

                                <SelectCustom
                                    placeholder={'Norma'}
                                    disabled
                                    defaultValue={clientiState.getCustomersByIdResponse?.norm}
                                    value={clientiState.getCustomersByIdResponse?.norm}
                                    options={normativa}

                                />
                            </div>
                            <div>

                                {
                                    clientiState.getCustomersByIdResponse?.norm === 'NORMA_BIO' && (
                                        <div className="mt-6 pt-4 borderTopSection">
                                            <Bio />
                                        </div>
                                    )
                                }
                                {
                                    clientiState.getCustomersByIdResponse?.norm === 'PRODUZIONE_INTEGRATA' && (
                                        <div className="mt-6 pt-4 borderTopSection">
                                            <ProduzioneIntegrata />
                                        </div>
                                    )
                                }
                                {
                                    clientiState.getCustomersByIdResponse?.norm === "QUALITA_SICURA" && (
                                        <div className="mt-6 pt-4 borderTopSection">
                                            <QualitaSicura />
                                        </div>
                                    )
                                }
                                {
                                    clientiState.getCustomersByIdResponse?.norm === "RESIDUO_ZERO" && (
                                        <div className="mt-6 pt-4 borderTopSection">
                                            <ResiduoZero />
                                        </div>
                                    )
                                }
                                {
                                    clientiState.getCustomersByIdResponse?.norm === "GLOBAL_GAP" && (
                                        <div className="mt-6 pt-4 borderTopSection">
                                            <GlobalGap />
                                        </div>
                                    )
                                }
                                <ClientTypology />

                                <div className="borderTopSection mt-6 pt-4">
                                    <AuthorityOffices />
                                </div>
                            </div>
                        </div>
                    </RightBar>
                </div>
            </div >
        </Layout >
    )
}