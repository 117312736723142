import React from "react";
import { IconProps } from "./dto";

export function UtilsSchedaPersonale() {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" >
            <rect x="1" y="1" width="62" height="62" rx="15" fill="#F5FDFF" />
            <rect x="1" y="1" width="62" height="62" rx="15" stroke="#74A4A9" strokeWidth="2" />
            <path d="M38.6667 45H25.3334C19.4534 45 17.6667 43.2133 17.6667 37.3333V26.6667C17.6667 20.7867 19.4534 19 25.3334 19H38.6667C44.5467 19 46.3334 20.7867 46.3334 26.6667V37.3333C46.3334 43.2133 44.5467 45 38.6667 45ZM25.3334 21C20.56 21 19.6667 21.9067 19.6667 26.6667V37.3333C19.6667 42.0933 20.56 43 25.3334 43H38.6667C43.44 43 44.3334 42.0933 44.3334 37.3333V26.6667C44.3334 21.9067 43.44 21 38.6667 21H25.3334Z" fill="#4D797F" />
            <path d="M41.3334 27.666H34.6667C34.12 27.666 33.6667 27.2127 33.6667 26.666C33.6667 26.1193 34.12 25.666 34.6667 25.666H41.3334C41.88 25.666 42.3334 26.1193 42.3334 26.666C42.3334 27.2127 41.88 27.666 41.3334 27.666Z" fill="#4D797F" />
            <path d="M41.3333 33H36C35.4533 33 35 32.5467 35 32C35 31.4533 35.4533 31 36 31H41.3333C41.88 31 42.3333 31.4533 42.3333 32C42.3333 32.5467 41.88 33 41.3333 33Z" fill="#4D797F" />
            <path d="M41.3334 38.334H38.6667C38.12 38.334 37.6667 37.8807 37.6667 37.334C37.6667 36.7873 38.12 36.334 38.6667 36.334H41.3334C41.88 36.334 42.3334 36.7873 42.3334 37.334C42.3334 37.8807 41.88 38.334 41.3334 38.334Z" fill="#4D797F" />
            <path d="M27.3333 32.0532C25.4533 32.0532 23.92 30.5199 23.92 28.6399C23.92 26.7599 25.4533 25.2266 27.3333 25.2266C29.2133 25.2266 30.7466 26.7599 30.7466 28.6399C30.7466 30.5199 29.2133 32.0532 27.3333 32.0532ZM27.3333 27.2266C26.56 27.2266 25.92 27.8666 25.92 28.6399C25.92 29.4132 26.56 30.0532 27.3333 30.0532C28.1066 30.0532 28.7466 29.4132 28.7466 28.6399C28.7466 27.8666 28.1066 27.2266 27.3333 27.2266Z" fill="#4D797F" />
            <path d="M32 38.7724C31.4933 38.7724 31.0533 38.3857 31 37.8657C30.8533 36.4257 29.6933 35.2657 28.24 35.1324C27.6267 35.079 27.0133 35.079 26.4 35.1324C24.9467 35.2657 23.7867 36.4124 23.64 37.8657C23.5867 38.4124 23.0933 38.8257 22.5467 38.759C22 38.7057 21.6 38.2124 21.6533 37.6657C21.8933 35.2657 23.8 33.359 26.2133 33.1457C26.9467 33.079 27.6933 33.079 28.4267 33.1457C30.8267 33.3724 32.7467 35.279 32.9867 37.6657C33.04 38.2124 32.64 38.7057 32.0933 38.759C32.0667 38.7724 32.0267 38.7724 32 38.7724Z" fill="#4D797F" />
        </svg>

    )
}