import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import Button from "../../../../ui/molecules/button"
import TextArea from "../../../../ui/molecules/textArea"
import { skillLevels } from "../../../../utils"
import { SelectCustom } from "../../../../ui/molecules/select"
import Input from "../../../../ui/molecules/input"
import { OtherInfoCreation, OtherInfoValidation, resetErrorLabel, resetOtherInfoCreateForm, resetOtherInfoValidationStatus, setDrivingLicense, setNativeLanguage, setOralExpressionSkill, setOrganizationalSkill, setOtherLanguage, setOtherSkill, setPMId, setPersonalSkill, setReadSkill, setRelationalSkill, setTechnicalSkill, setWriteSkill } from "../slice"
interface Props {
    close: () => void;
}
export default function AddOtherInfo(props: Props) {
    const dispatch = useAppDispatch()

    const otherInfoState = useAppSelector(state => state.PMOtherInfo)

    useEffect(() => {
        if(otherInfoState.OtherInfoValidationStatus === 'successfully') {
            dispatch(resetOtherInfoValidationStatus())
            dispatch(resetErrorLabel())
            dispatch(OtherInfoCreation(otherInfoState.otherInfoCreationRequest))
            props.close();
            dispatch(resetOtherInfoCreateForm())
        }
    }, [otherInfoState.OtherInfoValidationStatus])

    return (
        <>
            <div className="fieldset bg-brandPrimary-100 pr-5 pl-5 pt-4" style={{ flex: 0.7, borderTopRightRadius: '16px' }}>
                {
                    otherInfoState.errorLabel && (
                        <div className="gap-[8px] text-left bg-red-300" style={{ margin: '12px', borderRadius: '8px' }}>
                            <span className="text-red-700 fieldsetTitle" style={{ padding: '15px 12px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                {otherInfoState.errorLabel}
                            </span>
                        </div>
                    )
                }

                <div className="formAnagrafica " style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Patente (Es. A, A1, B, ecc.)'}
                            placeholder="Patente"
                            value={otherInfoState.otherInfoCreationRequest.drivingLicense}
                            defaultValue={otherInfoState.otherInfoCreationRequest.drivingLicense}
                            onChangeText={(text) => { dispatch(setDrivingLicense(text)) }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Madrelingua'}
                            placeholder="Madrelingua"
                            value={otherInfoState.otherInfoCreationRequest.nativeLanguage}
                            defaultValue={otherInfoState.otherInfoCreationRequest.nativeLanguage}
                            onChangeText={(text) => { dispatch(setNativeLanguage(text)) }}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Altre lingue (Francese, Inglese, ecc..)'}
                            value={otherInfoState.otherInfoCreationRequest.otherLanguage}
                            defaultValue={otherInfoState.otherInfoCreationRequest.otherLanguage}
                            placeholder="Specificare altre lingue..."
                            onChangeText={(text) => { dispatch(setOtherLanguage(text)) }}
                        />
                    </div>
                </div>


                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Capacità di lettura</span>
                        </div>
                        <SelectCustom
                            placeholder={'Specificare capacità'}
                            options={skillLevels}
                            value={otherInfoState.otherInfoCreationRequest.readSkill}
                            defaultValue={otherInfoState.otherInfoCreationRequest.readSkill}
                            onChange={(value) => {
                                dispatch(setReadSkill(value))
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Capacità di scrittura</span>
                        </div>
                        <SelectCustom
                            placeholder={'Specificare capacità'}
                            options={skillLevels}
                            value={otherInfoState.otherInfoCreationRequest.writeSkill}
                            defaultValue={otherInfoState.otherInfoCreationRequest.writeSkill}
                            onChange={(value) => {
                                dispatch(setWriteSkill(value))
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Capacità di espressione orale</span>
                        </div>
                        <SelectCustom
                            placeholder={'Specificare di espressione orale'}
                            options={skillLevels}
                            value={otherInfoState.otherInfoCreationRequest.oralExpressionSkill}
                            defaultValue={otherInfoState.otherInfoCreationRequest.oralExpressionSkill}
                            onChange={(value) => {
                                dispatch(setOralExpressionSkill(value))
                            }}
                        />
                    </div>
                </div>

                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: 0.5 }}>
                        <div className="text-left">
                            <span className="input-label">Capacità e competenze personali</span>
                        </div>
                        <TextArea
                            placeholder="Specificare capacità..."
                            value={otherInfoState.otherInfoCreationRequest.personalSkill}
                            defaultValue={otherInfoState.otherInfoCreationRequest.personalSkill}
                            onChangeText={(text) => { dispatch(setPersonalSkill(text)) }}
                        />
                    </div>

                    <div style={{ flex: 0.5 }}>
                        <div className="text-left">
                            <span className="input-label">Capacità e competenze relazionali</span>
                        </div>
                        <TextArea
                            placeholder="Specificare capacità..."
                            value={otherInfoState.otherInfoCreationRequest.relationalSkill}
                            defaultValue={otherInfoState.otherInfoCreationRequest.relationalSkill}
                            onChangeText={(text) => { dispatch(setRelationalSkill(text)) }}
                        />
                    </div>

                </div>

                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: 0.5 }}>
                        <div className="text-left">
                            <span className="input-label">Capacità e competenze organizzative</span>
                        </div>
                        <TextArea
                            placeholder="Specificare capacità..."
                            value={otherInfoState.otherInfoCreationRequest.organizationalSkill}
                            defaultValue={otherInfoState.otherInfoCreationRequest.organizationalSkill}
                            onChangeText={(text) => { dispatch(setOrganizationalSkill(text)) }}
                        />
                    </div>

                    <div style={{ flex: 0.5 }}>
                        <div className="text-left">
                            <span className="input-label">Capacità e competenze tecniche</span>
                        </div>
                        <TextArea
                            placeholder="Specificare capacità..."
                            value={otherInfoState.otherInfoCreationRequest.technicalSkill}
                            defaultValue={otherInfoState.otherInfoCreationRequest.technicalSkill}
                            onChangeText={(text) => { dispatch(setTechnicalSkill(text)) }}
                        />
                    </div>

                </div>

                <div className="formAnagrafica pt-5" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                    <div style={{ flex: 1 }}>
                        <div className="text-left">
                            <span className="input-label">Altre capacità e competenze</span>
                        </div>
                        <TextArea
                            placeholder="Specificare capacità..."
                            value={otherInfoState.otherInfoCreationRequest.otherSkill}
                            defaultValue={otherInfoState.otherInfoCreationRequest.otherSkill}
                            onChangeText={(text) => { dispatch(setOtherSkill(text)) }}
                        />
                    </div>

                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                <Button
                    size="md"
                    variant="outline"
                    iconPosition="off"
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        props.close();
                    }}
                />
                <div className="pl-2">
                    <Button
                        size="md"
                        variant="solid"
                        iconPosition="off"
                        label="Salva"
                        color="orange"
                        onClick={() => {
                            dispatch(OtherInfoValidation(otherInfoState.otherInfoCreationRequest))
                        }}
                    />
                </div>
            </div>
        </>
    )
}