import axios from "axios";
import { GetAllFiltersPersonalManagmentDTO, GetAllPersonalManagementDTO, PersonalManagementDTO, PersonalManagementUpdateDTO } from "./dto";
import { PersonalManagementService } from "./service";
import { keycloak } from "../../../lib/keycloak";

export class PersonalManagementServiceImpl implements PersonalManagementService {

    public GetAllPersonalManagementResources(filters: GetAllFiltersPersonalManagmentDTO): Promise<GetAllPersonalManagementDTO> {
        return axios.get("/api/personal-management", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page,
                norm: filters.norm,
                order: filters.order,
                region: filters.region,
                surname: filters.surname,
                function: filters.function,
                sort: filters.sort,
                active: filters.active
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public DownloadSPrev3(id: string): Promise<void> {
        return axios.get(`/api/download/sp-rev-03/${id}`, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            },
            responseType: 'text',
        }).then((response) => {
            const base64String = response.data;

            // Decode Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create blob with binary
            const blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            // tmp url
            const url = URL.createObjectURL(blob);

            //link trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'CSX SP rev3 .docx');
            document.body.appendChild(link);
            link.click();

            // remove link
            document.body.removeChild(link);

            // empty memory
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }

    public DownloadCV(id: string): Promise<void> {
        return axios.get(`/api/download/curriculum/${id}`, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            },
            responseType: 'arraybuffer',
        }).then((response) => {
            const blob = new Blob([response.data], {
                type: 'application/vnd.oasis.opendocument.text', // MIME per .docx
            });

            console.log(blob);
            

            // Crea un URL temporaneo per scaricare il file
            const url = URL.createObjectURL(blob);

            // Crea un link invisibile per scaricare il file
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'document.odt'); // Forza l'estensione .docx
            document.body.appendChild(link);
            link.click();

            // Rimuovi il link dopo il download
            document.body.removeChild(link);

            // Rilascia la memoria
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }

    public GetByIdPersonalManagementResources(id: string): Promise<PersonalManagementDTO> {
        return axios.get("/api/personal-management/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public CreatePersonalManagementResource(): Promise<string> {
        return axios.post("/api/personal-management",
            {},
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdatePersonalManagementResource(data: PersonalManagementUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/personal-management/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }
}