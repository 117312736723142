import axios from "axios";
import { AnagraphicService } from "./service";
import { keycloak } from "../../../lib/keycloak";
import { AnagraphicByIdDTO, AnagraphicCreationDTO } from "./dto";


export class AnagraphicServiceImpl implements AnagraphicService {

    public CreateAnagraphic(data: AnagraphicCreationDTO): Promise<void> {
        return axios.post("/api/pm-anagraphic",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public AnagraphicEdit(data: AnagraphicCreationDTO, id: string): Promise<void> {
        return axios.put("/api/pm-anagraphic/"+id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetByIdAnagraphic(id: string): Promise<AnagraphicByIdDTO> {
        return axios.get("/api/pm-anagraphic/"+id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }


}