import { Fragment } from "react/jsx-runtime";
import Button from "../../../../../ui/molecules/button";
import { PlusOrange } from "../../../../../ui/icons/plusOrange";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { addTrainingDefaultInternalTeacher, addTrainingDefaultInternalTeacherFunction, addUpdateTrainingDefaultInternalTeacher, addUpdateTrainingDefaultInternalTeacherFunction, setFunctions, setTrainingInternalTeacher, setTrainingInternalTeacherByIndex, setTrainingInternalTeacherFunction, setTrainingInternalTeacherFunctionByIndex, setUpdateTrainingInternalTeacher, setUpdateTrainingInternalTeacherByIndex, setUpdateTrainingInternalTeacherFunction, setUpdateTrainingInternalTeacherFunctionByIndex } from "../../slice";
import { SelectCustom } from "../../../../../ui/molecules/select";
import { optionsFormatter, OptionType, rolesOptions, trainingActivityOptions } from "../../../../../utils";
import { TrashIcon } from "../../../../../ui/icons/trash";
import { useEffect, useState } from "react";
import { GetAllFunctions, GetAllFunctionsByPMId, resetGetAllFunctionsByPMIdStatus, resetGetAllFunctionsStatus } from "../../../boxFunzioni/slice";

export function InternalTeachersAdd() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const functionsState = useAppSelector(state => state.PMFunctions)

    useEffect(() => {
        dispatch(GetAllFunctions())
    }, [])

    useEffect(() => {
        if(functionsState.GetAllFunctionsStatus === 'successfully' &&
            functionsState.GetAllFunctionsResponse !== undefined
        ) {
            dispatch(resetGetAllFunctionsStatus())
            dispatch(setFunctions(functionsState.GetAllFunctionsResponse))
        }
    }, [functionsState.GetAllFunctionsStatus])

    const handleFuncOptions = (PMid: string): OptionType[] => {
        return trainingState.functions.filter(f => f.personalManagementId === PMid).map(ff => {
            return {value: ff.id, label: optionsFormatter(ff.assignedFunction,rolesOptions)} as OptionType
        })
    }

    return (
        <div className="pb-5" style={{borderBottomWidth: 1}}>
            <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div className="text-left" style={{flex: '0.5'}}>
                    <span className="subtitle">Docenti interni</span>
                </div>
                <div style={{flex: '0.5', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button 
                        size='sm' 
                        color='orange' 
                        variant='outline'
                        label='Aggiungi docente interno' 
                        iconPosition='right' 
                        onClick={() => {
                            dispatch(addTrainingDefaultInternalTeacher())
                            dispatch(addTrainingDefaultInternalTeacherFunction())
                        }}
                        icon={<PlusOrange color='orange' size={20}/>} />
                </div>
            </div>
            {
                trainingState.trainingCreationRequest.internalTeacher.map((t,index) => (
                    <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{ flex: '0.475' }}>
                            <div className="text-left">
                                <span className="input-label">Nome e cognome</span>
                            </div>
                            <SelectCustom
                                // error={}
                                errorLabel="Nessun docente specificato"
                                placeholder={'Seleziona il docente interno'}
                                options={trainingState.PMResources}
                                onChange={(value) => {
                                    dispatch(setTrainingInternalTeacherByIndex({data: value, index: index}))
                                }}
                                defaultValue={trainingState.trainingCreationRequest.internalTeacher[index]}
                                value={trainingState.trainingCreationRequest.internalTeacher[index]}
                                />
                        </div>
                        <div style={{ flex: '0.475' }}>
                            <div className="text-left">
                                <span className="input-label">Funzione</span>
                            </div>
                            <SelectCustom
                                error={trainingState.trainingErrors.internalTeacherFunction}
                                errorLabel="Nessuna funzione specificata"
                                placeholder={'Funzione del docente interno'}
                                options={handleFuncOptions(t) || []}
                                onChange={(value) => dispatch(setTrainingInternalTeacherFunctionByIndex({data: value, index: index}))}
                                defaultValue={trainingState.trainingCreationRequest.internalTeacherFunction[index]}
                                value={trainingState.trainingCreationRequest.internalTeacherFunction[index]}
                                />
                        </div>
                        <div style={{ flex: '0.05', display: 'flex', alignSelf: 'flex-end', paddingBottom: 5}}>
                            <Button
                                size='md'
                                iconPosition='only'
                                icon={<TrashIcon color='#f00' size={20} />} 
                                variant='outline'
                                color='orange'
                                onClick={() => {
                                    const newTeachers = trainingState.trainingCreationRequest.internalTeacher.filter((t,idx) => idx !== index)
                                    const newFuncTeachers = trainingState.trainingCreationRequest.internalTeacherFunction.filter((t,idx) => idx !== index)
                                    dispatch(setTrainingInternalTeacher(newTeachers))
                                    dispatch(setTrainingInternalTeacherFunction(newFuncTeachers))
                                }}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export function InternalTeachersEdit() {
    const dispatch = useAppDispatch()
    const trainingState = useAppSelector(state => state.PMTraining)
    const functionsState = useAppSelector(state => state.PMFunctions)

    useEffect(() => {
        dispatch(GetAllFunctions())
    }, [])

    useEffect(() => {
        if(functionsState.GetAllFunctionsStatus === 'successfully' &&
            functionsState.GetAllFunctionsResponse !== undefined
        ) {
            dispatch(resetGetAllFunctionsStatus())
            dispatch(setFunctions(functionsState.GetAllFunctionsResponse))
        }
    }, [functionsState.GetAllFunctionsStatus])

    const handleFuncOptions = (PMid: string): OptionType[] => {
        return trainingState.functions.filter(f => f.personalManagementId === PMid).map(ff => {
            return {value: ff.id, label: optionsFormatter(ff.assignedFunction,rolesOptions)} as OptionType
        })
    }

    return (
        <div className="pb-5" style={{borderBottomWidth: 1}}>
            <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div className="text-left" style={{flex: '0.5'}}>
                    <span className="subtitle">Docenti interni</span>
                </div>
                <div style={{flex: '0.5', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button 
                        size='sm' 
                        color='orange' 
                        variant='outline'
                        label='Aggiungi docente interno' 
                        iconPosition='right' 
                        onClick={() => {
                            dispatch(addUpdateTrainingDefaultInternalTeacher())
                            dispatch(addUpdateTrainingDefaultInternalTeacherFunction())
                        }}
                        icon={<PlusOrange color='orange' size={20}/>} />
                </div>
            </div>
            {
                trainingState.trainingUpdateRequest.internalTeacher.map((t,index) => (
                    <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{ flex: '0.475' }}>
                            <div className="text-left">
                                <span className="input-label">Nome e cognome</span>
                            </div>
                            <SelectCustom
                                // error={}
                                errorLabel="Nessun docente specificato"
                                placeholder={'Seleziona il docente interno'}
                                options={trainingState.PMResources}
                                onChange={(value) => {
                                    dispatch(setUpdateTrainingInternalTeacherByIndex({data: value, index: index}))
                                }}
                                defaultValue={trainingState.trainingUpdateRequest.internalTeacher[index]}
                                value={trainingState.trainingUpdateRequest.internalTeacher[index]}
                                />
                        </div>
                        <div style={{ flex: '0.475' }}>
                            <div className="text-left">
                                <span className="input-label">Funzione</span>
                            </div>
                            <SelectCustom
                                // error={}
                                errorLabel="Nessuna funzione specificata"
                                placeholder={'Funzione del docente interno'}
                                options={handleFuncOptions(t) || []}
                                onChange={(value) => dispatch(setUpdateTrainingInternalTeacherFunctionByIndex({data: value, index: index}))}
                                defaultValue={trainingState.trainingUpdateRequest.internalTeacherFunction[index]}
                                value={trainingState.trainingUpdateRequest.internalTeacherFunction[index]}
                                />
                        </div>
                        <div style={{ flex: '0.05', display: 'flex', alignSelf: 'flex-end', paddingBottom: 5}}>
                            <Button
                                size='md'
                                iconPosition='only'
                                icon={<TrashIcon color='#f00' size={20} />} 
                                variant='outline'
                                color='orange'
                                onClick={() => {
                                    const newTeachers = trainingState.trainingUpdateRequest.internalTeacher?.filter((t,idx) => idx !== index)
                                    const newFuncTeachers = trainingState.trainingUpdateRequest.internalTeacherFunction?.filter((t,idx) => idx !== index)
                                    dispatch(setUpdateTrainingInternalTeacher(newTeachers))
                                    dispatch(setUpdateTrainingInternalTeacherFunction(newFuncTeachers))
                                }}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}