import { IconProps } from "./dto";

export function CloseIcon() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M17 1L1 17M1 1L17 17" stroke="#8383AD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}
