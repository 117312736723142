import { useEffect, useState } from "react";
import Button from "../../../../ui/molecules/button";
import { Calendar } from "../../../../ui/molecules/calendar";
import Input from "../../../../ui/molecules/input";
import { OptionType } from "../../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { GetAllEntryProtocolAttachments, GetAllEntryProtocolObjects, resetGetAllEntryProtocolAttachmentsStatus, resetGetAllEntryProtocolObjectsStatus } from "../../../protocol/entry/slice";
import { SelectCustom } from "../../../../ui/molecules/select";
import TextArea from "../../../../ui/molecules/textArea";
import { FileInputComponent } from "../../../../ui/molecules/fileInput";
import { DocumentCreation, DocumentValidation, GetAllDocuments, resetDocumentCreationRequest, resetDocumentCreationStatus, resetDocumentValidationStatus, setDocumentCreationCustomerDataId, setDocumentCreationDate, setDocumentCreationDetail, setDocumentCreationEntryAttachmentId, setDocumentCreationEntryObjectId, setDocumentCreationNote, setDocumentCreationUserId, setFileToUpload } from "../slice";
import { FileViewer } from "../../../../ui/molecules/fileViewer";
import { TrashIcon } from "../../../../ui/icons/trash";

export function UploadedDocumentRightBarAdd() {
    const dispatch = useAppDispatch()
    const userState = useAppSelector(state => state.users)
    const documentState = useAppSelector(state => state.document)
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const [objects, setObjects] = useState<OptionType[]>([])
    const [attachments, setAttachments] = useState<OptionType[]>([])

    let formData = new FormData()

    useEffect(() => {
        dispatch(setDocumentCreationUserId(userState.loggedUserId))
        dispatch(GetAllEntryProtocolObjects())
        dispatch(GetAllEntryProtocolAttachments())
    }, [])

    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolObjectsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolObjectsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolObjectsStatus())
            const data = entryProtocolState.getAllEntryProtocolObjectsResponse
            const newObjects: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setObjects(newObjects)
        }
    }, [entryProtocolState.getAllEntryProtocolObjectsStatus])

    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolAttachmentsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolAttachmentsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolAttachmentsStatus())
            const data = entryProtocolState.getAllEntryProtocolAttachmentsResponse
            const newAtts: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setAttachments(newAtts)
        }
    }, [entryProtocolState.getAllEntryProtocolAttachmentsStatus])

    useEffect(() => {
        if(documentState.documentValidationStatus === 'successfully') {
            dispatch(resetDocumentValidationStatus())
            formData.delete('file')
            formData.append('documentCreationDTO', JSON.stringify(documentState.documentCreationRequest))
            formData.append('file', documentState.fileToUpload!)
            dispatch(DocumentCreation(formData))
        }
    }, [documentState.documentValidationStatus])

    useEffect(() => {
        if(documentState.documentCreationStatus === 'successfully') {
            dispatch(resetDocumentCreationRequest())
            dispatch(resetDocumentCreationStatus())
            dispatch(setFileToUpload(undefined))
            dispatch(GetAllDocuments(documentState.getAllDocumentsFilters))
        }
    }, [documentState.documentCreationStatus])

    return (
        <div style={{ padding: '24px'}}>
            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button 
                    color="orange"
                    variant="solid"
                    iconPosition="off"
                    size="md"
                    label="Aggiungi documento"
                    onClick={() => dispatch(DocumentValidation({
                                body: documentState.documentCreationRequest,
                                file: documentState.fileToUpload!
                            }))}
                    />
            </div>

            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div style={{flex: '0.5'}}>
                    <Input
                        label={'Dettaglio'}
                        placeholder="Specifica dettaglio"
                        supportingText={documentState.documentErrors.detail ? "Inserire la ragione sociale." : undefined} 
                        error={documentState.documentErrors.detail}
                        value={documentState.documentCreationRequest.detail}
                        defaultValue={documentState.documentCreationRequest.detail}
                        onChangeText={(text) => dispatch(setDocumentCreationDetail(text))}
                    />
                </div>
                <div style={{flex: '0.5'}}>
                    <div className="text-left">
                        <span className="input-label">Data</span>
                    </div>
                    <Calendar
                        selected={documentState.documentCreationRequest.date}
                        onChange={(date) => {
                            setDocumentCreationDate(date)
                        }}
                    />
                </div>
            </div>

            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                <div style={{ flex: '0.5' }}>
                    <div className="text-left">
                        <span className="input-label">Oggetto</span>
                    </div>
                    <SelectCustom
                        value={documentState.documentCreationRequest.entryObjectId}
                        placeholder={'Seleziona...'}
                        options={objects}
                        onChange={(value) => dispatch(setDocumentCreationEntryObjectId(value))}
                    />
                </div>
                <div style={{ flex: '0.5' }}>
                    <div className="text-left">
                        <span className="input-label">Allegato</span>
                    </div>
                    <SelectCustom
                        value={documentState.documentCreationRequest.entryAttachmentId}
                        placeholder={'Seleziona...'}
                        options={attachments}
                        onChange={(value) => dispatch(setDocumentCreationEntryAttachmentId(value))}
                    />
                </div>
            </div>

            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1}}>
                <div style={{ flex: '1' }}>
                    <div className="text-left">
                        <span className="input-label">Note</span>
                    </div>
                    <TextArea
                        onChangeText={(value) => dispatch(setDocumentCreationNote(value))} />
                </div>
            </div>

            <div className="text-left pb-3 pt-5">
                <span className="input-label" style={{ fontSize: '16px', fontWeight: 600 }}>
                    Allega file
                </span>
            </div>

            <div className="pb-5">
                <FileInputComponent
                    id={"ep-edit-upload-add"}
                    key={"ep-edit-upload-add"}
                    infoText
                    fullHeight
                    color={"green"}
                    onChange={(e) => {
                        dispatch(setFileToUpload(e[0]))
                    }}
                />
                {
                    documentState.fileToUpload && (
                        <div className="pt-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: '0.8' }}>
                                <FileViewer
                                    toUpdate
                                    fileName={documentState.fileToUpload.name}
                                    otherInfo={documentState.fileToUpload.type}
                                />
                            </div>
                            <button style={{ flex: '0.2', display: 'flex', justifyContent: 'center' }} onClick={() => dispatch(setFileToUpload(undefined))}>
                                <TrashIcon size={20} color="dimgrey" />
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}