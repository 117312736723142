import { useEffect, useReducer, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import Button from "../../../ui/molecules/button";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { addCDXM026Instance, addCDXM026TableInstance, CDXM026Creation, CDXM026Delete, GetAllCDXM026, GetCDXM026ById, resetCDXM026CreationRequest, resetCDXM026CreationStatus, resetCDXM026DeleteStatus, resetCDXM026Errors, resetCDXM026Instances, resetCDXM026TableInstances, resetCDXM026UpdateRequest, resetGetAllCDXM026Status, resetGetCDXM026ByIdStatus, setCDXClient, setCDXClientRelationshipType, setCDXCodexClientTemp, setCDXEndingDate, setCDXHeadquarter, setCDXIdToUpdate, setCDXM026Instances, setCDXNote, setCDXNumber, setCDXOperator, setCDXOperatorRelationshipType, setCDXStartingDate, setCDXSupervisory, setCDXToUpdateCompanyName, setCDXYear, setPMId, setUpdateCDXActive, setUpdateCDXClient, setUpdateCDXClientRelationshipType, setUpdateCDXEndingDate, setUpdateCDXNote, setUpdateCDXOperator, setUpdateCDXOperatorRelationshipType, setUpdateCDXStartingDate, setUpdateCDXSupervisory } from "./slice";
import { PopupForm } from "../../../ui/organisms/popupForm";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import { formatterDate, headquartersOptions, odc, optionsFormatter, OptionType, yearOptions } from "../../../utils";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../../../ui/icons/calendar";
import { format } from "date-fns";
import TextArea from "../../../ui/molecules/textArea";
import { CDXM026PopupAdd } from "./boxPopupOptions/add";
import { NewClientiService } from "../../clienti/service";
import { GetCustomerByCompanyNameResponseDTO } from "../../clienti/dto";
import { GetCustomersByCompanyName, GetCustomersById, resetCustomerByIdStatus } from "../../clienti/slice";
import { v4 } from "uuid";
import { CDXM026CodexClientTemp, CDXM026DTO, CDXM026TableInstance } from "./dataService/dto";
import { CDXM026PopupEdit } from "./boxPopupOptions/edit";
import { GetByIdPersonalManagement, resetGetByIdPersonalManagementResourcesStatus } from "../slice";

export function BoxCDXM026() {
    const dispatch = useAppDispatch()
    const CDXM026State = useAppSelector(state => state.PMCDXM026)
    const clientiState = useAppSelector(state => state.clienti)
    const PMState = useAppSelector(state => state.personalManagement)
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const [openPopupEdit, setOpenPopupEdit] = useState<boolean>(false)

    const onEditHandler = (CDX: CDXM026DTO, companyName: string) => {
        if(CDX.client !== null && CDX.clientRelationshipType !== null) {
            dispatch(setCDXToUpdateCompanyName(companyName))
            dispatch(setUpdateCDXClient(CDX.client!))
            dispatch(setUpdateCDXClientRelationshipType(CDX.clientRelationshipType))
        }
        else if(CDX.operator !== null && 
            CDX.operatorRelationshipType !== null &&
            CDX.odc !== null
        ) {
            dispatch(setUpdateCDXOperator(CDX.operator))
            dispatch(setUpdateCDXOperatorRelationshipType(CDX.operatorRelationshipType))
            dispatch(setUpdateCDXSupervisory(CDX.odc))
        }
        dispatch(setUpdateCDXActive(CDX.active))
        dispatch(setUpdateCDXEndingDate(CDX.ending))
        dispatch(setUpdateCDXNote(CDX.note))
        dispatch(setUpdateCDXStartingDate(CDX.starting))
        dispatch(setCDXIdToUpdate(CDX.id))
        setOpenPopupEdit(true)
    }

    const handleOperatorCDX = (CDX: CDXM026DTO) => {
        const tableInstance: CDXM026TableInstance = {
            id: CDX.id,
            clientName: CDX.operator,
            relationshipType: CDX.operatorRelationshipType!,
            startingDate: CDX.starting,
            endingDate: CDX.ending,
            m026protocol: CDX.protocolIdEntry,
            note: CDX.note,
            odc: CDX.odc,
            data: CDX
        }
        dispatch(addCDXM026TableInstance(tableInstance))
    }

    const handleCodexClientCDX = (CDX: CDXM026DTO) => {
        const tempCodexClient: CDXM026CodexClientTemp = {
            id: CDX.id,
            relationshipType: CDX.clientRelationshipType!,
            startingDate: CDX.starting,
            endingDate: CDX.ending,
            m026protocol: CDX.protocolIdEntry,
            note: CDX.note,
            data: CDX
        }
        dispatch(setCDXCodexClientTemp(tempCodexClient))
    }

    useEffect(() => {
        if(PMState.currentPersonalManagementId) {
            dispatch(setPMId(PMState.currentPersonalManagementId))
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [PMState.currentPersonalManagementId])

    useEffect(() => {
        dispatch(resetCDXM026Instances());
        dispatch(resetCDXM026TableInstances());
        if (PMState.getByIdPersonalManagementResourcesStatus === 'successfully' &&
            PMState.getByIdPersonalManagementResourcesResponse?.m026Ids) {
            dispatch(resetGetByIdPersonalManagementResourcesStatus('idle'))
            for (let index = 0; index < PMState.getByIdPersonalManagementResourcesResponse?.m026Ids.length; index++) {
                dispatch(GetCDXM026ById(PMState.getByIdPersonalManagementResourcesResponse?.m026Ids[index].toString()))
            }
        }
    }, [PMState.getByIdPersonalManagementResourcesStatus])

    useEffect(() => {
        if(CDXM026State.getCDXM026ByIdStatus === 'successfully' &&
            CDXM026State.getCDXM026ByIdResponse !== undefined
        ) {
            const data = CDXM026State.getCDXM026ByIdResponse
            dispatch(resetGetCDXM026ByIdStatus())
            if(data.operator !== null &&
                data.operatorRelationshipType !== null &&
                data.odc !== null
            ) {
                handleOperatorCDX(data)
            } else if(
                data.client !== null &&
                data.clientRelationshipType !== null
            ) {
                
                handleCodexClientCDX(data)
                dispatch(GetCustomersById(data.client!))
            }
        }
    }, [CDXM026State.getCDXM026ByIdStatus])

    useEffect(() => {
        if(clientiState.getCustomersByIdResponseStatus === 'successfully' &&
            clientiState.getCustomersByIdResponse !== undefined
        ) {
            
            dispatch(resetCustomerByIdStatus('idle'))
            const tempData = CDXM026State.CDXCodexClientTemp
            const clientData = clientiState.getCustomersByIdResponse
            const tableInstance: CDXM026TableInstance = {
                id: tempData?.id,
                clientName: clientData.companyName,
                region: clientData.countryRegion,
                clientCode: clientData.clientCode,
                entryDate: clientData.startingDate,
                exitDate: clientData.endingDate,
                relationshipType: tempData?.relationshipType!,
                startingDate: tempData?.startingDate!,
                endingDate: tempData?.endingDate!,
                m026protocol: tempData?.m026protocol!,
                note: tempData?.note!,
                data: tempData?.data
            }
            dispatch(addCDXM026TableInstance(tableInstance))
            dispatch(setCDXCodexClientTemp(undefined))
        }
    }, [clientiState.getCustomersByIdResponseStatus])

    useEffect(() => {
        if(CDXM026State.CDXM026DeleteStatus === 'successfully') {
            dispatch(resetCDXM026DeleteStatus());
            dispatch(GetByIdPersonalManagement(PMState.currentPersonalManagementId!))
        }
    }, [CDXM026State.CDXM026DeleteStatus])

    return (
        <div style={{padding: '24px'}}>
            <div>
                <div style={{display: 'flex', justifyContent: 'space-between', paddingBottom: '10px'}}>
                    <span id="title-eval-tab">CDX M026</span>
                    <Button 
                        size="md" 
                        iconPosition="off" 
                        variant="outline" 
                        color="orange" 
                        label="Aggiungi CDX M026" 
                        onClick={() => setOpenPopup(true)}
                    />
                </div>

                <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px'}}>CLIENTE</th>
                            <th>REGIONE</th>
                            <th>COD. OP.</th>
                            <th>DATA INGRESSO</th>
                            <th>DATA USCITA</th>
                            <th>TIPOLOGIA RAPPORTO</th>
                            <th>DATA INIZIO</th>
                            <th>DATA FINE</th>
                            <th>PROTOCOLLO M026</th>
                            <th>NOTE</th>
                            <th>ODC</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {CDXM026State.CDXTableInstances.map(CDX =>
                            <tr
                            className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                            key={CDX.id}
                            >
                                <td className="text-neutral-700">
                                    {CDX.clientName}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.region}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.clientCode}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(CDX.entryDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(CDX.exitDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.relationshipType}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(CDX.startingDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(CDX.endingDate!)}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.m026protocol}
                                </td>
                                <td className="text-neutral-700">
                                    {CDX.note}
                                </td>
                                <td className="text-neutral-700">
                                    {optionsFormatter(CDX.odc!, odc)}
                                </td>
                                
                                <td className="w-[30px]">
                                    <div className="flex justify-content-between align-items-center">

                                        <button onClick={() => {
                                            onEditHandler(CDX.data!, CDX.clientName!)
                                        }}>
                                            <EditIcon color={"Orange"} size={18} />
                                        </button>

                                        <div className="pl-5 flex align-items-center">
                                            <button onClick={() => dispatch(CDXM026Delete(CDX.id!))}>
                                                <TrashIcon color={"Orange"} size={0} />
                                            </button>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {
                openPopup && (
                    <PopupForm title="Aggiungi CDX M026" close={() => {
                        dispatch(resetCDXM026CreationRequest())
                        dispatch(resetCDXM026Errors())
                        setOpenPopup(false)
                        }}>
                        <CDXM026PopupAdd close={() => setOpenPopup(false)} />
                    </PopupForm>
                )
            }
            {
                openPopupEdit && (
                    <PopupForm title="Modifica CDX M026" close={() => {
                        dispatch(resetCDXM026UpdateRequest());
                        dispatch(resetCDXM026Errors())
                        setOpenPopupEdit(false)
                        }}>
                        <CDXM026PopupEdit close={() => setOpenPopupEdit(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}