import { IconProps } from "./dto";

export function PlusIconAccordion() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M9 3.75V14.25M3.75 9H14.25" stroke="#ffffff" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}