import { DepartureProtocolAttachmentDTO, DepartureProtocolCreationUpdateDTO, DepartureProtocolDTO, DepartureProtocolObjectDTO, GetAllDepartureProtocolsDTO, GetAllDepartureProtocolsFiltersDTO, ReportRequestExcelDTO } from "./dto";
import { DepartureProtocolServiceImpl } from "./serviceImpl";

export interface DepartureProtocolService {
    DepartureProtocolCreation(data: DepartureProtocolCreationUpdateDTO): Promise<string>
    UpdateDepartureProtocol(data: FormData, id: string): Promise<string>
    DeleteDepartureProtocol(id: string): Promise<void>
    GetAllDepartureProtocols(filters: GetAllDepartureProtocolsFiltersDTO): Promise<GetAllDepartureProtocolsDTO>
    GetDepartureProtocolById(id: string): Promise<DepartureProtocolDTO>

    UploadAttachmentFile(data: FormData, id: string): Promise<void>

    GetAllDepartureProtocolObjects(): Promise<DepartureProtocolObjectDTO[]>
    GetDepartureProtocolObjectById(id: string): Promise<DepartureProtocolObjectDTO>
    GetAllDepartureProtocolAttachments(): Promise<DepartureProtocolAttachmentDTO[]>
    GetDepartureProtocolAttachmentById(id: string): Promise<DepartureProtocolAttachmentDTO>
    GetProtDepartureExcel(filters: ReportRequestExcelDTO): Promise<any>

    CreateDepartureProtocolAttachment(data: DepartureProtocolAttachmentDTO): Promise<void>
    UpdateDepartureProtocolAttachment(data: DepartureProtocolAttachmentDTO, id: string): Promise<void>
    DeleteDepartureProtocolAttachment(id: string): Promise<void>

    RemoveFileFromProtocol(protocolId: string): Promise<void>
    
}

export function NewDepartureProtocolService(): DepartureProtocolService {
    return new DepartureProtocolServiceImpl();
}