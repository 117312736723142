import { useEffect, useState } from "react"
import { PlusIcon } from "../../../../ui/icons/plus"
import Button from "../../../../ui/molecules/button"
import { FileInputComponent } from "../../../../ui/molecules/fileInput"
import { SelectCustom } from "../../../../ui/molecules/select"
import { OptionType, PPAttachmentGroupOptions } from "../../../../utils"
import { PopupForm } from "../../../../ui/organisms/popupForm"
import { AddAttachmentPopupEntry } from "../../popup/addAttachmentEntry"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { DepartureProtocolRemoveFile, GetAllDepartureProtocolAttachments, addDefaultUpdatePPDepartureAttachmentIds, addPPFileAndAtt, addUpdatePPDepartureAttachmentId, resetDepartureProtocolRemoveFileStatus, resetGetAllDepartureProtocolAttachmentsStatus, resetPPAttCreationRequest, setFileToUpdate, setPPFileAndAtt, setUpdatePPDepartureAttachmentIds, setUpdatePPDepartureAttachmentIdsByIndex, setUpdatedFile } from "../slice"
import { TrashIcon } from "../../../../ui/icons/trash"
import { FileViewer } from "../../../../ui/molecules/fileViewer"
import { DepartureProtocolFileAndAttachmentRequestDTO } from "../dataService/dto"
import { AddAttachmentPopupDeparture } from "../../popup/addAttachmentDeparture"

export function EditDepartureRight() {
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    const userState = useAppSelector(state => state.users)
    const objectStorageState = useAppSelector(state => state.objectStorage)
    const [attachments, setAttachments] = useState<OptionType[]>([])
    const [groupValues, setGroupValues] = useState<string | null>(null)

    const documentUploadPredicate = (index: number, attId: string): boolean => {
        return departureProtocolState.departureProtocolUpdateFileAndAtt
                .find(obj => obj.attachmentId === attId) !== undefined ||
                attId === '' ||
                departureProtocolState.updatedDepartureProtocolFileAndAtts?.find(obj => obj.attachmentId === attId) !== undefined
    }

    
    const NoAvailableAttachments = () => {
        return attachments.filter(att => departureProtocolState.departureProtocolUpdateRequest.departureAttachmentIds.includes(att.value))
    }

    useEffect(() => {
        dispatch(GetAllDepartureProtocolAttachments())
    }, [])

    useEffect(() => {
        if (departureProtocolState.getAllDepartureProtocolAttachmentsStatus === 'successfully' &&
            departureProtocolState.getAllDepartureProtocolAttachmentsResponse !== undefined
        ) {
            dispatch(resetGetAllDepartureProtocolAttachmentsStatus())
            const data = departureProtocolState.getAllDepartureProtocolAttachmentsResponse
            const newAtts: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setAttachments(newAtts)
        }
    }, [departureProtocolState.getAllDepartureProtocolAttachmentsStatus])

    useEffect(() => {
        if (departureProtocolState.departureProtocolRemoveFileStatus === 'successfully') {
            dispatch(resetDepartureProtocolRemoveFileStatus())
            dispatch(setUpdatedFile(undefined))
        }
    }, [departureProtocolState.departureProtocolRemoveFileStatus])

    const handleOnDelete = (attId: string) => {
        const newAtts = departureProtocolState.departureProtocolUpdateRequest.departureAttachmentIds.filter(a => a !== attId)
        const newFilesAtts = departureProtocolState.departureProtocolUpdateFileAndAtt.filter(obj => obj.attachmentId !== attId)
        dispatch(setUpdatePPDepartureAttachmentIds(newAtts))
        dispatch(setPPFileAndAtt(newFilesAtts))
    }

    const handleOnGroupSelect = (value: string | null) => {
        if (value === null)
            return
        const Ids: string[] = value?.split('-')!
        for (const id of Ids) {
            if (id !== '' && !departureProtocolState.departureProtocolUpdateRequest.departureAttachmentIds.includes(id))
                dispatch(addUpdatePPDepartureAttachmentId(id))
        }
    }

    return (
        <div style={{ padding: '24px' }}>

            <div className="text-left pt-5 pb-2">
                <span className="input-label" style={{ fontSize: '16px', fontWeight: 700 }}>
                    Aggiungi PDF
                </span>
            </div>

            <div className="pb-5" style={{ borderBottomWidth: 1 }}>
                <FileInputComponent
                    id={"ep-edit-upload-add"}
                    key={"ep-edit-upload-add"}
                    infoText
                    fullHeight
                    permittedExtensions={['PDF']}
                    color={"green"}
                    onChange={(e) => {
                        dispatch(setFileToUpdate(e[0]))
                    }}
                />
                {
                    departureProtocolState.fileToUpdate && (
                        <div className="pt-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: '0.9' }}>
                                <FileViewer
                                    toUpdate
                                    fileName={departureProtocolState.fileToUpdate.name}
                                    otherInfo={departureProtocolState.fileToUpdate.type}
                                />
                            </div>
                            <div className="cursor-pointer" style={{ display: 'flex', flex: '0.1', justifyContent: 'center' }}
                                onClick={() => dispatch(setFileToUpdate(null))}>
                                <TrashIcon size={20} color="dimgrey" />
                            </div>
                        </div>
                    )
                }
                {
                    departureProtocolState.updatedFile && (
                        <div className="pt-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ flex: '0.9' }}>
                                <FileViewer
                                    fileId={objectStorageState.getFileObjectByIdResponse?.id}
                                    toUpdate={false}
                                    fileName={departureProtocolState.updatedFile.name}
                                    otherInfo={departureProtocolState.updatedFile.type}
                                />
                            </div>
                            <div className="cursor-pointer" style={{ display: 'flex', flex: '0.1', justifyContent: 'center' }}
                                onClick={() => dispatch(DepartureProtocolRemoveFile(departureProtocolState.departureProtocolIdToUpdate))}>
                                <TrashIcon size={20} color="dimgrey" />
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="text-left pb-3 pt-5">
                <span className="input-label" style={{ fontSize: '16px', fontWeight: 600 }}>
                    Allegati
                </span>
            </div>

            {
                departureProtocolState.departureProtocolUpdateRequest.departureAttachmentIds.map((att, index) => (
                    <div>
                        <div className='pb-2 pt-3' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                            <div style={{ flex: '0.9' }}>
                                <div className="text-left">
                                    <span className="input-label">{`Aggiungi allegato (Singolo)`}</span>
                                </div>
                                <SelectCustom
                                    value={att}
                                    placeholder={'Seleziona...'}
                                    options={attachments}
                                    disabledOptions={NoAvailableAttachments()}
                                    onChange={(value) => dispatch(setUpdatePPDepartureAttachmentIdsByIndex({ data: value, index: index }))}
                                />
                            </div>

                            <div style={{ flex: '0.05', alignSelf: 'flex-end', paddingBottom: '3px' }}>
                                <FileInputComponent
                                    id={"ep-edit-upload-add-3"}
                                    key={"ep-edit-upload-add-3"}
                                    color={"green"}
                                    iconSize
                                    disabled={documentUploadPredicate(index, att)}
                                    onChange={(e) => {
                                        dispatch(addPPFileAndAtt({file: e[0], attachmentId: att} as DepartureProtocolFileAndAttachmentRequestDTO))
                                    }}
                                />
                            </div>

                            <div style={{ flex: '0.05', alignSelf: 'flex-end', paddingBottom: '3px' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    icon={<TrashIcon size={20} color="red" />}
                                    onClick={() => handleOnDelete(att)}
                                    iconPosition="only"
                                    size="md"
                                />
                            </div>
                        </div>
                        {
                            departureProtocolState.departureProtocolUpdateFileAndAtt.find(obj => obj.attachmentId === att) && (
                                <div className="pb-2 pl-2 pt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: '1', maxHeight: '100px' }}>
                                        <FileViewer
                                            smallSize
                                            toUpdate
                                            fileName={departureProtocolState.departureProtocolUpdateFileAndAtt.find(obj => obj.attachmentId === att)?.file.name!}
                                            otherInfo={departureProtocolState.departureProtocolUpdateFileAndAtt.find(obj => obj.attachmentId === att)?.file.type!}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {
                            departureProtocolState.updatedDepartureProtocolFileAndAtts?.find(obj => obj.attachmentId === att) && (
                                <div className="pt-3" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: '1' }}>
                                        <FileViewer
                                            smallSize
                                            fileId={departureProtocolState.updatedDepartureProtocolFileAndAtts?.find(obj => obj.attachmentId === att)?.fileInfo.fileId!}
                                            fileName={departureProtocolState.updatedDepartureProtocolFileAndAtts?.find(obj => obj.attachmentId === att)?.fileInfo.name!}
                                            otherInfo={departureProtocolState.updatedDepartureProtocolFileAndAtts?.find(obj => obj.attachmentId === att)?.fileInfo.type!}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                ))
            }

            <div className="pb-2 pt-3">
                <Button
                    variant="outline"
                    color="dimgrey"
                    iconPosition="left"
                    icon={<PlusIcon color="orange" size={16} />}
                    size="sm"
                    label="Nuovo allegato"
                    onClick={() => dispatch(addDefaultUpdatePPDepartureAttachmentIds())}
                />
            </div>

            {
                userState.findMeResponse.role === 'admin' &&
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', justifyContent: 'flex-end', borderBottomWidth: 1 }}>
                    <Button
                        variant="outline"
                        color="orange"
                        iconPosition="left"
                        icon={<PlusIcon color="orange" size={16} />}
                        size="sm"
                        label="Nuovo tipo di allegato"
                        onClick={() => setShowPopup(true)}
                    />
                </div>

            }
            <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                <div style={{ flex: '0.8' }}>
                    <div className="text-left">
                        <span className="input-label">Inserisci più allegati</span>
                    </div>
                    <SelectCustom
                        placeholder={'Seleziona...'}
                        options={PPAttachmentGroupOptions}
                        onChange={(value) => setGroupValues(value!)}
                    />
                </div>
                <div style={{ flex: '0.2', alignSelf: 'flex-end', paddingBottom: '3px' }}>
                    <Button
                        variant="outline"
                        color="dimgrey"
                        iconPosition="off"
                        size="md"
                        label="Allega"
                        onClick={() => {
                            handleOnGroupSelect(groupValues)
                            setGroupValues(null)
                        }}
                    />
                </div>
            </div>

            {
                showPopup && (
                    <PopupForm title='Aggiungi Allegato' close={() => {
                        dispatch(resetPPAttCreationRequest())
                        setShowPopup(false)
                    }}>
                        <AddAttachmentPopupDeparture close={() => {
                            dispatch(resetPPAttCreationRequest())
                            setShowPopup(false)
                        }} />
                    </PopupForm>
                )
            }
        </div>
    )
}