import axios from "axios";
import { keycloak } from "../../lib/keycloak";
import { CreateTechnicianDTO, addTechinicianUserDTO } from "./dto";
import { BusinessTechnicianService } from "./service";

export class BusinessTechnicianServiceImpl implements BusinessTechnicianService {

    public CreateTechnicianCreation(data: CreateTechnicianDTO): Promise<void> {
        return axios.post("/api/business-technician",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }
    
    public GetBusinessTechnician(): Promise<any>{
        return axios.get("/api/business-technician", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetBusinessTechnicianById(id: string): Promise<any>{
        return axios.get("/api/business-technician/"+id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
    
    public EditBusinessTechnician(id: string, data: CreateTechnicianDTO ): Promise<any>{
        return axios.put("/api/business-technician/"+id, 
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data)
    }

}
