import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import Input from "../../ui/molecules/input";
import { SelectCustom } from "../../ui/molecules/select";
import { requisiti_certificazione, residuo_zero_type, tipologia_residuo_zero } from "../../utils";
import { setPercResiduoContr, setResiduoZeroReqAgg, setResiduoZeroType, setResiduoZeroTypology } from "../clienti/slice";
import { commonRightBarStyle } from "./style";

export function ResiduoZero() {
  const clientiState = useAppSelector((state) => state.clienti);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (clientiState.getCustomersByIdResponse !== undefined) {
      dispatch(setResiduoZeroType(clientiState.getCustomersByIdResponse.zrCertificationType));
      dispatch(setPercResiduoContr(clientiState.getCustomersByIdResponse.zrTargetThreshold))
      dispatch(setResiduoZeroReqAgg(clientiState.getCustomersByIdResponse.zrAddingRequirements))
      dispatch(setResiduoZeroTypology(clientiState.getCustomersByIdResponse.zrType))
    }
  }, [clientiState.getCustomersByIdResponseStatus]);

  return (
    <div>
      <div
        style={commonRightBarStyle}
      >
        <span className="input-label">Tipo di certificazione</span>
        <SelectCustom
          placeholder="Tipo certificazione"
          defaultValue={clientiState.clienteEditCreateRequest.zrCertificationType}
          value={clientiState.clienteEditCreateRequest.zrCertificationType}
          options={residuo_zero_type}
          onChange={(value) => {
            dispatch(setResiduoZeroType(value));
          }}
        />
        {clientiState.clienteEditCreateRequest.zrType === "RESIDUO_CONTROLLATO" && (
          <div style={{ textAlign: "left" }}>
            <span className="input-label">
              Soglia Target minima ≤ al 50% rispetto al LMR di legge
            </span>
            <Input
              onChangeText={(value) => dispatch(setPercResiduoContr(value))}
              value={clientiState.percResiduoContr}
              placeholder="Specificare la percentuale (%)"
            />
          </div>
        )}
      </div>

      <div
        className="borderTopSection mt-6"
        style={{...commonRightBarStyle,
          paddingTop: 15,
        }}
      >
        <span className="input-label">
          Requisito aggiuntivo
        </span>
        <SelectCustom
          placeholder="Requisito aggiuntivo"
          defaultValue={clientiState.clienteEditCreateRequest.zrAddingRequirements}
          value={clientiState.clienteEditCreateRequest.zrAddingRequirements}
          options={requisiti_certificazione}
          onChange={(value) => {
            dispatch(setResiduoZeroReqAgg(value));
          }}
        />
      </div>

      <div
        className="borderTopSection mt-6"
        style={{...commonRightBarStyle,
          paddingTop: 15,
        }}
      >
        <span className="input-label">
          Tipologia
        </span>
        <SelectCustom
          placeholder="Tipologia"
          defaultValue={clientiState.clienteEditCreateRequest.zrType}
          value={clientiState.clienteEditCreateRequest.zrType}
          options={tipologia_residuo_zero}
          onChange={(value) => {
            dispatch(setResiduoZeroTypology(value));
          }}
        />
      </div>

    </div>
  );
}
