import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { setAddress, setContactCity, setContactCountryRegion, setContactProvince, setContactZipCode } from "../../../../pages/clienti/slice";
import Input from "../../input";
import { SelectCustom } from "../../select";
import { OptionType, Province, Region, regioniProvinceMap, regions } from "../../../../utils";
import { provinceComuniMap } from "../../../../utils/listaComuni";

export function RappLegaleRecapiti() {
    const clientiState = useAppSelector(state => state.clienti)
    const dispatch = useAppDispatch()
    const [rpProvinceOptions, setRpProvinceOptions] = useState<OptionType[]>([]);
    const [selectedRpComune, setSelectedRpComune] = useState<OptionType | null>(null);
    const [rpComuneOptions, setRpComuneOptions] = useState<OptionType[]>([]);
    const [selectedRpRegion, setSelectedRpRegion] = useState<OptionType | null>(null);
    const [selectedRpProvince, setSelectedRpProvince] = useState<OptionType | null>(null);
    
    const handleRpRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRpRegion(selectedOption);
        setSelectedRpProvince(null); // Reset province when region changes
        setSelectedRpComune(null);
    };

    const handleRpProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedRpProvince(selectedOption);
        setSelectedRpComune(null);
    };

    const regionOptions: OptionType[] = Array.from(regioniProvinceMap.keys()).map(region => ({
        value: region,
        label: region
    }));

    useEffect(() => {
        if (clientiState.selectedRappLegale?.contactCountryRegion !== null) {
            const foundRegion = regions.find((region: Region) => region.name === clientiState.selectedRappLegale?.contactCountryRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setRpProvinceOptions(newProvinceOptions);
            } else {
                setRpProvinceOptions([]);
            }
        } else {
            setRpProvinceOptions([]);
        }

        if (clientiState.selectedRappLegale?.contactProvince !== null) {
            const foundRegion = regions.find((region: Region) => region.name === clientiState.selectedRappLegale?.contactProvince.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setRpProvinceOptions(newProvinceOptions);
            } else {
                setRpProvinceOptions([]);
            }
        } else {
            setRpProvinceOptions([]);
        }
    }, [])

    //regioni, province, comuni rp
    useEffect(() => {
        if (selectedRpRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRpRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setRpProvinceOptions(newProvinceOptions);
            } else {
                setRpProvinceOptions([]);
            }
        } else {
            setRpProvinceOptions([]);
        }
    }, [selectedRpRegion]);

    useEffect(() => {
        if (selectedRpProvince) {
            const comuni = provinceComuniMap.get(selectedRpProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setRpComuneOptions(newComuneOptions);
        } else {
            setRpComuneOptions([]);
        }
    }, [selectedRpProvince]);

  return (
    <div className="bg-brandPrimary-100">
      <div
        className="formRappLegale pt-3"
        style={{ display: "flex", flex: "wrap", gap: "24px", paddingTop: 5}}
      >
        <div style={{ flex: "0.25" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
            }}
          >
            <span className="input-label">Regione</span>
          </div>
          <SelectCustom
            error={clientiState.createLegalRappErrors.contactCountryRegion}
            errorLabel="Inserire Regione."
            defaultValue={
              clientiState.rappLegaleEditCreateRequest.contactCountryRegion
            }
            value={
                clientiState.rappLegaleEditCreateRequest.contactCountryRegion
              }
            placeholder={"Regione"}
            options={regionOptions}
            onChange={(value) => {
              handleRpRegionChange(value as unknown as OptionType | null);
              dispatch(setContactCountryRegion(value));
            }}
          />
        </div>
        <div style={{ flex: "0.25" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
            }}
          >
            <span className="input-label">Provincia</span>
          </div>
          <SelectCustom
            error={clientiState.createLegalRappErrors.contactProvince}
            errorLabel="Inserire Provincia."
            defaultValue={
              clientiState.rappLegaleEditCreateRequest.contactProvince
            }
            value={
                clientiState.rappLegaleEditCreateRequest.contactProvince
              }
            placeholder={"Provincia"}
            options={rpProvinceOptions}
            onChange={(value) => {
              handleRpProvinceChange(value as unknown as OptionType | null);
              dispatch(setContactProvince(value));
            }}
          />
        </div>
        <div style={{ flex: "0.25" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
            }}
          >
            <span className="input-label">Comune</span>
          </div>
          <SelectCustom
            error={clientiState.createLegalRappErrors.city}
            errorLabel="Inserire Comune."
            defaultValue={clientiState.rappLegaleEditCreateRequest.contactCity}
            value={clientiState.rappLegaleEditCreateRequest.contactCity}
            placeholder={"Comune"}
            options={rpComuneOptions}
            onChange={(value) => {
              dispatch(setContactCity(value));
            }}
          />
        </div>
        <div style={{ flex: "0.25", paddingBottom: 20 }}>
          <Input
            label={"CAP"}
            placeholder="CAP"
            onChangeText={(text) => setContactZipCode(text)}
            defaultValue={
              clientiState.rappLegaleEditCreateRequest.contactZipCode
            }
            value={
                clientiState.rappLegaleEditCreateRequest.contactZipCode
              }
          />
        </div>
      </div>
      <div
        className="formRappLegale"
        style={{ display: "flex", flex: "wrap", gap: "24px", paddingBottom: 20 }}
      >
        <div style={{ flex: "1 1 auto"}}>
          <Input
            supportingText={
              clientiState.createLegalRappErrors.contactAddress
                ? "Inserire indirizzo."
                : undefined
            }
            error={clientiState.createLegalRappErrors.contactAddress}
            label={"Indirizzo"}
            placeholder="Indirizzo"
            onChangeText={(text) => dispatch(setAddress(text))}
            defaultValue={
              clientiState.rappLegaleEditCreateRequest.contactAddress
            }
            value={
                clientiState.rappLegaleEditCreateRequest.contactAddress
              }
          />
        </div>
      </div>
    </div>
  );
}
