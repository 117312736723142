import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { EvaluationDTO, EvaluationErrorDTO, GetAllEvaluationsResponseDTO } from "./dataService/dto"
import { PromiseStatuses } from "../../../utils"
import { NewEvaluationService } from "./dataService/service"

interface ValutazioniState {
    evaluations: EvaluationDTO[],

    // REQUEST
    evaluationCreationRequest: EvaluationDTO,
    evaluationUpdateRequest: EvaluationDTO,
    evalIdToUpdate: string

    // REQUEST (ERROR)
    evaluationError: EvaluationErrorDTO

    // STATUS
    evaluationCreationStatus: PromiseStatuses
    evaluationUpdateStatus: PromiseStatuses
    evaluationDeleteStatus: PromiseStatuses
    getAllEvaluationStatus: PromiseStatuses
    getEvaluationByIdStatus: PromiseStatuses
    evaluationValidationStatus: PromiseStatuses

    // RESPONSE
    getAllEvaluationResponse?: GetAllEvaluationsResponseDTO
    getEvaluationByIdResponse?: EvaluationDTO
}

const initialState: ValutazioniState = {
    evaluations: [],

    // REQUEST
    evaluationCreationRequest: {
        evaluationDate: new Date(),
        evaluationActivityId: "",
        evaluatorId: "",
        result: "",
        note: "",
        personalManagementId: ''
    },
    evaluationUpdateRequest: {
        evaluationDate: new Date(),
        evaluationActivityId: "",
        evaluatorId: "",
        result: "",
        note: "",
    },
    evalIdToUpdate: '',

    // REQUEST (ERROR)
    evaluationError: {
        evaluationDate: false,
        evaluationActivityId: false,
        evaluatorId: false,
        result: false
    },

    // STATUS
    evaluationCreationStatus: 'idle',
    evaluationUpdateStatus: 'idle',
    evaluationDeleteStatus: 'idle',
    getAllEvaluationStatus: 'idle',
    getEvaluationByIdStatus: 'idle',
    evaluationValidationStatus: 'idle'

}

export const EvaluationCreation = createAsyncThunk(
    'PM/Evaluation/Creation',
    async (body: EvaluationDTO, thunkApi): Promise<void> => {
        const evaluationService = NewEvaluationService()

        return evaluationService.CreateEvaluation(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const EvaluationUpdate = createAsyncThunk(
    'PM/Evaluation/Update',
    async (request: {body: EvaluationDTO, id: string}, thunkApi): Promise<void> => {
        const evaluationService = NewEvaluationService()

        return evaluationService.UpdateEvaluation(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const DeleteEvaluation = createAsyncThunk(
    'PM/Evaluation/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const evaluationService = NewEvaluationService()

        return evaluationService.DeleteEvaluation(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetAllEvaluations = createAsyncThunk(
    'PM/Evaluation/GetAll',
    async (request, thunkApi): Promise<GetAllEvaluationsResponseDTO> => {
        const evaluationService = NewEvaluationService()

        return evaluationService.GetAllEvaluation().catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetEvaluationById = createAsyncThunk(
    'PM/Evaluation/GetById',
    async (id: string, thunkApi): Promise<EvaluationDTO> => {
        const evaluationService = NewEvaluationService()

        return evaluationService.GetEvaluationById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const EvaluationValidation = createAsyncThunk(
    'PM/Evaluation/Validate',
    async (request: EvaluationDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetEvaluationError())

        if(request.evaluationActivityId === '') {
            thunkApi.dispatch(setValidateUpdateEvaluationActivityId(true))
            isValid = false
        }

        if(request.evaluationDate === null) {
            thunkApi.dispatch(setValidateUpdateEvaluationDate(true))
            isValid = false
        }

        if(request.evaluatorId === '') {
            thunkApi.dispatch(setValidateUpdateEvaluatorId(true))
            isValid = false
        }
        
        if(request.result === '') {
            thunkApi.dispatch(setValidateUpdateResult(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)



const PMevaluations = createSlice({
    name: 'PM/evaluationSlice',
    initialState,
    reducers: {
        resetEvaluations: (state) => {
            state.evaluations = []
        },
        addEvaluations: (state, action) => {
            state.evaluations.push(action.payload)
        },
        setEvaluations: (state, action) => {
            state.evaluations = action.payload
        },

        // REQUEST
        setEvaluationDate: (state, action) => {
            state.evaluationCreationRequest.evaluationDate = action.payload
        },
        setEvaluationActivityId: (state, action) => {
            state.evaluationCreationRequest.evaluationActivityId = action.payload
        },
        setEvaluatorId: (state, action) => {
            state.evaluationCreationRequest.evaluatorId = action.payload
        },
        setResult: (state, action) => {
            state.evaluationCreationRequest.result = action.payload
        },
        setNote: (state, action) => {
            state.evaluationCreationRequest.note = action.payload
        },
        setPMId: (state, action) => {
            state.evaluationCreationRequest.personalManagementId = action.payload
        },
        resetEvaluationCreationRequest: (state) => {
            state.evaluationCreationRequest = {
                evaluationDate: new Date(),
                evaluationActivityId: "",
                evaluatorId: "",
                result: "",
                note: "",
                personalManagementId: state.evaluationCreationRequest.personalManagementId
            }
        },

        // REQUEST (UPDATE)
        setEvalIdToUpdate: (state, action) => {
            state.evalIdToUpdate = action.payload
        },
        setUpdateEvaluationDate: (state, action) => {
            state.evaluationUpdateRequest.evaluationDate = action.payload
        },
        setUpdateEvaluationActivityId: (state, action) => {
            state.evaluationUpdateRequest.evaluationActivityId = action.payload
        },
        setUpdateEvaluatorId: (state, action) => {
            state.evaluationUpdateRequest.evaluatorId = action.payload
        },
        setUpdateResult: (state, action) => {
            state.evaluationUpdateRequest.result = action.payload
        },
        setUpdateNote: (state, action) => {
            state.evaluationUpdateRequest.note = action.payload
        },
        resetEvaluationUpdateRequest: (state) => {
            state.evaluationUpdateRequest = {
                evaluationDate: new Date(),
                evaluationActivityId: "",
                evaluatorId: "",
                result: "",
                note: ""
            }
        },

        // REQUEST (ERRORS)
        setValidateUpdateEvaluationDate: (state, action) => {
            state.evaluationError.evaluationDate = action.payload
        },
        setValidateUpdateEvaluationActivityId: (state, action) => {
            state.evaluationError.evaluationActivityId = action.payload
        },
        setValidateUpdateEvaluatorId: (state, action) => {
            state.evaluationError.evaluatorId = action.payload
        },
        setValidateUpdateResult: (state, action) => {
            state.evaluationError.result = action.payload
        },
        resetEvaluationError: (state) => {
            state.evaluationError = {
                evaluationDate: false,
                evaluationActivityId: false,
                evaluatorId: false,
                result: false
            }
        },

        // STATUS
        resetEvaluationCreationStatus: (state) => {
            state.evaluationCreationStatus = 'idle'
        },
        resetEvaluationUpdateStatus: (state) => {
            state.evaluationUpdateStatus = 'idle'
        },
        resetEvaluationDeleteStatus: (state) => {
            state.evaluationDeleteStatus = 'idle'
        },
        resetGetAllEvaluationsStatus: (state) => {
            state.getAllEvaluationStatus = 'idle'
        },
        resetGetEvaluationByIdStatus: (state) => {
            state.getEvaluationByIdStatus = 'idle'
        },
        resetEvaluationValidationStatus: (state) => {
            state.evaluationValidationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder

            // GET ALL
            .addCase(GetAllEvaluations.pending, (state) => {
                state.getAllEvaluationStatus = 'loading'
            })
            .addCase(GetAllEvaluations.fulfilled, (state, action) => {
                state.getAllEvaluationStatus = 'successfully'
                state.getAllEvaluationResponse = action.payload
            })
            .addCase(GetAllEvaluations.rejected, (state) => {
                state.getAllEvaluationStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetEvaluationById.pending, (state) => {
                state.getEvaluationByIdStatus = 'loading'
            })
            .addCase(GetEvaluationById.fulfilled, (state, action) => {
                state.getEvaluationByIdStatus = 'successfully'
                state.getEvaluationByIdResponse = action.payload
            })
            .addCase(GetEvaluationById.rejected, (state) => {
                state.getEvaluationByIdStatus = 'failed'
            })

            // CREATION
            .addCase(EvaluationCreation.pending, (state) => {
                state.evaluationCreationStatus = 'loading'
            })
            .addCase(EvaluationCreation.fulfilled, (state) => {
                state.evaluationCreationStatus = 'successfully'
            })
            .addCase(EvaluationCreation.rejected, (state) => {
                state.evaluationCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(EvaluationUpdate.pending, (state) => {
                state.evaluationUpdateStatus = 'loading'
            })
            .addCase(EvaluationUpdate.fulfilled, (state) => {
                state.evaluationUpdateStatus = 'successfully'
            })
            .addCase(EvaluationUpdate.rejected, (state) => {
                state.evaluationUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(DeleteEvaluation.pending, (state) => {
                state.evaluationDeleteStatus = 'loading'
            })
            .addCase(DeleteEvaluation.fulfilled, (state) => {
                state.evaluationDeleteStatus = 'successfully'
            })
            .addCase(DeleteEvaluation.rejected, (state) => {
                state.evaluationDeleteStatus = 'failed'
            })

            // VALIDATION
            .addCase(EvaluationValidation.pending, (state) => {
                state.evaluationValidationStatus = 'loading'
            })
            .addCase(EvaluationValidation.fulfilled, (state) => {
                state.evaluationValidationStatus = 'successfully'
            })
            .addCase(EvaluationValidation.rejected, (state) => {
                state.evaluationValidationStatus = 'failed'
            })
    },
})

export const {
    setEvaluations,
    addEvaluations,
    resetEvaluations,

    // REQUEST
    setEvaluationActivityId,
    setEvaluationDate,
    setEvaluatorId,
    setNote,
    setResult,
    setPMId,
    resetEvaluationCreationRequest,

    // REQUEST (UPDATE)
    setEvalIdToUpdate,
    setUpdateEvaluationActivityId,
    setUpdateEvaluationDate,
    setUpdateEvaluatorId,
    setUpdateNote,
    setUpdateResult,
    resetEvaluationUpdateRequest,

    // REQUEST (ERRORS)
    setValidateUpdateEvaluationActivityId,
    setValidateUpdateEvaluationDate,
    setValidateUpdateEvaluatorId,
    setValidateUpdateResult,
    resetEvaluationError,

    // STATUS
    resetEvaluationCreationStatus,
    resetEvaluationUpdateStatus,
    resetEvaluationDeleteStatus,
    resetGetAllEvaluationsStatus,
    resetGetEvaluationByIdStatus,
    resetEvaluationValidationStatus

} = PMevaluations.actions

export default PMevaluations.reducer